import {
  IDocId2InsightsVariables,
  ISearchPoiVariables,
  IDocId2InsightsResponse,
  IDocId2InformationResponse,
  IDocId2InformationVariables,
  User,
  IVerifyResetPasswordRequestVariables,
  IVerifyResetPasswordRequestResponse,
  CommutePreference,
  IPoiByIdsVariables,
  IPoiByIdsResponse,
  IProject,
  Project,
  IBulletin,
  Bulletin,
  IPoiIdentifier,
  DatedPoi,
  ILocalPageResponse,
  InsightDescriptor,
  IReference,
  IUpdateSaveSearchResponse,
  IDeleteSaveSearchResponse,
  ILHomePageSearchTemporaryInterface,
  ILocalPageVariables,
  Priority,
  PoiId,
  IAd,
  DealType,
  UserSavedAddresses,
  IUploadBulletinInput,
  BulletinStatusType,
  IImageItem,
  IDataLayerItem,
  ILocalPagePreviewResponse,
  ITileRange,
  DataLayerPoiType,
  IDeal,
  IDocId2InfoUnitResponse,
  IClusterDataLayer,
  ISearchParametersWithoutSort,
  IDataLayer,
  PurchasingReason,
  IDocIds2InformationVariables,
  IDocIds2InformationResponse,
  IAutocompleteEntry,
  MadadDto,
  PoiType,
  ISearchLocalAddressPageResponse,
  IBannersByDocIdVariables,
  BannersByDocIdResponse,
  ITabuByDocIdVariables,
  TabuByDocIdResponse,
  IFooterByDocIdVariables,
  FooterByDocIdResponse,
  IUpdateListingInput,
  MadadParticipantsResponse,
  IMadadParticipantsVariables,
  IMadadAgentsOfficesVariables,
  MadadAgentsOfficesResponse,
  IMadadWinnersBannersByDocIdVariables,
  MadadWinnersBannersByDocIdResponse,
  ICommercialBulletin,
  ICommercialProject,
  IUpdateListingStatusInput,
  ListingStatusType,
  IEditListingPoiByIdsVariables,
  IUpdateCommercialListingInput,
  IUploadCommercialBulletinInput,
  IEditAssignedAgentCommercialListingInput,
  IEditCommercialListingPromotionStatusVariables,
  IAgentsOfOfficeByIdVariables,
  IAdminSearchVariables,
  IMadadWinnersBannersForStreetByDocIdVariables,
  IDeveloperProfile,
  ContactDeveloperReason,
  ICommercialLocalInfoResponse,
  Area,
  IOfficePageData,
  ITeamByOfficeIdData,
  IMortgageOffice,
  IAgentPageData,
  IAgentOfficeBulletinsData,
  RealEstateLevel,
  IOfficeAgentDisplayLevel,
  DeviceType,
  MarketplaceType,
  AwardCategories,
  Award,
  IAgentWithBulletinsData,
  LeadingAgentFeature,
  IOfficeData,
  IDealAffiliationRequest,
  ISearchDeal,
  ISearchNumberRange,
  ISearchDateRange,
  IAffiliationRequestResponseDeal,
  DealAffiliationType,
  IRequestDealAffiliationResponse,
  ICommercialListing,
  IEditListingPromotionStatusVariables,
  IEditAssignedAgentListingInput,
  IBaseAutocompleteDoc,
  LoginResponseNG,
  ResetPasswordStatus,
} from 'utils/entities';
import { FetchPolicy, ErrorPolicy } from 'apollo-client';
import { SourceType } from 'components/expanded-highlights-page/types';
import { UpdateFrequency } from 'components/saved-search/config';
import {
  Condition,
  Amenity,
  RangeTuple,
  PropertyType,
  RoomOption,
  Seller,
  BathOption,
} from 'components/filters/types';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { FeedbackPopupType } from 'components/common-feedback-popup-view/utils';
import { Topic, RatingTopic } from 'components/user-generated-content/utils/initialUGCConfig';
import { MoveIn } from 'store/state/homepageWizard';
import { ContactAgentModalSource } from 'store/state/app';

export type MutationResponse<T extends MutationType> = FetchResult<MutationResponseByType[T]> & {
  errors?: IError[];
};

export enum LoadType {
  AddressSearch = 'addressSearch',
  StreetSearch = 'streetSearch',
  SearchList = 'searchList',
  SearchMapClusters = 'searchMapClusters',
  SearchMapListings = 'searchMapListings',
  AddressesByDocIds = 'addressesByDocIds',
  SearchMapDataLayers = 'searchMapLayers',
  SearchMapDataLayersV3 = 'searchMapDataLayersV3',
  Insights = 'insights',
  CurrentUser = 'currentUser',
  VerifyResetPasswordRequest = 'verifyResetPasswordRequest',
  SearchDoc = 'searchDoc',
  AddressDoc = 'addressDoc',
  LocalDoc = 'localDoc',
  BusRouteByIds = 'busRouteByIds',
  LocalDocPreview = 'localDocPreview',
  StrictLocalDoc = 'strictLocalDoc', // required for ads, populated after local page docId2Information returns the
                                     // strict location boundary
  CommercialLocalInfo = 'commercialLocalInfo',
  AreasWithCommercialInfoPage = 'areasWithCommercialInfoPage',
  UnitPageBulletin = 'bulletinsByIds',
  UnitPageCommercialBulletin = 'commercialBulletinsByIds',
  MapPreview = 'mapPreview',
  MapMultiPreview = 'mapMultiPreview',
  ProjectPageProject = 'projectsByIds',
  ProjectPageCommercialProject = 'projectPageCommercialProject',
  ShortlistPois = 'shortlistPois',
  FavoritesPois = 'favoritesPois',
  IlHomePageBulletins = 'ilHomePageBulletins',
  SearchBySearchId = 'searchBySearchId',
  UserListings = 'usersListings',
  UserAllListings = 'usersAllListings',
  LocalData = 'localData',
  SearchHistoryNotifications = 'searchHistoryNotifications',
  SavedSearchesNotifications = 'savedSearchesNotifications',
  Deal = 'Deal',
  SearchProjectList = 'searchProjectList',
  StrictUnitDoc = 'strictUnitDoc',
  UserContent = 'userContent',
  UserContentTextReviews = 'userContentTextReviews',
  UserContentRatingReviews = 'userContentRatingReviews',
  Madad = 'madad',
  MadadAgentsOffices = 'madadAgentsOffices',
  MadadSearchAgentsOffices = 'searchMadadAgentsOffices',
  BannersByDocId = 'bannersByDocId',
  SearchPageBannersByDocId = 'searchPageBannersByDocId',
  AdditionalBannersByDocId = 'additionalBannersByDocId',
  MadadWinnersBannersByDocId = 'madadWinnersBannersByDocId',
  MadadWinnersBannersForStreetByDocId = 'madadWinnersBannersForStreetByDocId',
  MadadWinnersExistForDocId = 'madadWinnersExistForDocId',
  MadadWinnersExistForStreetForDocId = 'madadWinnersExistForStreetForDocId',
  SearchLocalAddressPage = 'searchLocalAddressPage',
  Tabu = 'Tabu',
  FooterByDocId = 'FooterByDocId',
  MadadParticipants = 'madadParticipants',
  HomePageBanner = 'homePageBanner',
  EditListingPoi = 'editListingPoi',
  MadadAgentBanner = 'MadadAgentBanner',
  AgentsOfOfficeById = 'agentsOfOfficeById',
  AdminSearch = 'adminSearch',
  ProjectMapLogo = 'projectMapLogo',
  IsPhoneRegistered = 'isPhoneRegistered',
  DeveloperById = 'developerById',
  SearchDevelopers = 'searchDevelopers',
  OfficeById = 'officeById',
  OfficeDataById = 'officeDataById',
  GetOfficeListings = 'getOfficeListings',
  OfficeBulletinsById = 'officeBulletinsById',
  AgentById = 'agentById',
  AgentWithBulletinsById = 'agentWithBulletinsById',
  AgentContactInfoById = 'agentContactInfoById',
  AgentBulletinsById = 'agentBulletinsById',
  TeamByOfficeId = 'teamByOfficeId',
  AdditionalCarouselSearch = 'additionalCarouselSearch',
  ListingPostLeadCarouselSearch = 'listingPostLeadCarouselSearch',
  MortgageOfficesByCityDocIds = 'mortgageOfficesByCityDocIds',
  MortgageOfficeById = 'mortgageOfficeById',
  DevelopersAndPromotedProjects = 'developersAndPromotedProjects',
  UserNotificationDevices = 'userNotificationDevices',
  SearchAwardsByIds = 'searchAwardsByIds',
  GetUnitPageLeadingData = 'getUnitPageLeadingData',
  SearchAwardsByDocIds = 'searchAwardsByDocIds',
  FeaturedMortgageOffice = 'featuredMortgageOffice',
  GetDealsAffiliationRequests = 'getDealsAffiliationRequests',
  SearchDeals = 'searchDeals',
  GetDealAffiliationRequestsBySaleId = 'getDealAffiliationRequestBySaleId',
  GetCommercialListing = 'getCommercialListing',
  GetResidentialListing = 'getResidentialListing',
  GetRecommendedListings = 'getRecommendedListings',
  GetResidentialAndCommercialListings = 'getResidentialAndCommercialListings',
  GetBulletinsUsageOverview = 'getBulletinsUsageOverview',
  GetAgentBulletinsUsageOverview = 'getAgentBulletinsUsageOverview',
  GetPublicRealEstateOfficesByIds = 'getPublicRealEstateOfficesByIds',
  GetPublicRealEstateAgentsByIds = 'getPublicRealEstateAgentsByIds',
}

export interface ILocalDataVariables {
  docId: string;
}

export enum UserTypeCommercial {
  user = 'USER',
  agent = 'AGENT',
}

export interface IUserListingsVariables {
  userToken?: string;
  includeResidential?: boolean;
  usersIds?: string[];
  userType?: UserTypeCommercial;
  timestamp?: number;
  offset?: number;
  limit?: number;
}

export interface IUserContentVariables {
  docId: string;
  userToken: string;
}

export interface IUserContentTextReviewsVariables {
  docId: string;
}

export interface IUserContentRatingReviewsVariables {
  docId: string;
}

interface IStandartDataLayerQuery {
  dataLayerType: DataLayerPoiType;
  query: null;
}

interface IListingsDataLayerQuery {
  dataLayerType: 'listingCluster';
  query: ISearchParametersWithoutSort;
}

type DataLayerQuery = IStandartDataLayerQuery | IListingsDataLayerQuery;

export interface IDataLayersVariables {
  tileRanges: ITileRange[];
  tileRangesExcl?: ITileRange[];
  zoom: number;
  dataLayerQuery: DataLayerQuery[];
  withTransportationAPI3?: boolean;
}

export interface IMadadVariables {
  userToken?: string;
}

export interface IHPBannerVariables {
  userToken?: string;
  useTimestamp?: boolean;
}

export interface IProjectMapLogoVariables {
  userToken?: string;
}

export interface ICommercialLocalInfoVariables {
  docId: string;
}

export interface IPhoneRegisteredVariables {
  userToken?: string;
  phoneNumber: string;
}

export interface IGenerateValidationCodeVariables {
  phoneNumber: string;
}

export interface ICheckValidationCodeVariables {
  phoneNumber: string;
  code: string;
}

export interface IDeveloperByIdVariables {
  id: string;
}
export interface IOfficeByIdVariables {
  id: string;
  level: RealEstateLevel;
}

export interface IAgentByIdVariables {
  id: string;
}

export interface IOfficeListingsVariables {
  officeId?: string;
  listingsType: MarketplaceType;
  limit: number;
  _cachePreventor?: number;
}

export interface ITeamByOfficeIdVariables {
  id: string;
  shuffle: boolean;
}

export interface IMortgageOfficeByIdVariables {
  query: { ids: string[]; };
}

export interface IDevelopersAndPromotedProjectsVariables {
  ids: string[];
}

export interface ISearchAwardsByIdsVariables {
  ids: string[];
  products?: AwardCategories[];
  features?: LeadingAgentFeature[];
  year?: number;
}

export interface ISearchAwardsByDocIdsVariables {
  docIds: string[];
  products?: AwardCategories[];
  features?: LeadingAgentFeature[];
  year?: number;
}

export interface ISearchDevelopersVariables {
  query: {
    cityDocId: string;
    offset?: number;
    limit?: number;
  };
}

export interface IMortgageOfficesByCityDocIdsVariables {
  query: {
    cityDocIds: string[];
    offset?: number;
    limit?: number;
  };
}

export enum SearchDealsSortColumn {
  Price = 'AMOUNT',
  PricePerSqM = 'PRICE_PER_METER',
  Rooms = 'NUM_ROOMS',
  Floor = 'FLOOR',
  Area = 'GROSS_AREA',
  DealDate = 'DEAL_DATE',
  BuildingYear = 'BUILDING_YEAR',
}

export interface ISearchDealVariables {
  userToken?: string;
  filters?: {
    docId?: string;
    price?: ISearchNumberRange;
    rooms?: ISearchNumberRange;
    date?: ISearchDateRange;
    parcelBlock?: {
      parcel: number;
      block: number;
    };
  };
  sort: {
    sortColumn: SearchDealsSortColumn | string;
    sortOrder: 'ASC' | 'DESC';
  };
  offset?: number;
  limit?: number;
}

export interface IGetDealAffiliationRequestsBySaleIdVariables {
  userToken?: string;
  saleId: string;
}

export interface IGetCommercialListingVariables {
  id: string;
}

export interface IGetResidentialListingVariables {
  id: string;
  userToken?: string;
}

interface ListingSortOptions {
  sortColumn: string;
  sortOrder: string;
}

export interface IGetResidentialAndCommercialListingsVariables {
  userToken?: string;
  sortOptions?: ListingSortOptions;
  offset?: number;
  limit?: number;
  filters?: {
    agents?: string[];
    textFilterValue?: string;
    textFilterType?: string;
  };
}

export interface IBusRouteByIdsVariables {
  busLineIds: string[];
}

export interface IGetRecommendedListingsVariables {
  userToken: string;
}

export interface IGetBulletinsUsageOverviewVariables {
  userToken?: string;
  agents?: string[];
}

export interface VariablesByType {
  [LoadType.GetPublicRealEstateAgentsByIds]: {
    agentsIds: string[];
  };
  [LoadType.GetPublicRealEstateOfficesByIds]: {
    officesIds: string[];
  };
  [LoadType.AddressSearch]: ISearchPoiVariables;
  [LoadType.StreetSearch]: ISearchPoiVariables;
  [LoadType.SearchProjectList]: ISearchPoiVariables;
  [LoadType.Insights]: IDocId2InsightsVariables;
  [LoadType.SearchList]: ISearchPoiVariables;
  [LoadType.SearchMapListings]: ISearchPoiVariables;
  [LoadType.SearchMapDataLayers]: IDataLayersVariables;
  [LoadType.SearchMapDataLayersV3]: IDataLayersVariables;
  [LoadType.SearchMapClusters]: IDataLayersVariables;
  [LoadType.CurrentUser]: {
    userToken?: string,
  };
  [LoadType.SavedSearchesNotifications]: {
    userToken?: string,
  };
  [LoadType.SearchHistoryNotifications]: {
    userToken?: string,
  };
  [LoadType.VerifyResetPasswordRequest]: IVerifyResetPasswordRequestVariables;
  [LoadType.SearchDoc]: IDocIds2InformationVariables;
  [LoadType.AddressDoc]: IDocId2InformationVariables;
  [LoadType.LocalDoc]: ILocalPageVariables;
  [LoadType.AddressesByDocIds]: { docIds: string[] };
  [LoadType.LocalDocPreview]: ILocalPageVariables;
  [LoadType.SearchLocalAddressPage]: ILocalPageVariables;
  [LoadType.StrictLocalDoc]: IDocId2InformationVariables;
  [LoadType.CommercialLocalInfo]: ICommercialLocalInfoVariables;
  [LoadType.AreasWithCommercialInfoPage]: {};
  [LoadType.StrictUnitDoc]: IDocId2InformationVariables;
  [LoadType.UnitPageBulletin]: IPoiByIdsVariables;
  [LoadType.UnitPageCommercialBulletin]: IPoiByIdsVariables;
  [LoadType.ProjectPageProject]: IPoiByIdsVariables;
  [LoadType.ProjectPageCommercialProject]: IPoiByIdsVariables;
  [LoadType.MapPreview]: IPoiByIdsVariables;
  [LoadType.MapMultiPreview]: IPoiByIdsVariables;
  [LoadType.ShortlistPois]: IPoiByIdsVariables;
  [LoadType.FavoritesPois]: IPoiByIdsVariables;
  [LoadType.EditListingPoi]: IEditListingPoiByIdsVariables;
  [LoadType.IlHomePageBulletins]: ILHomePageSearchTemporaryInterface;
  [LoadType.SearchBySearchId]: { searchId: string };
  [LoadType.UserListings]: IUserListingsVariables;
  [LoadType.UserAllListings]: Omit<IUserListingsVariables, 'limit' | 'offset'>;
  [LoadType.LocalData]: ILocalDataVariables;
  [LoadType.Deal]: IPoiByIdsVariables;
  [LoadType.UserContent]: IUserContentVariables;
  [LoadType.UserContentTextReviews]: IUserContentTextReviewsVariables;
  [LoadType.UserContentRatingReviews]: IUserContentRatingReviewsVariables;
  [LoadType.Madad]: IMadadVariables;
  [LoadType.HomePageBanner]: IHPBannerVariables;
  [LoadType.BannersByDocId]: IBannersByDocIdVariables;
  [LoadType.SearchPageBannersByDocId]: IBannersByDocIdVariables;
  [LoadType.AdditionalBannersByDocId]: IBannersByDocIdVariables;
  [LoadType.MadadWinnersBannersByDocId]: IMadadWinnersBannersByDocIdVariables;
  [LoadType.MadadWinnersBannersForStreetByDocId]: IMadadWinnersBannersForStreetByDocIdVariables;
  [LoadType.MadadWinnersExistForDocId]: IMadadWinnersBannersByDocIdVariables;
  [LoadType.MadadWinnersExistForStreetForDocId]: IMadadWinnersBannersForStreetByDocIdVariables;
  [LoadType.Tabu]: ITabuByDocIdVariables;
  [LoadType.FooterByDocId]: IFooterByDocIdVariables;
  [LoadType.MadadParticipants]: IMadadParticipantsVariables;
  [LoadType.MadadAgentsOffices]: IMadadAgentsOfficesVariables;
  [LoadType.MadadSearchAgentsOffices]: IMadadAgentsOfficesVariables;
  [LoadType.AgentsOfOfficeById]: IAgentsOfOfficeByIdVariables;
  [LoadType.AdminSearch]: IAdminSearchVariables;
  [LoadType.MadadAgentBanner]: {
    docId: string;
  };
  [LoadType.ProjectMapLogo]: IProjectMapLogoVariables;
  [LoadType.IsPhoneRegistered]: IPhoneRegisteredVariables;
  [LoadType.DeveloperById]: IDeveloperByIdVariables;
  [LoadType.SearchDevelopers]: ISearchDevelopersVariables;
  [LoadType.MortgageOfficesByCityDocIds]: IMortgageOfficesByCityDocIdsVariables;
  [LoadType.BusRouteByIds]: IBusRouteByIdsVariables;
  [LoadType.OfficeById]: IOfficeByIdVariables;
  [LoadType.OfficeDataById]: IOfficeByIdVariables;
  [LoadType.OfficeBulletinsById]: IOfficeByIdVariables;
  [LoadType.AgentById]: IAgentByIdVariables;
  [LoadType.AgentContactInfoById]: IAgentByIdVariables;
  [LoadType.GetOfficeListings]: IOfficeListingsVariables;
  [LoadType.AgentBulletinsById]: IAgentByIdVariables;
  [LoadType.AgentWithBulletinsById]: IAgentByIdVariables;
  [LoadType.TeamByOfficeId]: ITeamByOfficeIdVariables;
  [LoadType.AdditionalCarouselSearch]: ISearchPoiVariables;
  [LoadType.ListingPostLeadCarouselSearch]: ISearchPoiVariables;
  [LoadType.MortgageOfficeById]: IMortgageOfficeByIdVariables;
  [LoadType.DevelopersAndPromotedProjects]: IDevelopersAndPromotedProjectsVariables;
  [LoadType.SearchAwardsByIds]: {
    args: ISearchAwardsByIdsVariables;
  };
  [LoadType.GetUnitPageLeadingData]: {
    args: ISearchAwardsByIdsVariables;
  };
  [LoadType.SearchAwardsByDocIds]: {
    args: ISearchAwardsByDocIdsVariables;
  };
  [LoadType.UserNotificationDevices]: {
    // not a real variable, used to avoid caching of the query
    __uid: string;
  };
  [LoadType.GetDealsAffiliationRequests]: { userToken?: string; };
  [LoadType.SearchDeals]: ISearchDealVariables;
  [LoadType.GetDealAffiliationRequestsBySaleId]: IGetDealAffiliationRequestsBySaleIdVariables;
  [LoadType.GetCommercialListing] : IGetCommercialListingVariables;
  [LoadType.GetResidentialListing] : IGetResidentialListingVariables;
  [LoadType.FeaturedMortgageOffice]: {};
  [LoadType.GetResidentialAndCommercialListings]: IGetResidentialAndCommercialListingsVariables;
  [LoadType.GetRecommendedListings]: IGetRecommendedListingsVariables;
  [LoadType.GetBulletinsUsageOverview]: IGetBulletinsUsageOverviewVariables;
  [LoadType.GetAgentBulletinsUsageOverview]: IGetBulletinsUsageOverviewVariables;
}

export type MapPreviewProjectFields = 'id' | keyof IProject;
export type MapPreviewCommercialProjectFields = 'id' | keyof ICommercialProject;

export type MapPreviewBulletinFields =
  'id'
  | 'type'
  | 'addressDetails'
  | 'price'
  | 'dealType'
  | 'images'
  | 'firstTimeSeen'
  | 'lastUpdated'
  | 'buildingClass'
  | 'area'
  | 'beds'
  | 'poc'
  | 'insights';

export type MapPreviewCommercialBulletinFields =
  'id'
  | 'type'
  | 'addressDetails'
  | 'price'
  | 'dealType'
  | 'images'
  | 'firstTimeSeen'
  | 'lastUpdated'
  | 'buildingClass'
  | 'area'
  | 'rooms'
  | 'poc'
  | 'insights';

export type SearchMapBulletinFields =
  'locationPoint'
  | 'price'
  | 'id'
  | 'addressDetails'
  | 'type'
  | 'firstTimeSeen'
  | 'poc';
export type SearchMapProjectFields =
  'locationPoint'
  | 'id'
  | 'addressDetails'
  | 'type'
  | 'promotionStatus'
  | 'firstTimeSeen';


export interface ICurrentUserResponse {
  me: User;
}

export type IlHomePageBulletinsResponse = {
  [key: string]: {
    poi: Array<IBulletin | IProject>;
  },
} & {
  totalBulletinsSection1: { total: number },
  totalBulletinsSection2: { total: number },
  totalBulletinsSection3: { total: number },
};

export interface ISearchBySearchIdResponse {
  searchBySearchId: ISaveSearch;
}

export interface IUserListingsResponse {
  getUsersListings: {
    residential: IBulletin[];
    commercial: ICommercialBulletin[];
  };
}

export interface AgentsOfOfficeByIdResponse {
  getAllAgentsInOffice: AgentOfOfficeById[];
}

export interface ILocalDataResponse {
  ppa?: number;
  yearNumberOfDeals?: number;
  document: IAutocompleteEntry;
}

export interface MadadAgentBannerItem {
  imageUrl: string;
  officeName: string;
  agentName: string;
  url: string;
  city?: string;
}

export interface MadadAgentBannerResponse {
  getMadadAgentBanner: MadadAgentBannerItem;
}


export interface AdminSearResponse {
  userListingsWithOffices: {
    listings: IUserListingsResponse['getUsersListings'];
    agentsInOffice: AgentsOfOfficeByIdResponse['getAllAgentsInOffice'];
  };
}

export interface AgentOfOfficeById {
  agentId: string;
  userId: string;
  name: string;
  officeId: string;
}

export type ISearchNotificationJar = Pick<ISaveSearch, 'searchId' | 'searchPreviews' | 'notificationsCount'>;

export interface IDataLayersResponse<T extends IDataLayer> {
  searchDataLayers?: {
    poi: T[];
  };

  [z: number]: Record<PoiId, T>;
}


interface BusStationLayerV3 {
  id: string;
  geometry: {
    type: 'POINT';
    coordinates: number[];
  };
  type: 'busStation';
  busStopName: string;
  busLines: Array<{
    id: string;
    name: string;
  }>;
}

interface TrainStationLayerV3 {
  id: string;
  geometry: {
    type: 'POINT';
    coordinates: number[];
  };
  type: 'trainStation';
  name: string;
  address: string;
}

type DataLayersV3 = BusStationLayerV3 | TrainStationLayerV3;

export interface IDataLayersV3Response {
  searchDataLayers?: DataLayersV3[];
}

export interface UserNotificationDevice {
  deviceId: string;
  deviceType: DeviceType;
}

export interface IUserNotificationDeviceResponse {
  getNotificationDevices: UserNotificationDevice[];
}

export interface ISearchAwardsByIdsResponse {
  searchAwardsByIds: Award[];
}
export interface ISearchAwardsByDocIdsResponse {
  searchAwardsByDocIds: Award[];
}

export interface RecommendedBulletin {
  bulletin: Bulletin;
  recommendationVersion: string;
}

export  interface RecommendedProject {
  project: Project;
  recommendationVersion: string;
}

export type RecommendedListing = RecommendedBulletin | RecommendedProject;

export interface IGetRecommendedListingsResponse {
  listings: RecommendedListing[];
  errorReason?: string;
}

export interface GetBulletinsUsageOverviewResponse {
  totalPresentedBulletins: number;
  totalBulletinsCredit: number;
  totalPromotedBulletins: number;
  totalPromotedCredit: number;
  totalFrozenBulletins: number;
}

export interface IGetPublicRealEstateAgentsByIdsResponseItem {
  agentId: string;
}

export interface IGetPublicRealEstateOfficesByIdsResponseItem {
  officeId: string;
}

export interface ResponseByType {
  [LoadType.GetPublicRealEstateAgentsByIds]: {
    getPublicRealEstateAgentsByIds: IGetPublicRealEstateAgentsByIdsResponseItem[];
  };
  [LoadType.GetPublicRealEstateOfficesByIds]: {
    getPublicRealEstateOfficesByIds: IGetPublicRealEstateOfficesByIdsResponseItem[];
  };
  [LoadType.AddressSearch]: {
    searchPoiV2: {
      poi: Array<IBulletin | IProject>;
      cursor?: unknown;
      total?: number;
    };
  };
  [LoadType.StreetSearch]: {
    poiByDocId: {
      poi: Array<IBulletin | IProject>;
      cursor?: unknown;
      total?: number;
    };
  };
  [LoadType.SearchProjectList]: {
    searchPoiV2: {
      poi: Array<IBulletin | IProject>;
    },
  };
  [LoadType.Insights]: IDocId2InsightsResponse;
  [LoadType.SearchList]: {
    searchPoiV2: {
      poi: Array<IBulletin | IProject | ICommercialProject | ICommercialBulletin | IAd>;
      cursor?: Cursor;
      total?: number;
      totalNearby?: number;
      lastInGeometryId?: string;
    };
  };
  [LoadType.AddressesByDocIds]: {
    addressesByDocIds: IBaseAutocompleteDoc[];
  };
  [LoadType.SearchMapClusters]: IDataLayersResponse<IClusterDataLayer>;
  [LoadType.SearchMapDataLayers]: IDataLayersResponse<IDataLayerItem>;
  [LoadType.SearchMapDataLayersV3]: IDataLayersV3Response;
  [LoadType.SearchMapListings]: {
    searchPoiV2: {
      poi: Array<Pick<IBulletin, SearchMapBulletinFields> | Pick<IProject, SearchMapProjectFields> | Pick<ICommercialBulletin, SearchMapBulletinFields> | Pick<ICommercialProject, SearchMapProjectFields>>,
    };
  };
  [LoadType.SearchHistoryNotifications]: {
    me: {
      searchHistory: ISearchNotificationJar[];
    },
  };
  [LoadType.SavedSearchesNotifications]: {
    me: {
      savedSearches: ISearchNotificationJar[];
    },
  };
  [LoadType.CurrentUser]: ICurrentUserResponse;
  [LoadType.VerifyResetPasswordRequest]: IVerifyResetPasswordRequestResponse;
  [LoadType.SearchDoc]: IDocIds2InformationResponse;
  [LoadType.AddressDoc]: IDocId2InformationResponse;
  [LoadType.UnitPageBulletin]: IPoiByIdsResponse<IBulletin>;
  [LoadType.UnitPageCommercialBulletin]: IPoiByIdsResponse<ICommercialBulletin>;
  [LoadType.ProjectPageProject]: IPoiByIdsResponse<IProject>;
  [LoadType.ProjectPageCommercialProject]: IPoiByIdsResponse<ICommercialProject>;
  [LoadType.Deal]: {
    poiByIds: IDeal[];
  };
  [LoadType.MapPreview]: IPoiByIdsResponse<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
  [LoadType.MapMultiPreview]: IPoiByIdsResponse<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
  [LoadType.ShortlistPois]: IPoiByIdsResponse<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
  [LoadType.FavoritesPois]: IPoiByIdsResponse<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
  [LoadType.EditListingPoi]: IPoiByIdsResponse<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
  [LoadType.LocalDoc]: ILocalPageResponse;
  [LoadType.LocalDocPreview]: ILocalPagePreviewResponse;
  [LoadType.SearchLocalAddressPage]: ISearchLocalAddressPageResponse;
  [LoadType.StrictLocalDoc]: IDocId2InformationResponse;
  [LoadType.CommercialLocalInfo]: ICommercialLocalInfoResponse;
  [LoadType.AreasWithCommercialInfoPage]: {
    getAreasWithCommercialInfoPage: Area[],
  };
  [LoadType.StrictUnitDoc]: IDocId2InfoUnitResponse;
  [LoadType.IlHomePageBulletins]: IlHomePageBulletinsResponse;
  [LoadType.SearchBySearchId]: ISearchBySearchIdResponse;
  [LoadType.UserListings]: IUserListingsResponse;
  [LoadType.UserAllListings]: IUserListingsResponse;
  [LoadType.LocalData]: {
    docId2Information: ILocalDataResponse;
  };
  [LoadType.UserContent]: {
    userContent: InputUGCEntry[];
  };
  [LoadType.UserContentTextReviews]: {
    docId2TextReviews: IUserTextReviewResponse[];
  };
  [LoadType.UserContentRatingReviews]: {
    docId2NumericReviews: IUserRatingReviewResponse;
  };
  [LoadType.Madad]: MadadResponse;
  [LoadType.BannersByDocId]: {
    bannersByDocId: BannersByDocIdResponse[];
  };
  [LoadType.SearchPageBannersByDocId]: {
    bannersByDocId: BannersByDocIdResponse[];
  };
  [LoadType.AdditionalBannersByDocId]: {
    bannersByDocId: BannersByDocIdResponse[];
  };
  [LoadType.MadadWinnersBannersByDocId]: {
    madadWinnersBanners: MadadWinnersBannersByDocIdResponse[];
  };
  [LoadType.MadadWinnersBannersForStreetByDocId]: {
    madadWinnersBanners: MadadWinnersBannersByDocIdResponse[];
  };
  [LoadType.MadadWinnersExistForDocId]: {
    madadWinnersExist: boolean;
  };
  [LoadType.MadadWinnersExistForStreetForDocId]: {
    madadWinnersExist: boolean;
  };
  [LoadType.Tabu]: TabuByDocIdResponse;
  [LoadType.HomePageBanner]: HPBannerResponse;
  [LoadType.FooterByDocId]: {
    footerByDocId: FooterByDocIdResponse;
  };
  [LoadType.MadadParticipants]: {
    madadParticipants: MadadParticipantsResponse;
  };
  [LoadType.MadadAgentsOffices]: {
    madadAgentsOffices: MadadAgentsOfficesResponse;
  };
  [LoadType.MadadSearchAgentsOffices]: {
    madadAgentsOffices: MadadAgentsOfficesResponse;
  };
  [LoadType.MadadAgentBanner]: MadadAgentBannerResponse;
  [LoadType.AgentsOfOfficeById]: AgentsOfOfficeByIdResponse;
  [LoadType.AdminSearch]: AdminSearResponse;
  [LoadType.ProjectMapLogo]: ProjectMapLogoResponse;
  [LoadType.IsPhoneRegistered]: {
    isPhoneRegistered: boolean;
  };
  [LoadType.DeveloperById]: DeveloperByIdResponse;
  [LoadType.SearchDevelopers]: SearchDevelopersResponse;
  [LoadType.MortgageOfficesByCityDocIds]: MortgagOfficesByCityDocIdsResponse;
  [LoadType.BusRouteByIds]: BusRouteByIdsResponse;
  [LoadType.OfficeById]: OfficeByIdResponse;
  [LoadType.OfficeDataById]: OfficeDataByIdResponse;
  [LoadType.OfficeBulletinsById]: OfficeBulletinsByIdResponse;
  [LoadType.AgentById]: AgentByIdResponse;
  [LoadType.AgentWithBulletinsById]: AgentWithBulletinsByIdResponse;
  [LoadType.AgentContactInfoById]: AgentByIdResponse;
  [LoadType.AgentBulletinsById]: AgentBulletinsByIdResponse;
  [LoadType.TeamByOfficeId]: TeamByOfficeIdResponse;
  [LoadType.AdditionalCarouselSearch]: {
    searchPoiV2: {
      poi: Array<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
    },
  };
  [LoadType.ListingPostLeadCarouselSearch]: {
    searchPoiV2: {
      poi: Array<IBulletin | IProject | ICommercialProject | ICommercialBulletin>;
    },
  };
  [LoadType.MortgageOfficeById]: MortgageOfficeByIdResponse;
  [LoadType.GetOfficeListings]: OfficeListingsResponse;
  [LoadType.DevelopersAndPromotedProjects]: IDevelopersWithPromotedProjectsResponse;
  [LoadType.UserNotificationDevices]: IUserNotificationDeviceResponse;
  [LoadType.SearchAwardsByIds]: ISearchAwardsByIdsResponse;
  [LoadType.GetUnitPageLeadingData]: ISearchAwardsByIdsResponse;
  [LoadType.SearchAwardsByDocIds]: ISearchAwardsByDocIdsResponse;
  [LoadType.FeaturedMortgageOffice]: {
    getRandomFeaturedMortgageAdvisorsOffice: IMortgageOffice | null;
  };
  [LoadType.GetDealsAffiliationRequests]: GetDealsAffiliationRequestsResponse;
  [LoadType.SearchDeals]: SearchDealsResponse;
  [LoadType.GetDealAffiliationRequestsBySaleId]: GetDealAffiliationRequestsBySaleIdResponse;
  [LoadType.GetCommercialListing] : GetCommercialListingResponse;
  [LoadType.GetResidentialListing]: GetResidentialListingResponse;
  [LoadType.GetRecommendedListings]: {
    getRecommendedListings: IGetRecommendedListingsResponse;
  };
  [LoadType.GetResidentialAndCommercialListings]: GetResidentialAndCommercialListingsResponse;
  [LoadType.GetBulletinsUsageOverview]: {
    getBulletinsUsageOverview: GetBulletinsUsageOverviewResponse;
  };
  [LoadType.GetAgentBulletinsUsageOverview]: {
    getBulletinsUsageOverview: GetBulletinsUsageOverviewResponse;
  };
}


export interface LoadOptions<T extends LoadType> {
  loadType: T;
  meta: {
    initialize?: boolean;
    variables: VariablesByType[T];
    wasSSRHydrated?: boolean;
    /** handle with care */
    __fetchPolicy?: FetchPolicy;
    errorPolicy?: ErrorPolicy;
  };
}

export interface MutationOptionsMeta<T extends MutationType> {
  key?: string;
  variables: MutationVariablesByType[T];
  errorPolicy?: ErrorPolicy;
}

export interface MutationOptions<T extends MutationType> {
  mutationType: T;
  meta: MutationOptionsMeta<T>;
}

export enum MutationType {
  FacebookLogin = 'facebookLogin',
  GoogleLogin = 'googleLogin',
  LoginWithEmailAndPassword = 'loginWithEmailAndPassword',
  SignInSignUpWithEmail = 'SignInSignUpWithEmail',
  RegisterVisitor = 'registerVisitor',
  TempTokenAuth = 'TempTokenAuth',
  RequestPasswordReset = 'requestPasswordReset',
  ResetPasswordUpdate = 'resetPasswordUpdate',
  UpdateUserProfile = 'updateUserProfile',
  ContactPageSubmit = 'contactPageSubmit',
  AgentOfficeContactPageSubmit = 'agentOfficeContactPageSubmit',
  ContactV4 = 'contactV4',
  ContactV4MortgageOffice = 'contactV4MortgageOffice',
  AddFavorites = 'addFavorites',
  RemoveFavorites = 'removeFavorites',
  AddContactAgent = 'addContactAgent',
  AddRecentlyViewed = 'addRecentlyViewed',
  SendFeedback = 'sendFeedback',
  SaveSearchQuery = 'saveSearchQuery',
  DeleteSaveSearch = 'deleteSearchQuery',
  SaveAddress = 'saveAddress',
  DeleteAddress = 'deleteAddress',
  SavedSearchUnsubscribe = 'savedSearchUnsubscribe',
  NewListing = 'newListing',
  EditListing = 'editListing',
  NewCommercialListing = 'newCommercialListing',
  EditCommercialListing = 'editCommercialListing',
  EditListingStatus = 'editListingStatus',
  EditCommercialListingStatus = 'editCommercialListingStatus',
  EditCommercialListingAssignedAgent = 'editCommercialListingAssignedAgent',
  EditCommercialListingPromotionStatus = 'editCommercialListingPromotionStatus',
  EditListingPromotionStatus = 'editListingPromotionStatus',
  UploadBulletinBadAddressReport = 'uploadBulletinBadAddressReport',
  EditBulletinStatus = 'editBulletinStatus',
  SaveRecentSearch = 'saveRecentSearch',
  AddUGC = 'addUGC',
  AddUGCEmotion = 'addUGCEmotion',
  FeedbackUGC = 'feedbackUGC',
  SaveUserData = 'saveUserData',
  LoginPersonalAssistant = 'loginPersonalAssistant',
  GenerateValidationCode = 'generateValidationCode',
  CheckValidationCode = 'checkValidationCode',
  SubmitContactMadlanLead = 'submitContactMadlanLead',
  SubmitDeveloperLead = 'submitDeveloperLead',
  DeregisterUser = 'deregisterUser',
  GetArticles = 'getArticles',
  AddNotificationDevice = 'addNotificationDevice',
  RemoveNotificationDevice = 'removeNotificationDevice',
  EditDealAffiliationRequest = 'editDealAffiliationRequest',
  RequestDealAffiliation = 'requestDealAffiliation',
  EditListingAssignedAgent = 'editListingAssignedAgent',
  AcceptConsents = 'acceptConsents',
  RemoveMarketingConsent = 'removeMarketingConsent',
}

export interface ILocation {
  line: number;
  column: number;
}

export enum AuthError {
  LoginFailed = 'login-failed',
  EmailAlreadyExist = 'email-already-exists',
  InvalidRegistrationType = 'invalid-registration-type',
  NeedPassword = 'need-password',
  NeedPasswordReset = 'need-password-reset',
  NoNeedToRegister = 'no-need-to-register',
  InvalidAgentRegistration = 'invalid-agent-registration',
  DeletedUser = 'Deleted user with same email',
  ConfirmConsentsV2 = 'User must approve terms and conditions',
}

export enum InvalidRegistrationType {
  Email = 'email-already-exists',
  Native = 'native-already-exists',
  Facebook = 'facebook-already-exists',
  Google = 'google-already-exists',
}

export interface IError extends GraphQLError {
  email: string;
  reason: AuthError;
  message: InvalidRegistrationType;
  locations: ILocation[];
  arguments: any;
  queryPath: string[];
}

export interface LoginResponse {
  user: User;
  token: string;
  signedUp: boolean;
}

type LoginQueryResponseNG<T extends string> = {
  [key in T]: LoginResponseNG;
};

export interface IFacebookLoginVariables {
  fbUserId: string;
  email: string;
  accessToken: string;
  consents?: IConsent[];
}

export interface IGoogleLoginVariables {
  idToken: string;
  consents?: IConsent[];
}

export interface ILoginWithEmailAndPasswordVariables {
  email: string;
  password: string;
  consents?: IConsent[];
}

export enum ConsentType {
  TC = 'termsAndConditions',
  Marketing = 'marketing',
}

export interface IConsentData {
  type: ConsentType;
  approval: boolean;
}

export enum ConsentTypeNG {
  MARKETING = 'MARKETING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  LEAD_TERMS_AND_CONDITIONS = 'LEAD_TERMS_AND_CONDITIONS',
  LISTING_UPLOAD_TERMS_AND_CONDITIONS = 'LISTING_UPLOAD_TERMS_AND_CONDITIONS',
}

export interface IConsent {
  type: ConsentTypeNG;
  approval: boolean;
}

export interface ISignInSignUpWithEmailVariables {
  email: string;
  userToken?: string;
  consents?: IConsent[];
}

interface IRequestPasswordResetResponse {
  requestPasswordReset?: {
    status: ResetPasswordStatus;
  };
}

export interface IRequestPasswordResetVariables {
  email: string;
}

export interface IResetPasswordUpdateResponse {
  resetPasswordUpdate?: {
    status: ResetPasswordStatus;
  };
}

export interface IResetPasswordUpdateVariables {
  token: string;
  newPassword: string;
}

export interface IUpdateUserProfileVariables {
  commutePreference?: CommutePreference;
  firstName?: string;
  lastName?: string;
  phone?: string;
  userInfo?: {
    moveIn?: MoveIn;
  };
}

export interface IFormFields {
  name?: string;
  message: string;
  phone: string;
  email?: string;
}

export interface UgcTextReviewEntry {
  topic: Topic.TextReview;
  textReview: string;
}

export interface UgcRatingReviewEntry {
  topic: RatingTopic;
  numericReview: number;
}

export type InputUGCEntry = UgcTextReviewEntry | UgcRatingReviewEntry;

export interface UGCEmotionsEntryByUser {
  emotions: number[];
  userEmotion: number;
}

export interface IUserTextReviewResponse {
  date: string;
  photo: string;
  textReview: string;
  topic: Topic.TextReview;
  ugcId: string;
  userName: string;
  emotions: UGCEmotionsEntryByUser | null;
}

interface IRatingReviewProps {
  numericReview: number;
  topic: RatingTopic;
}

export interface IUserRatingReviewResponse {
  basedOn: number;
  reviews: IRatingReviewProps[];
}

export interface AddUGCVariables {
  docId: string;
  email?: string;
  name?: string;
  numericUserInput: UgcRatingReviewEntry[];
  textUserInput: UgcTextReviewEntry[];
  userToken?: string;
}

export interface AddUGCEmotionVariables {
  isAddEmotion: boolean;
  ugcId: string;
  userEmotion: number;
  userToken?: string;
}

interface FeedbackUGCVariables {
  ugcId: string;
  address: string;
  email: string;
  feedback?: string;
  feedbackType?: string;
  message: string;
}

interface SaveUserDataVariables {
  priorities: Priority[];
  purchasePurpose: PurchasingReason;
  userToken?: string;
  docId: string;
  filters?: InputSavedSearchQuery;
}

interface InputSavedSearchQuery {
  dealType: DealType;
  roomsRange: RangeTuple<RoomOption>;
  priceRange: RangeTuple<number>;
  amenities: Partial<Record<Amenity, boolean>>;
}

export interface ContactV4Variables {
  poiId?: string;
  phone: string;
  message?: string;
  type: LeadType;
  userToken?: string;
  additionalData?: AdditionalData;
  name?: string;
  email?: string;
  refererId?: {
    id: string;
    type: string;
  };
  leadTermsAccepted?: boolean;
}

export interface ContactV4MortgageOfficeVariables {
  poiId: string;
  phone: string;
  type: LeadType;
  userToken?: string;
  additionalData?: {
    officeEmail?: string;
    officeName?: string;
  };
  name?: string;
  email?: string;
  leadTermsAccepted?: boolean;
}

export enum AdditionalDataPageType {
  NewOfficePage = 'newOfficePage',
  NewAgentPage = 'newAgentPage',
}

interface AdditionalData {
  dealType: DealType;
  amenities: Partial<Record<Amenity, boolean>>;
  priceRange: RangeTuple<number>;
  roomsRange: RangeTuple<RoomOption>;
  purchasePurpose: PurchasingReason;
  docId: string;
  officeEmail?: string;
  officeName?: string;
  pageType?: AdditionalDataPageType;
}

export enum LeadType {
  Project = 'project',
  Bulletin = 'bulletin',
  CommercialProject = 'commercialProject',
  CommercialBulletin = 'commercialBulletin',
  Ad = 'ad',
  Deal = 'deal',
  AccompanyingPurchasers = 'accompanyingPurchasers',
  Office = 'realEstateOffice',
  Agent = 'realEstateAgent',
  MortgageConsult = 'mortgageConsult',
  MortgageOffice = 'mortgageAdvisorsOffice',
}

export interface ISaveSearchQueryProp {
  dealType: DealType;
  location: IReference;
  amenities: Partial<Record<Amenity, boolean>>;
  areaRange: RangeTuple<number>;
  monthlyTaxRange: RangeTuple<number>;
  bathsRange: RangeTuple<BathOption>;
  generalCondition: Condition[];
  noFee: boolean;
  floorRange: RangeTuple<number>;
  priceRange: RangeTuple<number>;
  ppmRange: RangeTuple<number>;
  buildingClass: PropertyType[];
  roomsRange: RangeTuple<RoomOption>;
  sellerType: Seller[];
  notificationsCount?: number;
}


export interface ISaveSearchFromState {
  title?: string;
  term?: string;
  query?: ISaveSearchQueryProp;
  locationDocIds?: string[];
}

export interface ISaveSearch extends ISaveSearchFromState {
  updateFrequency: UpdateFrequency;
  searchId: string;
  notificationsCount?: number;
  searchPreviews?: IImageItem[];
}

export interface IAgentInOffice {
  agentName: string;
  userId: string;
  agentId: string;
  officeId?: string;
  officeName?: string;
}

export enum IAgentStatusType {
  Approved = 'approved',
  Unapproved = 'unapproved',
}

export enum PricingTier {
  Standard = 'standard',
  Discounted = 'discounted',
}

export enum PricingTierApi3 {
  Standard = 'STANDARD',
  Discounted = 'DISCOUNTED',
}

export interface IAgentStatus {
  agentId: string;
  officeId: string;
  status: IAgentStatusType;
  displayLevel: IOfficeAgentDisplayLevel;
  pricingTier: PricingTier;
}

interface AddUGCResponse {
  addUgc: boolean;
}

export interface ContactV4Response {
  contactV4: {
    lead: {
      leadId: string;
    };
  };
}

interface AddUGCEmotionResponse {
  addUgcEmotion: {
    emotions: number[];
    userEmotion?: number;
  };
}

interface FeedbackUGCResponse {
  feedbackV2: boolean;
}

export type IUpdateSaveSearch = Pick<ISaveSearch, 'searchId' | 'updateFrequency' | 'term'>;

export interface ISaveSearchQueryVariables {
  search: Partial<ISaveSearch>;
}

export interface IDeleteSaveSearchQueryVariables {
  searchId: string;
}

export interface IContactPageSubmitVariables {
  id?: string;
  email?: string;
  message?: string;
  isMortgageConsult?: boolean;
  name?: string;
  phone: string;
  type: LeadType | PoiType;
  userToken?: string;
  additionalData?: AdditionalData;
  refererId?: {
    id: string;
    type: string;
  };
  eventSource?: ContactAgentModalSource;
  source?: ContactAgentModalSource;
  leadTermsAccepted?: boolean;
}

export interface IContactPageSubmitResponse {
  contactV4: {
    lead: {
      leadId: string;
    },
  };
}

export interface IFavorites {
  favorites: IPoiIdentifier[];
}

export interface IFavoritesToggle extends IFavorites {
  isFavorite: boolean;
}

export interface IFavoritesToggleMeta {
  source?: string;
}

export interface ILastViewedVariables {
  lastViewed: IPoiIdentifier[];
}

export interface BadAddressReportVariables {
  docId?: string;
  remarks?: string;
}

export interface IContactAgentVariables {
  contactId: IPoiIdentifier;
}

export interface IContactAgentResponse {
  addContactAgent: DatedPoi[];
}

export interface ISendFeedbackVariables {
  insightType: InsightDescriptor;
  insightRecordId: string;
  message: string;
  bulletinId?: string;
  address?: string;
  feedback?: string;
  feedbackType?: FeedbackPopupType;
  email?: string;
  sourceType: SourceType;
}

export interface ISendFeedbackResponse {
  feedback: boolean;
}

export interface SavedAddressSettings {
  insightsUpdates: boolean;
  isHome: boolean;
  newListingUpdates: boolean;
}

export interface ISavedAddressToggle extends SavedAddress {
  isSaved: boolean;
  isChanged?: boolean;
}

export type ToggleAddressTriggers =
  'follow_address_sticky'
  | 'impact_card'
  | 'impact_empty_card'
  | 'insight_expand'
  | 'follow_address_form'
  | 'impact_card_bell'
  | 'user_generated_content';

export interface ToggleAddressMeta {
  source: ToggleAddressTriggers;
}

export interface IDeleteAddressVariables {
  docId: string;
}

export interface ISavedSearchUnsubscribeVariables {
  token: string;
  unsubscribe: boolean;
}

export interface IEditBulletinStatusVariables {
  bulletinId: PoiId;
  userToken?: string;
  status: {
    status: ListingStatusType,
    promoted: boolean,
    message: string,
  };
}

export interface LoginPersonalAssistantVariables {
  userToken?: string;
  phoneNumber: string;
}

type GlobalErrors = 'overTheCreditLimit' | 'masAddressFailure' | 'generalFailure';

export interface LoginPersonalAssistantResponse {
  signedUp: boolean;
  errorList: string[];
}

export interface IEditBulletinStatusResponse {
  editBulletinStatus: {
    bulletin: {
      id: PoiId;
      status: {
        status: BulletinStatusType,
      },
    },
    errorCodes?: GlobalErrors[];
    saved: boolean;
  };
}

export interface ISavedSearchUnsubscribeResponse {
  updatePriorities: boolean;
}

export type SaveBulletinErrorCodes = 'overTheCreditLimit' | 'maskAddressFailure' | 'generalFailure';

export interface SavedAddress {
  docId: string;
  settings?: SavedAddressSettings;
}

export enum ListingResponseType {
  NoPermissionsError = 'NoPermissionsError',
  GeneralListingError = 'GeneralListingError',
  UserCannotAddListingError = 'UserCannotAddListingError',
  InvalidInputError = 'InvalidInputError',
  SuccessfulListingsResponse = 'SuccessfulListingsResponse',
}
export enum UserCannotAddListingErrorField {
  UserId = 'USER_ID',
  PhoneNumber = 'PHONE_NUMBER',
}

export interface IUserListingResponseError {
  errorMessage?: string;
  invalidField?: string;
  field?: UserCannotAddListingErrorField;
}

export interface UpdateListingResponse {
  id: PoiId;
  agentId: string;
  status: {
    status: BulletinStatusType;
    promoted: boolean;
  };
}
export interface INewListingResponse extends IUserListingResponseError {
  listing: UpdateListingResponse;
  __typename: ListingResponseType;
}

export type IEditListingResponse = INewListingResponse;

export interface INewCommercialListingResponse extends IUserListingResponseError {
  commercialListing: UpdateListingResponse;
  __typename: ListingResponseType;
}

export interface IEditListingPromotionResponse {
  id: string;
  promoted: boolean;
}

export type IEditCommercialListingResponse = INewCommercialListingResponse;

export interface IListingStatusUpdate {
  id: PoiId;
  agentId: string;
  status: {
    status: BulletinStatusType;
  };
}

export interface IEditListingStatusResponse {
  listing: IListingStatusUpdate;
  errors: Array<{errorMessage: string}>;
}

export interface IEditCommercialListingStatusResponse {
  commercialListing: IListingStatusUpdate;
  errors: Array<{errorMessage: string}>;
}

export interface ITempTokenAuthVariables {
  loginToken: string;
}

export interface MadadResponse {
  madad: MadadDto[];
}

export interface HPSlideFIle {
  url: string;
  title: string;
}

export enum SlideType {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export type SlideStatus = 'publish' | 'draft';

export interface AsideCSSDate {
  maxWidth: number;
  transformX: number;
  transformY: number;
}

export interface HPBaseSlide {
  id: string;
  slideType: SlideType;
  status: SlideStatus;
  navigation: {
    text: string;
    link: string;
  };
  aside: {
    link: string;
    file: HPSlideFIle;
    imageCssProps?: AsideCSSDate;
  };
}

export interface HPSlide extends HPBaseSlide {
  title: string;
  subtitle: string;
  backgroundFile: HPSlideFIle;
}

export type HPBanner = { id: 'hp-banner' } & Record<SlideType, HPSlide[]>;

export interface HPBannerResponse {
  homePageBanner: HPBanner;
}

export interface IProjectMapLogo {
  id: PoiId;
  logo: {
    title: string;
    url: string;
  };
}

export interface ProjectMapLogoResponse {
  data:  IProjectMapLogo[];
}

export enum PhoneValidationStatus {
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Blocked = 'BLOCKED',
}

export interface DeveloperByIdResponse {
  developerById: IDeveloperProfile;
}

export interface OfficeByIdResponse {
  getRealEstateOfficeById: IOfficePageData;
}

export interface OfficeDataByIdResponse {
  getRealEstateOfficeById: IOfficeData;
}

export interface OfficeBulletinsByIdResponse {
  getRealEstateOfficeById: IAgentOfficeBulletinsData;
}

export interface AgentBulletinsByIdResponse {
  getRealEstateAgentById: IAgentOfficeBulletinsData;
}

export interface AgentByIdResponse {
  getRealEstateAgentById: IAgentPageData;
}

export interface AgentWithBulletinsByIdResponse {
  getRealEstateAgentById: IAgentWithBulletinsData;
}

export interface TeamByOfficeIdResponse {
  getPublicRealEstateAgentsByOfficeId: ITeamByOfficeIdData[];
}

interface IDevelopersWithPromotedProjectsResponse {
  developersAndPromotedProjects: {
    developers: IDeveloperProfile[];
    poi: Array<ICommercialProject | IProject>;
  };
}

export type ISearchDeveloper = Pick<IDeveloperProfile, 'id' | 'name' | 'logoUrl' | 'images' | 'projectsCount' | 'integrationLevel'>;

export interface SearchDevelopersResponse {
  searchDevelopersIndex: {
    totalCount: number;
    developers: ISearchDeveloper[];
  };
}

export interface MortgagOfficesByCityDocIdsResponse {
  getPublicMortgageAdvisorsOfficesByCityDocIds: {
    total: number;
    offices: IMortgageOffice[];
  };
}
export interface MortgageOfficeByIdResponse {
  getPublicMortgageAdvisorsOfficesByIds: {
    offices: IMortgageOffice[];
  };
}

export interface OfficeListingsResponse {
  getOfficeListings: {
    commercial: ICommercialBulletin[];
    residential: IBulletin[];
  };
}

export interface GetDealsAffiliationRequestsResponse {
  getDeals: {
    deals: IDealAffiliationRequest[];
    total: number;
  };
}

export interface GetResidentialAndCommercialListingsResponse {
  getResidentialAndCommercialListings: {
    listings: Array<{ bulletin?: IBulletin, commercialBulletin?: ICommercialBulletin }>;
    total: number;
  };
}

export interface SearchDealsResponse {
  searchDeals: {
    result: ISearchDeal[];
    total: number;
  };
}

export interface GetDealAffiliationRequestsBySaleIdResponse {
  getDealAffiliationRequestBySaleId:  {
    results: IRequestDealAffiliationResponse[];
  };
}

export interface GetCommercialListingResponse {
  getCommercialListing: ICommercialListing;
}

export interface GetResidentialListingResponse {
  getResidentialListingById: {
    listing: IBulletin;
  };
}

export type BusRoute = GeoJSON.Feature<GeoJSON.MultiLineString, unknown>;

export interface BusRouteByIdsResponse {
  busRouteByIds: BusRoute[];
}

interface ISubmitContactMadlanLeadVariables {
  leadInput: IContactMadlanLead;
}

export interface IContactMadlanLead {
  productId: ContactMadlanReason;
  email: string;
  phone?: string;
  name: string;
  sourceUrl: string;
  formContactData: IFormContactData;
}

export enum ContactMadlanReason {
  GeneralConnectUs = 'generalConnectUs',
  Job = 'job',
  MailingListHelp = 'mailingListHelp',
  BulletinError = 'bulletinError',
  LoginHelp = 'loginHelp',
  FindPropertyHelp = 'findPropertyHelp',
  NeigHelp = 'neigHelp',
  SoldHelp = 'soldHelp',
  ErrorReport = 'errorReport',
  Promotion = 'promotion',
  DevProTools = 'devProTools',
  RealEstateAgentProTools = 'realEstateAgentProTools',
  EducationHelp = 'educationHelp',
}

export interface IFormContactData {
  officeName?: string;
  errorDetails?: string;
  details?: string;
  cityOfActivity?: string;
  numberOfAgentsInOffice?: string;
  companyName?: string;
  role?: string;
}

export interface SubmitContactMadlanLeadResponse {
  submitContactMadlanLead: string;
}

export interface DeveloperLeadVariables {
  name: string;
  email: string;
  phone: string;
  contactReason: ContactDeveloperReason;
  developerId: string;
}

export interface SubmitDeveloperLeadVariables {
  data: DeveloperLeadVariables;
}

interface ArticleSearchArgument {
  docIds: string[];
  marketplaceType?: string;
}

export interface GetArticlesVariables {
  query: ArticleSearchArgument;
}

export interface IEditDealAffiliationRequestVariables {
  input: {
    id: string;
    agentId: string;
    agentType: DealAffiliationType;
    receiptUrls: string[];
  };
}

export interface IRequestDealAffiliationVariables {
  input: {
    soldId: string;
    agentId: string;
    agentType: DealAffiliationType;
    receiptUrls: string[];
  };
}

export interface SubmitDeveloperLeadResponse {
  submitDeveloperLead: string;
}

export interface ArticleOwner {
  id: string;
  name: string;
}

export interface Article {
  id: string;
  title: string;
  owner: ArticleOwner;
  image: string;
  link: string;
}

export interface GetArticlesResponse {
  getArticles: Article[];
}

export enum DeregisterUserStatus {
  DEREGISTER_USER_SUCCESS = 'DEREGISTER_USER_SUCCESS',
  DEREGISTER_USER_FAILURE = 'DEREGISTER_USER_FAILURE',
  DEREGISTER_USER_NO_USER = 'DEREGISTER_USER_NO_USER',
}

export interface DeregisterUserResponse {
  deregisterUser: {
    user: {
      userId: string;
    };
    status: DeregisterUserStatus;
  };
}

export interface EditDealAffiliationRequestResponse {
  publicEditDealAffiliationRequest: IAffiliationRequestResponseDeal;
}

export interface RequestDealAffiliation {
  requestDealAffiliation: IRequestDealAffiliationResponse;
}

export interface Cursor {
  bulletinsOffset?: number;
  projectsOffset?: number;
  seenProjects?: string[];
}

export interface IAcceptConsentsResponse {
  success: boolean;
  errorMessage: string;
}

export interface IRemoveMarketingConsentResponse {
  success: boolean;
  errorMessage: string;
}

export interface MutationResponseByType {
  [MutationType.FacebookLogin]: LoginQueryResponseNG<'facebookLogin'>;
  [MutationType.GoogleLogin]: LoginQueryResponseNG<'googleLogin'>;
  [MutationType.SignInSignUpWithEmail]: LoginQueryResponseNG<'loginV3'>;
  [MutationType.LoginWithEmailAndPassword]: LoginQueryResponseNG<'passwordLoginV3'>;
  [MutationType.TempTokenAuth]: LoginQueryResponseNG<'completeAutoLogin'>;
  [MutationType.RegisterVisitor]: LoginQueryResponseNG<'registerVisitor'>;
  [MutationType.RequestPasswordReset]: IRequestPasswordResetResponse;
  [MutationType.ResetPasswordUpdate]: IResetPasswordUpdateResponse;
  [MutationType.UpdateUserProfile]: {
    updateUserProfile: User;
  };
  [MutationType.ContactPageSubmit]: IContactPageSubmitResponse;
  [MutationType.AgentOfficeContactPageSubmit]: IContactPageSubmitResponse;
  [MutationType.AddFavorites]: {
    addFavorites: DatedPoi[];
  };
  [MutationType.RemoveFavorites]: {
    removeFavorites: DatedPoi[];
  };
  [MutationType.AddContactAgent]: IContactAgentResponse;
  [MutationType.AddRecentlyViewed]: {
    addRecentlyViewed: DatedPoi[];
  };
  [MutationType.SendFeedback]: ISendFeedbackResponse;
  [MutationType.SaveSearchQuery]: IUpdateSaveSearchResponse;
  [MutationType.DeleteSaveSearch]: IDeleteSaveSearchResponse;
  [MutationType.SaveAddress]: {
    saveAddress: UserSavedAddresses[],
  };
  [MutationType.DeleteAddress]: {
    deleteAddress: UserSavedAddresses[],
  };
  [MutationType.SavedSearchUnsubscribe]: ISavedSearchUnsubscribeResponse;
  [MutationType.NewListing]: { newListing: INewListingResponse };
  [MutationType.EditListing]: { editListing: IEditListingResponse };
  [MutationType.EditListingStatus]: { editListingStatus: IEditListingStatusResponse };
  [MutationType.EditListingPromotionStatus]: { editListingPromotionStatus: IEditListingPromotionResponse; };
  [MutationType.NewCommercialListing]: { newCommercialListing: INewCommercialListingResponse };
  [MutationType.EditCommercialListing]: { editCommercialListing: IEditCommercialListingResponse };
  [MutationType.EditCommercialListingStatus]: { editCommercialListingStatus: IEditCommercialListingStatusResponse };
  [MutationType.EditCommercialListingAssignedAgent]: { editAssignedAgentCommercialListing: IEditCommercialListingResponse; };
  [MutationType.EditListingAssignedAgent]: { editAssignedAgentListing: IEditListingResponse; };
  [MutationType.EditCommercialListingPromotionStatus]: { editCommercialListingPromotionStatus: IEditCommercialListingResponse; };
  [MutationType.UploadBulletinBadAddressReport]: {
    badAddressReport: boolean;
  };
  [MutationType.EditBulletinStatus]: IEditBulletinStatusResponse;
  [MutationType.SaveRecentSearch]: {
    saveRecentSearch: ISaveSearch,
  };
  [MutationType.AddUGC]: AddUGCResponse;
  [MutationType.AddUGCEmotion]: AddUGCEmotionResponse;
  [MutationType.FeedbackUGC]: FeedbackUGCResponse;
  [MutationType.SaveUserData]: {
    SaveUserData: boolean;
  };
  [MutationType.ContactV4]: ContactV4Response;
  [MutationType.ContactV4MortgageOffice]: ContactV4Response;
  [MutationType.LoginPersonalAssistant]: LoginPersonalAssistantResponse;
  [MutationType.GenerateValidationCode]: {
    generateValidationCode: PhoneValidationStatus;
  };
  [MutationType.CheckValidationCode]: {
    checkValidationCode: PhoneValidationStatus;
  };
  [MutationType.SubmitContactMadlanLead]: SubmitContactMadlanLeadResponse;
  [MutationType.SubmitDeveloperLead]: SubmitDeveloperLeadResponse;
  [MutationType.GetArticles]: GetArticlesResponse;
  [MutationType.DeregisterUser]: DeregisterUserResponse;
  [MutationType.AddNotificationDevice]: {
    addNotificationDevice: UserNotificationDevice;
  };
  [MutationType.RemoveNotificationDevice]: {
    removeNotificationDevice: UserNotificationDevice;
  };
  [MutationType.EditDealAffiliationRequest]: EditDealAffiliationRequestResponse;
  [MutationType.RequestDealAffiliation]: RequestDealAffiliation;
  [MutationType.AcceptConsents]: { acceptConsents: IAcceptConsentsResponse };
  [MutationType.RemoveMarketingConsent]: { removeMarketingConsent: IRemoveMarketingConsentResponse };
}

interface AddNotificationDeviceVariables {
  deviceToken: string;
  deviceType: DeviceType;
}

export enum RemoveNotificationDeviceReason {
  INVALIDATED_BY_PROVIDER = 'INVALIDATED_BY_PROVIDER',
  LOGGED_OUT = 'LOGGED_OUT',
  MANUALLY_DISABLED = 'MANUALLY_DISABLED',
}

interface RemoveNotificationDeviceVariables {
  deviceToken: string;
  deleteReason: RemoveNotificationDeviceReason;
}

export enum IConsentType {
  MARKETING = 'MARKETING',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  LEAD_TERMS_AND_CONDITIONS = 'LEAD_TERMS_AND_CONDITIONS',
  LISTING_UPLOAD_TERMS_AND_CONDITIONS = 'LISTING_UPLOAD_TERMS_AND_CONDITIONS',
}

interface IAcceptConsentsVariables {
  input: {
    consents: IConsentType[];
    unsubscribeMarketingToken?: string;
    source?: string;
  };
}

interface IRemoveMarketingConsentVariables {
  input: {
    unsubscribeToken: string;
    source?: string;
  };
}

export interface MutationVariablesByType {
  [MutationType.FacebookLogin]: IFacebookLoginVariables;
  [MutationType.GoogleLogin]: IGoogleLoginVariables;
  [MutationType.LoginWithEmailAndPassword]: ILoginWithEmailAndPasswordVariables;
  [MutationType.RegisterVisitor]: {};
  [MutationType.SignInSignUpWithEmail]: ISignInSignUpWithEmailVariables;
  [MutationType.TempTokenAuth]: ITempTokenAuthVariables;
  [MutationType.RequestPasswordReset]: IRequestPasswordResetVariables;
  [MutationType.ResetPasswordUpdate]: IResetPasswordUpdateVariables;
  [MutationType.UpdateUserProfile]: IUpdateUserProfileVariables;
  [MutationType.ContactPageSubmit]: IContactPageSubmitVariables;
  [MutationType.AgentOfficeContactPageSubmit]: IContactPageSubmitVariables;
  [MutationType.AddFavorites]: IFavorites;
  [MutationType.RemoveFavorites]: IFavorites;
  [MutationType.AddRecentlyViewed]: ILastViewedVariables;
  [MutationType.SendFeedback]: ISendFeedbackVariables;
  [MutationType.AddContactAgent]: IContactAgentVariables;
  [MutationType.SaveSearchQuery]: ISaveSearchQueryVariables;
  [MutationType.DeleteSaveSearch]: IDeleteSaveSearchQueryVariables;
  [MutationType.SaveAddress]: SavedAddress;
  [MutationType.DeleteAddress]: IDeleteAddressVariables;
  [MutationType.SavedSearchUnsubscribe]: ISavedSearchUnsubscribeVariables;
  [MutationType.NewListing]: IUploadBulletinInput;
  [MutationType.EditListing]: IUpdateListingInput;
  [MutationType.NewCommercialListing]: IUploadCommercialBulletinInput;
  [MutationType.EditCommercialListing]: IUpdateCommercialListingInput;
  [MutationType.EditListingStatus]: IUpdateListingStatusInput;
  [MutationType.EditCommercialListingStatus]: IUpdateListingStatusInput;
  [MutationType.EditCommercialListingAssignedAgent]: IEditAssignedAgentCommercialListingInput;
  [MutationType.EditListingAssignedAgent]: IEditAssignedAgentListingInput;
  [MutationType.EditCommercialListingPromotionStatus]: IEditCommercialListingPromotionStatusVariables;
  [MutationType.EditListingPromotionStatus]: IEditListingPromotionStatusVariables;
  [MutationType.UploadBulletinBadAddressReport]: BadAddressReportVariables;
  [MutationType.EditBulletinStatus]: IEditBulletinStatusVariables;
  [MutationType.SaveRecentSearch]: ISaveSearchQueryVariables;
  [MutationType.AddUGC]: AddUGCVariables;
  [MutationType.AddUGCEmotion]: AddUGCEmotionVariables;
  [MutationType.FeedbackUGC]: FeedbackUGCVariables;
  [MutationType.ContactV4]: ContactV4Variables;
  [MutationType.ContactV4MortgageOffice]: ContactV4MortgageOfficeVariables;
  [MutationType.SaveUserData]: SaveUserDataVariables;
  [MutationType.LoginPersonalAssistant]: LoginPersonalAssistantVariables;
  [MutationType.GenerateValidationCode]: IGenerateValidationCodeVariables;
  [MutationType.CheckValidationCode]: ICheckValidationCodeVariables;
  [MutationType.SubmitContactMadlanLead]: ISubmitContactMadlanLeadVariables;
  [MutationType.SubmitDeveloperLead]: SubmitDeveloperLeadVariables;
  [MutationType.GetArticles]: GetArticlesVariables;
  [MutationType.DeregisterUser]: {};
  [MutationType.AddNotificationDevice]: AddNotificationDeviceVariables;
  [MutationType.RemoveNotificationDevice]: RemoveNotificationDeviceVariables;
  [MutationType.EditDealAffiliationRequest]: IEditDealAffiliationRequestVariables;
  [MutationType.RequestDealAffiliation]: IRequestDealAffiliationVariables;
  [MutationType.AcceptConsents]: IAcceptConsentsVariables;
  [MutationType.RemoveMarketingConsent]: IRemoveMarketingConsentVariables;
}
