import config from 'config';
import { bboxEqual } from 'components/map/utils';
import { DecodedRouteParams, Route, State as RouteState } from 'config/routes';
import { DealType, IBulletin, ICommercialBulletin, IProject, ICommercialProject } from 'utils/entities';
import { DialogWithURLType } from 'store/state/selectors/router';
import { isCommercialBulletin, isProject, isUnitPageRoute } from 'utils/marketplaceRoutes';
import { findIndex } from 'lodash';

const skippedRoutes = new Set([
  Route.UploadBulletin,
  Route.EditBulletin,
  Route.EditCommercialBulletin,
  Route.UploadBulletinForm,
  Route.EditBulletinForm,
]);

const isExpanded = (params: DecodedRouteParams): boolean => Boolean(params && params.insightId && params.insightCollection);
const findBeforeUploadBulletinRoute = (history: RouteState[], routeName: Route): BackLinkConfig => {
  const reversedRoutesHistory = history ? [ ...history ].reverse() : [];
  const prevPageIndex = findIndex(reversedRoutesHistory, (historyRoute) => {
    return historyRoute.name !== routeName && !skippedRoutes.has(historyRoute.name);
  });

  if (prevPageIndex === -1) {
    return {
      routeName: Route.Home,
      routeParams: {},
    };
  }
  else {
    const { name, params } = reversedRoutesHistory[prevPageIndex];
    return {
      routeName: name,
      routeParams: params,
      isPrevRoute: true,
      prevPageIndex,
    };
  }
};

export interface FactoryOptions {
  prevRoute: RouteState;
  route: RouteState;
  poi: IBulletin | ICommercialBulletin | IProject | ICommercialProject;
  routeHistory: RouteState[];
}

export interface BackLinkConfig {
  routeName: Route;
  routeParams: DecodedRouteParams;
  isPrevRoute?: boolean;
  prevPageIndex?: number;
}

export const createBackLinkConfig = (options: FactoryOptions): BackLinkConfig => {
  const { prevRoute, route, poi, routeHistory } = options;
  let backLinkProps: BackLinkConfig = null;

  const isExpandActive = isExpanded(route.params);
  const isUGCTextReviewsExpanded = route.params.isActiveReviewResultPage;
  const isPrevRoute = Boolean(prevRoute);

  const isUploadBulletinPrevRoute = isPrevRoute && prevRoute.name === Route.UploadBulletin;
  const isEditBulletinPrevRoute = isPrevRoute && (prevRoute.name === Route.EditBulletin || prevRoute.name === Route.EditCommercialBulletin);
  const isUploadBulletinFormPrevRoute = isPrevRoute && prevRoute.name === Route.UploadBulletinForm;
  const isEditBulletinFormPrevRoute = isPrevRoute && (prevRoute.name === Route.EditBulletinForm || prevRoute.name === Route.EditResidentialBulletinForm);
  const isSameId = isPrevRoute && prevRoute.params.id === route.params.id;

  if (isUGCTextReviewsExpanded) {
    backLinkProps = {
      routeName: route.name,
      routeParams: { ...route.params, isActiveReviewResultPage: undefined },
      isPrevRoute,
    };
  }
  else if (isExpandActive) {
    backLinkProps = {
      routeName: route.name,
      routeParams: { ...route.params, insightCollection: undefined, insightId: undefined },
      isPrevRoute: isPrevRoute && !isUnitPageRoute(route.name),
    };
  }
  else if ((!skippedRoutes.has(route.name) && prevRoute && prevRoute.name === Route.UploadBulletin) || skippedRoutes.has(route.name)) {
    backLinkProps = findBeforeUploadBulletinRoute(routeHistory, route.name);
  }
  else if (isUnitPageRoute(route.name) && (isUploadBulletinPrevRoute || (isEditBulletinPrevRoute && isSameId))) {
    backLinkProps = {
      routeName: Route.ManageBulletins,
      routeParams: { ...route.params },
    };
  }
  else if (isUnitPageRoute(route.name) && (isUploadBulletinFormPrevRoute || (isEditBulletinFormPrevRoute && isSameId))) {
    backLinkProps = {
      routeName: Route.Listings,
      routeParams: { ...route.params },
    };
  }
  else if (
    isPrevRoute
    && !isExpanded(prevRoute.params)
    && (prevRoute.params.dialog && prevRoute.params.dialog.type) !== DialogWithURLType.TabuWizard
    && bboxEqual(prevRoute.params.bbox, route.params.bbox)
  ) {
    backLinkProps = {
      routeName: prevRoute.name,
      routeParams: prevRoute.params,
      isPrevRoute,
    };
  }
  else {
    let nextParams: DecodedRouteParams = { ...route.params, id: undefined };
    let nextRoute: Route = Route.Search;

    if (poi && (route.name === Route.UnitPage || route.name === Route.UnitPageCommercial || route.name === Route.ProjectPage || route.name === Route.ProjectPageCommercial)) {
      if (!nextParams.dealType) {
        nextParams = { ...nextParams, dealType: ('dealType' in poi && poi.dealType) || DealType.Buy };
      }
      if (!nextParams.term) {
        const docId = poi.addressDetails.neighbourhoodDocId || poi.addressDetails.cityDocId;
        nextParams = {
          ...nextParams,
          term: docId && [ docId ],
        };
      }
      if (isCommercialBulletin(poi)) {
        nextRoute = Route.SearchCommercial;
      }
      if (isProject(poi) && route.name === Route.ProjectPageCommercial) {
        nextRoute = Route.SearchCommercial;
      }
    }
    else if (route.name === Route.AddressPage && nextParams.dealType) {
      nextParams = {
        ...nextParams,
        term: nextParams.term || [ config.cityTerm ],
      };
    }
    else if (route.name === Route.StreetPage) {
      nextParams = {
        dealType: nextParams.dealType || DealType.Buy,
        term: nextParams.term || [ config.cityTerm ],
      };
    }
    else {
      nextRoute = Route.CheckAddress;
    }

    backLinkProps = {
      routeName: nextRoute,
      routeParams: { ...nextParams, insightId: undefined, insightCollection: undefined },
    };
  }

  if (!backLinkProps) return null;
  backLinkProps.routeParams.tracking_search_source = 'back_click';

  return backLinkProps;
};
