import { createStandardAction } from 'typesafe-actions';
import * as Types from './types';
import { AuthenticationModalPayload, SocialVendor } from 'components/authentication/types';
import { FeedbackToastMessage } from 'components/feedback-toast/ToastContainer';
import {
  IFavoritesToggle,
  MutationOptionsMeta,
  MutationType,
  ISavedAddressToggle,
  ILoginWithEmailAndPasswordVariables,
  SavedAddressSettings,
  IUpdateSaveSearch,
  ToggleAddressMeta,
  ISignInSignUpWithEmailVariables,
  IFavoritesToggleMeta,
  IConsent,
} from 'store/sagas/apiService/types';
import { CommutePopupStatus } from 'components/commute-popup/types';
import { PopupImageGalleryStatus } from 'components/unit-page/gallery/Gallery';
import {
  ContactModalOpenMeta,
  SaveSearchUserAction,
  AuthModalSource,
  FinishFormState,
  TabuDictionary,
  ManageListingsErrorModal,
  DeveloperFiltersState,
  PushNotificationsModalType,
  DevicePushStatus,
} from './';
import {
  CommutePreference,
  ISendFeedbackCallbackProps,
  PoiId,
  SaveSearchMeta,
  SaveSearchPayload,
} from 'utils/entities';
import { Section } from 'components/navigation/subheader/SectionsNavigation';
import { FixedMultiPreviewStatus } from 'components/map/Preview/MultiListingPreview';
import { ShareModalType } from 'components/share-modal';
import {
  SubmitContactAgentPayload,
  SetMadadContactModalStatePayload,
} from './payloads';
import { SortDirection, SortField } from 'components/listing-sort/types';
import { RoomOption } from 'components/filters/types';
import { SearchViewMode } from 'components/list-view-switcher/constants';
import { FullPoiType } from 'components/inner-contact-form/mortgage-form/modeB';
import { IDealAffiliationPopupState } from 'components/deal-affiliation-popup/types';

export const logout = createStandardAction(Types.LOGOUT)();
export const continueEditing = createStandardAction(Types.CONTINUE_EDITING)();
export const editLater = createStandardAction(Types.GET_BACK_TO_EDITING_LATER)();
export const setToastMessage = createStandardAction(Types.SET_TOAST_MESSAGE)<FeedbackToastMessage>();
export const setAuthModal = createStandardAction(Types.SET_AUTH_MODAL)<AuthenticationModalPayload, { isUserInitiated?: boolean, source?: AuthModalSource }>();
export const userLoginSocial = createStandardAction(Types.USER_LOGIN_SOCIAL)<SocialVendor>();
export const signInSignUpWithEmail = createStandardAction(Types.SIGN_IN_SIGN_UP_WITH_EMAIL)<ISignInSignUpWithEmailVariables>();
export const userLoginWithEmailAndPassword = createStandardAction(Types.USER_LOGIN_WITH_EMAIL_AND_PASSWORD)<ILoginWithEmailAndPasswordVariables>();
export const updateUser = createStandardAction(Types.UPDATE_USER)<MutationOptionsMeta<MutationType.UpdateUserProfile>>();
export const loginGoogleWithConsents = createStandardAction(Types.GOOGLE_LOGIN_WITH_CONSENTS)<IConsent[]>();
export const loginFacebookWithConsents = createStandardAction(Types.FACEBOOK_LOGIN_WITH_CONSENTS)<IConsent[]>();
export const loginWithPasswordAndConsents = createStandardAction(Types.PASSWORD_LOGIN_WITH_CONSENTS)<IConsent[]>();
export const setCommutePopupStatus = createStandardAction(Types.SET_COMMUTE_POPUP_STATUS)<CommutePopupStatus>();
export const setMobileDiscoveryPopupStatus = createStandardAction(Types.SET_MOBILE_DISCOVERY_POPUP_STATUS)<boolean>();
export const setContactAgentOpen = createStandardAction(Types.SET_CONTACT_AGENT_OPEN)<PoiId, ContactModalOpenMeta>();
export const setIntentModalOpen = createStandardAction(Types.SET_INTENT_MODAL_OPEN)<PoiId, ContactModalOpenMeta>();
export const setWelcomeModalOpen = createStandardAction(Types.SET_WELCOME_MODAL_OPEN)<PoiId, ContactModalOpenMeta>();
export const contactAgentInputClick = createStandardAction(Types.CONTACT_AGENT_INPUT_CLICK)<{ eventName: string; poi: FullPoiType }>();
export const setFeedbackPopupOpen = createStandardAction(Types.SET_FEEDBACK_POPUP_OPEN)<ISendFeedbackCallbackProps>();
export const setReportListingPopupOpen = createStandardAction(Types.SET_REPORT_LISTING_POPUP_OPEN)<boolean>();
export const setAddressFeedbackPopupOpen = createStandardAction(Types.SET_ADDRESS_FEEDBACK_POPUP_OPEN)<boolean>();
export const setReportUploadBulletinPopupOpen = createStandardAction(Types.SET_UPLOAD_BULLETIN_REPORT_MODAL_OPEN)<boolean>();
export const setAfterUploadBulletinModalOpen = createStandardAction(Types.SET_AFTER_UPLOAD_BULLETIN_MODAL_OPEN)<'UPLOAD' | 'UPDATE' | null>();
export const setNeedsPopupOpen = createStandardAction(Types.SET_NEEDS_POPUP_OPEN)<boolean>();
export const setUserMobileProfileOpen = createStandardAction(Types.SET_USER_MOBILE_PROFILE_OPEN)<boolean>();
export const setUserMobileProfileReturnable = createStandardAction(Types.SET_USER_MOBILE_PROFILE_RETURNABLE)<boolean>();
export const setSidebarOpen = createStandardAction(Types.SET_SIDEBAR_OPEN)<boolean>();
export const setFinishFormState = createStandardAction(Types.SET_FINISH_FORM_STATE)<Partial<FinishFormState>>();
export const setContactAgentSuccessPoiId = createStandardAction(Types.SET_CONTACT_AGENT_SUCCESS_OPEN)<PoiId>();
export const showContactPhone = createStandardAction(Types.SHOW_CONTACT_PHONE)<boolean>();
export const submitContactAgent = createStandardAction(Types.SUBMIT_CONTACT_AGENT)<SubmitContactAgentPayload>();
export const submitUserContactAgent = createStandardAction(Types.SUBMIT_USER_CONTACT_AGENT)<SubmitContactAgentPayload>();
export const setShareModalOpen = createStandardAction(Types.SET_SHARE_MODAL_OPEN)<ShareModalType>();
export const setOpenStatusDeleteListingModal = createStandardAction(Types.SET_OPEN_STATUS_DELETE_LISTING_MODAL)<boolean>();

export const setGalleryStatus = createStandardAction(Types.SET_GALLERY_STATUS)<PopupImageGalleryStatus>();
export const setFixedMultiPreview = createStandardAction(Types.SET_FIXED_MULTI_PREVIEW)<FixedMultiPreviewStatus>();

export const saveSearch = createStandardAction(Types.SAVE_SEARCH)<SaveSearchPayload, SaveSearchMeta>();
export const updateSaveSearch = createStandardAction(Types.UPDATE_SAVED_SEARCH)<IUpdateSaveSearch>();
export const deleteSaveSearch = createStandardAction(Types.DELETE_SAVE_SEARCH)<string, DeleteSaveSearchMeta>();
export const setSaveSearchModalOpen = createStandardAction(Types.SET_SAVE_SEARCH_MODAL_OPEN)<boolean>();
export type ResolveSaveSearchModalPayload = { userAction: SaveSearchUserAction.ChangeSearchArea, docId: string }
  | { userAction: SaveSearchUserAction.Reject }
  | { userAction: SaveSearchUserAction.Accept };
export const resolveSaveSearchModal = createStandardAction(Types.RESOLVE_SAVE_SEARCH_MODAL)<ResolveSaveSearchModalPayload>();

export const toggleFavorites = createStandardAction(Types.TOGGLE_FAVORITES)<IFavoritesToggle, IFavoritesToggleMeta>();
export const toggleAddress = createStandardAction(Types.TOGGLE_ADDRESS)<ISavedAddressToggle, ToggleAddressMeta>();

export const updatePendingAddressCardSettings = createStandardAction(Types.UPDATE_PENDING_ADDRESS_CARD_SETTINGS)<SavedAddressSettings>();

export const setMobileSearchMapViewActive = createStandardAction(Types.SET_MOBILE_SEARCH_MAP_VIEW_ACTIVE)<boolean>();
export const setVisibleSection = createStandardAction(Types.SET_VISIBLE_SECTION)<Section>();

export const setSaveAgentResultPopupOpen = createStandardAction(Types.SET_SAVE_AGENT_RESULT_POPUP_OPEN)<boolean>();
export const setTabuState = createStandardAction(Types.SET_TABU_STATE)<TabuDictionary>();
export const requestTabuByDocId = createStandardAction(Types.REQUEST_TABU_BY_DOC_ID)();

export const contactModalCall = createStandardAction(Types.CONTACT_MODAL_CALL)();

export const setMadadContactModalState = createStandardAction(Types.SET_MADAD_CONTACT_MODAL_STATE)<SetMadadContactModalStatePayload>();

export const setAuthEmail = createStandardAction(Types.SET_AUTH_EMAIL)<string>();
export const setManageListingsErrorModal = createStandardAction(Types.SET_MANAGE_LISTINGS_ERROR_MODAL)<ManageListingsErrorModal>();
export const closeManageListingsErrorModal = createStandardAction(Types.CLOSE_MANAGE_LISTINGS_ERROR_MODAL)<{ noRedirect?: boolean; }>();
export const setManageListingsFilterAgentUserId = createStandardAction(Types.SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID)<string>();

export const setDeveloperFilters = createStandardAction(Types.SET_DEVELOPER_FILTERS)<DeveloperFiltersState>();
export const setIsListingAdditionalBannerClosed = createStandardAction(Types.SET_IS_LISTING_ADDITIONAL_BANNER_CLOSED)<boolean>();
export const setIsSearchResultAdditionalBannerClosed = createStandardAction(Types.SET_IS_SEARCH_RESULT_ADDITIONAL_BANNER_CLOSED)<boolean>();
export const setIsMobileMenuHidden = createStandardAction(Types.SET_IS_MOBILE_MENU_HIDDEN)<boolean>();

export const setIsListingsTableLoading = createStandardAction(Types.SET_IS_LISTINGS_TABLE_LOADING)<boolean>();

export const setIsMizrahiBankModalOpen = createStandardAction(Types.SET_IS_MIZRAHI_BANK_MODAL_OPEN)<boolean>();

export const setIsAgentOfficeContactPopupOpen = createStandardAction(Types.SET_AGENT_OFFICE_CONTACT_POPUP_OPEN)<boolean>();
export const setIsMortgageOfficeContactPopupOpen = createStandardAction(Types.SET_MORTGAGE_OFFICE_CONTACT_POPUP_OPEN)<boolean>();

export const setIsLikedToastOpen = createStandardAction(Types.SET_IS_LIKED_TOAST_OPEN)<boolean>();
export const setSearchViewMode = createStandardAction(Types.SET_SEARCH_VIEW_MODE)<SearchViewMode>();

export const setIsHeaderTabSubmenuVisible = createStandardAction(Types.SET_IS_HEADER_TAB_SUBMENU_VISIBLE)<boolean>();
export const setPushNotificationsModalType = createStandardAction(Types.SET_PUSH_NOTIFICATIONS_MODAL_TYPE)<PushNotificationsModalType>();
export const setDevicePushStatus = createStandardAction(Types.SET_DEVICE_PUSH_STATUS)<DevicePushStatus>();

export const setDealAffiliationPopup = createStandardAction(Types.SET_DEAL_AFFILIATION_POPUP)<IDealAffiliationPopupState>();

export const setListingEdited = createStandardAction(Types.SET_LISTING_EDITED)<number>();
export const setNewUnitPageMapExpanded = createStandardAction(Types.SET_NEW_UNIT_PAGE_MAP_EXPANDED)<boolean>();
export const setMobileDiscoveryDisabled = createStandardAction(Types.SET_MOBILE_DISCOVERY_DISABLED)();
export const setMobileDiscoveryEnabled = createStandardAction(Types.SET_MOBILE_DISCOVERY_ENABLED)();

export enum ViewTypes {
  Needs = 'Needs',
  Commute = 'Commute',
  PriceAndBedrooms = 'PriceAndBedrooms',
  Price = 'Price',
  Bedrooms = 'Bedrooms',
}

interface DeleteSaveSearchMeta {
  source: 'search_result_page' | 'saved_searches_page';
}

export interface Payload {
  activeView: ViewTypes;
  priorities: Array<[ SortField, SortDirection ]>;
  commutePreference: CommutePreference;
  priceRange: [number, number];
  roomsRange: [RoomOption, RoomOption];
}
