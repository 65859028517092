import styled from '@emotion/styled';
import { Text } from 'ds/components/typography';

export const Wrapper = styled.div`
  ${({ theme }) => `
    text-align: center;
    padding: ${theme.spacing(6)} 0 ${theme.spacing(5)} 0;

    a {
      color: ${theme.colors.brand.main};
      text-decoration: underline;
    }
  `}
`;

export const Subtitle = styled(Text)`
  width: 370px;
  max-width: 100%;
  margin: 0 auto ${({ theme }) => theme.spacing(4)};
`;
