import React from 'react';
import { ClickAwayListener } from 'components/authentication/ClickAwayListener';
import { Manager, Popper, Reference } from 'react-popper';
import { Placement, Modifiers } from 'popper.js';
import { TooltipContainer } from 'ds/components/tooltip';
import { Wrapper, InnerRefEl } from './styled';

interface SeoTooltipProps {
  refElement: React.ReactNode;
  placement: Placement;
  modifiers?: Modifiers;
  positionFixed?: boolean;
  eventsEnabled?: boolean;
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const SeoTooltip: React.FC<SeoTooltipProps> = (props) => {
  const {
    children,
    placement,
    modifiers,
    refElement,
    positionFixed,
    eventsEnabled,
    visible,
    setVisible,
  } = props;

  const showTooltip = () => {
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  return (
    <Wrapper>
      <Manager>
        <Reference>
          {({ ref }) => (
            <InnerRefEl
              ref={ref}
              onMouseEnter={showTooltip}
              onMouseLeave={hideTooltip}
            >
              {refElement}
            </InnerRefEl>
          )}
        </Reference>
        <ClickAwayListener onClickAway={hideTooltip}>
          <Popper
            positionFixed={positionFixed}
            eventsEnabled={eventsEnabled}
            placement={placement}
            modifiers={modifiers}
          >
            {({ ref, style, arrowProps, scheduleUpdate }) => (
              <TooltipContainer
                linkRef={ref}
                style={{ ...style, top: '50px', transform: 'none' }}
                placement={placement}
                arrowProps={arrowProps}
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                styleVisibility={visible}
                scheduleUpdate={scheduleUpdate}
                hideArrow
              >
                {children}
              </TooltipContainer>
            )}
          </Popper>
        </ClickAwayListener>
      </Manager>
    </Wrapper>
  );
};
