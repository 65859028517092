import { Route, State as RouteState } from 'config/routes';
import { ShowAt } from 'consts/breakpoints';
import React, { lazy, useEffect } from 'react';
import { Layout } from './Layout';
import Navigation from 'components/navigation';
import Profile from 'components/profile';
import CheckAddress from 'components/check-address';
import { SafeSuspense } from 'utils/SafeSuspense';
import loadable from '@loadable/component';
import { AppMode } from 'store/state/selectors/router';
import { ROUTES_WITH_MAP, ROUTES_WITH_SUBHEADER } from './types';
import { isSearchRoute } from 'utils/marketplaceRoutes';
import { ContentWidthMode } from 'utils/entities';

const AddressPage = loadable(() => import('screens/AddressPage'));
const SearchPage = loadable(() => import('screens/List/residential'));
const CommercialSearchPage = loadable(() => import('screens/List/commercial'));
const HomePage = loadable(() => import('screens/HomePage'));
const ResidentialProjectPage = loadable(() => import('screens/ProjectPage/residential'));
const CommercialProjectPage = loadable(() => import('screens/ProjectPage/commercial'));
const ShortlistPage = loadable(() => import('screens/ShortlistPage'));
const SavedSearchPage = loadable(() => import('screens/SavedSearchPage'));
const PushSettingsPage = loadable(() => import('screens/PushSettingsPage'));
const MyHomesPage = loadable(() => import('screens/MyHomesPage'));
const AreaPage = loadable(() => import('screens/AreaPage'));
const UnsubscribePage = loadable(() => import('screens/UnsubscribePage'));
const ManageBulletinsPage = loadable(() => import('screens/ManageBulletinsPage'));
const ListingPage = loadable(() => import('screens/ListingPage/residential'));
const CommercialListingPage = loadable(() => import('screens/ListingPage/commercial'));
const SoldPage = loadable(() => import('screens/SoldPage'));
const StreetPage = loadable(() => import('screens/StreetPage'));
const MadadArchive2020Page = loadable(() => import('screens/MadadArchivePage/2020'));
const MadadArchiveSwitcher = loadable(() => import('screens/MadadArchivePage/MadadArchiveSwitcher'));
const MadadPage = loadable(() => import('screens/MadadPage'));
const MadadAgentPage = loadable(() => import('screens/MadadAgentPage'));
const AccessibilityPage = loadable(() => import('screens/AccessibilityPage'));
const TermsPage = loadable(() => import('screens/TermsPage'));
const MadadSearchPage = loadable(() => import('screens/MadadSearchPage'));
const SitemapPage = loadable(() => import('screens/Sitemap'));
const CommercialMarketPage = loadable(() => import('screens/HomePageCommercial'));
const DeveloperPage = loadable(() => import('screens/DeveloperPage'));
const MortgageOfficePage = loadable(() => import('screens/MortgageOfficePage'));
const DevelopersSearchPage = loadable(() => import('screens/DevelopersSearchPage/developers-search'));
const DevelopersSearchCityPage = loadable(() => import('screens/DevelopersSearchPage/developers-city'));
const MortgageOfficesSearchPage = loadable(() => import('screens/MortgageOfficesSearchPage'));
const EmploymentAreaPage = loadable(() => import('screens/EmploymentAreaPage'));
const OfficePage = loadable(() => import('screens/AgentOfficePages/OfficePage'));
const AgentPage = loadable(() => import('screens/AgentOfficePages/AgentPage'));
const DevelopersPromotionPage = loadable(() => import('screens/PromotionPages/DevelopersPromotionPage'));
const AgentsPromotionPage = loadable(() => import('screens/PromotionPages/AgentsPromotionPage'));
const DealsPage = loadable(() => import('screens/DealsPage'));
const ListingsPage = loadable(() => import('screens/ListingsPage'));

const UploadBulletinPage = lazy(() => import('screens/UploadBulletinPage'));
const EditBulletinPage = lazy(() => import('screens/EditBulletinPage'));
const UploadBulletinFormPage = lazy(() => import('screens/BulletinFormPage'));
const EditBulletinFormPage = lazy(() => import('screens/EditBulletinFormPage'));
const AccessibilityApp = lazy(() => import('screens/Accessibility'));

interface MainProps {
  route: RouteState;
  isMobileInsightsMapView: boolean;
  isMobileSearchMapViewActive: boolean;
  isMobileMapViewActive: boolean;
  isMobileLayersToggleOpen: boolean;
  isMapExpanded: boolean;
  setMobileSearchMapViewActive: (status: boolean) => void;
  mode: AppMode;
  contentWidthMode: ContentWidthMode;
}

export const Main: React.FunctionComponent<MainProps> = (props) => {
  const {
    route,
    isMobileInsightsMapView,
    isMobileSearchMapViewActive,
    isMobileMapViewActive,
    isMobileLayersToggleOpen,
    isMapExpanded,
    setMobileSearchMapViewActive,
    mode,
    contentWidthMode,
  } = props;

  useEffect(() => {
    const maybeScrollY = route && route.meta && route.meta.middlewareMeta && route.meta.middlewareMeta.scrollY;
    if (maybeScrollY !== undefined) {
      setTimeout(() => window.scrollTo(0, maybeScrollY), 1);
    }
  }, [ route ]);

  let mainContent: React.ReactNode = null;

  const isSearch = isSearchRoute(route.name);
  const isMapPresent = ROUTES_WITH_MAP.has(route.name);
  const isSubheaderPresent = ROUTES_WITH_SUBHEADER.has(route.name);

  switch (route.name) {
    case Route.Home:
      return <HomePage />;

    case Route.UnsubscribePage:
      return <UnsubscribePage />;

    case Route.ManageBulletins:
      mainContent = <ManageBulletinsPage />;
      break;

    case Route.ProjectPage:
      mainContent = (
        <ShowAt to={2}>
          {(isMobile) => <ResidentialProjectPage key={route.params.id} isMobile={isMobile} />}
        </ShowAt>
      );
      break;
    case Route.ProjectPageCommercial:
      mainContent = (
        <ShowAt to={2}>
          {(isMobile) => <CommercialProjectPage key={route.params.id} isMobile={isMobile} />}
        </ShowAt>
      );
      break;
    case Route.ProjectPromotionPage:
      return <main />;

    case Route.Sold:
      mainContent = (
        <SoldPage />
      );
      break;

    case Route.StreetPage:
      mainContent = <StreetPage />;
      break;

    case Route.EditBulletin:
    case Route.EditCommercialBulletin:
      mainContent = (
        <SafeSuspense>
          <EditBulletinPage key={route.params.id} />
        </SafeSuspense>
      );
      break;

    case Route.UnitPage:
      return (
        <ListingPage
          key={route.params.id}
        />
      );
    case Route.UnitPageCommercial:
      return (
        <CommercialListingPage
          key={route.params.id}
        />
      );
    case Route.Search:
      mainContent = <SearchPage />;
      break;

    case Route.SearchCommercial:
      mainContent = <CommercialSearchPage />;
      break;

    case Route.AddressPage:
      mainContent = <AddressPage key={route.params.address} />;
      break;

    case Route.LocalPage:
      mainContent = <AreaPage key={route.params.id} />;
      break;

    case Route.CheckAddress:
      mainContent = <CheckAddress />;
      break;

    case Route.UploadBulletin:
      mainContent = (
        <SafeSuspense>
          <UploadBulletinPage />
        </SafeSuspense>
      );
      break;

    case Route.Profile:
      mainContent = <Profile />;
      break;

    case Route.Shortlist:
      mainContent = <ShortlistPage />;
      break;

    case Route.SavedSearchesPage:
      mainContent = <SavedSearchPage />;
      break;

    case Route.PushSettings:
      mainContent = <PushSettingsPage />;
      break;

    case Route.MyHomes:
      mainContent = <MyHomesPage />;
      break;

    case Route.Sitemap:
      mainContent = <SitemapPage />;
      break;

    case Route.MadadPage:
    case Route.MadadCity:
      return <MadadPage />;

    case Route.MadadAgentPage:
      mainContent = <MadadAgentPage />;
      break;

    case Route.MadadArchive2020Page:
      mainContent = <MadadArchive2020Page key={route.params.id} />;
      break;

    case Route.MadadArchivePage:
    case Route.MadadCityArchive:
      return <MadadArchiveSwitcher />;

    case Route.MadadSearchPage:
      mainContent = <MadadSearchPage />;
      break;
    case Route.TermsPage:
      mainContent = <TermsPage />;
      break;
    case Route.Accessibility:
      mainContent = <AccessibilityPage />;
      break;
    case Route.Deals:
      mainContent = <DealsPage />;
      break;
    case Route.Listings:
      mainContent = <ListingsPage />;
      break;
    case Route.UploadBulletinForm:
      mainContent = (
        <SafeSuspense>
          <UploadBulletinFormPage />
        </SafeSuspense>
      );
      break;
    case Route.EditBulletinForm:
    case Route.EditResidentialBulletinForm:
      mainContent = (
        <SafeSuspense>
          <EditBulletinFormPage key={route.params.id} />
        </SafeSuspense>
      );
      break;

    case Route.DeveloperPage:
      return <DeveloperPage />;
    case Route.MortgageOfficePage:
      return <MortgageOfficePage />;
    case Route.DevelopersSearchPage:
      return <DevelopersSearchPage />;
    case Route.DevelopersCitySearchPage:
      return <DevelopersSearchCityPage />;
    case Route.MortgageOfficesSearchPage:
    case Route.MortgageOfficesCitySearchPage:
      return <MortgageOfficesSearchPage />;

    case Route.AriaSearchForm:
    case Route.AriaSearchResults:
    case Route.AriaUnit:
    case Route.AriaProject:
      return (
        <SafeSuspense>
          <AccessibilityApp />
        </SafeSuspense>
      );

    case Route.CommercialMarketLanding:
      return <CommercialMarketPage />;

    case Route.EmploymentAreaPage:
      mainContent = <EmploymentAreaPage />;
      break;

    case Route.OfficePage:
      return <OfficePage />;
    case Route.AgentPage:
      return <AgentPage />;
    case Route.MadadLottery:
    case Route.MadadLotteryTerms:
    case Route.Israel4ever:
    case Route.AutomationTestPage:
      return <div />;
    case Route.DevelopersPromotionPage:
      return <DevelopersPromotionPage />;
    case Route.AgentsPromotionPage:
      return <AgentsPromotionPage />;
  }

  return (
    <ShowAt to={2}>
      {(isMobile) => {
        const isMobileInsightsMap = isMobileInsightsMapView && isMobile;
        const isMobileSearchMap = isMobileSearchMapViewActive && isMobile && isSearch;
        const isMobileUnitMap = isMobileMapViewActive && isMobile;
        const isMobileMapSlidedInView = isMobileInsightsMap || isMobileSearchMap || isMobileUnitMap;
        const isFullScreenMobileMap = isMobileInsightsMap || isMobileSearchMap || isMobileUnitMap;

        return (
          <>
            <Layout
              mode={mode}
              isSearch={isSearch}
              isMobile={isMobile}
              isMobileInsightsMap={isMobileInsightsMap}
              isMobileOtherMap={isMobileMapSlidedInView}
              isMobileUnitMap={isMobileUnitMap}
              mainContent={mainContent}
              isMapPresent={isMapPresent}
              isSubheaderPresent={isSubheaderPresent}
              isMobileLayersToggleOpen={isMobileLayersToggleOpen}
              isMapExpanded={isMapExpanded}
              contentWidthMode={contentWidthMode}
              headerContent={(
                <Navigation
                  hideHeader={isFullScreenMobileMap}
                  isMobileMapViewActive={isMobileSearchMapViewActive}
                  setMobileMapViewActive={setMobileSearchMapViewActive}
                  isNavigationSubheaderVisible={false}
                />
              )}
            />
          </>
        );
      }}
    </ShowAt>
  );
};
