import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { isEmpty, omit } from 'lodash';
import * as Types from 'store/state/uploadBulletin/types';
import {
  editListingAction,
  newListingAction,
  reportAddressErrorAction,
  editListingStatusAction,
  editListingPromoteStatusAction,
  validateUploadPhoneAction,
  listingsTableInlineUpdateSuccess,
  editListingAssignedAgentAction,
} from 'store/state/uploadBulletin/actions';
import {
  mutate,
  SetMutationResponseAction,
  resetMutationResponse,
} from 'store/state/mutationsResponse/actions';
import {
  IConsentType,
  IEditCommercialListingResponse,
  IEditListingResponse,
  INewCommercialListingResponse,
  INewListingResponse,
  IUpdateUserProfileVariables,
  ListingResponseType,
  LoadType,
  MutationType,
  UpdateListingResponse,
} from './apiService/types';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { Route } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { uploadBulletinDraft } from 'components/bulletin-form/wizard/helpers';
import {
  closeManageListingsErrorModal,
  setAfterUploadBulletinModalOpen,
  setIsListingsTableLoading,
  setListingEdited,
  setManageListingsErrorModal,
  setToastMessage,
} from 'store/state/app/actions';
import { IMutationResponse, uploadBulletinBadAddressReportSelector } from '../state/mutationsResponse/selectors';
import {
  BulletinStatusType,
  IEditBulletinInput,
  INewBulletinCommercialInput,
  INewBulletinInput,
  INewBulletinResidentialInput,
  PocType,
  PoiId,
  User,
  ListingStatusType,
  IGeneralCondition,
} from 'utils/entities';
import { isAgentConsoleUserSelector, isAgentManagerSelector, isAgentSelector, userProfileSelector } from 'store/state/domainData/selectors';
import { resetDomainData } from 'store/state/domainData/actions';
import { CLOSE_MANAGE_LISTINGS_ERROR_MODAL } from 'store/state/app/types';
import { routeSelector } from 'store/state/selectors/router';
import { manageListingsErrorModalSelector } from 'store/state/app/selectors';
import { queryData } from 'store/sagas/apiService';
import { isPhoneRegisteredDomainSelector } from 'store/state/domainData/selectors/common';

const REMARKS_DELIMITER = '___';

const newListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.NewListing
);

const newCommercialListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.NewCommercialListing
);

const editListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditListing
);

const editCommercialListingMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditCommercialListing
);

const listingInlineUpdatePattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && (
    action.mutationType === MutationType.EditListingStatus ||
    action.mutationType === MutationType.EditCommercialListingStatus ||
    action.mutationType === MutationType.EditListingPromotionStatus ||
    action.mutationType === MutationType.EditCommercialListingPromotionStatus ||
    action.mutationType === MutationType.EditCommercialListingAssignedAgent ||
    action.mutationType === MutationType.EditListingAssignedAgent
  )
);

function* reportErrorAddress(action: ReturnType<typeof reportAddressErrorAction>) {
  const { email, message } = action.payload;
  const draftValues = uploadBulletinDraft.get();
  const { docId } = draftValues.formValues.addressDetails;

  const result: IMutationResponse<MutationType.UploadBulletinBadAddressReport> = yield select(uploadBulletinBadAddressReportSelector);

  yield put(mutate({
    mutationType: MutationType.UploadBulletinBadAddressReport,
    meta: {
      variables: {
        docId,
        remarks: message + REMARKS_DELIMITER + email,
      },
    },
  }));

  if (result && result.data && result.data.badAddressReport) {
    yield put(setToastMessage({ term: 'reportListing.popup.toasterMessageConfirmation' }));
  }
}

function* consentAcceptanceWorker() {
  yield put(mutate({
    mutationType: MutationType.AcceptConsents,
    meta: {
      variables: {
        input: {
          consents: [ IConsentType.LISTING_UPLOAD_TERMS_AND_CONDITIONS ],
        },
      },
    },
  }));
}

const generalConditionNewMap = {
  [IGeneralCondition.ToRenovated]: IGeneralCondition.ToRenovate,
};

function mapGeneralConditionToNew(generalCondition: IGeneralCondition): IGeneralCondition {
  return generalConditionNewMap[generalCondition] || generalCondition;
}

function* prepareListingData (listing: INewBulletinInput | IEditBulletinInput, isEdit?: boolean) {
  const isAgent = yield select(isAgentSelector);
  const isAgentManager = yield select(isAgentManagerSelector);
  const { type, userName, userPhone, generalCondition, ...rest } = listing;
  const assignedAgentUserId = 'assignedAgentUserId' in listing && isAgentManager ? listing.assignedAgentUserId : undefined;

  yield fork(consentAcceptanceWorker);

  return  {
    ...omit(rest, 'sellerType'),
    ...(isEdit ? {} : { sellerType: isAgent ? PocType.Agent : PocType.Private }),
    generalCondition: mapGeneralConditionToNew(generalCondition),
    userName: isAgent ? null : userName,
    userPhone: isAgent ? null : userPhone,
    assignedAgentUserId,
  };
}

function* editListingWatcher(action: ReturnType<typeof editListingAction>) {
  const { payload: { listing } } = action;
  const data = yield call(prepareListingData, listing, true);

  if (listing.type === 'commercialBulletin') {
    yield put(mutate({
      mutationType: MutationType.EditCommercialListing,
      meta: {
        variables: { editCommercialListing: data },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.EditListing,
      meta: {
        variables: { editListing: data },
      },
    }));
  }
}

function* createNewListingWatcher(action: ReturnType<typeof newListingAction>) {
  const { payload: { listing } } = action;
  const data = yield call(prepareListingData, listing);

  if (listing.type === 'commercialBulletin') {
    yield put(mutate({
      mutationType: MutationType.NewCommercialListing,
      meta: {
        variables: { newCommercialListing: data as INewBulletinCommercialInput },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.NewListing,
      meta: {
        variables: { newListing: data as INewBulletinResidentialInput },
      },
    }));
  }

  const isAgent = yield select(isAgentSelector);
  if (isAgent) return;

  const currentUser: User = yield select(userProfileSelector);
  const metaVariables: IUpdateUserProfileVariables = {};
  if (!currentUser.firstName) {
    const [ firstName, lastName ] = data.userName.split(' ');
    if (firstName) {
      metaVariables.firstName = firstName;
    }
    if (lastName) {
      metaVariables.lastName = lastName;
    }
  }
  if (!currentUser.phone) {
    metaVariables.phone = data.userPhone;
  }
  if (!isEmpty(metaVariables)) {
    yield put(mutate({
      mutationType: MutationType.UpdateUserProfile,
      meta: {
        variables: metaVariables,
      },
    }));
  }
}

function* listingResponseErrorWatcher(data: INewListingResponse | IEditListingResponse | INewCommercialListingResponse | IEditCommercialListingResponse) {
  if (!data) {
    yield put(setToastMessage({ term: 'bulletinForm.wizard.errorToast', type: 'error' }));
    return;
  }

  switch (data.__typename) {
    case ListingResponseType.UserCannotAddListingError:
    case ListingResponseType.GeneralListingError:
    case ListingResponseType.InvalidInputError:
      yield put(setManageListingsErrorModal({ type: data.__typename, field: data.field }));
      break;
    case ListingResponseType.NoPermissionsError:
      yield put(setToastMessage({ term: 'bulletinForm.wizard.errorToast', type: 'error' }));
      break;
    default: {
      yield fork(navigateToListingsPageWorker);
    }
  }
}

function* newListingResponseHandler(id: PoiId, isCommercial?: boolean) {
  yield put(navigateTo(isCommercial ? Route.UnitPageCommercial : Route.UnitPage, { id }, { replace: true }));
  yield put(setAfterUploadBulletinModalOpen('UPLOAD'));
  yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
  uploadBulletinDraft.clear();
}

function* newCommercialListingResponseWatcher(action: SetMutationResponseAction<MutationType.NewCommercialListing>) {
  const newCommercialListing = action.meta.response.data && action.meta.response.data.newCommercialListing;
  const commercialListing = newCommercialListing && newCommercialListing.commercialListing;

  if (commercialListing) {
    yield call(newListingResponseHandler, commercialListing.id, true);
  }
  else {
    yield fork(listingResponseErrorWatcher, newCommercialListing);
  }
}

function* newResidentialListingResponseWatcher(action: SetMutationResponseAction<MutationType.NewListing>) {
  const { data } = action.meta.response;

  if (data && data.newListing) {
    const { listing } = data.newListing;

    if (listing) {
      yield call(newListingResponseHandler, data.newListing.listing.id);
    }
    else {
      yield fork(listingResponseErrorWatcher, data.newListing);
    }
  }
  else {
    yield fork(listingResponseErrorWatcher, undefined);
  }
}

export function* navigateToListingsPageWorker() {
  const isAgentConsoleUser: boolean = yield select(isAgentConsoleUserSelector);
  yield put(navigateTo(isAgentConsoleUser ? Route.Listings : Route.ManageBulletins, {}, { replace: true }));
}

function* editListingResponseWorker(listing: UpdateListingResponse, isCommercial?: boolean) {
  const { id, status: { status } } = listing;
  yield put(resetDomainData({ loadType: LoadType.UnitPageBulletin }));
  yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
  yield put(setListingEdited(Date.now()));
  if (status === BulletinStatusType.Displayed) {
    yield put(navigateTo(isCommercial ? Route.UnitPageCommercial : Route.UnitPage, { id }, { replace: true, includeFrozen: true }));
    yield put(setAfterUploadBulletinModalOpen('UPDATE'));
  }
  else {
    yield fork(navigateToListingsPageWorker);
  }
  yield put(resetDomainData({ loadType: LoadType.EditListingPoi }));
}

function* editResidentialListingResponseWatcher(action: SetMutationResponseAction<MutationType.EditListing>) {
  const { data: { editListing } } = action.meta.response;
  if (editListing.listing) {
    yield call(editListingResponseWorker, editListing.listing);
  }
  else {
    yield fork(listingResponseErrorWatcher, editListing);
  }
}
function* editCommercialListingResponseWatcher(action: SetMutationResponseAction<MutationType.EditCommercialListing>) {
  const { data } = action.meta.response;
  if (data && data.editCommercialListing && data.editCommercialListing.commercialListing) {
    yield call(editListingResponseWorker, data.editCommercialListing.commercialListing, true);
  }
  else {
    yield fork(listingResponseErrorWatcher, data ? data.editCommercialListing : undefined);
  }
}

type InlineUpdateMutationAction = SetMutationResponseAction<
    MutationType.EditCommercialListingStatus
  | MutationType.EditCommercialListingPromotionStatus
  | MutationType.EditListingStatus
  | MutationType.EditListingPromotionStatus
  | MutationType.EditCommercialListingAssignedAgent
  | MutationType.EditListingAssignedAgent
>;

function* listingInlineUpdateResponseWatcher(action: InlineUpdateMutationAction) {
  const { data } = action.meta.response;
  let isSuccess = false;

  switch (action.mutationType) {
    case MutationType.EditCommercialListingStatus:
      if ('editCommercialListingStatus' in data && data.editCommercialListingStatus.commercialListing) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changeStatusModal.toast.success', params: { newStatus: data.editCommercialListingStatus.commercialListing.status.status }, type: 'success' }));
      }
      break;
    case MutationType.EditListingStatus:
      if ('editListingStatus' in data && data.editListingStatus.listing) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changeStatusModal.toast.success', params: { newStatus: data.editListingStatus.listing.status.status }, type: 'success' }));
      }
      break;
    case MutationType.EditCommercialListingPromotionStatus:
      if ('editCommercialListingPromotionStatus' in data && data.editCommercialListingPromotionStatus.commercialListing) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changePromotionStatus.toast.success', params: { newPromotionStatus: data.editCommercialListingPromotionStatus.commercialListing.status.promoted }, type: 'success' }));
      }
      break;
    case MutationType.EditListingPromotionStatus:
      if ('editListingPromotionStatus' in data && data.editListingPromotionStatus.id) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changePromotionStatus.toast.success', params: { newPromotionStatus: data.editListingPromotionStatus.promoted }, type: 'success' }));
      }
      break;
    case MutationType.EditCommercialListingAssignedAgent:
      if ('editAssignedAgentCommercialListing' in data && data.editAssignedAgentCommercialListing.commercialListing) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changeAssignedAgent.success', type: 'success' }));
      }
      break;
    case MutationType.EditListingAssignedAgent:
      if ('editAssignedAgentListing' in data && data.editAssignedAgentListing.listing) {
        isSuccess = true;
        yield put(setToastMessage({ term: 'listingsPage.changeAssignedAgent.success', type: 'success' }));
      }
      break;
  }

  if (isSuccess) {
    yield put(listingsTableInlineUpdateSuccess());
  }
  else {
    yield fork(listingResponseErrorWatcher, undefined);
    yield put(setIsListingsTableLoading(false));
  }
}

function* closeErrorModalWatcher(action: ReturnType<typeof closeManageListingsErrorModal>) {
  const route = yield select(routeSelector);

  if (route.name === Route.UploadBulletin) {
    const modalData = yield select(manageListingsErrorModalSelector);
    if (modalData && modalData.type === ListingResponseType.UserCannotAddListingError) {
      uploadBulletinDraft.clear();
    }
  }

  if (route.name !== Route.ManageBulletins || route.name !== Route.Listings) {
    yield put(resetDomainData({ loadType: LoadType.UserAllListings }));
    if (!action.payload || !action.payload.noRedirect) {
      yield fork(navigateToListingsPageWorker);
    }
  }

  yield put(setManageListingsErrorModal(null));
}


const PoiStatusToListingStatusMap: Record<BulletinStatusType, ListingStatusType> = {
  [BulletinStatusType.Displayed]: ListingStatusType.Displayed,
  [BulletinStatusType.Deleted]: ListingStatusType.Deleted,
  [BulletinStatusType.Frozen]: ListingStatusType.Frozen,
};

function* editListingStatusWorker(action: ReturnType<typeof editListingStatusAction>) {
  const { payload: { id, type, status } } = action;
  const isBulletin = type === 'bulletin';
  const mutationType = isBulletin ? MutationType.EditListingStatus : MutationType.EditCommercialListingStatus;

  yield put(setIsListingsTableLoading(true));

  yield put(mutate({
    mutationType,
    meta: {
      variables: {
        editListingStatus: {
          status: PoiStatusToListingStatusMap[status],
          id,
        },
      },
    },
  }));
}

function* editAssignedAgentWorker(action: ReturnType<typeof editListingAssignedAgentAction>) {
  const { payload: { id, type, agentId, userId } } = action;
  const isBulletin = type === 'bulletin';

  yield put(setIsListingsTableLoading(true));

  if (isBulletin) {
    yield put(mutate({
      mutationType: MutationType.EditListingAssignedAgent,
      meta: {
        variables: {
          editAssignedAgentListing: {
            listingId: id,
            assignedAgentId: agentId,
          },
        },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.EditCommercialListingAssignedAgent,
      meta: {
        variables: {
          editAssignedAgentCommercialListing: {
            listingId: id,
            assignedAgentUserId: userId,
          },
        },
      },
    }));
  }
}

function* editListingPromoteStatusWorker(action: ReturnType<typeof editListingPromoteStatusAction>) {
  const { payload: { id, promotedStatus: promoted, type } } = action;
  const isBulletin = type === 'bulletin';

  yield put(setIsListingsTableLoading(true));

  if (isBulletin) {
    yield put(mutate({
      mutationType: MutationType.EditListingPromotionStatus,
      meta: {
        variables: {
          editListingPromotionStatus: {
            id,
            promoted,
          },
        },
      },
    }));
  }
  else {
    yield put(mutate({
      mutationType: MutationType.EditCommercialListingPromotionStatus,
      meta: {
        variables: {
          editCommercialListingPromotionStatus: {
            id,
            promoted,
          },
        },
      },
    }));
  }
}

function* validatePhoneWorker(action: ReturnType<typeof validateUploadPhoneAction>) {
  yield put(resetDomainData({ loadType: LoadType.IsPhoneRegistered }));
  yield put(resetMutationResponse({ mutationType: MutationType.GenerateValidationCode, key: null }));
  yield put(resetMutationResponse({ mutationType: MutationType.CheckValidationCode, key: null }));

  const phoneNumber = action.payload;

  yield call(queryData, {
    loadType: LoadType.IsPhoneRegistered,
    meta: { variables: { phoneNumber } },
  });

  const isRegisteredResponse = yield select(isPhoneRegisteredDomainSelector);
  const isRegistered = isRegisteredResponse && isRegisteredResponse.data ? isRegisteredResponse.data.isPhoneRegistered : null;

  if (isRegistered === false) {
    yield put(mutate({
      mutationType: MutationType.GenerateValidationCode,
      meta: { variables: { phoneNumber } },
    }));
  }
}


export function* addEditBulletinWatcher() {
  yield takeLatest(Types.REPORTING_ERROR_ADDRESS, reportErrorAddress);
  yield takeLatest(Types.NEW_LISTING, createNewListingWatcher);
  yield takeLatest(Types.EDIT_LISTING, editListingWatcher);

  yield takeLatest(newListingMutationPattern, newResidentialListingResponseWatcher);
  yield takeLatest(newCommercialListingMutationPattern, newCommercialListingResponseWatcher);
  yield takeLatest(editListingMutationPattern, editResidentialListingResponseWatcher);
  yield takeLatest(editCommercialListingMutationPattern, editCommercialListingResponseWatcher);

  yield takeEvery(Types.EDIT_LISTING_STATUS, editListingStatusWorker);
  yield takeEvery(Types.EDIT_LISTING_ASSIGNED_AGENT, editAssignedAgentWorker);
  yield takeEvery(Types.EDIT_LISTING_PROMOTE_STATUS, editListingPromoteStatusWorker);

  yield takeLatest(listingInlineUpdatePattern, listingInlineUpdateResponseWatcher);

  yield takeEvery(Types.VALIDATE_PHONE, validatePhoneWorker);
  yield takeEvery(CLOSE_MANAGE_LISTINGS_ERROR_MODAL, closeErrorModalWatcher);
}
