import styled from '@emotion/styled';
import { HEADER_HEIGHT } from 'components/navigation/header.constants';
import { SmallText, Text } from 'ds/components/typography';
import { typographyLineHeightCfg } from 'ds/components/typography/types';

export const Root = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT + 1}px;
  left: 80px;
  background: ${({ theme }) => theme.colors.neutrals.white};
  border-radius: ${({ theme }) => theme.borderRadius.tiny};
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.06)) drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.10));
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    height: 1px;
    width: 100%;
    background: transparent;
  }
`;

export const NoListingsTitle = styled(Text)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-left: ${({ theme }) => theme.spacing(12)};
  margin-right: ${({ theme }) => theme.spacing(12)};
`;

export const NoListingsSubtitle = styled(SmallText)`
  line-height: ${typographyLineHeightCfg.text}px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const NoListingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(8)};
  padding-bottom: ${({ theme }) => theme.spacing(12)};
`;

export const ShortlistButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1.5)} 0;
  background: ${({ theme }) => theme.colors.neutrals.grey11};
`;
