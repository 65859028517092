import { call, fork, getContext, put, select, take, takeEvery } from 'redux-saga/effects';
import { queryData } from './apiService';
import { AuthError, LoadType, MutationType, LoadOptions, DeregisterUserStatus } from './apiService/types';
import {
  FACEBOOK_LOGIN_WITH_CONSENTS,
  GOOGLE_LOGIN_WITH_CONSENTS,
  PASSWORD_LOGIN_WITH_CONSENTS,
  LOGOUT,
  SIGN_IN_SIGN_UP_WITH_EMAIL,
  UPDATE_USER,
  USER_LOGIN_WITH_EMAIL_AND_PASSWORD,
} from 'store/state/app/types';
import {
  loginFacebookWithConsents,
  loginGoogleWithConsents,
  loginWithPasswordAndConsents,
  logout,
  setAuthModal,
  setCommutePopupStatus,
  setToastMessage,
  signInSignUpWithEmail,
  updateUser,
  userLoginWithEmailAndPassword,
} from 'store/state/app/actions';
import { resetDomainData } from 'store/state/domainData/actions';
import { MUTATE, SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { RootAction } from 'store/state';
import { mutate, setMutationResponse } from 'store/state/mutationsResponse/actions';
import { AuthenticationModalType, AuthSource, UserAlreadyExists } from 'components/authentication/types';
import { GoogleAPI } from 'utils/gapi';
import config from 'config';
import { CommutePopupStatus } from 'components/commute-popup/types';
import { ProfileTab } from 'components/profile/Profile';
import { navigateTo } from 'store/state/router/actions';
import { Route, State as RouteState } from 'config/routes';
import { routeSelector, showAgentConsoleBulletinFormSelector } from 'store/state/selectors/router';
import { RESET, RESET_SSR_HYDRATION, SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { isConnectedUserSelector, isUserSelector, isVisitorUserSelector, userAuthErrorSelector } from 'store/state/domainData/selectors';
import { LoginResponseNG, ResetPasswordStatus } from 'utils/entities';
import { ApolloError } from 'apollo-client';
import { UserTokenStore } from 'helpers/userToken';
import { TRANSITION_SUCCESS, TRANSITION_START } from '../state/router/types';
import { mutateWorker } from './apiService/mutationsWatcher';
import { isServer } from 'utils';
import { isBot } from 'utils/bot';
import { authModalAuthSourceVariablesSelector, authModalStatusSelector } from 'store/state/app/selectors';
import { RouterAction } from 'store/state/router/actionType';
import { waitForUserResolve } from './routing/handlers/utils';
import { getQAAutomationParams } from 'utils/qa';
import { signInSuccessMutationPattern, signUpSuccessMutationPattern } from 'store/sagas/analytics/userAuthEvents';
import { uploadBulletinDraft } from 'components/bulletin-form/wizard/helpers';


function* registerVisitor(
  key: string = null
) {
  // defensive checks:
  const isUser: boolean = yield select(isUserSelector);
  const ssr: boolean = yield call(isServer);
  const isSEOMode: boolean = yield getContext('isSEOMode');
  const bot = isSEOMode || (!ssr && isBot());
  const canRegister = !(bot || ssr || isUser);

  if (canRegister) {
    yield call(mutateWorker, mutate({
      mutationType: MutationType.RegisterVisitor,
      meta: { variables: {}, key },
    }));
  }
  else {
    yield put(resetDomainData({ loadType: LoadType.CurrentUser }));
  }
}

interface IError {
  errorMessage: string;
}

const isImplementsError = (value: unknown): value is IError =>
  typeof value === 'object' && value !== null && 'errorMessage' in value;

interface AuthErrorMeta {
  reason: AuthError;
  message: string;
  __typename: LoginResponseNG['__typename'];
}

const maybeConvertToAuthErrorMeta = (response: LoginResponseNG): AuthErrorMeta | null => {
  if (isImplementsError(response)) {
    return {
      reason: response.errorMessage as AuthError,
      message: response.errorMessage,
      __typename: response.__typename,
    };
  }
  return null;
};

function maybeConvertToError(action: ReturnType<typeof setMutationResponse>): AuthErrorMeta {
  try {
    if (action.mutationType === MutationType.GoogleLogin) {
      return maybeConvertToAuthErrorMeta(action.meta.response.data.googleLogin);
    }
    if (action.mutationType === MutationType.SignInSignUpWithEmail) {
      return maybeConvertToAuthErrorMeta(action.meta.response.data.loginV3);
    }
    if (action.mutationType === MutationType.TempTokenAuth) {
      return maybeConvertToAuthErrorMeta(action.meta.response.data.completeAutoLogin);
    }
    if (action.mutationType === MutationType.FacebookLogin) {
      return maybeConvertToAuthErrorMeta(action.meta.response.data.facebookLogin);
    }
    if (action.mutationType === MutationType.LoginWithEmailAndPassword) {
      return maybeConvertToAuthErrorMeta(action.meta.response.data.passwordLoginV3);
    }
    return null;
  }
  catch {
    return null;
  }
}

function* userMutationsEffectsWorker(action: ReturnType<typeof setMutationResponse>) {
  const isUser: boolean = yield select(isUserSelector);
  const route: RouteState = yield select(routeSelector);
  const error = (action.meta.response && action.meta.response.errors && action.meta.response.errors[0]) || maybeConvertToError(action);

  if (error && error.reason === AuthError.InvalidRegistrationType) {
    yield put(setAuthModal({ type: UserAlreadyExists[error.message] }, { isUserInitiated: false }));
    return;
  }

  switch (action.mutationType) {
    case MutationType.DeregisterUser:
      switch (action.meta.response.data.deregisterUser.status) {
        case DeregisterUserStatus.DEREGISTER_USER_FAILURE:
        case DeregisterUserStatus.DEREGISTER_USER_NO_USER:
          yield put(setToastMessage({ term: 'authentication.deregister.fail' }));
          break;
        case DeregisterUserStatus.DEREGISTER_USER_SUCCESS:
          yield put(setToastMessage({ term: 'authentication.deregister.success' }));
          yield put(resetDomainData({ loadType: LoadType.UserContent }));
          yield put(resetDomainData({ loadType: LoadType.CurrentUser }));
          yield put(navigateTo(Route.Home));
          break;
      }
      break;
    case MutationType.TempTokenAuth:
      if (error) {
        yield put(resetDomainData({ loadType: LoadType.CurrentUser }));
        yield put(setToastMessage({ term: 'authentication.authError', params: { error: error.reason } }));
      }
      break;
    case MutationType.SignInSignUpWithEmail:
      const isConnectedUser = yield select(isConnectedUserSelector);
      if (isConnectedUser) {
        yield put(setAuthModal({ type: null }, { isUserInitiated: false }));
      }
      else if (error) {
        switch (error.reason) {
          case AuthError.ConfirmConsentsV2:
            yield put(setAuthModal({ type: AuthenticationModalType.Consents }, { isUserInitiated: false }));
            return;
          case AuthError.NeedPassword:
            yield put(setAuthModal({ type: AuthenticationModalType.EnterPassword }, { isUserInitiated: false }));
            return;
          case AuthError.NeedPasswordReset:
            yield put(setAuthModal({ type: AuthenticationModalType.NeedPasswordReset }, { isUserInitiated: false }));
            return;
          case AuthError.LoginFailed:
            return;
          case AuthError.InvalidAgentRegistration:
            yield put(setAuthModal({ type: AuthenticationModalType.AgentCompanyNotFound }, { isUserInitiated: false }));
            return;
          case AuthError.DeletedUser:
            yield put(setToastMessage({ term: 'authentication.authError.deleted.user' }));
            return;
        }
        yield put(setToastMessage({ term: 'authentication.authError', params: { error: error.reason } }));
      }
      break;
    case MutationType.FacebookLogin:
    case MutationType.GoogleLogin:
    case MutationType.LoginWithEmailAndPassword: {
      const isConfirmConsentsAuthError = (reason: AuthError) => reason === AuthError.ConfirmConsentsV2;
      if (isUser && !error) {
        yield put(setAuthModal({ type: null, authSource: null, authSourceVariables: null }, { isUserInitiated: false }));
        if (route.params.noLoginRedirect) {
          break;
        }
      }
      else if (action.mutationType === MutationType.GoogleLogin && error && isConfirmConsentsAuthError(error.reason)) {
        yield put(setAuthModal({
          type: AuthenticationModalType.Consents,
          authSource: AuthSource.Google,
          authSourceVariables: { idToken: action.meta.variables.idToken },
        }, { isUserInitiated: false }
        ));
      }
      else if (action.mutationType === MutationType.FacebookLogin && error && isConfirmConsentsAuthError(error.reason)) {
        const { email, accessToken, fbUserId } = action.meta.variables;
        yield put(setAuthModal({
          type: AuthenticationModalType.Consents,
          authSource: AuthSource.Facebook,
          authSourceVariables: { email, accessToken, fbUserId },
        }, { isUserInitiated: false }
        ));
      }
      else if (action.mutationType === MutationType.LoginWithEmailAndPassword && error && isConfirmConsentsAuthError(error.reason)) {
        const { email, password } = action.meta.variables;
        yield put(setAuthModal({
          type: AuthenticationModalType.Consents,
          authSource: AuthSource.EmailAndPassword,
          authSourceVariables: { email, password },
        }, { isUserInitiated: false }
        ));
      }
      else if (error && error.reason !== AuthError.LoginFailed) {
        yield put(setToastMessage({ term: 'authentication.authError', params: { error: error.reason } }));
      }

      break;
    }

    case MutationType.UpdateUserProfile: {
      if (isUser && action.meta.key && action.meta.key !== 'moveIn') {
        if (action.meta.key === 'commutePopup') {
          yield put(setCommutePopupStatus(CommutePopupStatus.Resolved));
          yield put(setToastMessage({ term: 'profile.saveChangesFeedbackMessage', params: { tab: ProfileTab.Personal } }));
        }
        else {
          yield put(setToastMessage({ term: 'profile.saveChangesFeedbackMessage', params: { tab: action.meta.key } }));
        }
      }
      break;
    }

    case MutationType.RequestPasswordReset: {
      if (action.meta.response.data.requestPasswordReset && action.meta.response.data.requestPasswordReset.status === ResetPasswordStatus.RESET_SUCCESS) {
        yield put(setAuthModal({ type: null }, { isUserInitiated: false }));
        yield put(setToastMessage({ term: 'authentication.resetPasswordRequestFeedbackMessage' }));
      }

      break;
    }
    case MutationType.ResetPasswordUpdate: {
      if (action.meta.response.data.resetPasswordUpdate && action.meta.response.data.resetPasswordUpdate.status === ResetPasswordStatus.RESET_SUCCESS) {
        yield put(setAuthModal({ type: null }, { isUserInitiated: false }));
        if (action.meta.key === 'agentProfile') {
          yield put(navigateTo(route.name, { ...route.params, showAgentPlans: true }));
        }
        else {
          yield put(navigateTo(Route.Home));
          yield put(setToastMessage({ term: 'authentication.resetPasswordUpdateFeedbackMessage' }));
        }
      }

      break;
    }
  }
}

function* userUpdateWorker(action: ReturnType<typeof updateUser>) {
  const isUser: boolean = yield select(isUserSelector);

  if (isUser) {
    yield put(mutate({
      mutationType: MutationType.UpdateUserProfile,
      meta: action.payload,
    }));
  }
}

function* loginOrRegisterWorker(
  mutationType: MutationType.LoginWithEmailAndPassword | MutationType.SignInSignUpWithEmail,
  action: ReturnType<typeof signInSignUpWithEmail> | ReturnType<typeof userLoginWithEmailAndPassword>
) {
  const userData = action.payload;

  yield put(mutate({
    meta: {
      variables: {
        ...userData,
      },
    },
    mutationType,
  }));
}

const ITEM_PAGE_ROUTES = new Set([
  Route.UnitPage,
  Route.MadadPage,
  Route.MadadArchivePage,
  Route.MadadArchive2020Page,
  Route.MadadSearchPage,
  Route.MadadAgentPage,
  Route.AddressPage,
  Route.ProjectPage,
]);

function* logoutWorker() {
  yield put(resetDomainData({ loadType: LoadType.CurrentUser }));
  if (uploadBulletinDraft.get()) uploadBulletinDraft.clear();

  const route: RouteState = yield select(routeSelector);

  if (!ITEM_PAGE_ROUTES.has(route.name)) {
    const routeName = route.params.dealType && route.params.term ? Route.Search : Route.Home;
    yield put(navigateTo(routeName, route.params));
  }
}

function* resetWatcher() {
  const userTokenStore: UserTokenStore = yield getContext('tokenStore');
  userTokenStore.remove();
  yield call(registerVisitor);
}

export enum TokenError {
  Expired = 'token-expired',
  Invalid = 'token-invalid',
}

export function isTokenInvalidError(e: Error) {
  return (e instanceof ApolloError) && (e.graphQLErrors.some(error => error.message === TokenError.Expired || error.message === TokenError.Invalid));
}

export function* fetchUser() {
  const userTokenStore: UserTokenStore = yield getContext('tokenStore');
  const route: RouteState = yield select(routeSelector);

  try {
    if (userTokenStore.get() && !route.params.loginToken) {
      const loadOpts: LoadOptions<LoadType.CurrentUser> = {
        loadType: LoadType.CurrentUser,
        meta: {
          variables: {},
          errorPolicy: 'none',
        },
      };
      yield call(queryData, loadOpts);
    }
    else if (route.params.loginToken) {
      yield call(mutateWorker, mutate({
        mutationType: MutationType.TempTokenAuth,
        meta: {
          variables: {
            loginToken: route.params.loginToken,
          },
        },
      }));
      yield put(navigateTo(route.name, { ...route.params, loginToken: undefined }, { ssrHttpStatus: 302, replace: true }));
    }
    else {
      yield call(registerVisitor);
    }
  }
  catch (e) {
    if (isTokenInvalidError(e)) {
      yield call(registerVisitor);
    }
  }
}

function* resetAuthOnRouteChangeWorker(action: RouterAction) {
  const authModal = yield select(authModalStatusSelector);
  const hasRouteChanged = action.type === TRANSITION_START // defensive check
    && action.payload.previousRoute
    && action.payload.route.name !== action.payload.previousRoute.name;

  if (authModal !== null && hasRouteChanged) {
    yield put(setAuthModal({ type: null }, { isUserInitiated: false }));
  }
}


const userTokenMutations: Set<MutationType> = new Set([
  MutationType.FacebookLogin,
  MutationType.GoogleLogin,
  MutationType.LoginWithEmailAndPassword,
  MutationType.SignInSignUpWithEmail,
  MutationType.TempTokenAuth,
  MutationType.RegisterVisitor,
  MutationType.UpdateUserProfile,
  MutationType.ResetPasswordUpdate,
  MutationType.RequestPasswordReset,
  MutationType.DeregisterUser,
]);

const checkUserMutationsResponse = (action: RootAction) => action.type === SET_MUTATION_RESPONSE && userTokenMutations.has(action.mutationType);

const resetUserDataPattern = (action: RootAction) => (
  action.type === RESET && action.payload.loadType === LoadType.CurrentUser
);

const loginSuccessMutationPattern = signInSuccessMutationPattern || signUpSuccessMutationPattern;

export const googleLoginPattern = (action: RootAction) => (
  (action.type === SET_DOMAIN_DATA && action.loadType === LoadType.CurrentUser)
  || (action.type === RESET && action.payload.loadType === LoadType.CurrentUser)
  || (action.type === LOGOUT)
  || (action.type === MUTATE)
  || (action.type === SET_MUTATION_RESPONSE)
  || (action.type === RESET_SSR_HYDRATION && action.payload.loadType === LoadType.CurrentUser)
);

export function* googleAuthWatcher(googleAPI: GoogleAPI, _: ReturnType<typeof logout>) {
  const ssr = yield call(isServer);
  const { googleLogin } = getQAAutomationParams();

  if (!ssr && !isBot() && googleLogin !== 'disable') {
    const logger = yield getContext('logger');

    yield call(waitForUserResolve);
    const isVisitor = yield select(isVisitorUserSelector);
    const userError = yield select(userAuthErrorSelector);

    if (isVisitor || userError) {
      try {
        let idToken: string = null;
        idToken = yield call([ googleAPI, googleAPI.oneTapLogin ]);
        if (!idToken) {
          idToken = yield call([ googleAPI, googleAPI.buttonLogin ]);
        }

        yield put(mutate({
          mutationType: MutationType.GoogleLogin,
          meta: {
            variables: {
              idToken,
            },
          },
        }));
      }
      catch {
        logger.log('Google login error');
      }
    }
  }
}

function* maybeRedirectToUploadBulletinFormWorker() {
  const route: RouteState = yield select(routeSelector);
  const showAgentConsoleBulletinForm: boolean = yield select(showAgentConsoleBulletinFormSelector);

  if (showAgentConsoleBulletinForm && route.name === Route.UploadBulletin) {
    yield put(navigateTo(Route.UploadBulletinForm, { ...route.params }, { replace: true }));
  }
}

function* googleAuthWithConsents(action: ReturnType<typeof loginGoogleWithConsents>) {
  const { idToken } = yield select(authModalAuthSourceVariablesSelector);
  yield put(mutate({
    mutationType: MutationType.GoogleLogin,
    meta: {
      variables: {
        idToken,
        consents: action.payload,
      },
    },
  }));
}

function* facebookAuthWithConsents(action: ReturnType<typeof loginFacebookWithConsents>) {
  const { fbUserId, email, accessToken } = yield select(authModalAuthSourceVariablesSelector);
  yield put(mutate({
    mutationType: MutationType.FacebookLogin,
    meta: {
      variables: {
        fbUserId,
        email,
        accessToken,
        consents: action.payload,
      },
    },
  }));
}

function* passwordAuthWithConsents(action: ReturnType<typeof loginWithPasswordAndConsents>) {
  const { email, password } = yield select(authModalAuthSourceVariablesSelector);
  yield put(mutate({
    mutationType: MutationType.LoginWithEmailAndPassword,
    meta: {
      variables: {
        email,
        password,
        consents: action.payload,
      },
    },
  }));
}

export function* userWatcher() {

  const googleAPI = new GoogleAPI({
    'client_id': config.google.clientId,
  });

  yield takeEvery(resetUserDataPattern, resetWatcher);
  yield takeEvery(checkUserMutationsResponse, userMutationsEffectsWorker);

  yield takeEvery(LOGOUT, logoutWorker);
  yield takeEvery(UPDATE_USER, userUpdateWorker);
  yield takeEvery(USER_LOGIN_WITH_EMAIL_AND_PASSWORD, loginOrRegisterWorker, MutationType.LoginWithEmailAndPassword);
  yield takeEvery(SIGN_IN_SIGN_UP_WITH_EMAIL, loginOrRegisterWorker, MutationType.SignInSignUpWithEmail);
  yield takeEvery(TRANSITION_START, resetAuthOnRouteChangeWorker);

  yield take(TRANSITION_SUCCESS);
  yield fork(fetchUser);

  yield takeEvery(loginSuccessMutationPattern, maybeRedirectToUploadBulletinFormWorker);
  yield takeEvery(googleLoginPattern, googleAuthWatcher, googleAPI);
  yield takeEvery(GOOGLE_LOGIN_WITH_CONSENTS, googleAuthWithConsents);
  yield takeEvery(FACEBOOK_LOGIN_WITH_CONSENTS, facebookAuthWithConsents);
  yield takeEvery(PASSWORD_LOGIN_WITH_CONSENTS, passwordAuthWithConsents);
}
