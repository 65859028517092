const BASE_STEP = 8;

export type SpacingScale = 0 | 0.25 | 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5 | 6 | 6.5 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25;

// needed for the fixture, please update this variable every time you make updates in the Type
export const spacingScale: SpacingScale[] = [ 0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 5, 6, 6.5, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25 ];

// spacing conversion table from Smadar:
// 3 = 4
// 5 = 4
// 6 = 8
// 7 = 8
// 9 = 8
// 11 = 12
// 13 = 12
// 14 = 16
// 15 = 16
// 17 = 16
// 18 = 16
// 19 = 16
// 21 = 24
// 22 = 24
// 23 = 24
// 25 = 24
// 26 = 24
// 27 = 24
// 28 = 24
// 29 = 32
// 30 = 32
// 31 = 32
// 32+ = closest scale step

type ScaleArg = [ SpacingScale ]
  | [ SpacingScale, SpacingScale ]
  | [ SpacingScale, SpacingScale, SpacingScale ]
  | [ SpacingScale, SpacingScale, SpacingScale, SpacingScale ];

export const getSpacing: (...scaleVal: ScaleArg) => string = (...scaleVal) =>
  scaleVal.map(v => v === 0 ? '0' : `${v * BASE_STEP}px`).join(' ');
