import React from 'react';
import { useLocale } from 'locale';
import { FormApi } from 'final-form';
import { useField } from 'react-final-form-hooks';
import { TextField } from 'ds/components/input/TextField';
import {
  ReportFormWrapper,
  CommentTextWrapper,
  EmailWrapper,
} from './styled';
import { isEmailValid } from 'utils/formValidationHelpers';
import { withValidationMessage } from 'hocs/withValidationMessage';

interface ReportFormFields {
  commentMessage: string;
  userEmail: string;
}

interface ReportFormProps {
  formInstance: FormApi<ReportFormFields>;
  inputWrapperRef: (el: HTMLDivElement) => void;
}

const WithValidationTextField = withValidationMessage(TextField);

export const ReportForm: React.FC<ReportFormProps> = ({
  formInstance,
  inputWrapperRef,
}) => {
  const { t } = useLocale();
  const commentMessage = useField('commentMessage', formInstance);
  const userEmail = useField('userEmail', formInstance);

  return (
    <ReportFormWrapper>
      <CommentTextWrapper>
        <div ref={inputWrapperRef}>
          <WithValidationTextField
            multiline
            fullWidth
            {...commentMessage.input}
            {...commentMessage.meta}
            label={t('bulletinForm.wizard.addressStep.reportAddressMessageLabel')}
            placeholder={t('bulletinForm.wizard.addressStep.reportAddressMessagePlaceholder')}
            type="text"
            rows={5}
          />
        </div>
      </CommentTextWrapper>
      <EmailWrapper>
        <WithValidationTextField
          {...userEmail.input}
          {...userEmail.meta}
          type="email"
          hasError={!isEmailValid}
          errorMessage={t('reportListing.popup.emailError')}
          fullWidth
          label={t('bulletinForm.wizard.addressStep.reportAddressEmailLabel')}
          placeholder={t('bulletinForm.wizard.addressStep.reportAddressEmailPlaceholder')}
        />
      </EmailWrapper>
    </ReportFormWrapper>
  );
};
