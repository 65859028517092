import { fork, select, call, put, take, takeEvery } from 'redux-saga/effects';
import { agentIdSelector, approvedAgentSelector, isAgentManagerSelector } from 'store/state/domainData/selectors';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { waitForUserResolve } from 'store/sagas/routing/handlers/utils';
import { resetDomainData } from 'store/state/domainData/actions';
import { queryData } from 'store/sagas/apiService';
import { getRouteParams } from 'store/state/selectors/router';
import { RootAction } from 'store/state';
import { TRANSITION_SUCCESS } from 'store/state/router/types';
import { DecodedRouteParams, Route } from 'config/routes';
import { LISTINGS_PER_PAGE } from 'screens/ListingsPage/utils';
import { isNil } from 'lodash';
import { LISTINGS_TABLE_INLINE_UPDATE_SUCCESS } from 'store/state/uploadBulletin/types';
import { setIsListingsTableLoading } from 'store/state/app/actions';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';

const paramsChangedPattern = (action: RootAction) => (
  (action.type === TRANSITION_SUCCESS && action.payload.route.name === Route.Listings)
  || action.type === LISTINGS_TABLE_INLINE_UPDATE_SUCCESS
);

const listingsQueryResponsePattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.GetResidentialAndCommercialListings
);


function* fetchAgentListings() {
  yield call(waitForUserResolve);

  const isApprovedAgent: boolean = yield select(approvedAgentSelector);
  const isAgentManager: boolean = yield select(isAgentManagerSelector);

  if (!isApprovedAgent && !isAgentManager) {
    yield put(resetDomainData({ loadType: LoadType.GetResidentialAndCommercialListings }));
    yield put(setIsListingsTableLoading(false));
    return;
  }

  yield fork(paramsChangedWatcher);
}

function* paramsChangedWatcher() {
  while (true) {
    yield put(setIsListingsTableLoading(true));

    yield put(resetDomainData({ loadType: LoadType.GetResidentialAndCommercialListings }));
    yield put(resetDomainData({ loadType: LoadType.GetBulletinsUsageOverview }));
    yield put(resetDomainData({ loadType: LoadType.GetAgentBulletinsUsageOverview }));

    const routeParams: DecodedRouteParams = yield select(getRouteParams);
    const isAgentManager: boolean = yield select(isAgentManagerSelector);
    const agentId: string = yield select(agentIdSelector);
    const id = isAgentManager ? routeParams.agentId : agentId;

    const getListingsLoadOptions: LoadOptions<LoadType.GetResidentialAndCommercialListings> = {
      loadType: LoadType.GetResidentialAndCommercialListings,
      meta: {
        variables: {
          offset: !isNil(routeParams.page) ? (routeParams.page - 1) * LISTINGS_PER_PAGE : 0,
          limit: LISTINGS_PER_PAGE,
          sortOptions: {
            sortColumn: routeParams.sort && (routeParams.sort[0] && routeParams.sort[0][0]) || 'lastUpdated',
            sortOrder: routeParams.sort && (routeParams.sort[0] && routeParams.sort[0][1]) || 'desc',
          },
          filters: {
            textFilterType: routeParams.address ? 'ADDRESS' : 'EMPTY',
            textFilterValue: routeParams.address,
            agents: isAgentManager && routeParams.agentId ? [ routeParams.agentId ] : undefined,
          },
        },
      },
    };

    const getUsageOverviewOptions: LoadOptions<LoadType.GetBulletinsUsageOverview> = {
      loadType: LoadType.GetBulletinsUsageOverview,
      meta: {
        variables: {},
      },
    };

    const getAgentUsageOverviewOptions: LoadOptions<LoadType.GetAgentBulletinsUsageOverview> = {
      loadType: LoadType.GetAgentBulletinsUsageOverview,
      meta: {
        variables: {
          agents: id ? [ id ] : undefined,
        },
      },
    };

    yield fork(queryData, getListingsLoadOptions);
    yield fork(queryData, getUsageOverviewOptions);
    yield fork(queryData, getAgentUsageOverviewOptions);

    yield take(paramsChangedPattern);
  }
}

function* listingsResponseWatcher() {
  yield put(setIsListingsTableLoading(false));
}

export function* listingsPageHandler() {
  yield fork(fetchAgentListings);
  yield takeEvery(listingsQueryResponsePattern, listingsResponseWatcher);
}

