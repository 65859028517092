import { createIsLoadingSelector, makeNullSafeDomainGetter, unitBulletinsByIdsSelector, unitCommercialBulletinsByIdsSelector } from './common';
import { flow, head } from 'lodash';
import { createSelector } from 'reselect';
import { makeInsightsByTypeSelector, metaInsightsCurrentDocIdSelector } from './insights';
import {
  IBulletin,
  InsightDescriptor,
  PocType,
  ResolutionPreference,
  ICommercialBulletin,
  IInsight,
  DealType,
} from 'utils/entities';
import {
  isBulletinsAdminSelector,
  isAgentManagerSelector,
  isAgentSelector,
  isConnectedUserSelector,
  userIdSelector,
  agentsInOfficeSelector,
} from 'store/state/domainData/selectors/currentUser';

export const baseUnitPageBulletinSelector = makeNullSafeDomainGetter(unitBulletinsByIdsSelector, 'poiByIds');
export const baseUnitPageCommercialBulletinSelector = makeNullSafeDomainGetter(unitCommercialBulletinsByIdsSelector, 'poiByIds');

const bulletinSelector = <T>(poiByIds: T[]): T => {
  if (poiByIds && poiByIds.length) {
    const [ bulletin ] = poiByIds;

    return bulletin;
  }
  return null;
};

const bulletinWithInsightsCombiner = <T extends IBulletin | ICommercialBulletin>(bulletin: T, insight: IInsight): T => bulletin ? {
  ...bulletin,
  ...{
    estimatedPrice: insight && insight.summary ? (insight.summary.nonText.data as any).estimatedPrice : null,
    isExclusive: Boolean(bulletin.poc.type === PocType.Agent && bulletin.poc.exclusivity && bulletin.poc.exclusivity.exclusive),
    isAgent: bulletin.poc.type === PocType.Agent,
  },
} : null;

export const unitPageBulletinSelector = flow(baseUnitPageBulletinSelector, bulletinSelector);
export const unitPageCommercialBulletinSelector = flow(baseUnitPageCommercialBulletinSelector, bulletinSelector);

export const isUnitPageBulletinLoadingSelector = createIsLoadingSelector(unitBulletinsByIdsSelector);
export const isUnitPageCommercialBulletinLoadingSelector = createIsLoadingSelector(unitCommercialBulletinsByIdsSelector);

export const unitPageListingWithInsightsSelector = createSelector([
  unitPageBulletinSelector,
  flow(makeInsightsByTypeSelector(InsightDescriptor.PricesEstimation), head),
], bulletinWithInsightsCombiner);

export const unitPageCommercialListingWithInsightsSelector = createSelector([
  unitPageCommercialBulletinSelector,
  flow(makeInsightsByTypeSelector(InsightDescriptor.PricesEstimation), head),
], bulletinWithInsightsCombiner);

const insightsStalishSelector = createSelector([
  metaInsightsCurrentDocIdSelector,
  flow(unitPageBulletinSelector, (bulletin) => bulletin ? bulletin.addressDetails.docId : null),
], (currentDocId, bulletinDocId) => currentDocId !== bulletinDocId);

const hasBuildingDetails = (bulletin: IBulletin): boolean => {
  if (!bulletin) return false;
  const { addressDetails, buildingYear, commonCharges } = bulletin;
  return (!addressDetails.resolutionPreferences || addressDetails.resolutionPreferences === ResolutionPreference.Accurate)
    && Boolean(buildingYear || commonCharges);
};

export const hasBuildingDetailsSectionSelector = flow(unitPageListingWithInsightsSelector, hasBuildingDetails);

export const hasPricesEstimationSectionSelector = createSelector([
  insightsStalishSelector,
  flow(unitPageBulletinSelector, (bulletin) => bulletin && bulletin.dealType === DealType.Buy),
], (insightsStalish, isBuyListing) => isBuyListing && !insightsStalish);

export const isEditAllowedSelector = createSelector([
  unitPageBulletinSelector,
  isConnectedUserSelector,
  isAgentSelector,
  isAgentManagerSelector,
  isBulletinsAdminSelector,
  userIdSelector,
  agentsInOfficeSelector,
], (bulletin: IBulletin, isUser, isAgent, isAgentManager, isBulletinsAdmin, uid, agentsInOffice) => {
  if (!isUser) return false;

  const poc = bulletin && bulletin.poc;

  if (isAgentManager) {
    return poc && (
      poc.type === PocType.Agent && poc.userId === uid
      || agentsInOffice && agentsInOffice.some(a => a.userId === poc.userId)
    );
  }

  if (isAgent && bulletin) {
    return poc && poc.type === PocType.Agent && poc.userId === uid;
  }

  return poc && poc.type === PocType.Private && (poc.userId === uid || isBulletinsAdmin);
});

export const isEditStatusAllowedSelector = createSelector([
  isEditAllowedSelector,
  isAgentSelector,
  isBulletinsAdminSelector,
], (isEditAllowed, isAgent, isBulletinsAdmin) => isEditAllowed && (isBulletinsAdmin || !isAgent));

export const isEditCommercialAllowedSelector = createSelector([
  unitPageCommercialBulletinSelector,
  isConnectedUserSelector,
  isAgentSelector,
  isAgentManagerSelector,
  isBulletinsAdminSelector,
  userIdSelector,
  agentsInOfficeSelector,
], (bulletin: ICommercialBulletin, isUser, isAgent, isAgentManager, isBulletinsAdmin, uid, agentsInOffice) => {
  if (!isUser) return false;

  const poc = bulletin && bulletin.poc;

  if (isAgentManager) {
    return poc && (
      poc.type === PocType.Agent && poc.userId === uid
      || agentsInOffice && agentsInOffice.some(a => a.userId === poc.userId)
    );
  }

  if (isAgent && bulletin) {
    return poc && poc.type === PocType.Agent && poc.userId === uid;
  }

  return poc && poc.type === PocType.Private && (poc.userId === uid || isBulletinsAdmin);
});
