import { isServer } from '@madlan145/client-toolbox';
import { Product } from 'utils/entities';
import { ILocale } from 'locale';
// @ts-ignore
import devConfig from './devConfig';

export type Place = 'IL';

export interface SpecialURLConfig {
  homePage?: {
    mobile?: {
      actionText: string;
      image: string;
      landingText: string;
    };
  };
}

export interface DaysInMarket {
  bulletinsAsNewInDays?: number;
  projectsAsNewInDays?: number;
  bulletinsAsRelevantInDays?: number;
  projectsAsRelevantInDays?: number;
}

export type AppEnvMode = 'development' | 'staging' | 'production';

export interface Config {
  appID?: string;
  version?: string;
  imagesRoot: string;
  cdnRoot: string;
  oldILRoot: string;
  cityTerm: string;
  specialURLConfig?: SpecialURLConfig;
  enableReduxLogger?: boolean;
  enableReduxDevTools?: boolean;
  facebook: {
    appId: string;
  };
  google: {
    clientId: string;
  };
  privacyUrl: string;
  termsOfServiceUrl: string;
  manifestFilename: string;
  s3Root: string;
  mapbox: {
    styleApiEndpoint?: string;
    accessToken: string;
    bulletinsMapStyleURL: string;
    centerCoordinate?: { lng: number, lat: number };
    maxZoomLevel: number;
  };
  mode: AppEnvMode;
  intercomEnabled?: boolean;
  pushNotificationsEnabled?: boolean;
  server?: {
    perimeterXAppId?: string,
    enableSeoScanRecoreder?: boolean;
    isSeoMachine?: boolean;
    mapboxToken: string,
    disableURLConfigGetter?: boolean;
    db?: {
      user: string;
      host: string;
      database: string;
      password: string;
      port: number;
    },
    apiEndpoint?: string;
    newApiEndpoint?: string;
    disableFetchCache?: boolean;
    sagasTimeout: number,
    fileCachePath?: string;
    redisUri?: string;
    pagesRedisUri?: string;
    disablePoiListener?: boolean;
    redisApiCacheTTL?: number;
    redisPagesCacheTTL?: number;
    disableFullPageCache?: boolean;
    baseURI: string;
    cache?: string;
    pubsubCreds?: {
      accessKey: string;
      secretKey: string;
      region: string;
      topicPrefix: string;
    };
    gtm?: {
      script: string,
      noscript: string,
    },
    favicon?: string,
    author?: string,
    copyrights?: string,
    shareImage?: string,
    hotjar?: string,
    intercomInitScript?: string,
    noneCacheableUrls?: string[];
  };
  header: {
    showBetaBadge?: boolean;
  };
  drawCityGeometry: boolean;
  events: {
    processors: {
      analytics: {
        url: string;
        log: boolean;
      };
    };
  };
  daysInMarket: {
    unitRent: DaysInMarket,
    unitBuy: DaysInMarket,
  };
  apiEndpoint: string;
  newApiEndpoint?: string;
  logoCode: string;
  city: string;
  disableFullStory?: boolean;
  locale: ILocale;
  isRTL: boolean;
  displayCityDropdown: boolean;
  displayMaskedAddresses: boolean;
  displayAddressZeroRent: boolean;
  country: Place;
  universalCardWithLogo: boolean;
  campaignProjectId?: string;
  products?: Product[];
  covidPromo?: boolean;
  madad?: string;
  tabu?: {
    tabuPurchaseValue: number;
    notifyUrl: string;
    processId?: string;
  };
  madlanXUrl?: string;
  hideNesachTabu?: boolean;
}

let config: Config;
if (!isServer) {
  const ssrConfig = (window as any).__LOCALIZE_SSR_CONFIG__;
  config = ssrConfig || devConfig;
}
else {
  config = (global as any).serverConfig;
}

export default config;
