import { Router } from 'router5';
import { Store } from 'redux';
import { RouterAction } from 'store/state/router/actionType';
import {
  NAVIGATE_TO,
  CANCEL_TRANSITION,
  CAN_DEACTIVATE,
  CAN_ACTIVATE,
} from 'store/state/router/types';

export const createReduxRouter5Middleware = (router: Router) => (store: Store) => {
  router.setDependency('store', store);

  return (next: (val: any) => void) => (action: RouterAction) => {
    switch (action.type) {
      case NAVIGATE_TO:
        const { opts, name, params } = action.payload;
        if (opts && opts.openInNewTab) {
          window.open(router.buildUrl(name, params));
        }
        else if (opts && opts.externalUrl) {
          window.open(opts.externalUrl);
        }
        else {
          router.navigate(name, params, opts);
        }
        break;

      case CANCEL_TRANSITION:
        router.cancel();
        break;

      case CAN_DEACTIVATE:
        router.canDeactivate(action.payload.name, action.payload.canDeactivate);
        break;

      case CAN_ACTIVATE:
        router.canActivate(action.payload.name, action.payload.canActivate);
        break;

      default:
        return next(action);
    }
  };
};
