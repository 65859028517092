import { put } from 'redux-saga/effects';
import { mutate } from 'store/state/mutationsResponse/actions';
import { MutationType } from 'store/sagas/apiService/types';
import { State as RouteState } from 'config/routes';

export function* unsubscribePageHandler(route: RouteState) {
  if (route.params.type === 'marketing') {
    yield put(mutate({
      meta: {
        variables: {
          input: {
            unsubscribeToken: route.params.token,
            source: route.params.source,
          },
        },
      },
      mutationType: MutationType.RemoveMarketingConsent,
    }));
    return;
  }

  yield put(mutate({
    meta: {
      variables: {
        token: route.params.token,
        unsubscribe: true,
      },
    },
    mutationType: MutationType.SavedSearchUnsubscribe,
  }));
}
