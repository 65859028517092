import React, { useRef } from 'react';
import { useLocale } from 'locale';
import {
  AuthenticationSocialSubmitWrapper,
  AuthenticationSocialSuggestionWrapper,
  AuthenticationSocialSuggestion,
  AuthenticationSocialWrapper,
} from './styled';
import { SocialVendor } from '../types';
import { useLoginWithGoogleButton } from 'hooks/useLoginWithGoogleButton';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { SpacingBlock } from 'components/spacing-block';

export interface WebAuthSocialsButtonsProps {
  modalLaterallPaddingMobile?: number;
  withSuggestionBefore?: boolean;
  onSocialLogin: (vendor: SocialVendor) => void;
}

export const WebAuthSocialsButtons: React.FunctionComponent<WebAuthSocialsButtonsProps> = ({ modalLaterallPaddingMobile, withSuggestionBefore, onSocialLogin }) => {
  const { t } = useLocale();
  const googleButtonNode = useRef<HTMLDivElement>(null);
  const isMobile = useScreenBreakpoint() === 1;

  useLoginWithGoogleButton({ parent: googleButtonNode, width: isMobile && modalLaterallPaddingMobile ? window.innerWidth - 2 * modalLaterallPaddingMobile : 320 });

  const suggestion = (
    <AuthenticationSocialSuggestionWrapper>
      <AuthenticationSocialSuggestion caps>{t('authentication.submitSuggestion')}</AuthenticationSocialSuggestion>
    </AuthenticationSocialSuggestionWrapper>
  );

  return (
    <SpacingBlock mTop={3}>
      <AuthenticationSocialSubmitWrapper>
        {withSuggestionBefore && suggestion}
        <AuthenticationSocialWrapper>
          <div ref={googleButtonNode} />
        </AuthenticationSocialWrapper>
        {!withSuggestionBefore && suggestion}
      </AuthenticationSocialSubmitWrapper>
    </SpacingBlock>
  );
};
