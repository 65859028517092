import { State } from 'store/state';
import { createSelector } from 'reselect';
import { OpenListingNewTab } from 'config/abTests';


// tslint:disable-next-line: variable-name
const __abTestsStateSelector = (state: State) => state.abTests;
export const abTestsStateSelector = createSelector(__abTestsStateSelector, (s) => s);

export const isOpenListingNewTabModeB = createSelector(abTestsStateSelector, (s) => s && s.openListingNewTab === OpenListingNewTab.modeB);
