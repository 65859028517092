import { connect } from 'react-redux';
import { ToastContainer } from './ToastContainer';
import { State, RootAction } from 'store/state';
import { Dispatch } from 'redux';
import { setToastMessage } from 'store/state/app/actions';
import { appStateSelector } from 'store/state/app/selectors';
import { flow } from 'lodash';
import { routeSelector } from 'store/state/selectors/router';

const toastMessageSelector = flow(appStateSelector, ({ toastMessage }) => toastMessage);

const mapStateToProps = (state: State) => ({
  message: toastMessageSelector(state),
  route: routeSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(setToastMessage(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer);
