import { takeLatest, call, select, put, fork, takeEvery } from 'redux-saga/effects';
import { SET_PRICES_LAST_EXPANDED_ROW_SALE_ID } from 'store/state/insightsContext/types';
import { setPricesLastExpandedRow } from 'store/state/insightsContext/actions';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType, MutationType } from 'store/sagas/apiService/types';
import { isAgentManagerSelector, isAgentSelector } from 'store/state/domainData/selectors';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { SetMutationResponseAction } from 'store/state/mutationsResponse/actions';
import { resetDomainData } from 'store/state/domainData/actions';
import { SET_DEAL_AFFILIATION_POPUP } from 'store/state/app/types';
import { setDealAffiliationPopup } from 'store/state/app/actions';
import { SET_DOMAIN_DATA } from 'store/state/domainData/types';
import { IInsight } from 'utils/entities';
import { RawHebrewHistoryItem } from 'store/state/selectors/insights/prices';
import { flatten, uniq } from 'lodash';

function* fetchDealAffiliationRequestsBySaleId(action: ReturnType<typeof setPricesLastExpandedRow>) {
  const isAgent: boolean = yield select(isAgentSelector);
  const isAgentManager: boolean = yield select(isAgentManagerSelector);

  if (action.payload && (isAgent || isAgentManager)) {
    yield call(queryData, {
      loadType: LoadType.GetDealAffiliationRequestsBySaleId,
      meta: { variables: { saleId: action.payload } },
    });
  }
}

const requestDealAffiliationMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.RequestDealAffiliation
);

const editRequestDealAffiliationMutationPattern = (action: RootAction) => (
  action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.EditDealAffiliationRequest
);

function* refetchDealAffiliationRequestsBySaleId(soldId: string) {
  yield put(resetDomainData({ loadType: LoadType.GetDealAffiliationRequestsBySaleId }));
  yield put(setPricesLastExpandedRow(soldId));
}

function* requestDealAffiliationWatcher(action: SetMutationResponseAction<MutationType.RequestDealAffiliation>) {
  const { response } = action.meta;
  if (response && response.data && (response.data.requestDealAffiliation && response.data.requestDealAffiliation.soldId )) {
    yield fork(refetchDealAffiliationRequestsBySaleId, response.data.requestDealAffiliation.soldId);
  }
}
function* editRequestDealAffiliationWatcher(action: SetMutationResponseAction<MutationType.EditDealAffiliationRequest>) {
  const { response } = action.meta;
  if (response && response.data && (response.data.publicEditDealAffiliationRequest && response.data.publicEditDealAffiliationRequest.soldId )) {
    yield fork(refetchDealAffiliationRequestsBySaleId, response.data.publicEditDealAffiliationRequest.soldId);
  }
}

function* dealOpenAffiliationPopupWatcher(action: ReturnType<typeof setDealAffiliationPopup>) {
  yield put(setPricesLastExpandedRow(action.payload.deal.soldId));
}

const dealOpenAffiliationPopupPattern = (action: RootAction) => (
  action.type === SET_DEAL_AFFILIATION_POPUP && !!action.payload
);

const insightsRequestPattern = (action: RootAction) => (
  action.type === SET_DOMAIN_DATA && action.loadType === LoadType.Insights
);

function* verifyDealsAgentsOfficesWorker(action: any) {
  const dealsInsight: IInsight = action
    && action.payload
    && action.payload.data
    && action.payload.data.docId2Insights
    && action.payload.data.docId2Insights.insights
    && action.payload.data.docId2Insights.insights.find((i: IInsight) => i.preview === 'Local History');

  if (!dealsInsight) return;

  const { agentsIds, officesIds } = dealsInsight.summary
    && dealsInsight.summary.nonText
    && dealsInsight.summary.nonText.data
    && flatten(Object.values(dealsInsight.summary.nonText.data)).reduce((res, curr: RawHebrewHistoryItem) => {
      if (curr && curr.from && curr.from.length) {
        curr.from.forEach(item => {
          if (item.agentId) res.agentsIds.push(item.agentId);
          if (item.officeId) res.officesIds.push(item.officeId);
        });
      }

      if (curr && curr.to && curr.to.length) {
        curr.to.forEach(item => {
          if (item.agentId) res.agentsIds.push(item.agentId);
          if (item.officeId) res.officesIds.push(item.officeId);
        });
      }

      return res;
    }, { agentsIds: [] as string[], officesIds: [] as string[] });

  yield put(resetDomainData({ loadType: LoadType.GetPublicRealEstateAgentsByIds }));
  yield put(resetDomainData({ loadType: LoadType.GetPublicRealEstateOfficesByIds }));

  const uniqAgentsIds = uniq(agentsIds);
  const uniqOfficesIds = uniq(officesIds);

  if (uniqAgentsIds.length) {
    const loadAgentsOpts: LoadOptions<LoadType.GetPublicRealEstateAgentsByIds> = {
      loadType: LoadType.GetPublicRealEstateAgentsByIds,
      meta: {
        variables: {
          agentsIds: uniq(agentsIds),
        },
      },
    };

    yield fork(queryData, loadAgentsOpts);
  }

  if (uniqOfficesIds.length) {
    const loadOfficesOpts: LoadOptions<LoadType.GetPublicRealEstateOfficesByIds> = {
      loadType: LoadType.GetPublicRealEstateOfficesByIds,
      meta: {
        variables: {
          officesIds: uniq(officesIds),
        },
      },
    };

    yield fork(queryData, loadOfficesOpts);
  }
}


export function* pricesWatcher() {
  yield takeLatest(SET_PRICES_LAST_EXPANDED_ROW_SALE_ID, fetchDealAffiliationRequestsBySaleId);
  yield takeLatest(requestDealAffiliationMutationPattern, requestDealAffiliationWatcher);
  yield takeLatest(editRequestDealAffiliationMutationPattern, editRequestDealAffiliationWatcher);
  yield takeLatest(dealOpenAffiliationPopupPattern, dealOpenAffiliationPopupWatcher);
  yield takeEvery(insightsRequestPattern, verifyDealsAgentsOfficesWorker);
}
