import { LocalStorage } from 'utils/localStorage';
import { UploadBulletinDraft, UploadBulletinStep } from 'components/bulletin-form/types';

const LS_UPLOAD_BULLETIN_KEY = 'UPLOAD_BULLETIN_DRAFT';

export const uploadBulletinDraft: UploadBulletinDraft = {
  clear: () => LocalStorage.rm(LS_UPLOAD_BULLETIN_KEY),
  set: (val) => LocalStorage.set(LS_UPLOAD_BULLETIN_KEY, val),
  get: () => LocalStorage.get(LS_UPLOAD_BULLETIN_KEY),
};

export const genUploadSteps = (isEdit: boolean, isConnectedUser: boolean): UploadBulletinStep[] => {
  const steps = [
    (isConnectedUser ? null : UploadBulletinStep.Authentication),
    (isEdit ? null : UploadBulletinStep.BulletinType),
    UploadBulletinStep.DealType,
    UploadBulletinStep.Address,
    UploadBulletinStep.MainInfo,
    UploadBulletinStep.AdditionalInfo,
    UploadBulletinStep.UploadImage,
    UploadBulletinStep.Contacts,
    UploadBulletinStep.Preview,
  ];

  return steps.filter(Boolean);
};
