import { ApolloClient, ApolloClientOptions } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import config from 'config';
import { IntrospectionFragmentMatcher, InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import introspectionQueryResultData from 'config/fragmentTypes.json';
import { IPoiIdentifier, PoiType } from './entities';
import { isServer } from '@madlan145/client-toolbox';
const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
const poiTypeToSchemaTypename: Partial<Record<PoiType, string>> = {
  bulletin: 'Bulletin',
  project: 'Project',
  commercialBulletin: 'CommercialBulletin',
  commercialProject: 'CommercialProject',
};
interface ClientFactoryOptions {
  fetcher: (input: RequestInfo, init?: RequestInit) => Promise<Response>;
  disableCache?: boolean;
}
const NEW_API_OPERATIONS = new Set([
  'getPublicRealEstateAgentsByIds',
  'getPublicRealEstateOfficesByIds',
  'createDealInvoiceUrls',
  'createExclusivityDocUrl',
  'newListing',
  'editListing',
  'acceptConsents',
  'editListingStatus',
  'newCommercialListing',
  'editCommercialListing',
  'editCommercialListingStatus',
  'editAssignedAgentCommercialListing',
  'editCommercialListingPromotionStatus',
  'editListingPromotionStatus',
  'getBulletinsUsageOverview',
  'getResidentialListingById',
  'getUsersListings',
  'getAllAgentsInOffice',
  'generateValidationCode',
  'checkValidationCode',
  'developerById',
  'submitContactMadlanLead',
  'submitDeveloperLead',
  'searchDevelopersIndex',
  'searchDataLayersV3',
  'busRouteByIds',
  'getAutocompleteApi3',
  'getPublicMortgageAdvisorsOfficesByCityDocIds',
  'getArticles',
  'getDealAffiliationRequestBySaleId',
  'getPublicMortgageAdvisorsOfficesByIds',
  'getRealEstateOfficeById',
  'getRealEstateAgentById',
  'getPublicRealEstateAgentsByOfficeId',
  'deregisterUser',
  'getDeals',
  'requestDealAffiliation',
  'publicEditDealAffiliationRequest',
  'getCommercialListing',
  'getOfficeListings',
  'getNotificationDevices',
  'addNotificationDevice',
  'removeNotificationDevice',
  'searchAwardsByIds',
  'searchAwardsByDocIds',
  'getRandomFeaturedMortgageAdvisorsOffice',
  'searchDeals',
  'getDealAffiliationRequestBySaleId',
  'getRecommendedListings',
  'getResidentialAndCommercialListings',
  'editAssignedAgentListing',
  'addressesByDocIds',
  'googleLogin',
  'facebookLogin',
  'passwordLoginV3',
  'requestPasswordReset',
  'resetPasswordUpdate',
  'verifyResetPasswordRequest',
  'registerVisitor',
  'loginV3',
  'completeAutoLogin',
  'removeMarketingConsent',
]);

const endpointUriFn: HttpLink.UriFunction = (operation) => {
  if (NEW_API_OPERATIONS.has(operation.operationName)) {
    return isServer && config.server.newApiEndpoint ? config.server.newApiEndpoint : config.newApiEndpoint;
  }
  return isServer && config.server.apiEndpoint ? config.server.apiEndpoint : config.apiEndpoint;
};
export function clientFactory({ fetcher, disableCache }: ClientFactoryOptions) {
  const authLink = setContext((_, { headers }) => {
    const additionalHeaders: {[key: string]: string} = {
      'X-Requested-With': 'XMLHttpRequest',
    };
    return {
      headers: {
        ...headers,
        ...additionalHeaders,
      },
    };
  });
  const httpLinkOptions: HttpLink.Options = {
    uri: endpointUriFn,
    fetch: fetcher,
    fetchOptions: {
      credentials: 'include',
    },
  };
  const httpLink = new HttpLink(httpLinkOptions);
  const apolloClientOptions: ApolloClientOptions<NormalizedCacheObject> = {
    link: authLink.concat(httpLink),
    assumeImmutableResults: true,
    cache: new InMemoryCache({
      fragmentMatcher,
      freezeResults: true,
      cacheRedirects: {
        Query: {
          insightById: (_, args, { getCacheKey }) => (
            getCacheKey({ __typename: 'insight', id: args.id })
          ),
          poiByIds: (_, args, { getCacheKey }) => (
            args.ids.map(({ id, type }: IPoiIdentifier) => (
              getCacheKey({ __typename: poiTypeToSchemaTypename[type], id })
            ))
          ),
        },
      },
    }),
  };
  if (disableCache) {
    apolloClientOptions.defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
  }
  return new ApolloClient(apolloClientOptions);
}
