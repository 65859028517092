import './helpers/jstracking';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { RouterProvider } from 'react-router5';
import routerFactory from 'config/routes';

import { ApolloProvider } from '@apollo/react-hooks';
import { clientFactory } from 'utils/apollo-client';
import { initAnalytics, AnalyticsContext } from 'analytics';
import { ThemeApp } from 'ThemeApp';
import { Provider } from 'react-redux';
import initStore from 'store';
import rootSaga from 'store/sagas';
import { ClientTokenStore, TokenContext } from 'helpers/userToken';
import { STICKY_ROOT, REGULAR_ROOT_ID, OVERLAY_ROOT_ID, SEARCH_BOTTOM_ROOT } from 'consts/rootNodes';
import { UserAgentContext } from 'hooks/useImageUrlBuilder';
import config from 'config';
import { persistWindowWidth } from 'helpers/screenWidthStore';
import { getCurrentDesignRange } from 'consts/breakpoints';
import createBrowserPlugin from 'router5-plugin-browser';
import browserHistory from 'utils/browserHistory';
import { SSRHydratedContext, __SSR_HYDRATED_CONTEXT__ } from 'utils/ssrHydratedContext';
import { DocumentHead } from 'components/document-head';
import { TitleUpdater } from 'components/document-head/title-updater';
import { ClientPortalProvider } from 'utils/universalPortal';
import { requestIdleCallback } from 'utils/requestIdleCallback';
import { HydrationProvider } from 'hocs/withProgressiveHydration';
import { SpecialURLConfigContext } from 'hooks/useSpecialURLConfig';
import { IABTestContext } from 'config/abTests';
import { getABTestsContext } from 'utils/abtests';
import { loadableReady } from '@loadable/component';
import { IEnhancer, enhanceFetch } from 'utils/fetch-middleware';
import { AutomationFetchHookEnhancer } from 'utils/fetch-middleware/AutomationFetchHookEnhancer';
import { PXFetchEnhancer } from 'utils/fetch-middleware/PXFetchEnhancer';
import { AuthFetchEnhancer } from 'utils/fetch-middleware/AuthFetchEnhancer';
import { DisableCacheFetchEnhancer } from 'utils/fetch-middleware/DisableCacheFetchEnhancer';
import * as Cookies from 'js-cookie';
import { PerimeterXListener } from 'utils/fetch-middleware/PerometerXEnhancer';
import { ReactNativeClientTunnelContext, ReactNativeClientTunnel } from 'react-native/reactNativeClientTunnel';
import { SEARCH_VIEW_KEY, SearchViewMode } from 'components/list-view-switcher/constants';
import { maybeApplySearchView } from 'utils/maybeApplySearchView';
import { State } from 'store/state';
import { WebSourceEnhancer } from 'utils/fetch-middleware/WebSourceEnhancer';
import { ErrorDetailsEnhancer } from 'utils/fetch-middleware/ErrorDetailsEnhancer';
import MapApp from 'MapApp';
import { noop } from 'lodash';
import { TrackJS } from 'trackjs';
import { MapMover, MapMoverContextProvider } from 'hooks/useMapMover';
import { MapRefFn } from 'utils/mapUtilityTypes';

function cleanupOldCookies() {
  Cookies.remove('Infinite_ab_tests_context_key');
  Cookies.remove('SEARCH_VIEW_KEY');
  Cookies.remove('MORTGAGE_STORAGE_SESSION_KEY');
}

cleanupOldCookies();

function maybeApplyStateFromCookies(state: State): State {
  const searchView = Cookies.get(SEARCH_VIEW_KEY) as SearchViewMode;
  return maybeApplySearchView(state, searchView);
}

const tokenStore = new ClientTokenStore();
const router = routerFactory();
const analytics = initAnalytics(router);

const baseFetchEnhancers: IEnhancer[] = [
  new PerimeterXListener(),
  new AutomationFetchHookEnhancer(),
  new PXFetchEnhancer(),
  new DisableCacheFetchEnhancer(),
  new WebSourceEnhancer(),
  new ErrorDetailsEnhancer(),
];

const client = clientFactory({
  fetcher: enhanceFetch(
    window.fetch,
    ...baseFetchEnhancers,
    new AuthFetchEnhancer(tokenStore)
  ),
});

const reactNativeClientTunnel = new ReactNativeClientTunnel();

const {
  reduxInitialState,
  assumedDesignRange,
  initialPath,
}: SSRHydratedContext = (window as any)[__SSR_HYDRATED_CONTEXT__] || {};

const abTestsContext: IABTestContext = getABTestsContext().context;

const store = initStore({
  router,
  analytics,
  tokenStore,
  client,
  fetcher: enhanceFetch(
    window.fetch,
    ...baseFetchEnhancers
  ),
  saga: rootSaga,
  initialState: maybeApplyStateFromCookies(reduxInitialState),
  enableLogger: config.enableReduxLogger,
  enableReduxDevTools: config.enableReduxDevTools,
  userAgent: navigator.userAgent,
  abTestsContext,
  reactNativeClientTunnel,
}, noop, (err: Error) => {
  TrackJS.track([
    'REDUX-SAGA EXCEPTION: Something went wrong',
    err,
    err.toString(),
  ]);
});

const PORTALS_ROOTS = [ OVERLAY_ROOT_ID, STICKY_ROOT, SEARCH_BOTTOM_ROOT ];

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export const mapMover = new MapMover();

function createMapAppTree(refFn: MapRefFn) {
  return (
    <Provider store={store}>
      <TokenContext.Provider value={tokenStore}>
        <ClientPortalProvider ids={PORTALS_ROOTS}>
          <AnalyticsContext.Provider value={analytics}>
            <UserAgentContext.Provider value={navigator.userAgent}>
              <ThemeApp>
                <RouterProvider router={router}>
                  <ApolloProvider client={client}>
                    <SpecialURLConfigContext.Provider value={config.specialURLConfig}>
                      <HydrationProvider hydrating={false}>
                        <MapApp refFn={refFn} />
                      </HydrationProvider>
                    </SpecialURLConfigContext.Provider>
                  </ApolloProvider>
                </RouterProvider>
              </ThemeApp>
            </UserAgentContext.Provider>
          </AnalyticsContext.Provider>
        </ClientPortalProvider>
      </TokenContext.Provider>
    </Provider>
  );
}

function renderApp() {

  requestIdleCallback(persistWindowWidth);
  mapMover.initialize(createMapAppTree);

  const rootElt = document.getElementById(REGULAR_ROOT_ID) as HTMLElement;
  const realDesignRange = getCurrentDesignRange();
  const willHydrate = assumedDesignRange === realDesignRange;

  const appTree = (
    <Provider store={store}>
      <TokenContext.Provider value={tokenStore}>
        <ClientPortalProvider ids={PORTALS_ROOTS}>
          <DocumentHead config={config}>
            {({ title }) => <TitleUpdater title={title} />}
          </DocumentHead>
          <AnalyticsContext.Provider value={analytics}>
            <UserAgentContext.Provider value={navigator.userAgent}>
              <ThemeApp>
                <RouterProvider router={router}>
                  <ApolloProvider client={client}>
                    <SpecialURLConfigContext.Provider value={config.specialURLConfig}>
                      <HydrationProvider hydrating={willHydrate}>
                        <ReactNativeClientTunnelContext.Provider value={reactNativeClientTunnel}>
                          <MapMoverContextProvider value={mapMover}>
                            <App />
                          </MapMoverContextProvider>
                        </ReactNativeClientTunnelContext.Provider>
                      </HydrationProvider>
                    </SpecialURLConfigContext.Provider>
                  </ApolloProvider>
                </RouterProvider>
              </ThemeApp>
            </UserAgentContext.Provider>
          </AnalyticsContext.Provider>
        </ClientPortalProvider>
      </TokenContext.Provider>
    </Provider>
  );

  if (assumedDesignRange === realDesignRange) {
    ReactDOM.hydrate(appTree, rootElt);
  }
  else {
    // tslint:disable-next-line: no-console
    console.warn(`SSR assumed wrong design range. Real range: ${realDesignRange}, SSR: ${assumedDesignRange}`);
    ReactDOM.render(appTree, rootElt);
  }

}


const browserPluginOpts = { useHash: false };
const browserPlugin = createBrowserPlugin(browserPluginOpts, browserHistory);

loadableReady(() => {
  if (initialPath) {
    // don't ask what is this
    const realReplaceState = browserHistory.replaceState;
    browserHistory.replaceState = (state, title) => {
      const path = browserHistory.getLocation(browserPluginOpts);
      realReplaceState(state, title, path);
      browserHistory.replaceState = realReplaceState;
    };
    // ^^^^^^^^^^^^^^^^^^

    router.usePlugin(browserPlugin);
    router.start(initialPath, renderApp);
  }
  else {
    router.usePlugin(browserPlugin);
    router.start(renderApp);
  }

});
