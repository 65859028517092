import { upperCase, invert, trim, memoize, isNil } from 'lodash';
import {
  Amenity,
  AvailabilityType,
  BuildingClass,
  BulletinPoiType,
  BulletinStatusType,
  DealType,
  IAddressDetails,
  IAutocompleteAddress,
  IBulletin,
  IBulletinInput,
  ICommercialBulletin,
  IGeneralCondition,
  PocType,
  PoiId,
  ResolutionPreference,
} from './entities';
import { removeTypeName } from './index';
import { PropertyType, QualityClassOption } from 'components/filters/types';
import { COMMERCIAL_AMENITIES, RESIDENTIAL_AMENITIES } from 'components/bulletin-form/constants';
import {
  hideNumberOfEmployees,
  hideRoomsCount,
  hideTotalFloors,
  hideUnitFloor,
} from 'components/bulletin-form/helpers';
import { IsExclusive } from 'components/bulletin-form/types';

interface IUploadBulletinCommonFormValues {
  rooms?: number; // commercial
  beds?: number; // residential
  type: BulletinPoiType;
  id?: PoiId;
  area: string;
  addressDetails: IAutocompleteAddress;
  price: string;
  assignedAgentUserId?: string;
  availabilityDateMonth: number;
  availabilityDateYear: number;
  availabilityType: AvailabilityType;
  buildingClass: PropertyType;
  buildingYear?: number;
  parcel?: number;
  block?: number;
  dealType: DealType;
  description?: string;
  floor: number;
  floors: number;
  furniture?: string;
  generalCondition: IGeneralCondition;
  images: string[];
  monthlyTaxes: string;
  amenities: Amenity[];
  sellerType?: PocType;
  userName?: string;
  userPhone?: string;
  status: {
    message: string;
    status: BulletinStatusType;
    promoted: boolean;
  };
  resolutionPreferences?: ResolutionPreference;
}


export interface IUploadResidentialFormValues extends IUploadBulletinCommonFormValues {
  type: 'bulletin';
  commonCharges: string;
  balconyArea: string;
  storageArea: string;
  isExclusive?: string;
  exclusivityFromDate?: Date;
  exclusivityToDate?: Date;
  exclusiveUrl?: string;
  exclusivityFile?: File;
  rejectionReason?: string;
}

interface IUploadCommercialFormValues extends IUploadBulletinCommonFormValues {
  type: 'commercialBulletin';
  numberOfEmployees: string;
  qualityClass: QualityClassOption;
  monthlyManagementFee?: string;
}

export type IUploadBulletinFormValues = IUploadResidentialFormValues | IUploadCommercialFormValues;

export interface DraftState {
  formValues: Partial<IUploadBulletinFormValues>;
}

export const buildingClassToPropertyTypeMap: Partial<Record<BuildingClass, PropertyType>> = {
  [BuildingClass.GardenApartment]: PropertyType.GardenApartment,
  [BuildingClass.DualCottage]: PropertyType.TwoFamilyDwelling,
  [BuildingClass.PenthouseApp]: PropertyType.PenthouseApp,
  [BuildingClass.RoofFlat]: PropertyType.RoofFlat,
  [BuildingClass.MiniPenthouse]: PropertyType.MiniPenthouse,
  [BuildingClass.ResidentialUnit]: PropertyType.ResidentialUnit,
  [BuildingClass.SerialCottage]: PropertyType.SerialCottage,
  [BuildingClass.AgricultureFarm]: PropertyType.AgricultureFarm,
  [BuildingClass.SplitFlat]: PropertyType.SplitFlat,
  [BuildingClass.AgricultureLand]: PropertyType.AgricultureLand,
  [BuildingClass.VacationProperty]: PropertyType.VacationProperty,
  [BuildingClass.BuyerTeam]: PropertyType.BuyerTeam,
  [BuildingClass.ProfessionalFarm]: PropertyType.ProfessionalFarm,
  [BuildingClass.FarmLand]: PropertyType.FarmLand,
  [BuildingClass.SharedWorkSpace]: PropertyType.SharedWorkSpace,
  [BuildingClass.ParkingLot]: PropertyType.ParkingLot,
  [BuildingClass.IndustrialBuilding]: PropertyType.IndustrialBuilding,
  [BuildingClass.IncomeProducingProperty]: PropertyType.IncomeProducingProperty,
};

export const propertyTypeToBuildingClassMap = invert(buildingClassToPropertyTypeMap);

export const getBuildingClassFromPropertyType = memoize((val: PropertyType): BuildingClass =>
  (propertyTypeToBuildingClassMap[val] || val) as BuildingClass);

interface EditICommercialBulletin extends ICommercialBulletin {
  originalAddress?: IAddressDetails;
}

export const bulletinToFormValues = (bulletin: IBulletin | ICommercialBulletin | EditICommercialBulletin): DraftState => {
  const {
    type,
    addressDetails,
    amenities,
    area,
    availabilityType,
    availableDate,
    buildingClass,
    dealType,
    description = '',
    floor,
    floors,
    generalCondition,
    images,
    monthlyTaxes,
    locationPoint,
    price,
    buildingYear,
    furnitureDetails,
    status,
    id,
    poc,
    userPhoneNumberEdit,
  } = bulletin;

  const isCommercial = type === 'commercialBulletin';

  let parsedUnitAmenities: Amenity[] = [];
  const isAgent = poc.type === PocType.Agent;
  const beds = 'beds' in bulletin && bulletin.beds || null;
  const rooms = 'rooms' in bulletin && bulletin.rooms || null;
  const commonCharges = 'commonCharges' in bulletin && bulletin.commonCharges || null;
  const numberOfEmployees = 'numberOfEmployees' in bulletin && bulletin.numberOfEmployees || null;
  const qualityClass = 'qualityClass' in bulletin && bulletin.qualityClass || null;
  const address = 'originalAddress' in bulletin && bulletin.originalAddress || addressDetails;
  const netArea = 'netArea' in bulletin && bulletin.netArea;
  const grossArea = 'grossArea' in bulletin && bulletin.grossArea;
  const assignedAgentUserId = isCommercial && isAgent ? poc.userId : '';
  const parsedNumberOfEmployees = numberOfEmployees ? numberOfEmployees.toString() : '';
  const managementFee = 'monthlyManagementFee' in bulletin && bulletin.monthlyManagementFee || null;

  if (amenities) {
    parsedUnitAmenities = Object.entries(removeTypeName(amenities)).reduce((acc, [ amenity, isExist ]) => {
      if (isExist) acc.push(amenity);
      return acc;
    }, []);
  }

  let parsedArea;
  if (area) {
    parsedArea = area.toString();
  }
  else if (netArea && grossArea) {
    parsedArea = netArea.toString();
  }
  else if (grossArea && !netArea) {
    parsedArea = grossArea.toString();
  }

  let parsedPrice;
  if (price) {
    parsedPrice = price.toString();
  }

  let parsedMonthlyTaxes;
  if (monthlyTaxes) {
    parsedMonthlyTaxes = monthlyTaxes.toString();
  }

  let parsedCommonCharges;
  if (commonCharges) {
    parsedCommonCharges = commonCharges.toString();
  }

  const monthlyManagementFee = managementFee ? managementFee.toString() : null;


  let parsedImages;
  if (images && images.length) {
    parsedImages = images.map(image => image.imageUrl);
  }

  let availabilityDateYear;
  let availabilityDateMonth;

  const parsedAvailabilityType = upperCase(availabilityType) as AvailabilityType;
  if (parsedAvailabilityType === AvailabilityType.Date && availableDate) {
    const parsedDate = new Date(availableDate);
    availabilityDateYear = parsedDate.getFullYear();
    availabilityDateMonth = parsedDate.getMonth();
  }
  else {
    availabilityDateYear = (new Date).getFullYear();
    availabilityDateMonth = (new Date).getMonth();
  }

  const parcel = !isNil(address.parcel) ? address.parcel : undefined;
  const block = !isNil(address.block) ? address.block : undefined;
  const resolutionPreferences = addressDetails.resolutionPreferences;

  const parsedBuildingClass: PropertyType = (buildingClassToPropertyTypeMap[buildingClass] || buildingClass) as PropertyType;
  const parsedGeneralCondition = generalCondition === IGeneralCondition.ToRenovate ? IGeneralCondition.ToRenovated : generalCondition;

  const userPhone = userPhoneNumberEdit || (isAgent
    ? ('displayNumber' in poc ? poc.displayNumber : '')
    : ('contactInfo' in poc && poc.contactInfo && poc.contactInfo.phone || poc.displayNumber || ''));

  const userName = isAgent
    ? ('agentContact' in poc ? poc.agentContact.name : '')
    : ('contactInfo' in poc && poc.contactInfo && poc.contactInfo.name || '');

  if (isCommercial) {
    return {
      formValues: {
        type,
        sellerType: poc.type,
        addressDetails: {
          location: [ locationPoint.lng, locationPoint.lat ],
          docId: address.docId,
          name: `${address.streetName} ${address.streetNumber}, ${address.city}`,
        } as IAutocompleteAddress,
        resolutionPreferences,
        ...(assignedAgentUserId ? ({ assignedAgentUserId }) : null),
        amenities: parsedUnitAmenities,
        area: parsedArea,
        availabilityDateMonth,
        availabilityDateYear,
        qualityClass,
        numberOfEmployees: parsedNumberOfEmployees,
        availabilityType: parsedAvailabilityType,
        rooms,
        buildingClass: parsedBuildingClass,
        dealType,
        description: description || '',
        floor: +floor,
        floors,
        generalCondition: parsedGeneralCondition,
        images: parsedImages,
        monthlyTaxes: parsedMonthlyTaxes,
        price: parsedPrice,
        parcel,
        block,
        buildingYear: buildingYear || undefined,
        furniture: furnitureDetails || null,
        status,
        userName,
        userPhone,
        ...(monthlyManagementFee ? ({ monthlyManagementFee }) : null),
        ...(id ? ({ id }) : null),
      },
    };
  }

  return {
    formValues: {
      type,
      sellerType: poc.type,
      addressDetails: {
        location: [ locationPoint.lng, locationPoint.lat ],
        docId: addressDetails.docId,
        name: `${addressDetails.streetName} ${addressDetails.streetNumber}, ${addressDetails.city}`,
      } as IAutocompleteAddress,
      amenities: parsedUnitAmenities,
      area: parsedArea,
      availabilityDateMonth,
      availabilityDateYear,
      availabilityType: parsedAvailabilityType,
      beds,
      resolutionPreferences,
      buildingClass: parsedBuildingClass,
      commonCharges: parsedCommonCharges,
      dealType,
      description: description || '',
      floor: +floor,
      floors,
      generalCondition: parsedGeneralCondition,
      images: parsedImages,
      monthlyTaxes: parsedMonthlyTaxes,
      price: parsedPrice,
      parcel,
      block,
      buildingYear: buildingYear || undefined,
      furniture: furnitureDetails || null,
      status,
      userName,
      userPhone,
      ...(id ? ({ id }) : null),
    },
  };
};

const isFloorSelected = (floor: number) => !!floor || floor === 0;

export const uploadFormValues2UploadInput = (formValues: Partial<IUploadBulletinFormValues>): IBulletinInput => {
  const {
    type,
    addressDetails,
    area,
    availabilityDateMonth,
    availabilityDateYear,
    availabilityType,
    buildingClass,
    buildingYear,
    parcel,
    block,
    dealType,
    description,
    sellerType,
    floor,
    floors,
    furniture,
    generalCondition,
    images,
    monthlyTaxes,
    price,
    amenities,
    id,
    userName,
    userPhone,
  } = formValues;

  const isCommercial = type === 'commercialBulletin';
  const monthlyManagementFee = isCommercial && 'monthlyManagementFee' in formValues ? +formValues.monthlyManagementFee : null;
  const beds = formValues.type === 'bulletin' && formValues.beds && !hideRoomsCount(buildingClass, false) ? formValues.beds : null;
  const rooms = formValues.type === 'commercialBulletin' && formValues.rooms && !hideRoomsCount(buildingClass, true) ? formValues.rooms : null;
  const commonCharges = formValues.type === 'bulletin' ? formValues.commonCharges : null;
  const numberOfEmployees = formValues.type === 'commercialBulletin' && formValues.numberOfEmployees || null;
  const qualityClass = formValues.type === 'commercialBulletin' && formValues.qualityClass || null;
  const resolutionPreferences = 'resolutionPreferences' in formValues && formValues.resolutionPreferences || null;
  const assignedAgentUserId = 'assignedAgentUserId' in formValues && formValues.assignedAgentUserId || null;
  const exclusivityFromDate = 'exclusivityFromDate' in formValues && formValues.exclusivityFromDate || null;
  const exclusivityToDate = 'exclusivityToDate' in formValues && formValues.exclusivityToDate || null;
  const isExclusive = 'isExclusive' in formValues && formValues.isExclusive === IsExclusive.Yes;
  const exclusiveUrl = 'exclusiveUrl' in formValues && formValues.exclusiveUrl || null;
  const storageArea = 'storageArea' in formValues && +formValues.storageArea || undefined;
  const balconyArea = 'balconyArea' in formValues && +formValues.balconyArea || undefined;

  const selectedAmenities = [ ...(amenities || []) ];
  const parsedAmenities = (isCommercial ? COMMERCIAL_AMENITIES : RESIDENTIAL_AMENITIES)
    .reduce<Record<Amenity, boolean>>((acc, amenity) => {
      return ({
        ...acc,
        [amenity]: selectedAmenities.includes(amenity),
      });
    }, {} as Record<Amenity, boolean>);

  let parsedArea;
  if (area) {
    parsedArea = parseInt(area, 10);
  }

  let parsedNumberOfEmployees;
  if (numberOfEmployees && !hideNumberOfEmployees(buildingClass)) {
    parsedNumberOfEmployees = parseInt(numberOfEmployees, 10);
  }

  let availableDate;
  if (availabilityType === AvailabilityType.Date && availabilityDateYear && !isNil(availabilityDateMonth)) {
    availableDate = (new Date(availabilityDateYear, availabilityDateMonth)).toISOString();
  }

  const parsedFloor = isFloorSelected(floor) && !hideUnitFloor(buildingClass, isCommercial) ? floor : undefined;
  const parsedFloors = isFloorSelected(floors) && !hideTotalFloors(buildingClass, isCommercial) ? floors : undefined;

  let parsedPrice;
  if (price) {
    parsedPrice = parseInt(price, 10);
  }

  let parsedMonthlyTaxes;
  if (monthlyTaxes) {
    parsedMonthlyTaxes = parseInt(monthlyTaxes, 10);
  }

  let parsedCommonCharges;
  if (commonCharges) {
    parsedCommonCharges = parseInt(commonCharges, 10);
  }

  let parsedImages;
  if (images) {
    parsedImages = images.map((imageUrl: string) => ({ imageUrl, isFloorplan: false, description: undefined }));
  }

  const parsedDescription = description && trim(description) || null;

  const parsedParcel = !isNil(parcel) ? +parcel : undefined;
  const parsedBlock = !isNil(block) ? +block : undefined;

  if (isCommercial) {
    return {
      type,
      docId: addressDetails.docId,
      price: parsedPrice,
      images: parsedImages || [],
      amenities: parsedAmenities,
      rooms: +rooms,
      area: parsedArea,
      floor: parsedFloor,
      floors: parsedFloors,
      dealType,
      propertyType: buildingClass,
      generalCondition,
      availableDate,
      availabilityType,
      furniture,
      description: parsedDescription,
      sellerType,
      numberOfEmployees: parsedNumberOfEmployees,
      qualityClass,
      monthlyTax: parsedMonthlyTaxes,
      buildingYear: buildingYear ? +buildingYear : null,
      parcel: parsedParcel,
      block: parsedBlock,
      resolutionPreferences,
      ...(assignedAgentUserId ? { assignedAgentUserId } : { userName, userPhone }),
      ...(monthlyManagementFee ? { monthlyManagementFee } : {}),
      ...(id ? { id } : {}),
    };
  }

  return {
    ...(isExclusive ? {
      exclusivity: {
        exclusive: isExclusive,
        startDate: exclusivityFromDate ? new Date(Date.UTC(exclusivityFromDate.getFullYear(), exclusivityFromDate.getMonth(), exclusivityFromDate.getDate(), 0, 0, 0)).toISOString() : null,
        endDate: exclusivityToDate ? new Date(Date.UTC(exclusivityToDate.getFullYear(), exclusivityToDate.getMonth(), exclusivityToDate.getDate(), 0, 0, 0)).toISOString() : null,
        exclusiveUrl,
      },
    } : {}),
    type,
    docId: addressDetails.docId,
    price: parsedPrice,
    images: parsedImages || [],
    amenities: {
      ...parsedAmenities,
      ...((parsedAmenities.storage && storageArea) || (parsedAmenities.balcony && balconyArea) ? {
        additionalAreas: {
          storeroomArea: parsedAmenities.storage ? storageArea : undefined,
          balconiesArea: parsedAmenities.balcony ? balconyArea : undefined,
        },
      } : {}),
    },
    beds,
    area: parsedArea,
    floor: parsedFloor,
    floors: parsedFloors,
    dealType,
    propertyType: buildingClass,
    generalCondition,
    availableDate,
    availabilityType,
    furniture,
    description: parsedDescription,
    sellerType,
    monthlyTax: parsedMonthlyTaxes,
    commonCharges: parsedCommonCharges,
    buildingYear: buildingYear ? +buildingYear : null,
    parcel: parsedParcel,
    block: parsedBlock,
    ...(assignedAgentUserId ? { assignedAgentUserId } : { userName, userPhone }),
    ...(resolutionPreferences ? { resolutionPreferences } : {}),
    ...(id ? { id } : {}),
  };
};
