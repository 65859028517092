import { Route, State as RouteState } from 'config/routes';
import { uniq } from 'lodash';
import { call, put, select } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { resetDomainData } from 'store/state/domainData/actions';
import { officePageDataSelector, safeOfficeBulletinsByIdSelector, teamByOfficeIdDataSelector } from 'store/state/domainData/selectors/officeById';
import { navigateTo } from 'store/state/router/actions';
import { prevRouteSelector } from 'store/state/selectors/router';
import { IOfficeAgentDisplayLevel, RealEstateLevel, ITeamByOfficeIdData, IOfficePageData, IAgentOfficeBulletinsData } from 'utils/entities';


function* loadOfficePage(route: RouteState) {
  const prevRoute = yield select(prevRouteSelector);

  if (prevRoute) {
    yield put(resetDomainData({ loadType: LoadType.OfficeById }));
    yield put(resetDomainData({ loadType: LoadType.TeamByOfficeId }));
    yield put(resetDomainData({ loadType: LoadType.OfficeBulletinsById }));
    yield put(resetDomainData({ loadType: LoadType.SearchAwardsByIds }));
    yield put(resetDomainData({ loadType: LoadType.GetPublicRealEstateAgentsByIds }));
  }

  const { id } = route.params;

  yield call(queryData, { loadType: LoadType.OfficeById, meta: { variables: { id, level: RealEstateLevel.Public } } });

  const officeData: IOfficePageData = yield select(officePageDataSelector);

  if (officeData) {
    if (officeData.displayLevel !== IOfficeAgentDisplayLevel.Minimal) {
      yield call(queryData, { loadType: LoadType.TeamByOfficeId, meta: { variables: { id, shuffle: true } } });
      const team: ITeamByOfficeIdData[] = yield select(teamByOfficeIdDataSelector);
      if (team && team.length) {
        yield call(queryData, { loadType: LoadType.SearchAwardsByIds, meta: { variables: { args: { ids: team.map(i => i.agentId) } } } });
      }
    }

    yield call(queryData, { loadType: LoadType.OfficeBulletinsById, meta: { variables: { id, level: RealEstateLevel.Public } } });

    const officeListingsData: IAgentOfficeBulletinsData = yield select(safeOfficeBulletinsByIdSelector);

    if (officeListingsData && officeListingsData.deals) {
      const agentsIds = uniq(officeListingsData.deals.reduce((res, curr) => {
        if (curr && curr.agents) {
          res = res.concat(curr.agents.map(agent => agent.agentId));
        }
        return res;
      }, [] as string[]));

      if (agentsIds.length) {
        const loadAgentsOpts: LoadOptions<LoadType.GetPublicRealEstateAgentsByIds> = {
          loadType: LoadType.GetPublicRealEstateAgentsByIds,
          meta: {
            variables: {
              agentsIds: uniq(agentsIds),
            },
          },
        };

        yield call(queryData, loadAgentsOpts);
      }
    }
  }
  else {
    yield put(navigateTo(Route.MadadPage, {}, { replace: true }));
  }
}

export function* officePageHandler(params: RouteState) {
  yield call(loadOfficePage, params);
}
