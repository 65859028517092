import styled from '@emotion/styled';

export const Root = styled.div<{ mobileModalPositionBottom?: boolean; desktopModalPositionCenter?: boolean }>`
  @keyframes slideIn {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  display: flex;
  justify-content: center;
  ${({ theme, mobileModalPositionBottom }) => theme.media.showAt({ to: 2 })`
    ${mobileModalPositionBottom ? `
      position: fixed;
      width: 100%;
      z-index: 1002;
      bottom: 0;
      transform: translateY(100%);
      animation: slideIn 0.5s ease 0.2s forwards;` : ''}

      &:after {
        content: '';
        position: fixed;
        background: ${theme.colors.neutrals.white};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
  `}
  ${({ theme, desktopModalPositionCenter }) => theme.media.showAt({ from: 3 })`
    ${desktopModalPositionCenter ? `
      height: 100vh;
      align-items: center;
    ` : ''}
  `}
`;

export const MainContentWrapper = styled.div<{ isFullWidthMainContent?: boolean }>`
  ${({ isFullWidthMainContent }) => isFullWidthMainContent ? `
    width: 100%;
  ` : ''}
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    min-height: 20px;
  `}
`;

export const MODAL_PADDING_SCALE = 4;
export const MOBILE_MODAL_PADDING_SCALE = 3;

export const ContentWrapper = styled.div<{ mobileModalPositionBottom?: boolean; desktopModalPositionCenter?: boolean }>`
  ${({ theme }) => `
    overflow: hidden;
    position: relative;
    padding: ${theme.spacing(MODAL_PADDING_SCALE)};
    z-index: 1000;
    border-radius: ${theme.borderRadius.small};
    min-width: 402px;
    margin: ${theme.spacing(8)} 0;
    background: ${theme.colors.neutrals.white};
    box-shadow: ${theme.shadow.level7};
    @media screen and (max-height: 900px) {
      margin: ${theme.spacing(5)} 0;
    }
    @media screen and (max-height: 800px) {
      margin: ${theme.spacing(3)} 0;
    }
  `}
  ${({ theme, desktopModalPositionCenter }) => theme.media.showAt({ from: 3 })`
    ${desktopModalPositionCenter ? `
      margin: 0;
    ` : ''}
  `}
  ${({ theme, mobileModalPositionBottom }) => theme.media.showAt({ to: 2 })`
    width: 100%;
    min-width: auto;
    height: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0;
    box-shadow: none;
    margin: ${theme.spacing(6)} 0;
    padding: ${theme.spacing(MOBILE_MODAL_PADDING_SCALE)};

    ${mobileModalPositionBottom && `
      min-height: 300px;
      box-shadow: ${theme.shadow.level3};
      border: 1px solid ${theme.colors.neutrals.grey9};
      border-radius: ${theme.borderRadius.small};
      padding: ${theme.spacing(5)} ${theme.spacing(3)} ${theme.spacing(4)};
      margin: 0;
    `}
  `}
  ${({ theme }) => theme.media.showAt({ at: 2 })`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

// @TODO: put major z-index into object
export const ModalOverlay = styled.div<{mobileModalPositionBottom?: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 103;
  background: rgba(248, 248, 249, 0.9);
  ${({ theme, mobileModalPositionBottom }) => theme.media.showAt({ to: 2 })`
    background: ${mobileModalPositionBottom ? 'rgba(248, 248, 249, 0.9);' : theme.colors.neutrals.white};
  `}
`;

export const ModalTitle = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing(2)} 0;
  ${({ theme }) => theme.media.showAt({ from: 3 })`
    margin-bottom: ${theme.spacing(4)};
    font-size: 17px;
    height: 30px;
    font-weight: 700;
  `}
`;

export const CloseButton = styled.div<{
  mobileModalPositionBottom?: boolean;
  horizontalPosition: 'left' | 'right';
}>`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, horizontalPosition }) => theme.media.showAt({ from: 3 })`
    ${horizontalPosition === 'right' ? 'right' : 'left' }: -14px;
    top: -14px;
    svg {
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
  ${({ theme, mobileModalPositionBottom, horizontalPosition }) => theme.media.showAt({ to: 2 })`
    ${horizontalPosition === 'right' ? 'right' : 'left'}: ${mobileModalPositionBottom ? `-${theme.spacing(2)}` : theme.spacing(3) };
    height: 24px;
    width: 24px;
    top: ${mobileModalPositionBottom ? `-${theme.spacing(2)}` : theme.spacing(3) };
    transform: translateY(-50%) scaleX(${theme.isRTL ? '-' : ''}1);
    svg {
      path {
        fill: ${theme.colors.neutrals.grey1};
      }
    }
  `}
`;

interface IHaveShadow {
  hasShadow: boolean;
  mobileModalPositionBottom?: boolean;
}

export const ModalHeader = styled.div<IHaveShadow & { hasTitle?: boolean; }>`
  position: relative;
  ${props => props.mobileModalPositionBottom ? `
    & > div:nth-of-type(2) {
      font-weight: bold;
      font-size: 17px;
    }
  ` : ''}
  ${props => props.theme.media.showAt({ to: 2 })`
    width: 100%;
    ${!props.mobileModalPositionBottom && `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: ${props.hasShadow
      ? props.theme.shadow.level2
      : props.theme.shadow.level0
    }`};
    ${props.hasTitle ? 'min-height: 48px;' : ''}
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props.theme.colors.neutrals.white};
    justify-content: center;
    padding: 0 ${props.theme.spacing(8)};
    z-index: 3;
  `}
`;

export const ModalFooter = styled.div<IHaveShadow>`
  ${(props) => props.theme.media.showAt({ to: 2 })`
    ${!props.mobileModalPositionBottom ? `
      background: ${props.theme.colors.neutrals.white};
      box-shadow: ${props.hasShadow ? '0px 0px 5px 2px rgba(0,0,0,0.1)' : ''};
      border-top: ${props.hasShadow ? `1px solid ${props.theme.colors.neutrals.white}` : `1px solid ${props.theme.colors.neutrals.grey8}`};
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: ${props.theme.spacing(1)} ${props.theme.spacing(3)};`
    : `padding: ${props.theme.spacing(3)} ${props.theme.spacing(3)} ${props.theme.spacing(4)};`}
    z-index: 3;
  `}
  ${(props) => props.theme.media.showAt({ to: 2 })`
    max-width: 100%;
  `}
  ${({ theme }) => theme.media.showAt({ from: 2 }) `
    margin-top: ${theme.spacing(3)};
  `}
`;
