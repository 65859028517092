import {
  CommutePreference,
  DatedPoi,
  InterestPoint,
  IPoiUserData,
  RegisterType,
  UserRole,
  UserSavedAddresses,
} from 'utils/entities';
import { createSelector } from 'reselect';
import { createIsLoadingSelector, makeNullSafeDomainGetter, userSelector } from './common';
import { flow, isNil, keyBy, sortBy } from 'lodash';
import { IAgentInOffice, ISaveSearch, ISearchNotificationJar, IAgentStatusType } from 'store/sagas/apiService/types';
import { removeTypeName } from 'utils';
import { notificationsBySearchIdSelector } from './notificationsCount';


export const userLoadingSelector = createIsLoadingSelector(userSelector);
export const userProfileSelector = makeNullSafeDomainGetter(userSelector, 'me');
export const userAuthErrorSelector = flow(userSelector, (d) => d.errors && d.errors.length ? d.errors[0] : null);

export const interestPointsSelector = createSelector([ userProfileSelector ], (user): InterestPoint[] => {
  return user && user.commutePreference && user.commutePreference.commuteType && user.commutePreference.location ? [
    {
      name: user.commutePreference.text,
      chosenMode: user.commutePreference.commuteType,
      rushHour: user.commutePreference.rushHour,
      location: [ user.commutePreference.location.lat, user.commutePreference.location.lng ],
    },
  ] : [];
});

export const userCommuteTypeSelector = flow(userProfileSelector, (user) => user && user.commutePreference ? user.commutePreference.commuteType : null);

export const isUserSelector = flow(userProfileSelector, Boolean);
export const isConnectedUserSelector = flow(userProfileSelector, (user) => user && user.registrationType !== RegisterType.Visitor);

export const isVisitorUserSelector = flow(userProfileSelector, (user) => user && user.registrationType === RegisterType.Visitor);

export const isAgentSelector = flow(
  userProfileSelector,
  (user) => user && user.roles,
  (roles) => roles && roles.includes(UserRole.Agent)
);
export const displayLevelSelector = flow(
  userProfileSelector,
  (user) => (user && user.agentStatus && user.agentStatus.displayLevel) || null
);
export const pricingTierSelector = flow(
  userProfileSelector,
  (user) => (user && user.agentStatus && user.agentStatus.pricingTier) || null
);
export const isBulletinsAdminSelector = flow(
  userProfileSelector,
  (user) => user && user.roles,
  (roles) => roles && roles.includes(UserRole.BulletinsAdmin)
);

export const isAgentManagerSelector = flow(userProfileSelector, (user) => user && user.commercialIsOfficeManager);

export const isPrivateUserSelector = createSelector([
  isConnectedUserSelector,
  isBulletinsAdminSelector,
  isAgentSelector,
  isAgentManagerSelector,
], (
  isUser,
  isBulletinsAdmin,
  isAgent,
  isAgentManager
) => isUser && !isBulletinsAdmin && !isAgent && !isAgentManager);

export const approvedAgentSelector = createSelector([
  userProfileSelector,
  isAgentSelector,
], (user, isAgent) => isAgent && user && user.agentStatus && user.agentStatus.status === IAgentStatusType.Approved);

export const unapprovedAgentSelector = createSelector([
  userProfileSelector,
  isAgentSelector,
], (user, isAgent) => {
  return isAgent && user && (isNil(user.agentStatus) || user.agentStatus.status === IAgentStatusType.Unapproved);
});

export const agentIdSelector = createSelector([
  userProfileSelector,
  isAgentSelector,
], (user, isAgent) => user && isAgent && user.agentStatus && user.agentStatus.agentId);

export const userWithReducedListingLimitSelector = createSelector([
  isPrivateUserSelector,
  unapprovedAgentSelector,
], (privateUser, unapprovedAgent) => privateUser || unapprovedAgent);

export const agentsInOfficeSelector = createSelector([
  userProfileSelector,
  approvedAgentSelector,
], (user, isApprovedAgent): IAgentInOffice[] => {
  if (!user || !user.commercialIsOfficeManager) return null;

  const managerAgentId = user.commercialOfficeManagerAgentId;
  const maybeAddToList = isApprovedAgent && managerAgentId && !(user.commercialAgentsInOffice && user.commercialAgentsInOffice.find(item => item.agentId === managerAgentId));

  const filteredAgents = user.commercialOfficeManagerOfficeId
    ? user.commercialAgentsInOffice.filter(agent => agent.officeId === user.commercialOfficeManagerOfficeId)
    : user.commercialAgentsInOffice;

  return [
    (maybeAddToList ? ({
      agentName: user.firstName,
      userId: user.uid,
      agentId: managerAgentId,
      officeId: user.commercialOfficeManagerOfficeId,
      officeName: user.commercialOfficeManagerOfficeName,
    }) : null),
    ...filteredAgents,
  ].filter(Boolean);
});

export const agentsInOfficeSortedSelector = createSelector(agentsInOfficeSelector, (agents) =>
  agents && agents.length ? sortBy(agents, 'agentName') : []
);

export const agentsInOfficeDictSelector = createSelector(agentsInOfficeSelector, (list): Record<string, IAgentInOffice> => keyBy(list, ({ agentId }) => agentId));

export const agentConsoleAgentsInOfficeSelector = createSelector(
  [ userProfileSelector, agentsInOfficeSortedSelector, approvedAgentSelector, isAgentManagerSelector ],
  (user, agents, isApprovedAgent, isAgentManager) => {
    if (isAgentManager) return agents || [];
    else if (isApprovedAgent) {
      return [
        {
          agentName: user.firstName,
          userId: user.uid,
          agentId: user.agentStatus && user.agentStatus.agentId,
          officeId: user.agentStatus && user.agentStatus.officeId,
        },
      ];
    }

    return [];
  }
);

export const agentConsoleAgentsAllOfficesSelector = createSelector(
  [ userProfileSelector, agentsInOfficeSortedSelector, approvedAgentSelector, isAgentManagerSelector ],
  (user, agents, isApprovedAgent, isAgentManager) => {
    if (isAgentManager) {
      const otherOfficesAgents = user.commercialAgentsInOffice.filter(agent => agent.officeId !== user.commercialOfficeManagerOfficeId);
      return sortBy([ ...agents, ...otherOfficesAgents ], 'agentName');
    }
    else if (isApprovedAgent) {
      return [
        {
          agentName: user.firstName,
          userId: user.uid,
          agentId: user && user.agentStatus && user.agentStatus.agentId,
        },
      ];
    }

    return [];
  }
);

export const isAgentConsoleUserSelector = createSelector([
  approvedAgentSelector,
  isAgentManagerSelector,
], (isAgent, isAgentManager): boolean => Boolean(isAgent || isAgentManager));

export const commercialEnablePrivateUserSelector = flow(userProfileSelector, (user) => user && user.commercialEnablePrivateUser);
export const userIdSelector = flow(userProfileSelector, (user) => user && user.uid);

export const userHasCommutePreferenceSelector = flow(
  userSelector,
  ({ data }) => Boolean(
    // defensive check: BE's empty commute has non null commutePreference
    data && data.me && data.me.commutePreference && data.me.commutePreference.commuteType
  )
);

const EMPTY_DATED_POI: DatedPoi[] = [];
const EMPTY_SAVED_ADDRESS_ARR: UserSavedAddresses[] = [];
const EMPTY_SAVED_SEARCH_ARR: ISaveSearch[] = [];

export const savedAddressesSelector = flow(userProfileSelector, (u) => u ? u.savedAddresses : EMPTY_SAVED_ADDRESS_ARR);
export const savedDatedPoisSelector = flow(userProfileSelector, (f) => f ? f.favorites : EMPTY_DATED_POI);
export const lastContactedIdsSelector = flow(userProfileSelector, (f) => f ? f.lastContacted : EMPTY_DATED_POI);
export const lastViewedIdsSelector = flow(userProfileSelector, (f) => f ? f.lastViewed : EMPTY_DATED_POI);
export const userPrioritiesSelector = flow(userProfileSelector, (f) => f ? f.priorities : null);

const enhanceSearchesWithNotifications = (searches: ISaveSearch[], notificationsDict: Record<string, ISearchNotificationJar>): ISaveSearch[] => (
  searches.map((search): ISaveSearch => {
    const item = notificationsDict[search.searchId];
    return {
      ...search,
      notificationsCount: item ? item.notificationsCount : null,
      searchPreviews: item ? item.searchPreviews : [],
    };
  })
);

export const searchHistorySelector = createSelector([
  userProfileSelector,
  notificationsBySearchIdSelector,
], (profile, notificationsDict) => {
  if (!profile || !profile.searchHistory) return EMPTY_SAVED_SEARCH_ARR;

  return enhanceSearchesWithNotifications(profile.searchHistory, notificationsDict);
});

export const savedSearchesListSelector = createSelector([
  userProfileSelector,
  notificationsBySearchIdSelector,
], (user, notificationsDict) => {
  if (!(user && user.savedSearches)) return EMPTY_SAVED_SEARCH_ARR;
  return enhanceSearchesWithNotifications(user.savedSearches, notificationsDict);
});

export const savedAddressesIdsSelector = createSelector([ savedAddressesSelector ], (savedAddresses) => new Set(savedAddresses.map(({ document: { docId } }) => docId)));

export const userCommuteWithPrioritiesSelector = createSelector([
  flow(userProfileSelector, (u) => u ? u.commutePreference : null),
  userPrioritiesSelector,
], (commutePreference, priorities): IPoiUserData => {

  const sanitizedCommutePreference: CommutePreference = commutePreference && (commutePreference.location || commutePreference.commuteType === 'train') ? {
    ...removeTypeName({
      ...commutePreference,
      maxCommute: commutePreference.maxCommute ? removeTypeName(commutePreference.maxCommute) : null,
    }),
    location: commutePreference.location && removeTypeName(commutePreference.location),
  } : null;

  return sanitizedCommutePreference || (priorities && priorities.length) ? {
    commutePreference: sanitizedCommutePreference,
    priorities,
  } : null;
});

export const lastViewedPoiIdsSet = createSelector(
  lastViewedIdsSelector,
  (ids) => new Set(ids.map((i) => i.poiId.id))
);
