import styled from '@emotion/styled';
import { HrefText, Text } from 'ds/components/typography';
import { typographyFontSizeCfg } from 'ds/components/typography/types';
import { Label } from 'ds/components/input/RangeInputs/styled';


export const Hint = styled(Label)`
  ${({ theme }) => `
    font-weight: 700;
    margin-right: ${theme.spacing(0.5)};
    position: relative;
    bottom: 2px;
  `}
`;

export const Wrapper = styled.div<{ isFixed?: boolean }>`
  ${({ theme, isFixed }) => `
    height: 68px;
    border-radius: ${theme.borderRadius.small};
    padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(3)};
    display: flex;
    align-items: center;
    box-shadow: '0 1px 5px 0 rgba(1, 132, 137, 0.17), 0 2px 34px 0 rgba(0, 0, 0, 0.2)';
    background: ${theme.colors.neutrals.white};
    cursor: pointer;
    position: absolute;
    margin-top: 0;

    ${isFixed ? `
      position: fixed;
      bottom: ${theme.spacing(4)};
      z-index: 100;
      animation: fade-in .4s ease-in-out;
    ` : ''}
    > svg {
      position: relative;
      top: 10px;
      left: 2px;
    }
  `}
  ${({ theme }) => theme.media.showAt({ at: 1 }) `
    left: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    bottom: 8px;
    padding-left: ${theme.spacing(0.75)};
    padding-right: ${theme.spacing(0.75)};
    transition: bottom .2s;
    justify-content: start;
    > svg {
      position: relative;
      top: 12px;
      left: 0;
    }
  `}
  ${({ theme, isFixed }) => theme.media.showAt({ at: 2 }) `
    left: ${theme.spacing(5)};
    right: ${theme.spacing(5)};
    bottom: ${!isFixed ? 30 : 88}px;
    transition: bottom .2s;
  `}
  ${({ theme }) => theme.media.showAt({ to: 2 }) `
    @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateY(100px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `}
  ${({ theme, isFixed }) => theme.media.showAt({ from: 3 }) `
    ${isFixed ? '' : 'bottom: -34px;'}
    width: 864px;
    left: 50%;
    transform: translateX(-50%);
    @keyframes fade-in {
      from {
        opacity: 0;
        transform: translateY(100px) translateX(-50%);
      }
      to {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
      }
    }
  `}
  ${({ theme }) => theme.media.showAt({ from: 4 }) `
    width: 1144px;
  `}
`;

export const LinkText = styled(HrefText)`
  ${({ theme }) => `
    color: ${theme.colors.brand.secondary};
    display: flex;
    align-items: center;
    svg {
      margin-right: ${theme.spacing(0.5)};
      height: 24px;
      path {
        fill: ${theme.colors.brand.secondary};
      }
    }
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    font-size: ${typographyFontSizeCfg.smallText}px;
    margin-right: ${theme.spacing(1.25)};
  `}
`;

export const LinkTextBefore = styled(Text.withComponent('span'))`
  ${({ theme }) => `
    margin-left: ${theme.spacing(0.5)};
    margin-right: ${theme.spacing(1)};
    color: #074856;
  `}

  ${({ theme }) => theme.media.showAt({ at: 1 })`
    font-size: ${typographyFontSizeCfg.smallText}px;
  `}
`;

export const StaticWrapper = styled(Wrapper)`
  padding: ${({ theme }) => `${theme.spacing(3)}`};
  position: relative;
  && {
    width: 100%;
    transform: none;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
`;

export const HintBlock = styled(Hint)`
  bottom: 0;
  margin-right: 0;
`;
