import React, { useEffect, useRef } from 'react';
import { noop } from 'lodash';
import Downshift from 'downshift';
import { IAutocompleteEntry } from 'utils/entities';
import { TextField } from 'ds/components/input/TextField';
import SearchIcon from 'assets/svg/addresses-autocomplete-input-search.svg';
import { OverlayWrapper } from 'components/overlay-wrapper';
import { useScreenBreakpoint } from 'consts/breakpoints';
import CrossIcon from 'assets/svg/close.svg';
import { useLocale } from 'locale';
import {
  AutocompleteInputWrapper,
  ClearButton,
  CloseButton,
  TextFieldWrapper,
  AddressSuggestionsWrapper,
  MobileSuggestionsWrapper,
  InputWrapperStyled,
  SuggestionMessageWrapper,
} from './styled';
import { STICKY_ROOT } from 'consts/rootNodes';
import { SingleInputProps } from '../types';
import { Suggestions } from '../suggestions/Suggestions';
import { useRoute } from 'config/routes';
import { flattenInitialSuggestions } from '../suggestions/utils';
import { setMobileDiscoveryDisabled, setMobileDiscoveryEnabled } from 'store/state/app/actions';
import { connect } from 'react-redux';

const EMPTY_ARRAY: IAutocompleteEntry[] = [];

const DEFAULT_INPUT_ICON = <SearchIcon width={24} height={24} />;
const DEFAULT_OPTIONS_COUNT = 10;

const SingleLocationAutocompleteBase: React.FunctionComponent<SingleInputProps> = (props) => {
  const {
    onSearchStrChange,
    searchStr,
    disabled,
    showType,
    onChange,
    initialValue,
    placeholder,
    showClearButton,
    autoFocus,
    initialSuggestions,
    loading,
    source,
    inlineView,
    hasError,
    errorMessage,
    sendOnClickEvent = noop,
    onBlur = noop,
    onSuggest = noop,
    optionsCount = DEFAULT_OPTIONS_COUNT,
    suggestedAddresses = EMPTY_ARRAY,
    inputIcon = DEFAULT_INPUT_ICON,
    overlayId = STICKY_ROOT,
    downshiftRef,
    onDownshiftStateChange,
    suggestionItemIcon,
    suggestionMessage,
    makeMobileDiscoveryDisabled,
    makeMobileDiscoveryEnabled,
  } = props;

  const { route } = useRoute();
  const { t } = useLocale();
  const inputRef = useRef<HTMLDivElement>(null);
  const currentRange = useScreenBreakpoint();
  const isMobileView = inlineView ? false : currentRange <= 2;

  useEffect(() => {
    if (initialValue) {
      onSelectedAddressChange(initialValue as IAutocompleteEntry);
    }
  }, []);

  useEffect(() => {
    onSuggest(suggestedAddresses);
  }, [ suggestedAddresses ]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchStrChange(e.currentTarget.value);
    onChange(null);
  };

  const handleClear = () => {
    onSearchStrChange('');
    onChange(null);
    const inputEl = inputRef.current.querySelector('input');
    inputEl.focus();
  };

  const flatInitialSuggestions = flattenInitialSuggestions(initialSuggestions);
  const allSuggestedAddresses = [ ...suggestedAddresses, ...flatInitialSuggestions ];

  const onSelectedAddressChange = (selectedAddress: IAutocompleteEntry) => {
    if (selectedAddress) {
      onSearchStrChange(selectedAddress.name);
      onChange(
        selectedAddress,
        allSuggestedAddresses.indexOf(selectedAddress),
        suggestedAddresses.length || flatInitialSuggestions.length
      );
    }
    else {
      onSearchStrChange(initialValue ? initialValue.name : '');
    }
  };

  const handleInputChange = (selectedItemDocId: string) => {
    onSelectedAddressChange(allSuggestedAddresses.find(address => address.docId === selectedItemDocId));
    inputRef.current.querySelector('input').blur();
  };

  return (
    <AutocompleteInputWrapper data-auto="address-autocomplete-input-wrapper">
      <Downshift
        ref={downshiftRef as any}
        defaultHighlightedIndex={0}
        initialIsOpen={autoFocus}
        onChange={handleInputChange}
        onStateChange={onDownshiftStateChange}
      >
        {({ getInputProps, getItemProps, getRootProps, isOpen, toggleMenu, openMenu, highlightedIndex }) => {
          const isMobileViewOpen = isOpen && isMobileView;

          if (isMobileViewOpen) {
            makeMobileDiscoveryDisabled();
          }

          // We need to send click event in focus handler, because sometimes
          // we use autofocus on mobile instead of direct click
          const handleTextFieldFocus = () => {
            sendOnClickEvent(source, route.name);
          };

          const handleClick = () => !isOpen && !searchStr ? toggleMenu() : openMenu();

          const handleClose = () => {
            toggleMenu();
            onSearchStrChange(initialValue ? initialValue.name : '');
            makeMobileDiscoveryEnabled();
            onBlur();
          };

          const input = (
            <TextField
              {...getInputProps()}
              data-auto="autocomplete-textfield"
              disabled={disabled}
              onClick={handleClick}
              icon={!isMobileViewOpen ? inputIcon : null}
              hasError={hasError}
              errorMessage={errorMessage}
              value={searchStr}
              onChange={onInputChange}
              placeholder={placeholder}
              fullWidth
              onBlur={onBlur}
              onFocus={handleTextFieldFocus}
              autoFocus={isOpen || autoFocus}
            />
          );

          const clearButton = (
            <ClearButton data-auto="search-clear" onClick={handleClear}>
              <CrossIcon width={16} height={16} />
            </ClearButton>
          );

          const closeButton = (
            <CloseButton
              data-auto="close-button"
              onClick={handleClose}
              weight="medium"
            >
              {t('autocompleteInput.cancel')}
            </CloseButton>
          );

          const content = (
            <div>
              <InputWrapperStyled>
                <TextFieldWrapper
                  className="autocomplete-textfield-wrapper"
                  ref={inputRef}
                  isMobile={isMobileView}
                  isOpen={isOpen}
                  showClearButton={showClearButton}
                >
                  {input}
                  {showClearButton && searchStr && isOpen ? clearButton : null}
                </TextFieldWrapper>

                {isMobileViewOpen ? closeButton : null}

              </InputWrapperStyled>

              <Suggestions
                isOpen={isOpen}
                initialSuggestions={initialSuggestions}
                suggestedAddresses={suggestedAddresses}
                loading={loading}
                searchStr={searchStr}
                source={source}
                optionsCount={optionsCount}
                showType={showType}
                highlightedIndex={highlightedIndex}
                itemPropsGetter={getItemProps}
                itemIcon={suggestionItemIcon}
              >
                {(suggestions) => (
                  <AddressSuggestionsWrapper isMobile={isMobileView} data-auto="autocomplete-suggestions">
                    {suggestionMessage ? <SuggestionMessageWrapper>{suggestionMessage}</SuggestionMessageWrapper> : null}
                    {suggestions}
                  </AddressSuggestionsWrapper>
                )}
              </Suggestions>
            </div>
          );

          return isMobileViewOpen ? (
            <OverlayWrapper portalId={overlayId} {...getRootProps({ refKey: 'innerRef' })}>
              <MobileSuggestionsWrapper>{content}</MobileSuggestionsWrapper>
            </OverlayWrapper>
          ) : content;
        }}
      </Downshift>
    </AutocompleteInputWrapper>
  );
};

const mapDispatchToProps = {
  makeMobileDiscoveryDisabled: setMobileDiscoveryDisabled,
  makeMobileDiscoveryEnabled: setMobileDiscoveryEnabled,
};

export const SingleLocationAutocomplete = connect(null, mapDispatchToProps)(SingleLocationAutocompleteBase);
