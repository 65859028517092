import React from 'react';
import ClockIcon from 'assets/svg/clock.svg';
import CheckCircleIcon from 'assets/svg/check-circle.svg';
import WarningCircleIcon from 'assets/svg/warning-circle.svg';
import { useLocale } from 'locale';
import { DealAffiliationStatus } from 'utils/entities';
import * as Styled from './styled';
import Tooltip from 'ds/components/tooltip';
import { SmallText } from 'ds/components/typography';


interface StatusLabelProps {
  status: DealAffiliationStatus;
  rejectionReason?: string;
}

const StatusLabel: React.FC<StatusLabelProps> = ({ status, rejectionReason }) => {
  const { t } = useLocale();

  let icon;
  switch (status) {
    case DealAffiliationStatus.Pending:
      icon = <ClockIcon />;
      break;
    case DealAffiliationStatus.Approved:
      icon = <CheckCircleIcon />;
      break;
    case DealAffiliationStatus.Rejected:
      icon = <WarningCircleIcon />;
      break;
    default:
      const exhaustiveCheck: never = status;
      throw new Error(`Unhandled status case: ${exhaustiveCheck}`);
  }

  const label = (
    <Styled.Label status={status} data-auto="deal-status">
      {icon} {t('dealAffiliationPopup.statusLabel', { status })}
    </Styled.Label>
  );

  return status === DealAffiliationStatus.Rejected && rejectionReason ? (
    <Tooltip
      size="medium"
      placement="bottom"
      tooltip={<SmallText>{t('exclusivity.rejectionReason', { type: rejectionReason })}</SmallText>}
      tapThreshold={0}
    >
      {label}
    </Tooltip>
  ) : label;
};

export default StatusLabel;
