import { useContext } from 'react';
import { getApolloContext } from '@apollo/react-hooks';
import createDealInvoiceUrls from 'store/sagas/apiService/mutations/createDealInvoiceUrls.gql';
import { UploadedFile } from 'components/agent-console/document-upload/drop-zone';


export const useUploadFiles = () => {
  const { client } = useContext(getApolloContext());

  return  async (
    files: File[],
    soldId: string,
    agentId: string
  ): Promise<UploadedFile[]> => {
    const uploadFile = async (file: File) => {
      const {
        data: {
          createDealInvoiceUrls: {
            uploadMediaResult: [ { url, presignedUrl } ],
          },
        },
      } = await client.mutate({
        mutation: createDealInvoiceUrls,
        variables: {
          data: {
            fileName: file.name,
            soldId,
            agentId,
            mediaType: 'affiliationInvoice',
            entityType: 'realEstateAgent',
          },
        },
        fetchPolicy: 'no-cache',
      });

      const response = await fetch(presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type && file.type.length === 0 ? 'application/octet-stream' : file.type,
        },
        body: file,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return { file, link: url };
    };

    try {
      return await Promise.all(files.map(uploadFile));
    }
    catch (e) {
      throw new Error(e.message);
    }
  };
};

