import React, { useEffect } from 'react';
import Content from './IL';
import { HeaderWrapper } from 'ds/components/Layout';
import Navigation from 'components/navigation';
import { noop } from 'lodash';
import { DesktopHomePageWrapper } from 'screens/HomePage/styled';

const HomePage: React.FC = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderWrapper>
        <Navigation
          isMobileMapViewActive={false}
          setMobileMapViewActive={noop}
          isNavigationSubheaderVisible={false}
        />
      </HeaderWrapper>
      <DesktopHomePageWrapper>
        <Content />
      </DesktopHomePageWrapper>
    </>
  );
};

export default HomePage;
