import {
  makeNullSafeDomainGetter,
  userListingsSelector,
  createIsLoadingSelector,
  makeByIdsDictionarySelector,
  userListingsWithOfficesSelector,
  getBulletinsUsageOverviewSelector,
  getAgentBulletinsUsageOverviewSelector,
} from './common';
import { omit, groupBy } from 'lodash';
import { createSelector } from 'reselect';
import {
  BuildingClass,
  BulletinStatusType,
  IBulletin,
  ICommercialBulletin,
  IUserListingsByStatus,
} from 'utils/entities';
import { isAgentSelector } from 'store/state/domainData/selectors';
import { propertyTypeToBuildingClassMap } from 'utils/uploadBulletin';
import { IAgentInOffice } from 'store/sagas/apiService/types';


export const getBulletinsUsageOverviewDataSelector = makeNullSafeDomainGetter(getBulletinsUsageOverviewSelector, 'getBulletinsUsageOverview');
export const getAgentBulletinsUsageOverviewDataSelector = makeNullSafeDomainGetter(getAgentBulletinsUsageOverviewSelector, 'getBulletinsUsageOverview');

const baseUserListingsSelector = makeNullSafeDomainGetter(userListingsSelector, 'getUsersListings');
const baseUserListingsWithOfficesSelector = makeNullSafeDomainGetter(userListingsWithOfficesSelector, 'userListingsWithOffices');

export const isUserListingsLoadingSelector = createIsLoadingSelector(userListingsSelector);
export const isUserListingsWithOfficesLoadingSelector = createIsLoadingSelector(userListingsWithOfficesSelector);

const listingMapper = (listing: IBulletin | ICommercialBulletin) => {
  const parsedFloor = parseInt(`${listing.floor}`, 10);
  return ({
    ...listing,
    buildingClass: (propertyTypeToBuildingClassMap[listing.buildingClass] || listing.buildingClass) as BuildingClass,
    floor: isNaN(parsedFloor) ? null : parsedFloor,
  });
};

const baseParsedUserListingsSelector = createSelector(baseUserListingsSelector, (listings) => {
  if (!listings) return null;

  return ({
    residential: listings.residential && listings.residential.map(listingMapper) || null,
    commercial: listings.commercial && listings.commercial.map(listingMapper) || null,
  });
});

const baseParsedAdminListingsSelector = createSelector(baseUserListingsWithOfficesSelector, (data) => {
  if (!(data && data.listings)) return null;

  return ({
    residential: data.listings.residential && data.listings.residential.map(listingMapper) || null,
    commercial: data.listings.commercial && data.listings.commercial.map(listingMapper) || null,
  });
});

const allUserListingsSelector = createSelector([
  baseParsedUserListingsSelector,
  baseParsedAdminListingsSelector,
  isAgentSelector,
], (listings, adminListings, isAgent): Array<ICommercialBulletin | IBulletin> => {
  const residential = !isAgent && listings && listings.residential || [];
  const commercial = listings && listings.commercial || [];
  const adminResidential = adminListings && adminListings.residential || [];
  const adminCommercial = adminListings && adminListings.commercial || [];

  return [ ...residential, ...commercial, ...adminResidential, ...adminCommercial ];
});

export const userListingsByStatusSelector = createSelector([
  baseParsedUserListingsSelector,
  isAgentSelector,
], (usersListings, isAgent): IUserListingsByStatus => {
  const hasResidential = !isAgent && usersListings && usersListings.residential && usersListings.residential.length;
  const hasCommercial = usersListings && usersListings.commercial && usersListings.commercial.length;

  if (!hasResidential && !hasCommercial) return null;

  const grouped = groupBy([
    ...(isAgent || !usersListings.residential ? [] : usersListings.residential),
    ...(usersListings.commercial ? usersListings.commercial : []),
  ], bulletin => bulletin.status.status);
  return omit(grouped, BulletinStatusType.Deleted);
});

export const adminListingsByStatusSelector = createSelector([
  baseUserListingsWithOfficesSelector,
], (data): IUserListingsByStatus => {
  if (data && data.listings) {
    const { commercial, residential } = data.listings;
    const hasResidential = residential && residential.length;
    const hasCommercial = commercial && commercial.length;

    if (!hasResidential && !hasCommercial) return null;

    const grouped = groupBy([
      ...(residential || []),
      ...(commercial || []),
    ], bulletin => bulletin.status.status);
    return omit(grouped, BulletinStatusType.Deleted);
  }
  return null;
});

export const adminAgentsInOfficeSelector = createSelector([
  baseUserListingsWithOfficesSelector,
], (data): IAgentInOffice[] => {
  if (data && data.agentsInOffice) {
    return data.agentsInOffice.map(({ agentId, name: agentName, userId }) => ({
      agentName,
      userId,
      agentId,
    }));
  }
  return null;
});

export const userListingsByIdsSelector = makeByIdsDictionarySelector(allUserListingsSelector);

