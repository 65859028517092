import React, { useContext, useMemo } from 'react';
import Modal from 'ds/components/modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { routeContext } from 'react-router5';
import { useLocale } from 'locale';
import HomeDoneIcon from 'assets/svg/home-done.svg';
import { shareActionTypeToIcon, shareHandler, ShareType } from 'helpers/share-helpers';
import { IBulletin, ICommercialBulletin } from 'utils/entities';
import { isServer } from 'utils';
import { isCommercialBulletin } from 'utils/marketplaceRoutes';
import { Route } from 'config/routes';
import { useScreenBreakpoint } from 'consts/breakpoints';
import {
  ModalContentRoot,
  ModalContentInfoSection,
  ModalContentBannerSection,
  Description,
  HeaderTitle,
  IconWrapper,
  LinksWrapper,
  SocialLink,
  SocialLinkWrapper,
  BannerImage,
} from './styled';
import { BannerLinkWithEvents } from 'components/menora-insurance-banner/BannerLinkWithEvents';
import { getBanner } from 'components/menora-insurance-banner/config';


export interface ReportPopupViewProps {
  popupStatus?: 'UPLOAD' | 'UPDATE' | null;
  poi: IBulletin | ICommercialBulletin;
  isPrivateUser: boolean;
  isModalOpen?: boolean;
  onClosePopup: () => void;
}

export const AfterUploadModalView: React.FC<ReportPopupViewProps> = ({
  poi,
  popupStatus,
  isPrivateUser,
  onClosePopup,
}) => {
  const { t } = useLocale();
  const router = useContext(routeContext);
  const isMobile = useScreenBreakpoint() < 3;
  const bannerConfig = useMemo(() => (
    getBanner('uploadBulletinPopup', isPrivateUser ? 'privateUser' : 'agent')
  ), [ poi && poi.id, isPrivateUser ]);

  const onClose = () => onClosePopup();

  if (!popupStatus || !poi) return null;

  const isNewListing = popupStatus === 'UPLOAD';

  const onSocialShare = (type: ShareType) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    shareHandler({ type, url: window.location.href, shareViaEmailOptions: { poi, t } });
  };

  const isCommercial: boolean = isCommercialBulletin(poi);
  const linkRoute = isCommercial ? Route.UnitPageCommercial : Route.UnitPage;
  const copyText = isServer() ? null : (window.location.origin + router.router.buildUrl(linkRoute, { id: poi.id }));
  const withBanner = Boolean(bannerConfig);

  return (
    <Modal
      isOpen
      onClose={onClose}
      mobileModalPositionBottom
      closeButtonHorizontalPosition={withBanner && !isMobile ? 'right' : 'left'}
    >
      <ModalContentRoot isMobile={isMobile}>
        <ModalContentInfoSection>
          <IconWrapper>
            <HomeDoneIcon />
          </IconWrapper>
          <HeaderTitle weight="bold">{t('uploadBulletin.afterUpload.modal.title', { isNewListing })}</HeaderTitle>
          <Description>{t('uploadBulletin.afterUpload.modal.description')}</Description>
          <LinksWrapper>
            <SocialLinkWrapper onClick={onSocialShare(ShareType.Facebook)}>
              <SocialLink>
                {shareActionTypeToIcon[ShareType.Facebook]}
              </SocialLink>
            </SocialLinkWrapper>
            <SocialLinkWrapper>
              <CopyToClipboard text={copyText}>
                <SocialLink>
                  {shareActionTypeToIcon[ShareType.CopyLink]}
                </SocialLink>
              </CopyToClipboard>
            </SocialLinkWrapper>
            <SocialLinkWrapper onClick={onSocialShare(ShareType.Email)}>
              <SocialLink>
                {shareActionTypeToIcon[ShareType.Email]}
              </SocialLink>
            </SocialLinkWrapper>
          </LinksWrapper>
        </ModalContentInfoSection>
        <ModalContentBannerSection>
          {withBanner ? (
            <BannerLinkWithEvents
              bannerId={bannerConfig.analyticsBannerId}
              href={bannerConfig.href}
              dealType={poi.dealType}
              bulletinId={poi.id}
            >
              <BannerImage
                src={isMobile ? bannerConfig.imageUrlMobile : bannerConfig.imageUrlDesktop}
                alt={bannerConfig.imageAlt}
                isMobile={isMobile}
              />
            </BannerLinkWithEvents>
          ) : null}
        </ModalContentBannerSection>
      </ModalContentRoot>
    </Modal>
  );
};
