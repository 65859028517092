import { Route, State as RouteState } from 'config/routes';
import { call, fork, put, select } from 'redux-saga/effects';
import { queryData } from 'store/sagas/apiService';
import { LoadType } from 'store/sagas/apiService/types';
import { getRouteParams, routeSelector, showAgentConsoleBulletinFormSelector } from 'store/state/selectors/router';
import { commercialListingSelector, residentialListingSelector } from 'store/state/domainData/selectors/editListing';
import { navigateToListingsPageWorker } from 'store/sagas/addEditBulletinWatcher';
import { AgentPoc, IBulletin, ICommercialListing } from 'utils/entities';
import { createSelector } from 'reselect';
import { isConnectedUserSelector, isAgentSelector, isAgentManagerSelector, userIdSelector, agentsInOfficeSelector } from 'store/state/domainData/selectors';
import { resetDomainData } from 'store/state/domainData/actions';

export const isEditCommercialAllowedSelector = createSelector([
  commercialListingSelector,
  isConnectedUserSelector,
  isAgentSelector,
  isAgentManagerSelector,
  userIdSelector,
  agentsInOfficeSelector,
], (bulletin: ICommercialListing, isUser, isAgent, isAgentManager, uid, agentsInOffice) => {
  if (!isUser) return false;

  const poc = bulletin && bulletin.poc;

  if (isAgentManager) {
    return poc && (
      poc.__typename === 'CommercialAgentPoc' && (poc.userId === uid
      || agentsInOffice && agentsInOffice.some(a => a.userId === poc.userId))
    );
  }

  if (isAgent && bulletin) {
    return poc && poc.__typename === 'CommercialAgentPoc' && poc.userId === uid;
  }

  return false;
});

export const isEditAllowedSelector = createSelector([
  residentialListingSelector,
  isConnectedUserSelector,
  isAgentSelector,
  isAgentManagerSelector,
  userIdSelector,
  agentsInOfficeSelector,
], (bulletin: { listing: IBulletin }, isUser, isAgent, isAgentManager, uid, agentsInOffice) => {
  if (!isUser) return false;

  const poc = bulletin && bulletin.listing && bulletin.listing.poc;
  const pocUserId = poc && poc.__typename === 'AgentPoc' && (poc as unknown as AgentPoc).agent.userId;

  if (isAgentManager) {
    return poc && (
      poc.__typename === 'AgentPoc' && (pocUserId === uid
      || agentsInOffice && agentsInOffice.some(a => a.userId === pocUserId))
    );
  }

  if (isAgent && bulletin) {
    return poc && poc.__typename === 'AgentPoc' && pocUserId === uid;
  }

  return false;
});

function* editBulletinFormWorker() {
  const { id } = yield select(getRouteParams);
  const route: RouteState = yield select(routeSelector);
  const isConsoleUser: boolean = yield select(showAgentConsoleBulletinFormSelector);

  if (!id || !isConsoleUser) return;

  const isCommercialRoute = route.name === Route.EditBulletinForm;
  const isResidentialRoute = route.name === Route.EditResidentialBulletinForm;

  const loadType = isResidentialRoute ? LoadType.GetResidentialListing : LoadType.GetCommercialListing;

  yield put(resetDomainData({ loadType }));

  try {
    yield call(queryData, {
      loadType,
      meta: {
        variables: {
          id,
        },
      },
    });
  }
  catch (error) {
    yield fork(navigateToListingsPageWorker);
    return;
  }

  const commercialPoi: ICommercialListing = yield select(commercialListingSelector);
  const residentialPoi: { listing: IBulletin } = yield select(residentialListingSelector);

  const isCommercialListingMissing = isCommercialRoute && !commercialPoi;
  const isResidentialListingMissing = isResidentialRoute && !(residentialPoi && residentialPoi.listing);

  if (isCommercialListingMissing && isResidentialListingMissing) {
    yield fork(navigateToListingsPageWorker);
    return;
  }

  const isResidentialEditAllowed: boolean = yield select(isEditAllowedSelector);
  const isCommercialEditAllowed: boolean = yield select(isEditCommercialAllowedSelector);

  const isEditAllowed = (isResidentialRoute && isResidentialEditAllowed) || (isCommercialRoute && isCommercialEditAllowed);

  if (!isEditAllowed) {
    yield fork(navigateToListingsPageWorker);
    return;
  }
}

export function* editBulletinFormPageHandler() {
  yield call(editBulletinFormWorker);
}
