import { UploadBulletinLink } from './UploadBulletinLink';
import { connect } from 'react-redux';
import { State } from 'store/state';
import { showAgentConsoleBulletinFormSelector } from 'store/state/selectors/router';

const mapStateToProps = (state: State) => ({
  showAgentConsoleBulletinForm: showAgentConsoleBulletinFormSelector(state),
});

export default connect(mapStateToProps)(UploadBulletinLink);
