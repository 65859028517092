import withEvents from 'analytics/withEvents';
import { DealType } from 'utils/entities';
import { BannerLink, BannerLinkProps } from './BannerLink';

interface BannerLinkWithEventsProps extends BannerLinkProps {
  bannerId: string;
  bulletinId?: string;
  dealType?: string;
}

export const BannerLinkWithEvents = withEvents<BannerLinkWithEventsProps>((sendEvent, props) => {
  const property = props.bulletinId && props.dealType ? {
    property_id: props.bulletinId,
    dealType: props.dealType === DealType.Rent ? 'for_rent' : 'for_sale',
  } : undefined;
  return {
    onExpose() {
      sendEvent('banner_exposed', 'banner', {
        event: {
          banner_id: props.bannerId,
          banner_is_external: true,
          banner_url: props.href,
          banner_index: 0,
        },
        property,
      });
    },
    onClick() {
      sendEvent('banner_click', 'banner', {
        event: {
          banner_id: props.bannerId,
          banner_is_external: true,
          banner_url: props.href,
        },
        property,
      });
    },
  };
})(BannerLink);
