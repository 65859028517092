import React from 'react';
import { SingleMap } from 'components/map';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { MapRefFn } from 'utils/mapUtilityTypes';

interface IMapAppProps {
  refFn: MapRefFn;
}

function MapApp(props: IMapAppProps)  {
  const breakpoint = useScreenBreakpoint();

  return (
    <ShowAt to={2}>
      {(isMobile) => (
        <SingleMap
          breakpoint={breakpoint}
          isMobile={isMobile}
          isCoveredFromTop={!isMobile}
          refFn={props.refFn}
        />
      )}
    </ShowAt>
  );
}

export default MapApp;
