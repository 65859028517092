import React, { FC, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import Modal from 'ds/components/modal';
import {
  Header,
  Title,
  BrokerageDetails,
  SelectTitle,
  SelectInputsWrapper,
  DocumentUploadSection,
  Information,
  BokerageStatement,
  CheckboxContainer,
  SubmitButton,
  ButtonWrapper,
  UploadedFiles,
  File,
  RemoveFileIconWrapper,
  TitleWrapper,
  CloseModalIconWrapper,
  SelectWrapper,
  SelectPlaceholder,
  StatusWrapper,
  DropZoneWrapper,
  FeedbackToastWrapper,
  Body,
  ScrollContainer,
  RequestSideItemWrapper,
  RequestSideStatusLabel,
  RequestSideLabel,
  MobilePopupText,
  MobileCloseButtonWrapper,
  MobilePopupInner,
} from './styled';
import CrossIcon from 'assets/svg/close.svg';
import AlertInfoIcon from 'assets/svg/alert-info.svg';
import FileIcon from 'assets/svg/file.svg';
import CloseIcon from 'assets/svg/dropdown/close.svg';
import { useTheme } from 'hooks/useTheme';
import { useLocale } from 'locale';
import { H3, SmallText, Text } from 'ds/components/typography';
import Select, { SelectItemProps } from 'ds/components/input/Select';
import { SpacingBlock } from 'components/spacing-block';
import { noop } from 'lodash';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { DropZone } from 'ds/components/dropzone';
import { withValidationMessage } from 'hocs/withValidationMessage';
import { useUploadFiles } from './useUploadFiles';
import StatusLabel from 'components/deal-affiliation-popup/status-label';
import {
  IAgentInOffice,
  IEditDealAffiliationRequestVariables,
  IRequestDealAffiliationVariables,
} from 'store/sagas/apiService/types';
import { useField, useForm } from 'react-final-form-hooks';
import DealAffiliationSubheader from 'components/deal-affiliation-popup/DealAffiliationSubheader';
import {
  IDealAffiliationPopupState,
  AffiliationPopupMode,
  IDealAffiliationFormValues,
  IDealAffiliationUserRequestsToStatus,
} from './types';
import { LoadingDots } from 'ds/components/button/FeedbackButton';
import ErrorPopup from 'components/deal-affiliation-popup/ErrorPopup';
import {
  AffiliationRequestResponseDealType,
  DealAffiliationStatus,
  RequestDealAffiliationResponseType,
  DealAffiliationType,
} from 'utils/entities';
import { FeedbackToast } from 'components/feedback-toast/FeedbackToast';
import { State as RouteState } from 'config/routes';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';
import config from 'config';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { Button } from 'ds/components/button';
import { NavigateToFn, Route } from 'config/routes';

const MAX_FILES_COUNT = 4;
const DropZoneWithValidationMessage = withValidationMessage(DropZone);

export interface UploadedFile {
  file?: File;
  link: string;
}

interface DealAffiliationPopupProps {
  popupState: IDealAffiliationPopupState;
  agentsInOffice: IAgentInOffice[];
  isAgentManager: boolean;
  formInitialValues: IDealAffiliationFormValues;
  route: RouteState;
  navigate: NavigateToFn;
  onClose: () => void;
  submit: (data: IEditDealAffiliationRequestVariables | IRequestDealAffiliationVariables) => void;
  isLoading: boolean;
  submitError: boolean;
  responseType: RequestDealAffiliationResponseType | AffiliationRequestResponseDealType;
  openSuccessPopup: () => void;
  dealSideToStatusMap: IDealAffiliationUserRequestsToStatus;
}

const filePrefix = /(realEstateOffice\/affiliationInvoice\/soldId\/agentId\/)|(realEstateAgent\/image\/)/gi;
const filePrefixLegacy = /(\/\/.+amazonaws\.com\/.+\/)/gi;

export const DealAffiliationPopup: FC<DealAffiliationPopupProps> = (props) => {
  const {
    popupState,
    agentsInOffice,
    isAgentManager,
    formInitialValues,
    navigate,
    onClose,
    submit,
    isLoading,
    submitError,
    responseType,
    openSuccessPopup,
    dealSideToStatusMap,
  } = props;
  const { t } = useLocale();
  const isMobile = useScreenBreakpoint() < 3;
  const [ uploadedFiles, setUploadedFiles ] = useState<UploadedFile[]>([]);
  const [ submitted, setSubmitted ] = useState<boolean>(false);
  const [ isErrorPopupOpen, setIsErrorPopupOpen ] = useState<boolean>(false);
  const [ isExceededFileLimitError, setIsExceededFileLimitError ] = useState<boolean>(false);
  const [ isFileTypeError, setIsFileTypeError ] = useState<boolean>(false);

  const [ isScrolled, setIsScrolled ] = useState(false);
  const scrollRef = useRef(null);

  const onIntersect = ([ entry ]: IntersectionObserverEntry[]) => {
    if (entry.intersectionRatio === 1) setIsScrolled(false);
    else setIsScrolled(true);
  };
  useIntersectionObserver(onIntersect, [ scrollRef ]);

  const [ isUploadLoading, setIsUploadLoading ] = useState(false);
  const [ statementCheckboxChecked, setStatementCheckboxChecked ] = useState(false);
  const theme = useTheme();

  const uploadFiles = useUploadFiles();
  const { mode, deal } = popupState;
  const affiliationRequest = popupState.affiliationRequest || {};
  const isEditMode = mode === AffiliationPopupMode.Edit;
  const isViewMode = mode === AffiliationPopupMode.View;
  const isNewMode = mode === AffiliationPopupMode.New;

  const { form } = useForm<IDealAffiliationFormValues>({
    onSubmit: noop,
    initialValues: { ...formInitialValues, dealSide: popupState.forceDealSide || formInitialValues.dealSide },
    validateOnBlur: true,
  });
  const agentIdField = useField('agentId', form);
  const dealSideField = useField('dealSide', form);

  const agentsOptions = useMemo(() => {
    if (agentsInOffice) {
      return agentsInOffice.map((agent) => ({ label: agent.agentName, value: agent.agentId }));
    }
    return [];
  }, [ agentsInOffice ]);

  const renderDealSideItem = useCallback((item: SelectItemProps) => {
    const dealSideStatus = dealSideToStatusMap && dealSideToStatusMap[item.value].status;
    return (
      <RequestSideItemWrapper disabled={item.disabled}>
        <RequestSideLabel disabled={item.disabled}>{item.label}</RequestSideLabel>
        <RequestSideStatusLabel status={dealSideStatus}>
          {t('dealAffiliationPopup.selectInput.affiliationTypeStatus', {
            status: dealSideStatus,
            side: dealSideToStatusMap[item.value].side,
          })}
        </RequestSideStatusLabel>
      </RequestSideItemWrapper>
    );
  }, [ dealSideToStatusMap ]);

  const dealSideSelectItems = useMemo(() =>
    [ DealAffiliationType.Seller,  DealAffiliationType.Buyer, DealAffiliationType.Both ].map(
      (affiliationType) => ({
        label: t('dealAffiliationPopup.selectInput.affiliationType', { affiliationType }),
        value: affiliationType,
        disabled: dealSideToStatusMap && dealSideToStatusMap[affiliationType].status === DealAffiliationStatus.Approved,
      })
    ), [ dealSideToStatusMap ]);

  useEffect(() => {
    if (formInitialValues && formInitialValues.dealReceiptLinks) {
      setUploadedFiles(formInitialValues.dealReceiptLinks.map((val): UploadedFile => ({
        link: val,
        file: {
          name: val,
        },
      }) as UploadedFile));
    }
  }, [ formInitialValues ]);

  useEffect(() => {
    if (isLoading) {
      setSubmitted(true);
    }
    if (!isLoading && submitted) {
      if (!submitError) {
        openSuccessPopup();
        onClose();
      }
      else {
        setSubmitted(true);
        setIsErrorPopupOpen(true);
      }
    }
  }, [ isLoading, submitted, submitError ]);

  useEffect(() => {
    let exceededFileLimitErrorTimeout: NodeJS.Timeout;
    if (isExceededFileLimitError) exceededFileLimitErrorTimeout = setTimeout(() => setIsExceededFileLimitError(false), 8000);

    let fileTypeErrorTimeout: NodeJS.Timeout;
    if (isFileTypeError) fileTypeErrorTimeout = setTimeout(() => setIsFileTypeError(false), 8000);

    return () => {
      clearTimeout(exceededFileLimitErrorTimeout);
      clearTimeout(fileTypeErrorTimeout);
    };
  }, [ isExceededFileLimitError, isFileTypeError, setIsFileTypeError, setIsExceededFileLimitError ]);

  if (isMobile) {
    return (
      <Modal isOpen onClose={onClose} mobileModalPositionBottom>
        <MobilePopupInner>
          <MobilePopupText weight="medium">{t('dealAffiliationPopup.mobile.message')}</MobilePopupText>
          <MobileCloseButtonWrapper>
            <Button fullWidth size="large" onClick={onClose}>
              {t('dealAffiliationPopup.mobile.close')}
            </Button>
          </MobileCloseButtonWrapper>
        </MobilePopupInner>
      </Modal>
    );
  }

  const isSubmitButtonActive =
    uploadedFiles &&
    uploadedFiles.length &&
    Boolean(agentIdField.input.value) &&
    Boolean(dealSideField.input.value) &&
    statementCheckboxChecked;

  const handleDropFiles = async (files: File[], rejectedFiles: File[]) => {
    if (rejectedFiles.length) setIsFileTypeError(true);

    if (!files.length) return;
    const possibleUploadCount = MAX_FILES_COUNT - uploadedFiles.length;

    if (possibleUploadCount < 0 || !files.length) return;
    const filteredAcceptedFiles = files.slice(0, possibleUploadCount);

    try {
      setIsUploadLoading(true);

      if (files.length > possibleUploadCount) setIsExceededFileLimitError(true);

      const justUploadedFiles = await uploadFiles(filteredAcceptedFiles, popupState.deal.soldId, 'agent');
      const newFiles = [ ...uploadedFiles, ...justUploadedFiles ];

      setUploadedFiles(newFiles);
    }
    catch (e) {
      // tslint:disable-next-line: no-console
      console.error('image upload url error', e);
    }
    finally {
      setIsUploadLoading(false);
    }
  };

  const handleDeleteFile = (e: React.MouseEvent<HTMLElement>, fileName: string) => {
    e.preventDefault();
    e.stopPropagation();
    const files = uploadedFiles.filter((uf) => uf.file.name !== fileName);
    setUploadedFiles(files);
  };

  const handleCloseModal = () => {
    onClose();
    form.reset();
  };

  const onSubmit = () => {
    if (isLoading) return;

    const { agentId, dealSide } = form.getState().values;
    const dealReceiptLinks = uploadedFiles.map((uf) => uf.link);

    submit({
      input: {
        ...(isEditMode ? { id: popupState.affiliationRequest.affiliationRequestId } : null),
        ...(isNewMode ? { soldId: popupState.deal.soldId } : null),
        agentId,
        agentType: dealSide,
        receiptUrls: dealReceiptLinks,
      },
    });
  };

  const closeErrorPopup = () => setIsErrorPopupOpen(false);

  let handleButtonClick;
  switch (responseType) {
    case RequestDealAffiliationResponseType.NoPermissionsError:
    case AffiliationRequestResponseDealType.NoPermissionsError:
      handleButtonClick = closeErrorPopup;
      break;
    default:
      handleButtonClick = () => {
        closeErrorPopup();
        onClose();
        navigate(Route.Deals, { openDeals: true });
      };
      break;
  }

  const selectPlaceholderTxt = t('dealAffiliationPopup.selectInput.placeholder');
  const selectPlaceholder = <SelectPlaceholder>{selectPlaceholderTxt}</SelectPlaceholder>;

  const onFileClick = (file: string) => {
    if (file && !isNewMode) {
      const legacyReceiptPrefix = '//s3-eu-west-1.amazonaws.com/media.madlan.co.il/';
      const fileToOpen = file.replace(legacyReceiptPrefix, '');
      window.open(`${config.cdnRoot}/${fileToOpen}`);
    }
  };

  return (
    <>
      <Modal
        isOpen
        hideCloseButton
        onClose={onClose}
        contentWrapperStyle={{
          width: '100%',
          padding: 0,
          margin: `${theme.spacing(10)} ${theme.spacing(4)} ${theme.spacing(6)} ${theme.spacing(4)}`,
        }}
      >
        <Header isScrolled={isScrolled}>
          <TitleWrapper>
            <Title weight="bold" data-auto="affiliation-popup-header">
              {t('dealAffiliationPopup.title', { isEdit: isEditMode })}
            </Title>
            {affiliationRequest.affiliationStatus ? (
              <StatusWrapper>
                <StatusLabel status={affiliationRequest.affiliationStatus} />
              </StatusWrapper>
            ) : null}
          </TitleWrapper>
          <CloseModalIconWrapper>
            <CrossIcon width={24} height={24} onClick={handleCloseModal} />
          </CloseModalIconWrapper>
        </Header>
        <ScrollContainer>
          <Body>
            <div ref={scrollRef} />
            <DealAffiliationSubheader deal={deal} />
            <SpacingBlock mLeft={5} mRight={5} mBottom={6}>
              <BrokerageDetails>
                <H3 weight="bold" data-auto="affiliation-brokerage-details-header">
                  {t('dealAffiliationPopup.brokerageDetails.title')}
                </H3>
                <SelectInputsWrapper>
                  <SelectWrapper data-auto="affiliation-popup-agent">
                    <SelectTitle data-auto="affiliation-popup-agent-header">
                      {t('dealAffiliationPopup.brokerageDetails.broker')}
                    </SelectTitle>
                    <Select
                      {...agentIdField.input}
                      {...agentIdField.meta}
                      size="large"
                      items={agentsOptions}
                      searchPlaceholderText={selectPlaceholderTxt}
                      selectionPromptText={selectPlaceholder}
                      disabled={isViewMode || !isAgentManager}
                      isSearchable
                      customIcon={isViewMode || !isAgentManager ? <></> : null}
                    />
                  </SelectWrapper>
                  <SelectWrapper data-auto="affiliation-popup-side">
                    <SelectTitle data-auto="affiliation-popup-side-header">
                      {t('dealAffiliationPopup.brokerageDetails.mediationType')}
                    </SelectTitle>
                    <Select
                      {...dealSideField.input}
                      {...dealSideField.meta}
                      size="large"
                      items={dealSideSelectItems}
                      selectionPromptText={selectPlaceholder}
                      disabled={isViewMode}
                      renderItem={renderDealSideItem}
                      customIcon={isViewMode ? <></> : null}
                    />
                  </SelectWrapper>
                </SelectInputsWrapper>
              </BrokerageDetails>
              {isViewMode && !(uploadedFiles && uploadedFiles.length) ? null : (
                <DocumentUploadSection>
                  <SpacingBlock mBottom={1}>
                    <H3 weight="bold" data-auto="affiliation-popup-upload-header">
                      {t('dealAffiliationPopup.uploadDocument.title')}
                    </H3>
                  </SpacingBlock>
                  <Text>{t('dealAffiliationPopup.uploadDocument.description')}</Text>
                  {uploadedFiles.length > 0 && (
                    <UploadedFiles>
                      <SmallText weight="bold">{t('dealAffiliationPopup.uploadDocument.uploadedFiles')}</SmallText>
                      {uploadedFiles.map((uf) => (
                        <File weight="medium" key={uf.file.name} onClick={() => onFileClick(uf.file.name)} canDownload={!isNewMode}>
                          <FileIcon />
                          {uf.file.name.replace(filePrefix, '').replace(filePrefixLegacy, '')}
                          {isViewMode ? null : (
                            <RemoveFileIconWrapper>
                              <CloseIcon
                                onClick={(e: React.MouseEvent<HTMLElement>) => handleDeleteFile(e, uf.file.name)}
                                stroke={theme.colors.neutrals.grey2}
                                width={16}
                                height={16}
                              />
                            </RemoveFileIconWrapper>
                          )}
                        </File>
                      ))}
                    </UploadedFiles>
                  )}
                  {uploadedFiles.length < MAX_FILES_COUNT && !isViewMode ? (
                    <DropZoneWrapper>
                      <DropZoneWithValidationMessage
                        name="fileDropZone"
                        isMultiple
                        isDragNDropAvailable
                        isClickAvailable
                        onDropFiles={handleDropFiles}
                        uploadedFiles={null}
                        isLoading={isUploadLoading}
                        accept=".pdf,.doc,.docx,image/*"
                        uploadButtonText={t('dealAffiliationPopup.uploadDocument.button.text')}
                        uploadButtonProps={{ size: 'large' }}
                        uploadMessage={t('dealAffiliationPopup.uploadDocument.text')}
                      />
                    </DropZoneWrapper>
                  ) : null}
                  {isViewMode ? null : (
                    <Information>
                      <AlertInfoIcon viewBox="10 0 20 40" width={20} />
                      {t('dealAffiliationPopup.uploadDocument.info')}
                    </Information>
                  )}
                </DocumentUploadSection>
              )}
              <BokerageStatement>
                <SpacingBlock mBottom={1}>
                  <H3 weight="bold" data-auto="affiliation-popup-agreement-header">
                    {t('dealAffiliationPopup.bokerageStatement.title')}
                  </H3>
                </SpacingBlock>
                <CheckboxContainer data-auto="affiliation-popup-agreement">
                  <CheckboxField
                    disabled={isViewMode}
                    checked={isViewMode || statementCheckboxChecked}
                    onClick={() => setStatementCheckboxChecked(!statementCheckboxChecked)}
                  />
                  <Text>{t('dealAffiliationPopup.bokerageStatement.description')}</Text>
                </CheckboxContainer>
              </BokerageStatement>
              {isViewMode ? null : (
                <ButtonWrapper>
                  <SubmitButton
                    disabled={!isSubmitButtonActive}
                    size="extraLarge"
                    onClick={onSubmit}
                    data-auto="affiliation-popup-submit"
                  >
                    {isLoading ? <LoadingDots /> : t('dealAffiliationPopup.submitButton.text')}
                  </SubmitButton>
                </ButtonWrapper>
              )}
            </SpacingBlock>
          </Body>
        </ScrollContainer>
        {isExceededFileLimitError && (
          <FeedbackToastWrapper>
            <FeedbackToast type="error">{t('dealAffiliationPopup.toastError.exceededFilesLimit')}</FeedbackToast>
          </FeedbackToastWrapper>
        )}
        {isFileTypeError && (
          <FeedbackToastWrapper displayHigher={isExceededFileLimitError && isFileTypeError}>
            <FeedbackToast type="error">{t('dealAffiliationPopup.toastError.unsupportedFileType')}</FeedbackToast>
          </FeedbackToastWrapper>
        )}
      </Modal>

      {isErrorPopupOpen ? <ErrorPopup onClose={closeErrorPopup} onButtonClick={handleButtonClick} responseType={responseType} /> : null}
    </>
  );
};
