import styled from '@emotion/styled';
import { Button } from 'ds/components/button';
import { H2, SmallText, Text } from 'ds/components/typography';
import { typographyFontSizeCfg, typographyLineHeightCfg } from 'ds/components/typography/types';
import { DealAffiliationStatus } from 'utils/entities';
import { colorToAlpha } from 'helpers/insight';

export const Title = styled(H2)`
  font-size: ${typographyFontSizeCfg.h2.mobile}px;
  line-height: ${typographyLineHeightCfg.h2.mobile}px;
`;

export const Header = styled.div<{ isScrolled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(5)}`};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  ${({ isScrolled }) => isScrolled ? 'box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.10);' : ''}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 212px);
  direction: ltr;
`;

export const Body = styled.div`
  direction: rtl;
`;

export const Subheader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #FAFBFC;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)} ${theme.spacing(2)} ${theme.spacing(3)}`};

  a, a:hover, a:active {
    text-decoration: none;
  }
`;

export const PropertyImage = styled.div`
  ${({ theme }) => `
    position: relative;
    height: 84px;
    width: 139px;
    border-radius: ${theme.borderRadius.tiny};
    overflow: hidden;
    background-color: ${theme.colors.neutrals.white};

    > img {
      position: relative;
      height: 100px;
      top: -14px;
      left: 25px;
    }
  `}
`;

export const Address = styled(Text)`
  padding: 0 ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.colors.brand.main};
  width: 239px;
  white-space: pre-line;
`;

export const PropertyDetails = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-right: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const PropertyDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PropertyDetailItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 ${({ theme }) => theme.spacing(4)};
  gap: 4px;
`;

export const PropertyDetailTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
`;

export const PropertyDetailValue = styled(Text)`
  color: ${({ theme }) => theme.colors.neutrals.grey1};
`;

export const BrokerageDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 24px;
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(5)}`};
  margin-top: ${({ theme }) => theme.spacing(3)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const SelectInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div:first-of-type {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`;

export const SelectWrapper = styled.div`
  .select-value-text {
    text-align: right;
  }
  div {
    min-width: 208px;
  }
`;

export const SelectPlaceholder = styled.span`
  color: ${({ theme }) => theme.colors.neutrals.grey4};
`;

export const SelectTitle = styled(SmallText)`
  color: ${({ theme }) => theme.colors.neutrals.grey2};
  text-align: right;
  line-height: ${typographyLineHeightCfg.text}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const DocumentUploadSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const CloseModalIconWrapper = styled.div`
  cursor: pointer;
`;

export const Information = styled(Text)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutrals.grey2};
  text-align: right;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(9)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  gap: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: #F2F9F9;
  white-space: pre-wrap;
`;

export const BokerageStatement = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.grey8};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  div {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const SubmitButton = styled(Button)`
  width: 191px;
  margin-top: ${({ theme }) => theme.spacing(6)};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

export const StatusWrapper = styled.div`
  ${({ theme }) => `
    margin-right: ${theme.spacing(2)};
  `}
`;

export const FeedbackToastWrapper = styled.div<{ displayHigher?: boolean }>`
  position: fixed;
  bottom: ${({ displayHigher }) => displayHigher ? '72px' : '16px'};
  left: 48px;
  right: 48px;
  z-index: 1;
  animation: fadeIn 0.5s;

  > div {
    background: linear-gradient(0deg, rgba(208, 2, 27, 0.12) 0%, rgba(208, 2, 27, 0.12) 100%), #FFF;
    width: 100%;
    max-width: unset;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

export const RequestSideItemWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;

  &&& {
    width: 244px;
    min-width: 0;
    margin-left: 0;
  }
  ${({ disabled }) => disabled ? 'cursor: not-allowed;' : ''}
`;

export const RequestSideStatusLabel = styled.div<{ status: DealAffiliationStatus; }>`
  padding: ${({ theme }) => theme.spacing(1)};
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: ${typographyFontSizeCfg.textLabel.small}px;
  font-weight: 700;
  ${({ theme, status  }) => `
    ${status === DealAffiliationStatus.Pending ? `
      color: #EC8537;
      background: #FDF2DC;
    ` : ''}
    ${status === DealAffiliationStatus.Approved ? `
      color: ${theme.colors.neutrals.grey4};
      background: ${theme.colors.neutrals.grey8};
    ` : ''}
    ${status === DealAffiliationStatus.Rejected ? `
      color: ${theme.colors.alert.red};
      background: ${colorToAlpha(theme.colors.alert.red, 0.12)};
    ` : ''}
  `}
  gap: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.spacing(0.75)};;
  &&& {
    width: auto;
    min-width: 0;
  }
`;

export const RequestSideLabel = styled(Text)<{ disabled: boolean }>`
  &&& {
    min-width: 0;
  }

  ${({ theme }) => theme.media.showAt({ from: 2 })`
    font-size: ${typographyFontSizeCfg.smallText}px;
    line-height: ${typographyLineHeightCfg.smallText}px;
  `}

  ${({ disabled, theme }) => disabled ? `color: ${theme.colors.neutrals.grey6}` : ''}
`;

export const MobilePopupText = styled(Text)`
  ${({ theme }) => `
    text-align: center;
    padding-top: ${theme.spacing(8)};
  `}
`;

export const MobileCloseButtonWrapper = styled(Text)`
  ${({ theme }) => `
    text-align: center;
    padding-top: ${theme.spacing(10)};
    width: 50%;
    margin: 0 auto;
  `}
`;

export const MobilePopupInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DropZoneWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};

  .dropzone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(2)};
    color: ${({ theme }) => theme.colors.neutrals.grey3};
    gap: 8px;
    font-size: ${typographyFontSizeCfg.text}px;
    line-height: ${typographyLineHeightCfg.text}px;
    border: 1px dashed ${({ theme }) => theme.colors.brand.main};
    border-radius: ${({ theme }) => theme.borderRadius.small};
  }

  .dropzone {
    width: 100%;
    padding: ${({ theme }) => theme.spacing(3)};
    background: #EDF6F7;
    border-radius: ${({ theme }) => theme.borderRadius.small};
  }

  .dropzone-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
`;

export const UploadedFiles = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  gap: 16px;
`;

export const RemoveFileIconWrapper = styled.span`
  display: flex;
  align-content: center;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const File = styled(Text)<{ canDownload?: boolean; }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1.5)};
  height: 36px;
  border-radius: 24px;
  line-height: ${typographyLineHeightCfg.smallText}px;
  color: ${({ theme }) => theme.colors.brand.main};
  background: ${({ theme }) => theme.colors.brand.lightest};
  gap: 4px;

  ${({ canDownload }) => canDownload ? 'cursor: pointer;' : ''}
`;
