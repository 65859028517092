import { useLocale } from 'locale';
import React, { useState } from 'react';
import ThumbsUpIcon from 'assets/svg/thumbs-up.svg';
import { ButtonWrapper, CheckboxWrapper, ConsentsInfoHeader, ConsentsLogoWrapper } from './styled';
import { CheckboxField } from 'ds/components/input/CheckboxField';
import { SpacingBlock } from 'components/spacing-block';
import { useScreenBreakpoint } from 'consts/breakpoints';
import { Button } from 'ds/components/button';
import { ConsentTypeNG, IConsent } from 'store/sagas/apiService/types';
import { connect } from 'react-redux';
import { authModalAuthSourceSelector } from 'store/state/app/selectors';
import { State } from 'store/state';
import { AuthSource } from './types';
import { loginFacebookWithConsents, loginGoogleWithConsents, loginWithPasswordAndConsents } from 'store/state/app/actions';

interface ConsentsModalProps {
  handleSubmit: (data?: IConsent[]) => void;
  authSource: AuthSource;
  onGoogleLoginWithConsents: (data?: IConsent[]) => void;
  onFacebookLoginWithConsents: (data?: IConsent[]) => void;
  onPasswordLoginWithConsents: (data?: IConsent[]) => void;
}

const ConsentsFormBase: React.FC<ConsentsModalProps> = props => {
  const {
    handleSubmit,
    authSource,
    onGoogleLoginWithConsents,
    onFacebookLoginWithConsents,
    onPasswordLoginWithConsents,
  } = props;
  const { t } = useLocale();
  const [ rulesAccepted, setRulesAccepted ] = useState(false);
  const [ marketingAccepted, setMarketingAccepted ] = useState(false);
  const screen = useScreenBreakpoint();
  const isMobile = screen < 3;

  const handleSubmitForm = () => {
    const consentsApi3: IConsent[] = [
      { type: ConsentTypeNG.TERMS_AND_CONDITIONS, approval: rulesAccepted },
      { type: ConsentTypeNG.MARKETING, approval: marketingAccepted },
    ].filter(el => Boolean(el.approval));

    switch (authSource) {
      case AuthSource.Google: {
        onGoogleLoginWithConsents(consentsApi3);
        break;
      }
      case AuthSource.Facebook:
        onFacebookLoginWithConsents(consentsApi3);
        break;
      case AuthSource.EmailAndPassword:
        onPasswordLoginWithConsents(consentsApi3);
        break;
      default: {
        handleSubmit(consentsApi3.length ? consentsApi3 : undefined);
      }
    }
  };

  return (
    <>
      {isMobile ? null : (
        <>
          <SpacingBlock mTop={4} />
          <ConsentsLogoWrapper>
            <ThumbsUpIcon />
          </ConsentsLogoWrapper>
          <SpacingBlock mTop={2} />
        </>
      )}
      <ConsentsInfoHeader weight="bold">
        {t('authentication.consents.title')}
      </ConsentsInfoHeader>
      <SpacingBlock mTop={3} />
      <CheckboxWrapper>
        <CheckboxField
          checked={rulesAccepted}
          small
          onClick={() => setRulesAccepted(!rulesAccepted)}
          htmlLabel={t('authentication.consents.rulesLabel')}
          size={20}
        />
        <CheckboxField
          checked={marketingAccepted}
          small
          onChange={() => setMarketingAccepted(!marketingAccepted)}
          label={t('authentication.consents.newsLabel')}
          size={20}
          alignStart
        />
      </CheckboxWrapper>
      <SpacingBlock mTop={3} />
      <ButtonWrapper>
        <Button size="extraLarge" onClick={handleSubmitForm} fullWidth disabled={!rulesAccepted}>{t('authentication.consents.button')}</Button>
      </ButtonWrapper>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  authSource: authModalAuthSourceSelector(state),
});

const mapDispatchToProps = {
  onGoogleLoginWithConsents: (data: IConsent[]) => loginGoogleWithConsents(data),
  onFacebookLoginWithConsents: (data: IConsent[]) => loginFacebookWithConsents(data),
  onPasswordLoginWithConsents: (data: IConsent[]) => loginWithPasswordAndConsents(data),
};

export const ConsentsForm = connect(mapStateToProps, mapDispatchToProps)(ConsentsFormBase);
