import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useLocale } from 'locale';
import { State } from 'store/state';
import { authModalStatusSelector, contactModalPoiIdSelector } from 'store/state/app/selectors';
import { ShowAt, useScreenBreakpoint } from 'consts/breakpoints';
import { PoiId } from 'utils/entities';

import ContactModal from 'components/contact-modal';
import FeedbackPopup from 'components/feedback-popup';
import ReportListingPopup from 'components/report-listing-popup';
import ReportUploadBulletinPopup from 'components/upload-bulletin/report-error-popup';
import AfterUploadBulletinModal from 'components/upload-bulletin/after-upload-modal';
import AddressFeedBackPopUp from 'components/address-feedback/AddressFeedBackPopup';
import { ContactAgentSuccessPopup } from 'components/contact-modal/success-popup';
import UgcFeedbackModal from 'components/user-generated-content/connected-components/feedback-modal';
import UgcGratitudeModal from 'components/user-generated-content/gratitude-modal';
import UgcReviewWizard from 'components/user-generated-content/connected-components/review-wizard';
import UgcShareReviewModal from 'components/user-generated-content/share-review-modal';
import MadadContactModal from 'components/madad/contact-modal';
import { withIdleCallback } from 'hocs/withIdleCallback';
import AuthenticationModal from 'components/authentication';
import FeedbackToast from 'components/feedback-toast';
import { AuthenticationModalType } from 'components/authentication/types';
import ManageListingsErrorModal from 'components/bulletin-form/wizard/ErrorModal';
import DeleteListingPopup from 'components/delete-listing-popup';
import { HiddenListingModal } from './components/direct-link-undisplayed-listing-popup';
import MizrahiBankModal from 'components/mizrahi-bank-modal';
import MobileDiscovery from 'components/mobile-discovery';
import { insideReactNative } from 'react-native/lib/platform';
import { isAndroid, isIOS } from 'utils/uaMatchers';
import { UserAgentContext } from 'hooks/useImageUrlBuilder';
import { mobileDiscoveryPopupStatusSelector } from 'store/state/app/selectors';
import { PushNotificationsDrawerConnected } from 'components/push-notifications-drawer';
import DealAffiliationPopup from 'components/deal-affiliation-popup';


const AddressFeedBackPopUpWhenIdle = withIdleCallback(AddressFeedBackPopUp);
const AuthenticatioModalWhenIdle = withIdleCallback(AuthenticationModal);
const ContactModalWhenIdle = withIdleCallback(ContactModal);
const ContactAgentSuccessPopupWhenIdle = withIdleCallback(ContactAgentSuccessPopup);
const FeedbackPopupWhenIdle = withIdleCallback(FeedbackPopup);
const FeedbackToastWhenIdle = withIdleCallback(FeedbackToast);
const ReportListingPopupWhenIdle = withIdleCallback(ReportListingPopup);
const ReportUploadBulletinAddressErrorPopupWhenIdle = withIdleCallback(ReportUploadBulletinPopup);
const AfterUploadBulletinModalWhenIdle = withIdleCallback(AfterUploadBulletinModal);
const UgcFeedbackModalWhenIdle = withIdleCallback(UgcFeedbackModal);
const UgcGratitudeModalWhenIdle = withIdleCallback(UgcGratitudeModal);
const UgcShareReviewModalWhenIdle = withIdleCallback(UgcShareReviewModal);
const UgcReviewWizardWhenIdle = withIdleCallback(UgcReviewWizard);
const MadadContactModalWhenIdle = withIdleCallback(MadadContactModal);
const ManageListingsErrorModalWhenIdle = withIdleCallback(ManageListingsErrorModal);
const DeleteListingModalWhenIdle = withIdleCallback(DeleteListingPopup);
const HiddenListingModalWhenIdle = withIdleCallback(HiddenListingModal);
const MizrahiBankModalWhenIdle = withIdleCallback(MizrahiBankModal);
const MobileDiscoveryModalWhenIdle = withIdleCallback(MobileDiscovery);
const PushNotificationsDrawerWhenIdle = withIdleCallback(PushNotificationsDrawerConnected);
const DealAffiliationModalWhenIdle = withIdleCallback(DealAffiliationPopup);

interface ModalsProps {
  authModal: AuthenticationModalType;
  contactModalPoiId: PoiId;
  discoveryIsOpen: boolean;
}

const appleStoreLink = 'https://apps.apple.com/ua/app/madlan/id6472173076';
const playMarketLink = 'https://play.google.com/store/apps/details?id=com.madlanmobclient';

const Modals: React.FC<ModalsProps> = (props) => {
  const { authModal, contactModalPoiId, discoveryIsOpen } = props;
  const { t } = useLocale();
  const isInsideRN = insideReactNative();
  const currentRange = useScreenBreakpoint();
  const isMobileView = currentRange <= 2;
  const userAgent = useContext(UserAgentContext);
  const isIOSDevice = isIOS(userAgent);
  const isAndroidDevice = isAndroid(userAgent);
  const isMobileDevice = isIOSDevice || isAndroidDevice;
  const marketLink = isAndroidDevice ? playMarketLink : appleStoreLink;

  if (!isInsideRN && isMobileView && isMobileDevice && discoveryIsOpen) return <MobileDiscoveryModalWhenIdle marketLink={marketLink} />;

  return (
    <>
      {authModal ? <AuthenticatioModalWhenIdle /> : null}

      {contactModalPoiId ? <ContactModalWhenIdle /> : null}
      <ContactAgentSuccessPopupWhenIdle />

      <MadadContactModalWhenIdle />
      <FeedbackPopupWhenIdle
        popupTitle={t('feedback.popup.title')}
        submitButtonText={t('feedback.popup.submit')}
      />
      <AddressFeedBackPopUpWhenIdle
        popupTitle={t('feedback.popup.title')}
        submitButtonText={t('addressPage.feedback.submit')}
      />
      <ReportListingPopupWhenIdle
        popupTitle={t('reportListing.popup.title')}
        submitButtonText={t('reportListing.popup.button.text')}
      />
      <ReportUploadBulletinAddressErrorPopupWhenIdle
        popupTitle={t('reportListing.popup.title')}
        submitButtonText={t('reportListing.popup.button.text')}
      />

      <UgcFeedbackModalWhenIdle />
      <UgcGratitudeModalWhenIdle />
      <UgcShareReviewModalWhenIdle />
      <UgcReviewWizardWhenIdle />
      <AfterUploadBulletinModalWhenIdle />
      <ManageListingsErrorModalWhenIdle />
      <DeleteListingModalWhenIdle />
      <HiddenListingModalWhenIdle />
      <MizrahiBankModalWhenIdle />
      <PushNotificationsDrawerWhenIdle />
      <DealAffiliationModalWhenIdle />

      <ShowAt from={3}>
        {isDesktop => <FeedbackToastWhenIdle isDesktop={isDesktop} />}
      </ShowAt>
    </>
  );
};

const mapStateToProps = (state: State) => ({
  authModal: authModalStatusSelector(state),
  contactModalPoiId: contactModalPoiIdSelector(state),
  discoveryIsOpen: mobileDiscoveryPopupStatusSelector(state),
});

export default connect(mapStateToProps)(Modals);
