import { takeEvery, call, put, getContext } from 'redux-saga/effects';
import ApolloClient, { ApolloError } from 'apollo-client';

import { UserTokenStore } from 'helpers/userToken';
import { setMutationResponse } from 'store/state/mutationsResponse/actions';
import { MUTATE } from 'store/state/mutationsResponse/types';
import { MutationType, MutationOptions, MutationResponse, IError } from './types';
import { enrichGQLVariables, getOperationByType } from './utils';



type SetMutationResponseActionUnion = ReturnType<typeof setMutationResponse>;

function extractToken(action: SetMutationResponseActionUnion) {
  if (action.meta.response.errors && action.meta.response.errors.length) {
    return null;
  }

  switch (action.mutationType) {
    // can't de-dupe cause of type guards
    case MutationType.FacebookLogin: {
      const response = action.meta.response.data.facebookLogin;
      return response.__typename === 'LoginOutcome' && response.token;
    }
    case MutationType.GoogleLogin: {
      const response = action.meta.response.data.googleLogin;
      return response.__typename === 'LoginOutcome' && response.token;
    }
    case MutationType.LoginWithEmailAndPassword: {
      const response = action.meta.response.data.passwordLoginV3;
      return response.__typename === 'LoginOutcome' && response.token;
    }
    case MutationType.TempTokenAuth: {
      const response = action.meta.response.data.completeAutoLogin;
      return response.__typename === 'LoginOutcome' && response.token;
    }
    case MutationType.SignInSignUpWithEmail: {
      const response = action.meta.response.data.loginV3;
      return response.__typename === 'LoginOutcome' && response.token;
    }
    case MutationType.RegisterVisitor: {
      const response = action.meta.response.data.registerVisitor;
      return response.__typename === 'LoginOutcome' && response.token;
    }
  }

  return null;
}

const MUTATION_TYPE_WITHOUT_USER_TOKEN = new Set([ MutationType.GetArticles ]);

export function* mutateWorker<T extends MutationType>(opts: MutationOptions<T> & { type: typeof MUTATE }) {
  const { meta: { key, variables }, mutationType } = opts;

  let action: SetMutationResponseActionUnion = null;
  const client: ApolloClient<unknown> = yield getContext('client');
  const tokenStore: UserTokenStore = yield getContext('tokenStore');
  const mutation = yield call(getOperationByType, 'mutation', mutationType);
  try {
    const finalVariables = yield call(enrichGQLVariables, mutation, variables);

    const result: MutationResponse<T> = yield call(client.mutate, {
      mutation,
      variables: finalVariables,
      errorPolicy: opts.meta.errorPolicy,
    });

    action = setMutationResponse({
      mutationType,
      meta: {
        variables,
        key,
        response: result,
      },
    });

  }
  catch (e) {
    if (e instanceof ApolloError) {
      action = setMutationResponse({
        mutationType,
        meta: {
          variables,
          key,
          response: {
            errors: e.graphQLErrors as IError[],
            data: null,
          },
        },
      });
    }
  }
  finally {
    if (action) {
      const token = extractToken(action);
      if (!MUTATION_TYPE_WITHOUT_USER_TOKEN.has(action.mutationType)) {
        yield call([ tokenStore, tokenStore.set ], token);
      }
      yield put(action);
    }
  }
}

export function* mutationsWatcher() {
  yield takeEvery(MUTATE, mutateWorker);
}
