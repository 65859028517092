import React from 'react';
import { createSelector } from 'reselect';

import { DecodedRouteParams, Route } from 'config/routes';
import ProfileIcon from 'assets/svg/profile.svg';
import SaveSearchIcon from 'assets/svg/saved-search.svg';
import SavedSearchMobileIcon from 'assets/svg/navigation/magnifying-glass.svg';
import ListIcon from 'assets/svg/list.svg';
import ListThickIcon from 'assets/svg/navigation/list.svg';
import HeartIcon from 'assets/svg/navigation/heart.svg';
import AgentListingsIcon from 'assets/svg/project/building.svg';
import LogoutIcon from 'assets/svg/log-out.svg';
import LogoutMobileIcon from 'assets/svg/navigation/logout.svg';
import ViewProfileIcon from 'assets/svg/seller.svg';
import MyHomesIcon from 'assets/svg/saved-addresses-nav.svg';
import MyHomesMobileIcon from 'assets/svg/navigation/saved-addresses.svg';
import MyCommuteIcon from 'assets/svg/commute-profile-menu.svg';
import WorkLocationIcon from 'assets/svg/navigation/work-location.svg';
import TagIcon from 'assets/svg/price-us.svg';
import EditIcon from 'assets/svg/edit-tooltip.svg';
import PlusThickIcon from 'assets/svg/navigation/plus.svg';
import PlusIcon from 'assets/svg/add.svg';
import UserIcon from 'assets/svg/navigation/user.svg';
import HouseIcon from 'assets/svg/navigation/house.svg';
import KeyIcon from 'assets/svg/navigation/key.svg';
import ArmchairIcon from 'assets/svg/navigation/armchair.svg';
import DollarSignIcon from 'assets/svg/navigation/dollar-sign.svg';
import BackhoeIcon from 'assets/svg/navigation/backhoe.svg';
import HandsIcon from 'assets/svg/handshake.svg';
import BellRingingIcon from 'assets/svg/bell-ringing.svg';
import CommercialBuildingIcon from 'assets/svg/navigation/commercial-building.svg';
import { isAgentSelector, isConnectedUserSelector } from 'store/state/domainData/selectors';
import { ProfileTab } from 'components/profile/Profile';
import { DealType, MarketplaceType } from 'utils/entities';
import config from 'config';
import { Seller } from 'components/filters/types';
import { pushNotificationsEnabledSelector } from 'store/state/selectors/pushNotificationsStatus';
import { pushNotificationsFeatureEnabled } from 'utils/pushNotificationsFeatureEnabled';
import { showAgentConsoleBulletinFormSelector } from 'store/state/selectors/router';


export enum UserActionName {
  Home = 'home',
  SavedSearches = 'savedSearches',
  Logout = 'logout',
  ViewAgentPage = 'viewAgentPage',
  EditAgentPage = 'editAgentPage',
  MyHomes = 'myHomes',
  MyCommute = 'myCommute',
  ProfileSettings = 'profileSettings',
  MyListings = 'myListings',
  SavedListings = 'savedListings',
  AgentCommercialBulletins = 'agentCommercialBulletins',
  UploadBulletin = 'uploadBulletin',
  Billing = 'billing',
  ResidentialMarketplaceBuy = 'residentialMarketplaceBuy',
  ResidentialMarketplaceRent = 'residentialMarketplaceRent',
  ResidentialMarketplaceNewProjects = 'residentialMarketplaceNewProjects',
  CommercialLandingPage = 'commercialLandingPage',
  ExplorePage = 'explorePage',
  DeveloperIndex = 'developerIndex',
  MortgageOffices = 'mortgageOffices',
  PushSettings = 'pushSettings',
}

export interface UserAction {
  name: UserActionName;
  route: Route;
  customRoute?: string;
  params?: Partial<DecodedRouteParams>;
  hasWarning?: boolean;
}

export const actionToIcon: Record<UserActionName, React.ReactNode> = {
  [UserActionName.Home]: null,
  [UserActionName.ProfileSettings]: <ProfileIcon width={24} height={24} />,
  [UserActionName.SavedSearches]: <SaveSearchIcon width={24} height={24} />,
  [UserActionName.MyListings]: <ListIcon width={24} height={24} />,
  [UserActionName.SavedListings]: null,
  [UserActionName.AgentCommercialBulletins]: <AgentListingsIcon width={24} height={24} />,
  [UserActionName.Logout]: <LogoutIcon width={24} height={24} />,
  [UserActionName.ViewAgentPage]: <ViewProfileIcon width={24} height={24} />,
  [UserActionName.MyHomes]: <MyHomesIcon width={24} height={24} />,
  [UserActionName.MyCommute]: <MyCommuteIcon width={24} height={24} />,
  [UserActionName.Billing]: <TagIcon width={24} height={24} />,
  [UserActionName.EditAgentPage]: <EditIcon width={24} height={24} />,
  [UserActionName.UploadBulletin]: <PlusIcon width={24} height={24} />,
  [UserActionName.ResidentialMarketplaceBuy]: null,
  [UserActionName.ResidentialMarketplaceRent]: null,
  [UserActionName.ResidentialMarketplaceNewProjects]: null,
  [UserActionName.CommercialLandingPage]: null,
  [UserActionName.ExplorePage]: null,
  [UserActionName.DeveloperIndex]: null,
  [UserActionName.MortgageOffices]: <HandsIcon width={24} height={24} />,
  [UserActionName.PushSettings]: null,
};

export const actionToIconMobile: Record<UserActionName, React.ReactNode> = {
  [UserActionName.Home]: <HouseIcon width={24} />,
  [UserActionName.ProfileSettings]: <UserIcon width={24} />,
  [UserActionName.SavedSearches]: <SavedSearchMobileIcon width={24} />,
  [UserActionName.MyListings]: <ListThickIcon width={24}  />,
  [UserActionName.SavedListings]: <HeartIcon width={24} />,
  [UserActionName.AgentCommercialBulletins]: <AgentListingsIcon width={24} />,
  [UserActionName.Logout]: <LogoutMobileIcon width={24} />,
  [UserActionName.ViewAgentPage]: <ViewProfileIcon width={24} />,
  [UserActionName.MyHomes]: <MyHomesMobileIcon width={24} />,
  [UserActionName.MyCommute]: <WorkLocationIcon width={24} />,
  [UserActionName.Billing]: <TagIcon width={24} />,
  [UserActionName.EditAgentPage]: <EditIcon width={24} />,
  [UserActionName.UploadBulletin]: <PlusThickIcon width={24} />,
  [UserActionName.ResidentialMarketplaceBuy]: <KeyIcon width={24} />,
  [UserActionName.ResidentialMarketplaceRent]: <ArmchairIcon width={24} />,
  [UserActionName.ResidentialMarketplaceNewProjects]: <MyHomesMobileIcon width={24} />,
  [UserActionName.CommercialLandingPage]: <CommercialBuildingIcon width={24} />,
  [UserActionName.ExplorePage]: <DollarSignIcon width={24} />,
  [UserActionName.DeveloperIndex]: <BackhoeIcon width={24} />,
  [UserActionName.MortgageOffices]: <HandsIcon width={24} />,
  [UserActionName.PushSettings]: <BellRingingIcon />,
};


export const desktopUserActionsSelector = createSelector([
  isAgentSelector,
  isConnectedUserSelector,
  showAgentConsoleBulletinFormSelector,
], (isAgent, isUser, showAgentConsoleBulletinForm): UserAction[] => [
  {
    name: UserActionName.ProfileSettings,
    route: Route.Profile,
  },
  {
    name: UserActionName.SavedSearches,
    route: Route.SavedSearchesPage,
  },
  {
    name: UserActionName.MyHomes,
    route: Route.MyHomes,
  },
  ...(isAgent ? [ {
    name: UserActionName.MyListings,
    route: null,
    customRoute: '/personal/main',
  } ] : []),
  (isUser ? {
    name: showAgentConsoleBulletinForm ? UserActionName.AgentCommercialBulletins : UserActionName.MyListings,
    route: showAgentConsoleBulletinForm ? Route.Listings : Route.ManageBulletins,
  } : null),
  (isUser ? {
    name: UserActionName.UploadBulletin,
    route: showAgentConsoleBulletinForm ? Route.UploadBulletinForm : Route.UploadBulletin,
    params: {
      source: 'profile_menu',
    },
  } : null),
].filter(Boolean));

export const mobileProfileMenuActionsSelector = createSelector([
  isAgentSelector,
  isConnectedUserSelector,
  showAgentConsoleBulletinFormSelector,
  pushNotificationsEnabledSelector,
], (isAgent, isUser, showAgentConsoleBulletinForm, pushEnabled): UserAction[] => [
  {
    name: UserActionName.ProfileSettings,
    route: Route.Profile,
  },
  (isUser ? {
    name: UserActionName.UploadBulletin,
    route: showAgentConsoleBulletinForm ? Route.UploadBulletinForm : Route.UploadBulletin,
    params: {
      source: 'profile_menu',
    },
  } : null),
  (!isAgent && isUser ? {
    name: UserActionName.SavedListings,
    route: Route.Shortlist,
  } : null),
  (!isAgent && isUser ? {
    name: UserActionName.MyListings,
    route: Route.ManageBulletins,
  } : null),
  (pushNotificationsFeatureEnabled() ? ({
    name: UserActionName.PushSettings,
    route: Route.PushSettings,
    hasWarning: !pushEnabled,
  }) : null),
  {
    name: UserActionName.SavedSearches,
    route: Route.SavedSearchesPage,
  },
  {
    name: UserActionName.MyHomes,
    route: Route.MyHomes,
  },
  ...(isAgent ? [ {
    name: UserActionName.MyListings,
    route: null,
    customRoute: '/personal/main',
  } ] : []),
  ...(showAgentConsoleBulletinForm ? [ {
    name: UserActionName.AgentCommercialBulletins,
    route: Route.Listings,
  } ] : []),
  {
    name: UserActionName.MyCommute,
    route: Route.Profile,
    params: { initialTab: ProfileTab.Personal },
  },
].filter(Boolean));

export const mobileMoreMenuActions: UserAction[] = [
  {
    name: UserActionName.Home,
    route: Route.Home,
  },
  {
    name: UserActionName.ResidentialMarketplaceBuy,
    route: Route.Search,
    params: { id: undefined, dealType: DealType.Buy, term:  [ config.cityTerm ], marketplace: MarketplaceType.Residential, filters: {} },
  },
  {
    name: UserActionName.ResidentialMarketplaceNewProjects,
    route: Route.Search,
    params: { id: undefined, dealType: DealType.Buy, term:  [ config.cityTerm ], marketplace: MarketplaceType.Residential, filters: { seller: [ Seller.Developer ] } },
  },
  {
    name: UserActionName.ResidentialMarketplaceRent,
    route: Route.Search,
    params: { id: undefined, dealType: DealType.Rent, term:  [ config.cityTerm ], marketplace: MarketplaceType.Residential, filters: {} },
  },
  {
    name: UserActionName.CommercialLandingPage,
    route: Route.CommercialMarketLanding,
  },
  {
    name: UserActionName.ExplorePage,
    route: Route.CheckAddress,
  },
  {
    name: UserActionName.MortgageOffices,
    route: Route.MortgageOfficesSearchPage,
  },
  {
    name: UserActionName.DeveloperIndex,
    route: Route.DevelopersSearchPage,
  },
];
