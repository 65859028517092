import { call, put, select } from 'redux-saga/effects';
import { Route, State as RouteState } from 'config/routes';
import { authGate, AuthGateResponse, waitForUserResolve } from '../utils';
import { AuthenticationModalType } from 'components/authentication/types';
import { isVisitorUserSelector } from 'store/state/domainData/selectors';
import { navigateTo } from 'store/state/router/actions';
import { getRouteParams, prevRouteSelector } from 'store/state/selectors/router';
import { loadListingsWorker } from 'store/sagas/routing/handlers/manageBulletinsPage';
import { UploadBulletinStep } from 'components/bulletin-form/types';


export function* uploadBulletinWizardHandler(route: RouteState) {
  if (route.params.step === UploadBulletinStep.Preview) {
    yield call(maybeNavigateFromDirectPreviewToFirstStep, route.name);
  }

  yield call(waitForUserResolve);
  const isVisitor: boolean = yield select(isVisitorUserSelector);

  if (route.params.step && isVisitor) {
    const status: AuthGateResponse = yield call(authGate, undefined, AuthenticationModalType.SignIn);

    if (status === AuthGateResponse.Rejected) {
      yield put(navigateTo(Route.Home, {}, { replace: true }));
    }
  }
  else {
    yield call(maybeNavigateToFirstStep, route.name);
    yield call(loadListingsWorker);
  }
}

function* maybeNavigateFromDirectPreviewToFirstStep(bulletinWizardRoute: Route) {
  const previousRoute = yield select(prevRouteSelector);

  if (!previousRoute) {
    yield put(navigateTo(bulletinWizardRoute, { step: undefined }, { replace: true }));
  }
}

function* maybeNavigateToFirstStep(bulletinWizardRoute: Route) {
  const { step } = yield select(getRouteParams);
  const previousRoute = yield select(prevRouteSelector);

  if (step && (!previousRoute || previousRoute && previousRoute.name !== bulletinWizardRoute)) {
    yield put(navigateTo(bulletinWizardRoute, { step: undefined }, { replace: true }));
  }
}
