import { createSelector } from 'reselect';
import { head } from 'lodash';
import {
  createIsLoadingSelector,
  editListingPoiSelector,
  getCommercialListingSelector,
  getResidentialListingSelector,
  makeNullSafeDomainGetter,
} from 'store/state/domainData/selectors/common';
import { IBulletin } from 'utils/entities';

export const commercialListingSelector = makeNullSafeDomainGetter(getCommercialListingSelector, 'getCommercialListing');
export const residentialListingSelector = makeNullSafeDomainGetter(getResidentialListingSelector, 'getResidentialListingById');
export const isCommercialListingLoadingSelector = createIsLoadingSelector(getCommercialListingSelector);
export const isResidentialListingLoadingSelector = createIsLoadingSelector(getResidentialListingSelector);

export const baseEditListingPoiSelector = makeNullSafeDomainGetter(editListingPoiSelector, 'poiByIds');
export const isEditListingPoiLoadingSelector = createIsLoadingSelector(editListingPoiSelector);

export const editListingSelector = createSelector([ baseEditListingPoiSelector ], (pois: IBulletin[]): IBulletin => head(pois));
