import { every, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
import { AgentOfficeBulletinList } from 'screens/AgentOfficePages/common/sections/listing';
import {
  createIsLoadingSelector,
  officeByIdSelector,
  makeNullSafeDomainGetter,
  teamByOfficeIdSelector,
  officeBulletinsByIdSelector,
} from 'store/state/domainData/selectors/common';
import { agentBulletinsDataSelector } from './agentById';
import { Route } from 'config/routes';
import { routeSelector } from 'store/state/selectors/router';
import { IAgentOfficeDeal } from 'utils/entities';

export const safeOfficeBulletinsByIdSelector = makeNullSafeDomainGetter(officeBulletinsByIdSelector, 'getRealEstateOfficeById');
export const teamByOfficeIdDataSelector = makeNullSafeDomainGetter(teamByOfficeIdSelector, 'getPublicRealEstateAgentsByOfficeId');
export const isOfficeLoadingSelector = createIsLoadingSelector(officeByIdSelector);
export const officePageDataSelector = makeNullSafeDomainGetter(officeByIdSelector, 'getRealEstateOfficeById');
export const officeBulletinsDataSelector = createSelector([
  safeOfficeBulletinsByIdSelector,
  officePageDataSelector,
  teamByOfficeIdDataSelector,
], (listings, officeData, agentsInOffice) => {
  if (!listings) return null;

  const agentsInOfficeIds = new Set((agentsInOffice || []).map(item => item && item.agentId));
  const dealsByAgent = listings.deals && listings.deals.length ? (
    listings.deals.reduce((acc, d: IAgentOfficeDeal) => {
      if (!d.location) return acc;

      const agentList = d.agents ? d.agents.map(a => a.officeId === officeData.officeId ? ({
        ...a,
        ...(!agentsInOfficeIds.has(a.agentId) ? { agentName: null } : {}),
      }) : null).filter(Boolean) : [];
      if (!agentList.length) return acc;

      const dealsWithSameAgent = agentList.map(a => ({
        ...d,
        agents: [ a ],
      }));
      return [ ...acc, ...dealsWithSameAgent ];
    }, [])
  ) : [];
  return {
    ...listings,
    deals: dealsByAgent,
  };
});

export const officePageBulletinSelector = createSelector([ officeBulletinsDataSelector, officePageDataSelector ], (office, officePageData) => {
  if (!office) return [];
  const dealsEnriched = office.deals ? office.deals.reduce((acc, d: IAgentOfficeDeal) => {
    if (!d.location) return acc;

    const agentList = d.agents ? d.agents.filter(a => a.officeId === officePageData.officeId) : [];
    if (!agentList.length) return acc;

    const dealsWithSameAgent = agentList.map(a => ({
      ...d,
      agents: [ a ],
      type: 'sold',
      locationPoint: { lng: d.location.coordinates[0], lat: d.location.coordinates[1] },
      dealType: 'sold',
      id: d.saleId,
    }));
    return [ ...acc, ...dealsWithSameAgent ];
  }, []) : [];
  return [ ...(office.bulletins || []), ...(office.commercialBulletins || []), ...dealsEnriched ];
});
export const agentOfficePageBulletinByDealTypeAndMarketplaceSelector = createSelector([ routeSelector, officePageDataSelector, agentBulletinsDataSelector, officeBulletinsDataSelector ], (route, officePageData, agentBulletins, officeBulletins) => {
  if (!officePageData) return null;

  const poi = route.name === Route.AgentPage
    ? (officePageData.allOfficeBulletinsInAgentPage ? officeBulletins : agentBulletins)
    : officeBulletins;
  const result = [ ...poi.bulletins, ...poi.commercialBulletins ].reduce<AgentOfficeBulletinList>((acc, bulletin) => {
    switch (`${bulletin.type}_${bulletin.dealType}`) {
      case 'bulletin_unitBuy':
        acc.sale.push(bulletin);
        break;
      case 'bulletin_unitRent':
        acc.rent.push(bulletin);
        break;
      case 'commercialBulletin_unitBuy':
        acc.commercialSale.push(bulletin);
        break;
      case 'commercialBulletin_unitRent':
        acc.commercialRent.push(bulletin);
        break;
    }
    return acc;
  }, { sale: [], rent: [], commercialSale: [], commercialRent: [] });
  if (every(result, isEmpty)) return null;

  return result;
});
