import { call, fork, getContext, select, take } from 'redux-saga/effects';
import { authModalStatusSelector } from 'store/state/app/selectors';
import { setAuthModal, userLoginSocial, userLoginWithEmailAndPassword, signInSignUpWithEmail } from 'store/state/app/actions';
import { LOGOUT, SET_AUTH_MODAL, USER_LOGIN_SOCIAL, USER_LOGIN_WITH_EMAIL_AND_PASSWORD, SIGN_IN_SIGN_UP_WITH_EMAIL } from 'store/state/app/types';
import { AuthenticationModalType, SocialVendor } from 'components/authentication/types';
import { RootAction } from 'store/state';
import { SET_MUTATION_RESPONSE } from 'store/state/mutationsResponse/types';
import { setMutationResponse } from 'store/state/mutationsResponse/actions';
import { MutationType } from '../apiService/types';
import { AuthModalSource } from 'store/state/app';

const NATIVE_AUTH_METHOD = 'native';

// smells, but required, not for export
let globalAuthModalSource: AuthModalSource;

type AuthMethods = SocialVendor | 'native';
type AuthMutationTypes =
  MutationType.GoogleLogin |
  MutationType.FacebookLogin |
  MutationType.LoginWithEmailAndPassword |
  MutationType.SignInSignUpWithEmail |
  MutationType.TempTokenAuth;

const getUserEmailFromAction = (action: ReturnType<typeof setMutationResponse>) => {
  switch (action.mutationType) {
    case MutationType.GoogleLogin: {
      const response = action.meta.response.data.googleLogin;
      return response.__typename === 'LoginOutcome' ? response.user.email : null;
    }

    case MutationType.FacebookLogin: {
      const response = action.meta.response.data.facebookLogin;
      return response.__typename === 'LoginOutcome' ? response.user.email : null;
    }

    case MutationType.LoginWithEmailAndPassword: {
      const response = action.meta.response.data.passwordLoginV3;
      return response.__typename === 'LoginOutcome' ? response.user.email : null;
    }

    case MutationType.SignInSignUpWithEmail: {
      const response = action.meta.response.data.loginV3;
      return response.__typename === 'LoginOutcome' ? response.user.email : null;
    }

    case MutationType.TempTokenAuth: {
      const response = action.meta.response.data.completeAutoLogin;
      return response.__typename === 'LoginOutcome' ? response.user.email : null;
    }

    default:
      return null;
  }
};

export const mutationTypeToAuthMethodMap: Record<AuthMutationTypes, AuthMethods> = {
  [MutationType.GoogleLogin]: 'google',
  [MutationType.FacebookLogin]: 'facebook',
  [MutationType.LoginWithEmailAndPassword]: NATIVE_AUTH_METHOD,
  [MutationType.SignInSignUpWithEmail]: NATIVE_AUTH_METHOD,
  [MutationType.TempTokenAuth]: NATIVE_AUTH_METHOD,
};

function* authModalChangeWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const previousModalType = yield select(authModalStatusSelector);
    const { payload, meta: { source, isUserInitiated } } : ReturnType<typeof setAuthModal> = yield take(SET_AUTH_MODAL);
    const currentModalType = payload.type;

    if (source) {
      globalAuthModalSource = source;
    }

    if (globalAuthModalSource === AuthModalSource.AgentsLanding) {
      continue;
    }

    if (currentModalType === AuthenticationModalType.SignUp) {
      if (previousModalType === AuthenticationModalType.SignIn) {
        yield call(sendEvent, 'user_sign_up_form_change', 'user');
      }

      if (!previousModalType) {
        yield call(sendEvent, 'user_sign_up_form_open', 'user', { event: { source } });
      }
    }

    if (currentModalType === AuthenticationModalType.SignIn && previousModalType === AuthenticationModalType.SignUp) {
      yield call(sendEvent, 'user_sign_in_form_change', 'user');
    }

    // if modal is closed by user action
    if (!currentModalType && isUserInitiated) {
      if (previousModalType === AuthenticationModalType.SignIn) {
        yield call(sendEvent, 'user_sign_in_form_close', 'user');
      }

      if (previousModalType === AuthenticationModalType.SignUp) {
        yield call(sendEvent, 'user_sign_up_form_close', 'user');
      }
    }
  }
}

function* signInNativeSubmissionWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const action: ReturnType<typeof userLoginWithEmailAndPassword> = yield take(USER_LOGIN_WITH_EMAIL_AND_PASSWORD);

    yield call(sendEvent, 'user_sign_in_submission', 'user', {
      event: {
        sign_in_method: NATIVE_AUTH_METHOD,
        email_address: action.payload.email,
      },
    });
  }

}


function* signInFromEmailSubmissionWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    yield take((action: RootAction) => action.type === SET_MUTATION_RESPONSE && action.mutationType === MutationType.TempTokenAuth);

    yield call(sendEvent, 'user_sign_in_submission', 'user', {
      event: { sign_in_method: mutationTypeToAuthMethodMap[MutationType.TempTokenAuth] },
    });
  }
}

function* signUpNativeSubmissionWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const action: ReturnType<typeof signInSignUpWithEmail> = yield take(SIGN_IN_SIGN_UP_WITH_EMAIL);

    if (globalAuthModalSource === AuthModalSource.AgentsLanding) {
      yield call(sendEvent, 'agent_identification_verify_email_click', 'agent', {
        agent: {},
      });
    }
    else {
      yield call(sendEvent, 'user_sign_up_submission', 'user', {
        event: {
          sign_up_method: NATIVE_AUTH_METHOD,
          email_address: action.payload.email,
          source: globalAuthModalSource,
        },
      });
    }
  }
}

function* socialAuthSubmissionWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const action: ReturnType<typeof userLoginSocial> = yield take(USER_LOGIN_SOCIAL);

    const authModalStatus = yield select(authModalStatusSelector);
    const isSignIn = authModalStatus === AuthenticationModalType.SignIn;
    const eventAction = isSignIn ? 'user_sign_in_submission' : 'user_sign_up_submission';
    const methodName = isSignIn ? 'sign_in_method' : 'sign_up_method';

    yield call(sendEvent, eventAction, 'user', {
      event: {
        [methodName]: action.payload,
        source: !isSignIn ? globalAuthModalSource : undefined,
      },
    });
  }
}


function* signInSuccessWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const action: ReturnType<typeof setMutationResponse> = yield take(signInSuccessMutationPattern);
    yield call(sendEvent, 'user_sign_in_success', 'user', {
      event: {
        sign_in_method: mutationTypeToAuthMethodMap[action.mutationType],
        email_address: getUserEmailFromAction(action),
      },
    });
  }
}

function* signUpSuccessWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    const action: ReturnType<typeof setMutationResponse> = yield take(signUpSuccessMutationPattern);

    yield call(sendEvent, 'user_sign_up_success', 'user', {
      event: {
        sign_up_method: mutationTypeToAuthMethodMap[action.mutationType],
        email_address: getUserEmailFromAction(action),
        source: globalAuthModalSource,
      },
    });
  }
}

function* logoutWorker() {
  const { sendEvent } = yield getContext('analytics');

  while (true) {
    yield take(LOGOUT);

    yield call(sendEvent, 'user_log_out', 'user');
  }
}

export const signInSuccessMutationPattern = (action: RootAction) => {
  if (action.type !== SET_MUTATION_RESPONSE ||
    (action.meta.response.errors && action.meta.response.errors.length)
  ) return false;

  if (action.mutationType === MutationType.LoginWithEmailAndPassword) return true; // verify is it sign in or sign up
  if (action.mutationType === MutationType.GoogleLogin) {
    const response = action.meta.response.data.googleLogin;
    if (response.__typename === 'LoginOutcome' && !response.signedUp) return true;
  }
  if (action.mutationType === MutationType.FacebookLogin) {
    const response = action.meta.response.data.facebookLogin;
    if (response.__typename === 'LoginOutcome' && !response.signedUp) return true;
  }

  return false;
};

export const signUpSuccessMutationPattern = (action: RootAction) => {
  if (action.type !== SET_MUTATION_RESPONSE ||
    (action.meta.response.errors && action.meta.response.errors.length)
  ) return false;

  if (action.mutationType === MutationType.SignInSignUpWithEmail) return true; // verify is it sign in or sign up
  if (action.mutationType === MutationType.TempTokenAuth) return true; // verify is it sign in or sign up
  if (action.mutationType === MutationType.GoogleLogin ) {
    const response = action.meta.response.data.googleLogin;
    if (response.__typename === 'LoginOutcome' && response.signedUp) return true;
  }
  if (action.mutationType === MutationType.FacebookLogin) {
    const response = action.meta.response.data.facebookLogin;
    if (response.__typename === 'LoginOutcome' && response.signedUp) return true;
  }

  return false;
};


export function* userAuthEventsWorker() {
  yield fork(authModalChangeWorker);

  yield fork(signInNativeSubmissionWorker);
  yield fork(signInFromEmailSubmissionWorker);
  yield fork(signUpNativeSubmissionWorker);

  yield fork(socialAuthSubmissionWorker);

  yield fork(signInSuccessWorker);
  yield fork(signUpSuccessWorker);

  yield fork(logoutWorker);
}
