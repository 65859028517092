import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { State, RootAction } from 'store/state';
import { setDealAffiliationPopup } from 'store/state/app/actions';
import { DealAffiliationPopup as Popup } from 'components/deal-affiliation-popup/DealAffiliationPopup';
import { isAgentManagerSelector } from 'store/state/domainData/selectors';
import {
  IDealAffiliationPopupState,
  IDealAffiliationFormValues,
  AffiliationPopupMode,
  IDealAffiliationUserRequestsToStatus,
} from 'components/deal-affiliation-popup/types';
import {
  popupStateSelector,
  dealAffiliationPopupInitialValuesSelector,
  dealAffiliationPopupAgentsSelector,
  isEditLoadingSelector,
  hasEditResponseErrorSelector,
  isCreateLoadingSelector,
  hasCreateResponseErrorSelector,
  responseTypeSelector,
} from './selectors';
import { Dispatch } from 'redux';
import {
  IAgentInOffice,
  MutationType,
  IEditDealAffiliationRequestVariables,
  IRequestDealAffiliationVariables,
} from 'store/sagas/apiService/types';
import { inlineMutate } from 'store/state/mutationsResponse/actions';
import SuccessPopup from 'components/deal-affiliation-popup/SuccessPopup';
import { AffiliationRequestResponseDealType, RequestDealAffiliationResponseType } from 'utils/entities';
import { routeSelector } from 'store/state/selectors/router';
import { DecodedRouteParams, NavigateToFn } from 'config/routes';
import { State as RouteState } from 'config/routes';
import { navigateTo } from 'store/state/router/actions';
import { NavigationOptions } from 'router5';
import {
  dealAffiliationUserRequestsToStatusMapSelector,
} from 'store/state/domainData/selectors/getDealsAffiliationRequests';


interface DealAffiliationPopupProps {
  popupState: IDealAffiliationPopupState;
  agentsInOffice: IAgentInOffice[];
  isAgentManager: boolean;
  formInitialValues: IDealAffiliationFormValues;
  onClose: () => void;
  editAffiliationRequest: (data: IEditDealAffiliationRequestVariables) => void;
  isEditLoading: boolean;
  hasEditError: boolean;
  requestDealAffiliation: (data: IRequestDealAffiliationVariables) => void;
  isCreateLoading: boolean;
  hasCreateError: boolean;
  dealSideToStatusMap: IDealAffiliationUserRequestsToStatus;
  responseType: RequestDealAffiliationResponseType | AffiliationRequestResponseDealType;
  route: RouteState;
  navigate: NavigateToFn;
}

const DealAffiliationPopup: React.FC<DealAffiliationPopupProps> = (props) => {
  const { editAffiliationRequest, isEditLoading, hasEditError, requestDealAffiliation,
    isCreateLoading, hasCreateError, ...rest
  } = props;
  const { popupState } = rest;
  const [ successPopup, setSuccessPopup ] = useState<boolean>(false);
  const [ isEdit, setIsEdit ] = useState<boolean>();

  const isEditMode = popupState.mode === AffiliationPopupMode.Edit;
  const closeSuccessPopup = () => setSuccessPopup(null);
  const openSuccessPopup = () => setSuccessPopup(true);

  useEffect(() => {
    if (popupState.isOpen) {
      setIsEdit(isEditMode);
    }
  }, [ popupState.isOpen, isEditMode, setIsEdit ]);

  if (successPopup) {
    return <SuccessPopup isEdit={isEdit} onClose={closeSuccessPopup} />;
  }

  if (!popupState.isOpen) return null;

  const onSubmit = isEditMode ? editAffiliationRequest : requestDealAffiliation;
  const isLoading = isEditMode ? isEditLoading : isCreateLoading;
  const submitError = isEditMode ? hasEditError : hasCreateError;


  return (
    <Popup
      {...rest}
      submit={onSubmit}
      isLoading={isLoading}
      submitError={submitError}
      openSuccessPopup={openSuccessPopup}
    />
  );
};


const mapStateToProps = (state: State) => ({
  agentsInOffice: dealAffiliationPopupAgentsSelector(state),
  popupState: popupStateSelector(state),
  isAgentManager: isAgentManagerSelector(state),
  formInitialValues: dealAffiliationPopupInitialValuesSelector(state),
  isEditLoading: isEditLoadingSelector(state),
  hasEditError: hasEditResponseErrorSelector(state),
  isCreateLoading: isCreateLoadingSelector(state),
  hasCreateError: hasCreateResponseErrorSelector(state),
  responseType: responseTypeSelector(state),
  route: routeSelector(state),
  dealSideToStatusMap: dealAffiliationUserRequestsToStatusMapSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  onClose: () => dispatch(setDealAffiliationPopup(null)),
  editAffiliationRequest: (data: IEditDealAffiliationRequestVariables) => dispatch(inlineMutate(MutationType.EditDealAffiliationRequest, data )),
  requestDealAffiliation: (data: IRequestDealAffiliationVariables) => dispatch(inlineMutate(MutationType.RequestDealAffiliation, data )),
  navigate: (name: string, params?: DecodedRouteParams, opts?: NavigationOptions) => dispatch(navigateTo(name, params, opts)),
});


export default connect(mapStateToProps, mapDispatchToProps)(DealAffiliationPopup);
