import { createStore, applyMiddleware, Middleware } from 'redux';
import rootReducer, { State } from './state';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { createReduxRouter5Middleware } from './reduxRouter5Middleware';
import logger from 'redux-logger';
import { Router } from 'router5';
import { IAnalytics } from 'analytics';
import ApolloClient from 'apollo-client';
import TrackJSLogger from './trackJSLogger';
import { UserTokenStore } from 'helpers/userToken';
import { isTrackJSEnabled } from 'helpers/jstracking';
import { Fetcher } from 'utils/fetch-middleware';
import { ReactNativeClientTunnel } from 'react-native/reactNativeClientTunnel';
import { isServer } from 'utils';


interface StoreParams {
  router: Router;
  analytics: IAnalytics;
  client: ApolloClient<unknown>;
  initialState?: State;
  saga: Saga;
  fetcher: Fetcher;
  sagaArgs?: any[];
  tokenStore: UserTokenStore;
  userAgent: string;
  enableLogger: boolean;
  enableReduxDevTools?: boolean;
  abTestsContext: unknown;
  isSEOMode?: boolean;
  reactNativeClientTunnel?: ReactNativeClientTunnel;
}

export default function initStore(params: StoreParams, onSuccess?: () => void, onErr?: (err: Error) => void) {
  const { reactNativeClientTunnel, saga, router, analytics, client, fetcher, initialState = {}, tokenStore, abTestsContext, userAgent, enableLogger, enableReduxDevTools, sagaArgs = [], isSEOMode = false } = params;

  const sagaMiddleware = createSagaMiddleware();
  const reduxRouter5Middleware = createReduxRouter5Middleware(router);

  const middlewares: Middleware[] = [
    reduxRouter5Middleware,
    sagaMiddleware,
  ];

  if (isTrackJSEnabled()) {
    middlewares.push(TrackJSLogger);
  }

  if (enableLogger) middlewares.push(logger);

  // convert ES6 Proxy into POJO
  const abTests = JSON.parse(JSON.stringify(abTestsContext));

  const reduxDevToolsCompose = enableReduxDevTools && !isServer() ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null;

  const store = createStore(
    rootReducer,
    { ...initialState, abTests },
    reduxDevToolsCompose
      ? reduxDevToolsCompose(applyMiddleware(...middlewares))
      : applyMiddleware(...middlewares)
  );

  sagaMiddleware.setContext({ analytics, reactNativeClientTunnel, client, tokenStore, userAgent, router, isSEOMode, fetcher });

  const task = sagaMiddleware.run(saga, ...sagaArgs);
  if (onSuccess && onErr) {
    task.toPromise().then(onSuccess).catch(onErr);
  }

  return store;
}
