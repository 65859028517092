import React from 'react';
import { ContentSlot, ContentSlotSlider, HeaderWrapper, MapSlot, RootSlot } from 'ds/components/Layout';
import CommutePopup from 'components/commute-popup/';
import CloseMobileMapButton from 'components/navigation/user-mobile-navigation/CloseMapMobileButton';
import { ScrollRestorer } from 'components/scroll-restorer';
import { AppMode } from 'store/state/selectors/router';
import { ContentWidthMode } from 'utils/entities';
import { MovableMap } from 'components/movable-map';


interface LayoutProps {
  headerContent: React.ReactNode;
  isMobile: boolean;
  mainContent: React.ReactNode;
  mapContent?: React.ReactNode;
  mode: AppMode;
  isSearch: boolean;
  isMobileInsightsMap: boolean;
  isMobileOtherMap: boolean;
  isMobileUnitMap: boolean;
  isMapPresent: boolean;
  isSubheaderPresent: boolean;
  isMobileLayersToggleOpen: boolean;
  isMapExpanded: boolean;
  contentWidthMode: ContentWidthMode;
}

const isCheckMode = (mode: AppMode) => {
  return mode === AppMode.Check;
};

export const Layout: React.FC<LayoutProps> = (props) => {
  const {
    isSearch,
    isMobile,
    mainContent,
    headerContent,
    isMapPresent,
    isMobileInsightsMap,
    isMobileOtherMap,
    isMobileUnitMap,
    mode,
    isSubheaderPresent,
    isMobileLayersToggleOpen,
    isMapExpanded,
    contentWidthMode,
  } = props;

  const isMobileMapViewReallyActive = isMobileInsightsMap || isMobileOtherMap || isMobileUnitMap;
  const isMobileSearch = isSearch && isMobile;

  return (
    <RootSlot>
      <ScrollRestorer restore={!isMobileMapViewReallyActive} />
      <CommutePopup />
      {(isMobileUnitMap || isMobileSearch) && !isMobileLayersToggleOpen ? <CloseMobileMapButton isSearch={isMobileSearch} /> : null}
      <HeaderWrapper
        isCheckMode={isCheckMode(mode)}
      >
        {headerContent}
      </HeaderWrapper>
      <ContentSlotSlider isSlided={isMobileMapViewReallyActive}>
        <ContentSlot fullWidth={!isMapPresent} contentWidthMode={contentWidthMode} isMapExpanded={isMapExpanded} isSubheaderPresent={isSubheaderPresent}>
          {isMobileInsightsMap || isMobileUnitMap ? null : mainContent}
        </ContentSlot>
      </ContentSlotSlider>

      <MapSlot
        isSubheaderPresent={isSubheaderPresent}
        isSlidedIntoView={isMobileMapViewReallyActive}
        isVisible={isMapPresent}
        isFull={isMobileInsightsMap || isMobileUnitMap || isSearch}
      >
        <MovableMap />
      </MapSlot>

    </RootSlot>
  );
};
