import {
  Amenity as FilterAmenity,
  BathOption,
  Condition,
  PropertyType,
  RangeTuple,
  RoomOption,
  Seller,
  QualityClassOption,
} from 'components/filters/types';
import { InsightSummary, IConstructionJobLocation, IConstructionTimeLine, ConstructionStatusType } from 'store/state/selectors/insights/summaryTypes';
import { SortDirection, SortField } from 'components/listing-sort/types';
import { LayerProperties } from 'components/map/Insights/layersMappings';
import { FeatureCollection } from 'geojson';
import { invert } from 'lodash';
import { ISOString } from 'locale/formatDate';
import {
  IAgentInOffice,
  ISaveSearch,
  SavedAddressSettings,
  IUserListingsVariables,
  IAgentStatus,
  Article,
  PricingTierApi3,
} from 'store/sagas/apiService/types';
import { ILClassSpan } from 'store/state/selectors/insights/schools/types';
import { RawDraftContentState } from 'draft-js';
import { RangeValues } from 'components/cards/types';


export enum DealType {
  Rent = 'unitRent',
  Buy = 'unitBuy',
}

export type DeviceType = 'iOS' | 'Android';

export interface CommutePreference {
  location: ILatLngPoint;
  text: string;
  commuteType: CommuteType;
  maxCommute: MaximumCommute;
  rushHour: boolean;
  docId: string;
}

export interface DatedPoi {
  date: ISOString;
  poiId: IPoiIdentifier;
}

export interface UserSavedAddresses {
  document: IBaseAutocompleteDoc;
  settings: SavedAddressSettings;
}

export enum RegisterType {
  Local = 'native',
  Facebook = 'facebook',
  Google = 'google',
  Visitor = 'visitor',
}

export interface User {
  uid?: string;
  commutePreference?: CommutePreference;
  firstName?: string;
  lastName?: string;
  email?: string;
  registrationType?: RegisterType;
  phone?: string;
  avatar?: string;
  lastContacted?: DatedPoi[];
  favorites?: DatedPoi[];
  lastViewed?: DatedPoi[];
  savedAddresses?: UserSavedAddresses[];
  savedSearches?: ISaveSearch[];
  searchHistory?: ISaveSearch[];
  priorities?: Priority[];
  roles?: Role[];
  commercialIsOfficeManager?: boolean;
  commercialAgentsInOffice?: IAgentInOffice[];
  agentStatus?: IAgentStatus;
  commercialOfficeManagerAgentId?: string;
  commercialEnablePrivateUser?: boolean;
  commercialOfficeManagerOfficeName?: string;
  commercialOfficeManagerOfficeId?: string;
}

export type CommuteType = 'walk' | 'bike' | 'commute' | 'car' | 'bus' | 'train';

export interface MaximumCommute {
  distanceMaxBicycleCommute?: number;
  distanceMaxTrainCommute?: number;
  timeMaxCarCommute?: number;
  timeMaxWalkCommute?: number;
  timeMaxCommute?: number;
}

export const commuteTypeDefaultOrder: CommuteType[] = [ 'train', 'bus', 'car', 'walk' ];

export interface AddressBuildingMeta {
  constructionYear: number;
  floorCount: number;
  unitsTotal: number;
}

export enum InsightType {
  Education = 'education',
  Prices = 'prices',
  Transportation = 'transportation',
  Livability = 'livability',
  Planning = 'planning',
  Safety = 'safety',
  Nuisances = 'nuisances',
  BuildingPermit = 'buildingPermit',
  FuturePlans = 'futurePlans',
  DivisionAreaPlan = 'divisionAreaPlan',
}

export const INSIGHT_TYPE_TO_EVENT_INSIGHT_CATEGORY: Partial<Record<InsightType, string>> = {
  [InsightType.Education]: 'education',
  [InsightType.Prices]: 'prices',
  [InsightType.Transportation]: 'transportation',
  [InsightType.Livability]: 'livability',
  [InsightType.Planning]: 'planning',
  [InsightType.Safety]: 'safety',
  [InsightType.Nuisances]: 'nuisances',
};

export enum InsightPlaceType {
  Unit = 'unit',
  Building = 'building',
  Area = 'area',
}

export interface ITradeOff {
  goodTradeoff: boolean;
  futureTradeoff: boolean;
  actionText: string;
  insightPlace: InsightPlaceType;
  tagLine: string;
  value: string;
  impactful: boolean;
  verified: boolean;
  verifiedText: string;
}

export interface InterestPoint {
  name: string;
  chosenMode: CommuteType;
  rushHour: boolean;
  location: [number, number];
}

export interface IDocId2InsightsResponse {
  docId2Insights: {
    addressData: unknown;
    building: unknown;
    insights: IInsight[];
  };
}

export interface UserInsightContextInfo {
  interestPoints: InterestPoint[];
  bulletin?: UserInsightContextBulletinInfo;
  abtests?: unknown;
}

export interface UserInsightContextBulletinInfo {
  unit?: string;
  exposures?: Exposure[];
  floor?: number;
  id?: PoiId;
  dealType?: DealType;
}

export interface UserInsightContextInfo {
  interestPoints: InterestPoint[];
  bulletin?: UserInsightContextBulletinInfo;
}

export interface UserInsightContextBulletinInfo {
  unit?: string;
  id?: PoiId;
  dealType?: DealType;
}

export interface IDocId2InsightsVariables {
  docId: string;
  user?: UserInsightContextInfo;
  withGeoData?: boolean;
  insights?: InsightDescriptor[];
}

export interface IInsight {
  avatar?: string;
  category: InsightType;
  fullStory?: string;
  geoData?: FeatureCollection<any, LayerProperties>;
  id: string;
  insightRecordId: string;
  preview: string;
  raw?: unknown;
  summary: InsightSummary;
  tidbit?: string;
  tradeoff?: ITradeOff;
  areaInsight?: boolean;
  type: InsightDescriptor;
  label?: string;
}

export interface ITagsValues {
  bestSchool: number;
  safety: number;
  parkAccess: number;
  quietStreet: number;
  familyFriendly: number;
  dogPark: number;
  commute: undefined;
  bestSecular: number;
  bestReligious: number;
  lightRail: number;
}

/** [ [ west, south ], [ east, north ] ] */
export type BoundingBox = [[number, number], [number, number]];

export interface ILatLngPoint {
  lat: number;
  lng: number;
}

export interface Size {
  width: number;
  height: number;
}

export interface IThumbnail {
  url: string;
  isFloorPlan?: boolean;
  description?: string;
  rotateDegrees?: number;
}

export interface IStructuredAddress {
  borough?: string;
  city?: string;
  neighbourhood?: string;
  streetName?: string;
  streetNumber?: string;
  unitNumber?: string;
  zipcode?: string;
  docId?: string;
  originalDocId?: string;
  originalAddress?: IAddressDetails;
}

// Temporary, until typescript gets one of these:
// https://github.com/microsoft/TypeScript/pull/33038
// https://github.com/microsoft/TypeScript/pull/33290
export type PoiId = string;

export interface IDocItem {
  text: string;
  docId: string;
}

export interface IBaseAutocompleteDoc {
  id: string;
  type: CompletionType;
  name: string;
  state: string;
  city: string;
  docId: string;
  identityDocId: string;
  reference: IReference;
  location: [number, number];
  payload: unknown;
  hierarchyLevel: IDocItem[];
  district: string;
  relevantDocIds: RelevantDocId[];
}

export interface IAutocompleteStreet extends IBaseAutocompleteDoc {
  type: CompletionType.Street;
  lineString: GeoJSON.Position[][];
  virtualLineString: GeoJSON.Position[][];
  street: string;
  relevantNeighbourhoods: RelevantNeighbourhoods[];
}
export interface IAutocompleteAddress extends IBaseAutocompleteDoc {
  type: CompletionType.Address | CompletionType.CustomZone;
  height: number;
  houseNumber: string;
  neighbourhood: string;
  neighbourhoodDocId: string;
  borough: string;
  street: string;
  geometry: GeoJSON.Position[][][];
  zipcode: string;
  floorCount: number;
  unitsTotal: number;
  constructionYear: number;
  localizeBuildingDescription: BuildingClass;
}

export interface IAutocompleteProject extends IBaseAutocompleteDoc {
  type: CompletionType.Project;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteCommercialProject extends IBaseAutocompleteDoc {
  type: CompletionType.CommercialProject;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteNode extends IBaseAutocompleteDoc {
  type: CompletionType.Node;
  borough: string;
  houseNumber: string;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteNeighbourhood extends IBaseAutocompleteDoc {
  type: CompletionType.Neighbourhood;
  borough: string;
  neighbourhood: string;
  description: string;
  geometry: GeoJSON.Position[][][];
  nearbyNeighbourhoods?: IDocItem[];
}

export interface IAutocompleteBorough extends IBaseAutocompleteDoc {
  type: CompletionType.Borough;
  borough: string;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteCity extends IBaseAutocompleteDoc {
  type: CompletionType.City;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteCountry extends IBaseAutocompleteDoc {
  type: CompletionType.Country;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteArea extends IBaseAutocompleteDoc {
  type: CompletionType.CustomZone;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteCounty extends IBaseAutocompleteDoc {
  type: CompletionType.County;
  county: string;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteZipcode extends IBaseAutocompleteDoc {
  type: CompletionType.Zipcode;
  neighbourhood: string;
  zipcode: string;
  geometry: GeoJSON.Position[][][];
}

export interface IAutocompleteAgent extends IBaseAutocompleteDoc {
  url: string;
  type: CompletionType.Agent;
}

export interface IAutocompleteOffice extends IBaseAutocompleteDoc {
  url: string;
  type: CompletionType.Office;
}

export interface IAutocompleteDeveloper extends IBaseAutocompleteDoc {
  url: string;
  type: CompletionType.Developer;
}

export interface IAutocompleteSchool extends IBaseAutocompleteDoc {
  url: string;
  type: CompletionType.SchoolDoc;
  schoolName: string;
  zoneGeometry: GeoJSON.Position[][][];
  schoolType: string;
  schoolBin: number;
  schoolAddress: string;
  classRange: string;
  virtualSchoolGeo: boolean;
}

export interface IAutocompleteEmploymentArea extends IBaseAutocompleteDoc {
  url: string;
  type: CompletionType.EmploymentArea;
  description: string;
}

export interface IAutocompleteMortgageOffice extends IBaseAutocompleteDoc {
  id: string;
  type: CompletionType.Api3MortgageOffice;
}

export interface IAutocompleteApi3City extends IBaseAutocompleteDoc {
  type: CompletionType.Api3City;
}

export type IAutocompleteEntry =
  | IAutocompleteCity
  | IAutocompleteCounty
  | IAutocompleteCountry
  | IAutocompleteAddress
  | IAutocompleteNode
  | IAutocompleteNeighbourhood
  | IAutocompleteBorough
  | IAutocompleteZipcode
  | IAutocompleteStreet
  | IAutocompleteProject
  | IAutocompleteCommercialProject
  | IAutocompleteAgent
  | IAutocompleteOffice
  | IAutocompleteDeveloper
  | IAutocompleteSchool
  | IAutocompleteArea
  | IAutocompleteEmploymentArea
  | IAutocompleteApi3City
  | IAutocompleteMortgageOffice;

export enum CompletionType {
  City = 'city',
  County = 'county',
  Country = 'country',
  Address = 'address',
  Neighbourhood = 'neighbourhood',
  Zipcode = 'zipcode',
  Node = 'node',
  Borough = 'borough',
  Street = 'street',
  Project = 'project',
  CommercialProject = 'commercialProject',
  Office = 'office',
  Agent = 'agent',
  Developer = 'developer',
  SchoolDoc = 'schoolDoc',
  CustomZone = 'customZone',
  EmploymentArea = 'employmentArea',
  Api3City = 'CITY',
  Api3MortgageOffice = 'MORTGAGE_ADVISORS_OFFICE',
}

export interface DocId2InfoDocument {
  document: IAutocompleteEntry;
  ppa?: number;
  yearNumberOfDeals?: number;
  shortName?: string;
}

export interface IDocId2InformationVariables {
  docId: string;
}
export interface IDocId2InformationResponse {
  docId2Information: DocId2InfoDocument;
}

export interface IDocIds2InformationVariables {
  docIds: string[];
}
export interface IDocIds2InformationResponse {
  docIds2Information: DocId2InfoDocument[];
}

export interface IDocInfoUnitDocument {
  reference: IReference;
  docId: string;
}

export interface IDocId2InfoUnitResponse {
  docId2Information: {
    document: IDocInfoUnitDocument,
  };
}

export interface ILocalSchool {
  rating: number;
  name: string;
  id: string;
  educationLevel: string[];
  educationSystem: string;
  lastExamYear: string;
  claims: {
    claim: string;
    score: number;
  };
}
export interface ILocalDemographic {
  data: [
    {
      name: string;
      value: number;
    }
  ];
  name: string;
}

export interface ILocalKindergarten {
  name: string;
  educationSystem: string;
  organization: string;
  address: string;
}

export type UserRatings =
  'cleanRank' |
  'communityRank' |
  'kindergartenRank' |
  'parkingRank' |
  'parksRank' |
  'pastimeRank' |
  'publicTransportRank' |
  'safetyRank' |
  'schoolsRank' |
  'shoppingRank' |
  'transportRank' |
  'walkabilityRank' |
  'suitableForBachelor' |
  'suitableForFamilies' |
  'suitableForNonReligious' |
  'suitableForOrthodox' |
  'suitableForPensioner' |
  'suitableForReligious' |
  'rankingsCount';

export type PreviewUserRatings =
  'communityRank' |
  'cleanRank' |
  'schoolsRank' |
  'publicTransportRank' |
  'shoppingRank' |
  'pastimeRank';

export type Demographics =
  'demographicIndex' |
  'ages' |
  'aliya' |
  'ethnic' |
  'occupation' |
  'ownership' |
  'parties2021' |
  'parties2022' |
  'rooms' |
  'studies';

export interface ILocalPageVariables {
  docId: string;
}

export interface ILocalPricesByRooms {
  rooms: number;
  newPrice: number;
  oldPrice: number;
  rent: number;
  rentRoi: number;
}

export interface ILocalOffice {
  id: string;
  logo: string;
  name: string;
  sold: number;
  currentExclusive: number;
}

export interface ILocalAgent extends ILocalOffice {
  officeName: string;
}

export interface Opinion {
  id: string;
  date?: string;
  sex?: string;
  agentDetails?: AgentDetails;
  age?: string;
  areaId?: string;
  zoneName?: string;
  advantage?: string;
  disadvantage?: string;
  community?: string;
  population?: string;
  buildings?: string;
  seniority?: string;
  parks?: string;
  remarks?: string;
  education?: string;
  municipalityFunctionality?: string;
  publicTransport?: string;
  traffic?: string;
  occupationOpportunity?: string;
  shopping?: string;
  pastime?: string;
  planing?: string;
  relationToLocation?: string;
}

export interface AgentDetails {
  url: string;
  logo: string;
  icon: string;
  name: string;
  phone: string;
}

export interface ILocalPagePreviewResponse {
  searchLocal2: {
    userRatings: { [rating in PreviewUserRatings]?: number };
    latestOpinion?: Opinion;
  };
}
export interface IZoneSummaryProps {
  bulletinsForSaleCount: number;
  bulletinsForRentCount: number;
  demographicIndex: number;
  schoolsRating: number;
  popularParty: string;
}

export interface ILocalPageResponse {
  searchLocal2: {
    city: string;
    docId: string;
    neighbourhood: string;
    district: string;
    zoneName: string;
    description?: string;
    isSmallZone: boolean;
    leadingAgent?: ILocalAgent;
    leadingOffice?: ILocalOffice;
    opinions?: Opinion[];
    latestOpinion?: Opinion;
    PPM: number;
    kindergartenInArea: ILocalKindergarten[];
    userRatings: { [rating in UserRatings]?: number | boolean };
    demographic: { [rating in Demographics]: ILocalDemographic | number };
    schoolsInArea: {
      schoolList: ILocalSchool[];
    };
    zoneSummary: IZoneSummaryProps;
    educationRating: {
      schoolsInNeighbourhood?: number;
      schoolsInCity?: number;
      schoolsInCountry?: number;
    };
    pricesTable: ILocalPricesByRooms[];
    madadWinners?: MadadWinnersGroup;
  };
}

export interface ISearchLocalAddressPageResponse {
  searchLocal2: {
    docId: string;
    madadWinners?: MadadWinnersGroup;
  };
}

export enum PocType {
  Agent = 'agent',
  Private = 'private',
}

export interface IContactInfo {
  email?: string;
  imageUrl?: string;
  name: string;
  madadCategory?: MadadCategory;
  phone?: string;
  title?: string;
}

export interface IExclusivity {
  endDate: string;
  exclusive: boolean;
  exclusivityMode: ExclusivityMode;
  startDate: string;
  exclusiveStatus: string;
  exclusiveUrl: string;
  rejectionReason: string;
}

export interface IBulletinPrivate {
  contactInfo: IContactInfo;
  type: PocType.Private;
  userId: string;
  displayNumber?: string;
  __typename?: string;
}

export interface IBulletinAgent {
  agentContact?: IContactInfo;
  agentId: string;
  /** @deprecated */
  company: string;
  editorsUserId?: [string];
  /** @deprecated */
  email?: string;
  /** @deprecated */
  exclusive?: boolean;
  exclusivity?: IExclusivity;
  /** @deprecated */
  name: string;
  officeContact: IContactInfo;
  officeId: string;
  displayNumber?: string;
  madadSearchResult?: MadadSearchResultCategory;
  title?: string;
  type: PocType.Agent;
  userId: string;
  showOnlyOffice?: boolean;
  __typename?: string;
}

export enum LeadingAgentFeature {
  SearchResultsCarrousel = 'SearchResultsCarrousel',
  InfoPagesCarrousel = 'InfoPagesCarrousel',
  ContactForm = 'ContactForm',
  LeadingStatement = 'LeadingStatement',
  LeadingAgentLabelOnOfficePage = 'LeadingAgentLabelOnOfficePage',
  AboutTheAgentInListingPage = 'AboutTheAgentInListingPage',
}

export type POC = IBulletinAgent | IBulletinPrivate;
export enum BuildingDetails {
  BuildingClass = 'buildingClass',
  BuildingYear = 'buildingYear',
  CommonCharges = 'commonCharges',
  Floor = 'floorCount',
  PastSales = 'pastSales',
  PastRentals = 'pastRentals',
  Units = 'unitsTotal',
}

export enum Amenity {
  UnitLaundry = 'unitLaundry',
  UnitPetsAllowed = 'unitPetsAllowed',
  Parking = 'parking',
  UnitPetPolicy = 'unitPetPolicy',
  Furnished = 'furnished',
  Heating = 'heating',
  Fireplace = 'fireplace',
  Roofdeck = 'roofDeck',
  Garden = 'garden',
  Terrace = 'terrace',
  Patio = 'patio',
  Balcony = 'balcony',
  PiedATerreAllowed = 'piedaterreallowed',
  Dishwasher = 'dishwasher',
  Garage = 'garage',
  BikeStorage = 'bikeStorage',
  BuildingLaundry = 'buildingLaundry',
  Pool = 'pool',
  Gym = 'gym',
  BuildingBalcony = 'buildingBalcony',
  BuildingPatio = 'buildingPatio',
  BuildingTerrace = 'buildingTerrace',
  BuildingGarden = 'buildingGarden',
  Courtyard = 'courtyard',
  BuildingRoofdeck = 'buildingRoofdeck',
  BuildingPenthouse = 'buildingPenthouse',
  Basement = 'basement',
  Attic = 'attic',
  Grating = 'grating',
  PandoorDoors = 'pandoorDoors',
  // common start
  Doorman = 'doorman',
  Elevator = 'elevator',
  AirConditioner = 'airConditioner',
  Storage = 'storage',
  SecureRoom = 'secureRoom',
  Accessible = 'accessible',
  // common end
  OuterSpace = 'outerSpace',
  ParkingEmployee = 'parkingEmployee',
  ParkingBikes = 'parkingBikes',
  ParkingVisitors = 'parkingVisitors',
  Kitchenette = 'kitchenette',
  FullTimeAccess = 'fullTimeAccess',
  Alarm = 'alarm',
  ConferenceRoom = 'conferenceRoom',
  SubDivisible = 'subDivisible',
  Reception = 'reception',
  JulietBalcony = 'julietBalcony',
  CeilingFan = 'ceilingFan',
  OutdoorSpace = 'outdoorSpace',
  HeatingSystem = 'heatingSystem',
  Fiber = 'privateFiber',
  ColdRoom = 'coldRoom',
  LoadingRamp = 'loadingRamp',
  HighCeiling = 'highCeiling',
  ActiveBusiness = 'activeBusiness',
}

export enum BuildingClass {
  SerialCottage = 'serialcottage',
  AgricultureLand = 'agricultureland',
  RoofFlat = 'roofflat',
  Parking = 'parking',
  VacationProperty = 'vacationproperty',
  DualCottage = 'dualcottage',
  Duplex = 'duplex',
  ResidentialUnit = 'residentialunit',
  MiniPenthouse = 'minipenthouse',
  Hotel = 'hotel',
  PenthouseApp = 'penthouseapp',
  Cottage = 'cottage',
  BuyerTeam = 'buyerteam',
  SplitFlat = 'splitflat',
  Shop = 'shop',
  Studio = 'studio',
  Other = 'other',
  Office = 'office',
  Storeroom = 'storeroom',
  Villa = 'villa',
  AgricultureFarm = 'agriculturefarm',
  ProfessionalFarm = 'professionalfarm',
  Hall = 'hall',
  Basement = 'basement',
  GardenApartment = 'gardenapartment',
  Triplex = 'triplex',
  Clinic = 'clinic',
  Land = 'land',
  Flat = 'flat',
  Building = 'building',
  Penthouse = 'penthouse',
  Rooftop = 'attic',
  Project = 'project',
  Restaurant = 'restaurant',
  SharedWorkSpace = 'sharedworkspace',
  ParkingLot = 'parkinglot',
  IndustrialBuilding = 'industrialbuilding',
  IncomeProducingProperty = 'incomeproducingproperty',
  FarmLand = 'farmland',
}

export const buildingClassInvert = invert(BuildingClass);

export const getBuildingClassKey = (value: BuildingClass) => buildingClassInvert[value];

export enum PriceEventType {
  new = 'new',
  delete = 'delete',
  priceUp = 'priceUp',
  priceDrop = 'priceDrop',
}

export interface PriceEvent {
  date: ISOString;
  eventType: PriceEventType;
  price: number;
}

export enum Exposure {
  North = 'N',
  South = 'S',
  West = 'W',
  East = 'E',
  NorhtEast = 'NE',
  NorthWest = 'NW',
  SouthEast = 'SE',
  SouthWest = 'SW',
}

export enum FeeType {
  CollectYourOwnFee = 'Collect Your Own Fee',
  NoFee = 'No fee',
  CoBroke = 'Co-broke',
}

export enum PetPolicy {
  Allowed = 'Pets allowed',
  NotAllowed = 'No pets',
  NoDogsAllowed = 'No dogs',
  NoBigDogsAllowed = 'Dogs under 20 pounds',
  NoCatsAllowed = 'No cats',
}

export enum ResolutionPreference {
  Accurate = 'accurate',
  Neighborhood = 'neighborhood',
  Street = 'street',
  City = 'city',
}

export interface RelevantDocId {
  docId: string;
  text: string;
  type: CompletionType;
}

export interface RelevantNeighbourhoods {
  docId: string;
  text: string;
  type: CompletionType;
}

export interface IAddressDetails {
  unitNumber: string;
  borough: string;
  city: string;
  docId: string;
  neighbourhood: string;
  neighbourhoodDocId: string;
  cityDocId: string;
  resolutionPreferences: ResolutionPreference;
  streetName: string;
  streetNumber: string;
  zipcode: string;
  district: string;
  relevantDocIds: RelevantDocId[];
  block: number;
  parcel: number;
}
export interface IApartmentType {
  apartmentSpecification: string;
  beds: number;
  price: number;
  size: number;
  type: string;
}
export interface IArchitect {
  identifier?: string;
  name: string;
}
export interface IConstructor {
  name: string;
  id?: string;
}
export interface IProjectMinMax {
  max: number;
  min: number;
}
export interface IBlockDetails {
  buildingsNum: number;
  floorRange: IProjectMinMax;
  units: number;
  mishtakenPrice: boolean;
  urbanRenewal: string;
}
export enum BuildingStage {
  Initial = 'initialPlanning',
  Permit = 'withPermit',
  PermitRequest = 'permitRequest',
  Building = 'building',
  Finished = 'doneBuilding',
}

export enum ProjectIntegration {
  Level0 = 'level0',
  Level100 = 'level100',
  Level200 = 'level200',
  Level300 = 'level300',
}

export enum UrbanRenewal {
  None = 'none',
  Destruction = 'destruction',
  DestructionAfterBuilding = 'destructionAfterBuilding',
  MilitaryBaseEvacuation = 'militaryBaseEvacuation',
  Rehabilitation = 'rehabilitation',
  Renovation = 'renovation',
  Tama38a = 'tama38a',
  Tama38b = 'tama38b',
  Thickening = 'thickening',
}

export interface IDeveloper {
  id: string;
  integrationLevel?: ProjectIntegration;
  logoPath: string;
  name: string;
  developerLink: string;
  establishmentYear: number;
  inSaleProjects: number;
  finishedProjects: number;
}
export interface IProjectImage {
  path: string;
  description: string;
}

export interface IPoiInsights {
  addressData: unknown;
  insights: IInsight[];
}
export interface IProjectMessages {
  benefits: string[];
  developerDescription: string;
  lastMessages: string[];
  mifrat: string;
  listingDescription?: string;
}
export enum PromotionValues {
  Promoted = 'promoted',
  NotPromoted = 'notPromoted',
}
export interface IPromotionStatus {
  status: PromotionValues;
}
export interface IStageDescription {
  message: string;
}
export interface IProjectStage {
  buildingStage: BuildingStage;
  payload: IStageDescription;
}

export type DataLayerPoiType = 'school'
  | 'park'
  | 'trainStation'
  | 'busStation'
  | 'newConstruction'
  | 'dogPark'
  | 'deal'
  | 'area'
  | 'listingCluster';

export interface Award {
  id: string;
  product?: AwardCategories;
  features: Array<{ name: LeadingAgentFeature, isEnabled: boolean; }>;
  year?: number;
  docIds?: string[];
  leadingStatement?: string;
  createdAt?: string;
  updatedAt?: string;
  agent?: IAgentPageData;
  neighborhoods: Array<{
    ref: {
      docId: string;
      name: string;
    };
  }>;
}

export interface LeadingAgentCarouselCardData {
  officeName: string;
  agentName: string;
  agentLogo: string;
  agentId: string;
}

export type LeadingAgentData = { isLeadingAgent: false } | {
  id: string;
  award: Award;
  isLeadingAgent: true;
  leadingStatement: string;
  neighborhoods: string[];
};

export enum AwardCategories {
  LeadingAgent = 'LeadingAgent',
}

export enum MarketplaceType {
  Commercial = 'commercial',
  Residential = 'residential',
}

export type ListingPoiType = 'bulletin' | 'project' | 'commercialBulletin' | 'commercialProject';

export type BulletinPoiType = 'bulletin' | 'commercialBulletin';
type ProjectPoiType = 'project' | 'commercialProject';

export type PoiType = ListingPoiType | 'ad' | DataLayerPoiType;

export type SearchContext = 'marketplace' | 'commercialMarketplace' | 'information';
export type AdditionalAdsSearchContext = 'listing' | 'stickySearchResult';

export const LISTING_POI_TYPES: Set<PoiType> = new Set([ 'bulletin', 'project', 'commercialBulletin', 'commercialProject' ]);
export const DATA_LAYERS_POI_TYPES: Set<DataLayerPoiType> = new Set([ 'school', 'park', 'trainStation', 'busStation', 'newConstruction', 'dogPark', 'deal', 'area' ]);
export const DATA_LAYERS_COMMERCIAL_POI_TYPES: Set<DataLayerPoiType> = new Set([ 'deal', 'area', 'trainStation', 'busStation' ]);

interface IPoiCommon<MarketType, BulletinType> {
  id: PoiId;
  images: MarketType extends BulletinPoiType ? IImageItem[] : IProjectImage[]; //
  locationPoint: ILatLngPoint;
  dealType: DealType;
  firstTimeSeen?: string;
  addressDetails: IAddressDetails;
  qualityBin: number;
  isInactive?: boolean;
  notificationTags?: [string, string];
  type: BulletinType extends ListingPoiType ? BulletinType : PoiType;
}

interface OfficeType {
  type: string;
  rooms: number;
  price: number;
  size: number;
  floor: string;
  numberOfEmployees: number;
  ppm: number;
  apartmentSpecification: string;
}

interface IdObject {
  id: string;
  name: string;
}

interface IProjectBanners {
  desktopBanners: IProjectImage[];
  mobileBanners: IProjectImage[];
}

interface PriceUpdate {
  date: string;
  price: number;
}

interface OpenHouse {
  from: string;
  to: string;
}

export interface IImageItem {
  imageUrl: string;
  description?: string;
  rotation?: number;
  isFloorplan?: boolean;
}

interface IStatus {
  isNew: boolean;
}

export interface IProjectDiscount {
  showDiscount: boolean;
  bannerUrl?: string;
  draftjsDescription?: RawDraftContentState;
}

export interface IImage {
  path: string;
}

interface IProjectPoiCommon<BulletinType> extends IPoiCommon<ProjectPoiType, BulletinType> {
  priceRange: IProjectMinMax;
  blockDetails: IBlockDetails;
  buildingStage: BuildingStage;
  projectName: string;
  projectMessages: IProjectMessages;
  developers: IDeveloper[];
  architects: IArchitect[];
  stages: IProjectStage[];
  promotionStatus: IPromotionStatus;
  constructors: IConstructor[];
  saleAgents: IdObject[];
  status: IStatus;
  projectLogo: string;
  phoneNumber: string[];
  needsMatch: number;
  previewImage: IImage;
  banners: IProjectBanners;
  apartmentType: IApartmentType[];
  discount?: IProjectDiscount;
}

export interface IProject extends IProjectPoiCommon<'project'> {
  bedsRange: IProjectMinMax;
  tags: ITagsValues;
  isCommercial: false;
}

export interface ICommercialProject extends IProjectPoiCommon<'commercialProject'> {
  roomsRange: IProjectMinMax;
  officeType: OfficeType;
  parking: number;
  tags: ITagsValues;
  elevators: number;
  isCommercial: true;
}

export interface IDeal {
  addressDetails: IAddressDetails;
  id: PoiId;
  firstTimeSeen?: string;
  isInactive?: boolean;
  tags: ITagsValues;
  commuteTime?: number;
  notificationTags?: [string, string];
  beds: number;
  saleDate: number;
  price: number;
  type: 'deal';
  locationPoint: ILatLngPoint;
  brokerDeal: string;
  totalFloors: number;
  pricePerMeter: number;
  floor: number;
  buildingYear: number;
  grossArea: number;
  agents: IAgentInfo[];
  projectName: string;
  projectIds: string[];
}

export interface IAgentInfo {
  agentId: string;
  agentLogo: string;
  agentName: string;
  agentType: AgentType;
  officeId: string;
  officeLogo: string;
  officeName: string;
}

export enum AgentType {
  Buyer = 'buyer',
  Seller = 'seller',
  Both = 'both',
}

export enum BulletinStatusType {
  Frozen = 'frozen',
  Displayed = 'displayed',
  Deleted = 'deleted',
}

// status on be
export enum ListingStatusType {
  Frozen = 'FROZEN',
  Displayed = 'DISPLAYED',
  Deleted = 'DELETED',
}

export type IUserListingsByStatus = Partial<Record<BulletinStatusType, Array<IBulletin | ICommercialBulletin>>>;

export enum IGeneralCondition {
  New = 'new',
  AsNew = 'asNew',
  Renovated = 'renovated',
  Preserved = 'preserved',
  ToRenovated = 'toRenovated',
  Fine = 'fine',
  FullFinish = 'fullFinish',
  FurnishedFinish = 'furnishedFinish',
  RequiresRenovation = 'requiresRenovation',
  Finish = 'finish',
  Skin = 'skin',
  /** because of difference of api2 and api3 */
  ToRenovate = 'toRenovate',
}

export enum IFurniture {
  Full = 'full',
  Partial = 'partial',
  None = 'none',
}

export interface BestSchool {
  schoolName: string;
  bestReligious?: string;
  gradeSpan: string;
  zoned: boolean;
}

export interface IClosestRailStation {
  lightRail: {
    stationName: string;
  };
}

export interface IVirtualTourItem {
  type: string;
  url: string;
}

export interface IVirtualTours {
  hasVirtualTour: boolean;
  items: IVirtualTourItem[];
}

export enum ExclusivityMode {
  Exclusive = 'exclusive',
  CoExclusive = 'coExclusive',
}

export interface IBulletinPoiCommon<BulletinType> extends IPoiCommon<BulletinPoiType, BulletinType> {
  originalId: string;
  insights: IPoiInsights;
  floor: number;
  agent: POC;
  poc: POC;
  address: string;
  availableDate: number;
  fromDateTime: string;
  price: number;
  priceHistory: PriceUpdate[];
  eventsHistory: PriceEvent[];
  ppm: number;
  area: number;
  leaseType: string;
  leaseTerm: string;
  description: string;
  buildingType: string;
  amenities: Record<Amenity, boolean> & { additionalAreas?: AdditionalListingAreas };
  currency: string;
  structuredAddress: IStructuredAddress;
  newListing: boolean;
  feeType: string;
  generalCondition: IGeneralCondition;
  monthlyTaxes: number;
  openHouses: OpenHouse[];
  source: string;
  url: string;
  buildingClass: BuildingClass;
  buildingYear: number;
  availabilityType: AvailabilityType;
  floors?: number;
  lastActiveMarkDate: string;
  furnitureDetails: string;
  status: {
    message: string;
    status: BulletinStatusType;
    promoted: boolean;
  };
  estimatedPrice?: number;
  balconyArea?: number;
  matchScore: number;
  agentId: string;
  rentalBrokerFee: boolean;
  virtualTours?: IVirtualTours;
  userPhoneNumberEdit?: string;
  lastUpdated: string;
}

export interface IBulletin extends IBulletinPoiCommon<'bulletin'> {
  baths: number;
  beds: number;
  recommendation: number;
  unitHasWasherdryer: boolean;
  unitPetPolicy?: PetPolicy;
  numeralSystem: string;
  exposures: Exposure[];
  airConditioningType: string;
  gasFireplaces: number;
  decorativeFireplaces: number;
  parkView: boolean;
  cityView: boolean;
  woodBurningFireplaces: number;
  homeOffices: number;
  heatType: string;
  commonCharges: number;
  commuteTime?: number;
  dogsParkWalkTime?: number;
  parkWalkTime?: number;
  tags: ITagsValues;
  postponed: boolean;
  propertyType: string;
  needsMatch: number;
  bestSchool?: BestSchool;
  closestLightRailStation: any;
  closestRailStation?: IClosestRailStation;
  parkName?: string;
  netArea?: number;
  grossArea?: number;
}

export interface ICommercialBulletin extends IBulletinPoiCommon<'commercialBulletin'>{
  rooms: number;
  numberOfEmployees: number;
  qualityClass: QualityClassOption;
  sellerType?: PocType;
  monthlyManagementFee?: number;
}

export interface ICommercialListing {
  addressDetails: IAddressDetails;
  structuredAddress: IStructuredAddress;
  agentId: string;
  amenities: Record<Amenity, boolean>;
  area: number;
  availableDate: ISOString;
  availabilityType: string;
  buildingClass: string;
  buildingYear: number;
  sellerType: PocType;
  dealType: DealType;
  description: string;
  floor: string;
  floors: number;
  furnitureDetails: string;
  generalCondition: IGeneralCondition;
  id: string;
  images: IImageItem[];
  locationPoint: ILatLngPoint;
  monthlyManagementFee: number;
  monthlyTaxes: number;
  numberOfEmployees: number;
  poc: POC;
  price: number;
  rooms: number;
  status: {
    message: string;
    status: BulletinStatusType;
    promoted: boolean;
  };
  type: BulletinPoiType;
  qualityClass: QualityClassOption;
}

export interface IAgentOfficeBulletin {
  id: string;
  type: 'bulletin' | 'commercialBulletin';
  price: number;
  beds?: number;
  rooms?: number;
  area: number;
  floor: string;
  floors: number;
  propertyType: PropertyType;
  buildingClass?: string;
  images: IImageItem[];
  dealType: DealType;
  structuredAddress: {
    city: string;
    text: string;
    docId: string;
    streetName: string;
    unitNumber?: string;
    streetNumber?: string;
    relevantDocIds?: [RelevantDocId]
    localizeBuildingDescription?: string;
    originalDocId?: string;
    heighborhood?: string;
    resolutionPreferences?: ResolutionPreference;
  };
  locationPoint: ILatLngPoint;
}

export interface ITag {
  insightType: InsightType;
  type: keyof ITagsValues;
  commuteType?: CommuteType;
  value?: number;
  hours?: number;
  mins?: number;
  school?: BestSchool;
  parkName?: string;
  parkWalkTime?: number;
  lightRail?: string;
}

export interface ITileRange {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface IAd {
  addressDetails?: IAddressDetails;
  city: string;
  district: string;
  firstTimeSeen?: string;
  id: PoiId;
  locationPoint?: ILatLngPoint;
  neighbourhood: string;
  type: 'ad';
}

export interface IDataLayer {
  id: PoiId;
  type: DataLayerPoiType;
  geometry: GeoJSON.Feature;
}

export interface IClusterDataLayer extends IDataLayer {
  type: 'listingCluster';
  count: number;
  geometry: GeoJSON.Feature<GeoJSON.Point, {}>;
}

export interface IAreaDataLayer extends IDataLayer {
  type: 'area';
  areaType: CompletionType;
  name: string;
}
export interface IParkDataLayer extends IDataLayer {
  amenities?: string[];
  isRenovated?: boolean;
  name?: string;
  isAccessible?: boolean;
  type: 'park' | 'dogPark';
}

export interface ISchoolDataLayer extends IDataLayer {
  schoolName: string;
  address: string;
  schoolScore: number;
  schoolType: 'state' | 'religious' | 'orthodox';
  specialPrograms?: boolean;
  gradeSpan?: string;
  borough?: string;
  classesFrom?: ILClassSpan;
  classesTo?: ILClassSpan;
  name?: string;
  quality?: string;
  zoneGeometry?: GeoJSON.Feature<GeoJSON.MultiPolygon, {}>;
  type: 'school';
}

export interface IDealDataLayer extends IDataLayer {
  price: number;
  addressDetails?: IAddressDetails;
  beds: number;
  saleDate: ISOString;
  brokerDeal?: boolean;
  type: 'deal';
  projectName?: number;
  highestPrice?: number;
  latestId: string;
  groupLength?: number;
  address?: string;
  locationPoint?: [number, number];
  size?: number;
  pricePerSquareMeter?: number;
  constructionYear?: number;
  floor?: number;
}

export interface StationRoute {
  routeColor: string;
  routeName: string;
  routeId: string;
  service?: string;
  routeGeometry?: GeoJSON.Feature<GeoJSON.MultiLineString, LayerProperties>;
}

export interface BusStationRoute {
  name: string;
  id: string;
}

export interface ITrainStationDataLayer extends IDataLayer {
  name: string;
  address: string;
  type: 'trainStation';
}

export interface IBusStationDataLayer extends IDataLayer {
  busLines: BusStationRoute[];
  type: 'busStation';
  busStopName?: string;
}

export interface INewConstructionDataLayer extends IDataLayer {
  jobLocation: IConstructionJobLocation;
  timeLine: IConstructionTimeLine;
  type: 'newConstruction';
  status: ConstructionStatusType;
}

export type IDataLayerItem = IParkDataLayer
  | ISchoolDataLayer
  | ITrainStationDataLayer
  | IBusStationDataLayer
  | INewConstructionDataLayer
  | IDealDataLayer
  | IAreaDataLayer
  | IClusterDataLayer;

export type PoiEntry<
  BulletinKeys extends keyof IBulletin = keyof IBulletin,
  ProjectKeys extends keyof IProject = keyof IProject,
  > = { id: PoiId } & (
    Pick<IBulletin, Exclude<BulletinKeys, 'id'>>
    | Pick<IProject, Exclude<ProjectKeys, 'id'>>
  );

export interface IPoiIdentifier {
  id: PoiId;
  type: PoiType;
}

export interface IPoiBulletinIdentifier extends IPoiIdentifier {
  addressDetails: {
    city: string;
    cityDocId: string;
    neighbourhoodDocId: string;
  };
}

export interface IPoiUserData {
  priorities: Priority[];
  commutePreference: CommutePreference;
  canFetchDemo?: boolean;
}

export interface IPoiByIdsVariables {
  campaignProjectId?: string;
  ids: IPoiIdentifier[];
  userData?: IPoiUserData;
}

export interface IPoiByIdsResponse<P extends PoiEntry<any, any>> {
  poiByIds: P[];
}

export interface IUpdateSaveSearchResponse {
  saveSearchQuery: ISaveSearch;
}

export interface IDeleteSaveSearchResponse {
  deleteSearchQuery: ISaveSearch;
}

export type AmenitiesMap = Partial<Record<FilterAmenity, boolean>>;

export interface ISearchParametersWithoutSort {
  noFee?: boolean;
  dealType: DealType;
  priceRange?: RangeTuple<number>;
  ppmRange?: RangeTuple<number>;
  monthlyTaxRange?: RangeTuple<number>;
  roomsRange?: RangeTuple<RoomOption>;
  amenities?: AmenitiesMap;
  commercialAmenities?: AmenitiesMap;
  bathsRange?: RangeTuple<BathOption>;
  numberOfEmployeesRange?: RangeTuple<number>;
  qualityClass?: QualityClassOption[];
  buildingClass?: PropertyType[];
  sellerType?: Seller[];
  discountedProjects?: boolean;
  generalCondition?: Condition[];
  floorRange?: RangeTuple<number>;
  areaRange?: RangeTuple<number>;
  locationDocId?: string;
  priceDrop?: boolean;
  underPriceEstimation?: boolean;
  /** @deprecated */
  isCommercialRealEstate?: boolean;
  poiTypes?: PoiType[];
}

export interface ISearchParameters extends ISearchParametersWithoutSort {
  sort?: Array<{ field: SortField; order: SortDirection; reference?: unknown; docId?: string, docIds?: string[] }>;
}

export interface ISearchPoiVariables extends ISearchParameters {
  userContext?: IPoiUserData;
  tileRanges: ITileRange[];
  tileRangesExcl?: ITileRange[];
  limit?: number;
  offset?: number;
  cursor?: unknown;
  poiTypes?: PoiType[];
  searchContext?: SearchContext;
  listingEdited?: number;
}

// TODO: remove it when BE will fix Reference scheme
type TempSortArray = Array<{ field: SortField; order: SortDirection; reference?: unknown; docId?: string }>;
export interface ILHomePageSearchTemporaryInterface {
  sort1?: TempSortArray;
  sort2?: TempSortArray;
  sort3?: TempSortArray;
  sort4?: TempSortArray;
  sort5?: TempSortArray;
  sort6?: TempSortArray;
  sort7?: TempSortArray;
  sort8?: TempSortArray;
  sort9?: TempSortArray;
  sort10?: TempSortArray;
}

export enum InsightDescriptor {
  Liens = 'liens',
  Accessibility = 'accessibility',
  AffordableHousing = 'affordableHousing',
  Airbnb = 'airbnb',
  AirplaneNoise = 'airplaneNoise',
  Alterations = 'alterations',
  Antennas = 'antennas',
  AnalystInsight = 'analystInsight',
  Asbestos = 'asbestos',
  BikeCommute = 'bikeCommute',
  Bikes = 'bikes',
  Cleaning = 'cleaning',
  Connectivity = 'connectivity',
  Demographics = 'demographics',
  NewDevelopmentJobs = 'newDevelopmentJobs',
  NewDevelopmentJobsArea = 'newDevelopmentJobsArea',
  Crime = 'crime',
  Density = 'density',
  DogsFriendly = 'dogsFriendly',
  EarthquakeAndTsunami = 'earthquakeAndTsunami',
  Schools = 'newEducationSchools',
  ILSchools = 'educationSchoolsIl',
  ILSyntheticSchools = 'syntheticSchools',
  Elevators = 'elevators',
  FarmersMarket = 'farmersMarket',
  FarRights = 'farRights',
  FireRiskZones = 'fireRiskZones',
  Floods311 = 'floods311',
  HistoricLandmarks = 'historicLandmarks',
  HomelessnessAndNeedles = 'homelessnessAndNeedles',
  HouseFlipping = 'houseFlipping',
  HpdViolations = 'hpdViolations',
  IndoorAirQuality = 'indoorAirQuality',
  NewBuildings = 'newBuildings',
  NewConstruction = 'newConstruction',
  Noise = 'noise',
  NoiseFromBars = 'noiseFromBars',
  OpenSpaces = 'openSpaces',
  QuietStreet = 'quietStreet',
  OutdoorAirQuality = 'outdoorAirQuality',
  ParkDesert = 'parkDesert',
  Parking = 'parking',
  ProhibitedAirbnbs = 'prohibitedAirbnbs',
  ProtectedStreets = 'protectedStreets',
  RailwayProximity = 'railwayProximity',
  RentStabilizedApartments = 'rentStabilizedApartments',
  RoadwayConstructionMoratoriums = 'roadwayConstructionMoratoriums',
  Rodents = 'rodents',
  Shade = 'shade',
  SnowAndIce = 'snowAndIce',
  StreetLights = 'streetLights',
  TruckRoutes = 'truckRoutes',
  WaterfrontBlock = 'waterfrontBlock',
  WaterQuality = 'waterQuality',
  ZoneStatic = 'zoneStatic',
  Prices = 'prices',
  PricesEstimation = 'pricesEstimations',
  PriceTrends = 'priceTrends',
  PriceTrendsNew = 'priceTrendsNew',
  ComparablePrices = 'pricesComparable',
  BuildingComplaints = 'buildingComplaints',
  OpenSpacesNew = 'openSpacesNew',
  Elevator = 'elevator',
  BlockedView = 'blockedView',
  ZoningBlockedView = 'zoningBlockedView',
  TabuRegistration = 'tabuRegistration',
  DangerousIntersections = 'dangerousIntersections',
  BuildingPermitRequests = 'buildingPermitRequests',
  LightRail = 'lightRail',
  PinuiBinui = 'pinuiBinui',
  ApartmentsType = 'apartmentsType',
}

export type InsightSpecialDescriptor =
  | InsightDescriptor.HpdViolations
  | InsightDescriptor.NewDevelopmentJobs
  | InsightDescriptor.NewDevelopmentJobsArea
  | InsightDescriptor.Liens
  | InsightDescriptor.ILSyntheticSchools
  | InsightDescriptor.QuietStreet
  | InsightDescriptor.Crime
  | InsightDescriptor.TabuRegistration
  | InsightDescriptor.BuildingPermitRequests;

export interface IVerifyResetPasswordRequestVariables {
  token: string;
}

export interface IVerifyResetPasswordRequestResponse {
  verifyResetPasswordRequest: {
    status: ResetPasswordStatus;
  };
}

interface ReferenceDataset {
  country: string;
  state: string;
  city: string;
  type: string;
}

export interface IReference {
  dataset: ReferenceDataset;
  value: string;
  field: string;
}

export enum LoginType {
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google',
}

export enum AvailabilityType {
  Flexible = 'FLEXIBLE',
  Immediate = 'IMMEDIATE',
  Date = 'DATE',
}

export interface AdditionalListingAreas {
  gardenArea?: number;
  storeroomArea?: number;
  roofArea?: number;
  migrashArea?: number;
  balconiesArea?: number;
}

export type IPartialAmenities = Record<Partial<Amenity>, boolean> & { additionalAreas?: AdditionalListingAreas };

interface INewBulletinInputCommon {
  type?: BulletinPoiType;
  id?: PoiId;
  docId: string;
  price: number;
  images: IImageItem[];
  amenities?: IPartialAmenities;
  area: number;
  floor?: number;
  floors?: number;
  dealType: DealType;
  propertyType: PropertyType;
  generalCondition: IGeneralCondition;
  availableDate?: ISOString;
  availabilityType: AvailabilityType;
  furniture?: string;
  description?: string;
  address?: string;
  sellerType?: PocType;
  monthlyTax?: number;
  commonCharges?: number;
  buildingYear?: number;
  parcel?: number;
  block?: number;
  userName?: string;
  userPhone?: string;
  assignedAgentUserId?: string;
}

export interface INewBulletinResidentialInput extends INewBulletinInputCommon {
  beds: number;
  commonCharges?: number;
  exclusivity?: {
    startDate?: string;
    endDate?: string;
    exclusive?: boolean;
    exclusiveUrl?: string;
  };
}

export interface INewBulletinCommercialInput extends INewBulletinInputCommon {
  qualityClass: QualityClassOption;
  numberOfEmployees: number;
  rooms: number;
  resolutionPreferences: ResolutionPreference;
  monthlyManagementFee?: number;
}

export type INewBulletinInput = INewBulletinResidentialInput | INewBulletinCommercialInput;

export type IEditBulletinInput = INewBulletinInput & { id: PoiId; };

export type IBulletinInput = IEditBulletinInput | INewBulletinInput;

export interface IUploadBulletinInput {
  newListing: INewBulletinResidentialInput;
}

export interface IUploadCommercialBulletinInput {
  newCommercialListing: INewBulletinCommercialInput;
}

export interface IUpdateListingInput {
  editListing: IEditBulletinInput;
}

export interface IUpdateCommercialListingInput {
  editCommercialListing: IEditBulletinInput;
}

export interface IEditListingStatusInput {
  id: PoiId;
  status: ListingStatusType;
}

export interface IUpdateListingStatusInput {
  editListingStatus: IEditListingStatusInput;
}

export interface IEditAssignedAgentCommercialListingInput {
  editAssignedAgentCommercialListing: {
    listingId: string;
    assignedAgentUserId: string;
  };
}

export interface IEditAssignedAgentListingInput {
  editAssignedAgentListing: {
    listingId: string;
    assignedAgentId: string;
  };
}

export interface IEditCommercialListingPromotionStatusVariables {
  editCommercialListingPromotionStatus: {
    id: PoiId,
    promoted: boolean;
  };
}

export interface IEditListingPromotionStatusVariables {
  editListingPromotionStatus: {
    id: PoiId,
    promoted: boolean;
  };
}


export interface IEditListingPoiByIdsVariables extends IPoiByIdsVariables {
  includeFrozen: boolean;
  forEdit?: boolean;
}

const prioritiesList = [
  'schools',
  'bestSecular',
  'bestReligious',
  'lightRail',
  'dogFriendly',
  'lowCrime',
  'tranquilStreet',
  'parksAccess',
  'commuteTime',
  'familyFriendly',
] as const;

export type Priority = typeof prioritiesList[number];
const rolesList = [ 'user', 'agent', 'bulletins_admin' ] as const;
export type Role = typeof rolesList[number];

export enum UserRole {
  User = 'user',
  Agent = 'agent',
  BulletinsAdmin = 'bulletins_admin',
}

export type SaveSearchPayload = Omit<ISaveSearch, 'searchId' | 'searchPreviews'>;
export type SaveSearchMetaSource = 'lower_dynamic_button' | 'top_fixed_button' | 'search_result_card' | 'pop_up' | 'impact_card' | 'insight_expand' | 'track_address';
export interface SaveSearchMeta {
  source: SaveSearchMetaSource;
  saveSilently?: boolean;
}

export enum CardEventSource {
  Favorites = 'favorites',
  Agent = 'agent',
  SectionList = 'section_list',
}

export interface ISendFeedbackCallbackProps {
  isOpen: boolean;
  insightId: string;
}

export enum PurchasingReason {
  FirstHouse = 'firstApartment',
  Upgrading = 'upgradeApartment',
  Investment = 'forInvestment',
  SomethingElse = 'other',
}

export interface Product {
  id: string;
  packageType: 'rentals' | 'sales_n_rentals' | 'sales';
  name: string;
  tag?: string;
  subscriptions: Subscription[];
}

export interface Subscription {
  id: string;
  months: number;
  name: string;
  pricePerMonth: number;
  type: 'regular' | 'promo';
}

export interface IBannersByDocIdVariables {
  docId: string;
  searchContext: SearchContext;
}

export interface IAdditionalBannersByDocIdVariables {
  docId: string;
  searchContext: AdditionalAdsSearchContext;
}

export interface IMadadWinnersBannersByDocIdVariables {
  docId: string;
}

export interface IMadadWinnersBannersForStreetByDocIdVariables {
  docIds: string[];
}

export interface ITabuByDocIdVariables {
  docId: string;
  timestamp: number;
}

export interface IMadadParticipantsVariables {
  year?: number;
}

export enum RealEstateLevel {
  Public = 'PUBLIC',
  Any = 'ANY',
  Promoted = 'PROMOTED',
}

export interface IMadadAgentsOfficesVariables {
  docIds: string[];
  onlyWinners: boolean;
  year?: number;
}

export interface IAgentsOfOfficeByIdVariables {
  id: string;
}

export interface IAdminSearchVariables extends IUserListingsVariables {
  timestamp: number;
}

export interface Tabu {
  block: number;
  downloadToken: string;
  parcel: number;
}

export interface TabuByDocIdResponse {
  docId2NesachTabu: Tabu;
}

export type FooterContext = 'sale' | 'rent' | 'area';

export interface IFooterByDocIdVariables {
  cityDocId: string;
  footerContext: FooterContext;
}

export interface BannersByDocIdResponse {
  id: string;
  link: string | null;
  isExternalLink: boolean;
  isCommercial: boolean;
  desktopImage: string;
  mobileImage: string;
}

export interface MadadWinnersBannersByDocIdResponse {
  agentId: string;
  agentName: string;
  avatarImage: string;
  category: string;
  coverImage: string;
  docId: string;
  exclusiveListingCount: number;
  officeAvatarImage: string;
  isRegistered: boolean;
  officeId: string;
  officeName: string;
  phone: string;
  soldCount: number;
}

export enum MadadWinnerBannerType {
  Agent = 'agent',
  Agency = 'agency',
  Combined = 'combined',
}

export interface MadadAgentWinnerBanner extends Omit<MadadWinnersBannersByDocIdResponse, 'officeId' | 'officeName' | 'officeAvatarImage'> {
  type: MadadWinnerBannerType.Agent;
}

export interface MadadAgencyWinnerBanner extends Omit<MadadWinnersBannersByDocIdResponse, 'agentId' | 'agentName' | 'officeAvatarImage'> {
  type: MadadWinnerBannerType.Agency;
}

export interface MadadCombinedWinnerBanner extends MadadWinnersBannersByDocIdResponse {
  type: MadadWinnerBannerType.Combined;
}


export type MadadWinnersBanners = MadadAgentWinnerBanner
  | MadadAgencyWinnerBanner
  | MadadCombinedWinnerBanner;

export interface FooterRow {
  docId: string;
  name: string;
  link: string;
  copy: string;
}

export interface FooterByDocIdResponse {
  cityDocId: string;
  rows: FooterRow[];
}

export interface MadadParticipantPlace {
  name: string;
  docId: string;
}
export interface MadadParticipant {
  city: MadadParticipantPlace;
  neighbourhoods: MadadParticipantPlace[];
}

export interface MadadRenewDto {
  agentId: string;
  agentName: string;
  avatarImage: string;
  category: MadadCategory;
  coverImage: string;
  docId: string;
  exclusiveListingCount: number;
  officeId: string;
  officeName: string;
  soldCount: number;
  phone: string;
  isRegistered: boolean;
}
export interface MadadAgentsOfficesResponse {
  agent: MadadRenewDto[];
  office: MadadRenewDto[];
}

export type MadadParticipantsResponse = MadadParticipant[];
export interface MadadDto {
  id: string;
  role: 'office' | 'agent';
  docId: string; // city or neighborhood docId
  cityDocId: string;
  neighborhoodDocId?: string;
  exclusiveListingsCount: number;
  soldCount: number;
  avatarImage: string;
  coverImage: string;
  officeId: string;
  officeName: string;
  officeIconUrl?: string;
  sortLevel: number;
  category: MadadCategory;
  agentId?: string;
  agentName?: string;
  phone: string;
  isRegistered: boolean;
}

export interface MadadAgentDto extends MadadDto {
  role: 'agent';
  agentId: string;
  agentName: string;
}

export interface MadadOfficeDto extends MadadDto {
  role: 'office';
}

export type MadadCategory = 'top_10' | 'top_10_gold' | 'winner';

export type MadadSearchResultCategory = Extract<MadadCategory, 'top_10' | 'top_10_gold'>;

export interface MadadWinnersGroup {
  agent: MadadAgentDto[];
  office: MadadOfficeDto[];
}

export enum ContactDeveloperReason {
  BuyProject = 'BUY_PROJECT_UNIT',
  GetOffer = 'GET_TAMA_OFER',
}

export enum DeveloperIntegrationLevel {
  Free = 'FREE',
  Basic = 'BASIC',
  Full = 'FULL',
}

export interface IDeveloperStaff {
  name: string;
  title: string;
  imageUrl: string;
  about: string;
}

export enum DeveloperAwardType {
  Madad2022 = 'CITY_RENEWAL_MADAD_2022',
  Madad2021 = 'CITY_RENEWAL_MADAD_2021',
  Madad2020 = 'CITY_RENEWAL_MADAD_2020',
  Madad2019 = 'CITY_RENEWAL_MADAD_2019',
  Madad2018 = 'CITY_RENEWAL_MADAD_2018',
  Duns2022 = 'DUNS_100_2022',
  Duns2021 = 'DUNS_100_2021',
  Duns2020 = 'DUNS_100_2020',
  Duns2019 = 'DUNS_100_2019',
  Duns2018 = 'DUNS_100_2018',
  Other = 'OTHER',
}

export interface IDeveloperAward {
  type: DeveloperAwardType;
  imageUrl: string;
  linkUrl: string;
  title: string;
  description: string;
}

export enum DeveloperProjectSection {
  Promoted = 'promoted',
  Planning = 'planning',
  Building = 'building',
  Done = 'done',
}

export interface IDeveloperImage {
  imageUrl: string;
  isMain: boolean;
  title?: string;
  url?: string;
}

export enum ProjectIntegrationLevel {
  Free = 'FREE',
  DevDisplay = 'DEV_DISPLAY',
  Demo = 'DEMO',
  Full = 'FULL',
}

export enum ProjectStatusType {
  Published = 'PUBLISHED',
  Draft = 'DRAFT',
  Deleted = 'DELETED',
}

export enum ProjectBuildingStage {
  Planning = 'PLANNING',
  Building = 'BUILDING',
  Done = 'DONE',
  OnHold = 'ON_HOLD',
  Rejected = 'REJECTED',
  PartialPopulation = 'PARTIAL_POPULATION',
}

export enum DeveloperProjectMarketplace {
  Commercial = 'COMMERCIAL',
  Residential = 'RESIDENTIAL',
}

export interface IDeveloperProjectAddress {
  docId: string;
  street: string;
  settlement: string;
  houseNumber: number;
}

export interface IDeveloperProject {
  id: PoiId;
  projectName: string;
  address: IDeveloperProjectAddress;
  integrationLevel: ProjectIntegrationLevel;
  status: ProjectStatusType;
  buildingStage: ProjectBuildingStage;
  updatedAt: ISOString;
  createdAt: ISOString;
  dealType: DealType;
  marketplaceType: DeveloperProjectMarketplace;
  locationPoint: {
    longitude: number;
    latitude: number;
  };
  targetingDisplay: {
    subTitle: string;
  };
  images: Array<{
    url: string;
    isMain: boolean;
  }>;
  developers: IDeveloperProfile[];
  price: RangeValues;
  rooms: RangeValues;
  discount?: {
    showDiscount: boolean;
  };
}

export interface IDeveloperProfile {
  id: string;
  name: string;
  address: string;
  logoUrl: string;
  articles: Article[];
  phoneNumber: string;
  integrationLevel: DeveloperIntegrationLevel;
  staffMembers: IDeveloperStaff[];
  description: string;
  draftjsDescription: RawDraftContentState;
  awards: IDeveloperAward[];
  videoUrl: string;
  images: IDeveloperImage[];
  projects: IDeveloperProject[];
  shouldPresentProjectCounter: boolean;
  externalLinks: {
    facebook: string;
    linkedIn: string;
    instagram: string;
    website: string;
  };
  showContactDetailsOnDevPage: boolean;
  projectsCount?: number;
}

enum OfficeStatus {
  Pending = 'pending',
  Approved = 'approved',
  ChangedAfterApproved = 'changedAfterApproved',
  NoApproved = 'noApproved',
  Closed = 'closed',
}

export interface IAgentOfficeDeal {
  id: string;
  date: string;
  structuredAddress: {
    docId: string;
    city: string;
    cityDocId: string;
    borough: string;
    zipcode: string;
    streetName: string;
    streetNumber: string;
    neighbourhood: string;
    neighbourhoodDocId: string;
    district: string;
    unitNumber: string;
    resolutionPreferences: ResolutionPreference;
  };
  declaredPrice: number;
  propertyType: PropertyType;
  numRooms: number;
  floor: number;
  saleId: string;
  grossArea: string;
  totalFloors: number;
  agents: Array<{
    agentId: string;
    officeId: string;
    agentLogo: string;
    agentName: string;
    agentType: string;
    officeLogo: string;
    officeName: string;
  }>;
  sellers: Array<{
    agentId: string;
    officeId: string;
    agentLogo: string;
    agentName: string;
    agentType: string;
    officeLogo: string;
    officeName: string;
  }>;
  location: {
    type: string;
    coordinates: RangeTuple<number>;
  };
}

export interface IAgentOfficeDealEnriched extends IAgentOfficeDeal {
  type: 'sold';
  dealType: 'sold';
  locationPoint: {
    lng: number;
    lat: number;
  };
}

export enum IOfficeAwardCategory {
  Top10Gold = 'top_10_gold',
  Top10 = 'top_10',
}

export enum IOfficeAwardYear {
  Y2020 = '2020',
  Y2021 = '2021',
  Y2022 = '2022',
  Y2023 = '2023',
  Y2024 = '2024',
}

export interface IOfficeAward {
  category: IOfficeAwardCategory;
  year: IOfficeAwardYear;
}

export enum IOfficeAgentDisplayLevel {
  Basic = 'basic',
  Premium = 'premium',
  Minimal = 'minimal',
}

export interface ISpotlightListing {
  listingId: string;
  listingType: MarketplaceType;
}

export interface IOfficeData {
  descriptionHtml: string;
  isMadadWinner: boolean;
  payingAboutTheOfficeInListingPage: boolean;
}

export interface IOfficePageData {
  officeId: string;
  officeName: string;
  integrationLevel: any;
  isMadadWinner: boolean;
  payingAboutTheOfficeInListingPage: boolean;
  area: string;
  rawAddress: string;
  virtualPhone: string;
  fax: string;
  iconUrl: string;
  descriptionHtml: string;
  status: OfficeStatus;
  computedStatus: OfficeStatus;
  leadConsumerEmails: string[];
  madadAwards: IOfficeAward[];
  displayLevel: IOfficeAgentDisplayLevel;
  linkedinUrl: string;
  instagramUrl: string;
  facebookPageUrl: string;
  websiteUrl: string;
  videoUrl?: string;
  videoSource?: VideoSource;
  staffMembers?: IStaffMember[];
  officeConnectionInAgentPage: boolean;
  allOfficeBulletinsInAgentPage: boolean;
}

export interface IAgentOfficeBulletinsData {
  isMadadWinner: boolean;
  payingAboutTheOfficeInListingPage: boolean;
  deals: IAgentOfficeDeal[];
  bulletins: IAgentOfficeBulletin[];
  commercialBulletins: IAgentOfficeBulletin[];
  spotlightListings?: ISpotlightListing[];
}

export type IAgentWithBulletinsData = IAgentPageData & {
  [MarketplaceType.Residential]: {
    [DealType.Buy]: IBulletin[],
    [DealType.Rent]: IBulletin[],
  },
  [MarketplaceType.Commercial]: {
    [DealType.Buy]: ICommercialBulletin[],
    [DealType.Rent]: ICommercialBulletin[],
  },
};

export interface IAgentPageData {
  agentId: string;
  userId: string;
  officeId: string;
  officeName: string;
  virtualPhone: string;
  emailAddress: string;
  title: string;
  name: string;
  office?: {
    officeName: string;
  };
  licenseId: string;
  licenseFromYear: string;
  integrationLevel: any;
  iconUrl: string;
  descriptionHtml: string;
  madadAwards: IOfficeAward[];
  displayLevel: IOfficeAgentDisplayLevel;
  linkedinUrl: string;
  instagramUrl: string;
  facebookPageUrl: string;
  websiteUrl: string;
  pricingTier: PricingTierApi3;
  testimonials: Array<{
    customerName: string;
    text: string;
  }>;
  videoUrl?: string;
  videoSource?: VideoSource;
}

export interface ITeamByOfficeIdData {
  agentId: string;
  name: string;
  title: string;
  iconUrl: string;
  pricingTier: PricingTierApi3;
  testimonials: Array<{
    customerName: string;
    text: string;
  }>;
}

export interface IStaffMember {
  name: string;
  role: string;
  description?: string;
  imageUrl?: string;
}

export enum MortgageOfficeService {
  NewMortgage = 'NewMortgage',
  LoanConsolidation = 'LoanConsolidation',
  MortgageTurnover = 'MortgageTurnover',
  ConsolidationToBlockedClients = 'ConsolidationToBlockedClients',
  PragmaticMortgage = 'PragmaticMortgage',
  MortgageFreeze = 'MortgageFreeze',
  ConsultingOnly = 'ConsultingOnly',
  MortgageForInvestors = 'MortgageForInvestors',
  TransferOfMortgage = 'TransferOfMortgage',
}

export enum MortgageOfficeRole {
  OfficeManagement = 'OfficeManagement',
  ClientManagement = 'ClientManagement',
  MortgageAdvice = 'MortgageAdvice',
  CompanyManager = 'CompanyManager',
  Other = 'Other',
}

export interface MortgageOfficeRecomendations {
  customerName: string;
  text: string;
  agentName?: string;
}

export interface MortgageOfficeAdvisor {
  role: {
    role: MortgageOfficeRole;
    otherValue: string;
  };
  name: string;
  imageUrl: string;
  description?: string;
}

export enum VideoSource {
  Link = 'LINK',
  S3 = 'S3',
}

export enum MortgageAdvisorsOfficeIntegrationLevel {
  FREE = 'FREE',
  PAYING = 'PAYING',
}

export interface IMortgageOffice {
  id: string;
  name: string;
  phone: string;
  email?: string;
  managerName: string;
  singleAdvisorOffice: boolean;
  cityDocIds: string[];
  integrationLevel?: MortgageAdvisorsOfficeIntegrationLevel;
  services?: MortgageOfficeService[];
  description?: string;
  memberInAssociationOfMortgageAdvisors: boolean;
  webSiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  linkedInUrl?: string;
  logoUrl?: string;
  videoUrl?: string;
  recommendations?: MortgageOfficeRecomendations[];
  featuredRecommendationIndex: number | null;
  advisors: MortgageOfficeAdvisor[];
  videoSource?: VideoSource;
  virtualPhoneNumber?: string;
}

export interface NameValuePair<TValue> {
  name: string;
  value: TValue;
}

export interface ITrainStationInfo {
  nearestStation: string;
  inWalkingDistance: boolean;
  walkingTime: number;
}

export interface ICommercialLocalInfo {
  classAPrice: number;
  classBPrice: number;
  employeeOrigins: Array<NameValuePair<number>>;
  industryTypes: Array<NameValuePair<number>>;
  nearestTrainStation: ITrainStationInfo;
  nearbyIntersections: string[];
  nearbyRoads: string[];
}

export interface ICommercialLocalInfoResponse {
  commercialLocalInfo: ICommercialLocalInfo;
}

export interface Area {
  areaName: string;
  docId: string;
}

export enum ContentWidthMode {
  Default = 'default',
  Narrow = 'narrow',
  Wide = 'wide',
}

export enum DealAffiliationStatus {
  Approved = 'approved',
  Pending = 'pending',
  Rejected = 'rejected',
}

export enum DealRejectionReason {
  WaitingToMissim = 'waitingToMissim',
  MissmatchedAddress = 'missmatchedAddress',
  MissingReceipt = 'missingReceipt',
  MissingDates = 'missingDates',
  MissingAddress = 'missingAddress',
  BlurredDoc = 'blurredDoc',
  Mismatch = 'mismatch',
  MismatchPrice = 'mismatchPrice',
  MissingBuyerDetails = 'missingBuyerDetails',
  MissingAgentDetails = 'missingAgentDetails',
  MismatchAgentName = 'mismatchAgentName',
  MissingDateInReceipt = 'missingDateInReceipt',
  MissingCommission = 'missingCommission',
  NoApproved = 'noApproved',
  MissingStatement = 'missingStatement',
  Frozen = 'frozen',
  MissingSign = 'missingSign',
  MissingDocument = 'missingDocument',
}

export enum DealAffiliationType {
  Seller = 'seller',
  Buyer = 'buyer',
  Both = 'both',
}

export interface IDealAffiliationRequest {
  soldId: string;
  dealDate: ISOString;
  agentId: string;
  agentName: string;
  price: number;
  structuredAddress: {
    city: string;
    streetName: string;
    streetNumber: string;
    neighbourhood: string;
  };
  propertyType: string;
  rooms: number;
  receiptUrls: string[];
  affiliationStatus: DealAffiliationStatus;
  rejectionReason: DealRejectionReason;
  createdAt: ISOString;
  lastUpdated: ISOString;
  affiliationType: DealAffiliationType;
  affiliationRequestId: string;
  constructionYear: number;
  area: string;
  floor: number;
}

export enum AffiliationRequestResponseDealType {
  DealAffiliationRequestOutput = 'DealAffiliationRequestOutput',
  EditDealAffiliationRequestError = 'EditDealAffiliationRequestError',
  NoPermissionsError = 'NoPermissionsError',
  DealAffiliationApprovalRejectionDueToPendingRequestsError = 'DealAffiliationApprovalRejectionDueToPendingRequestsError',
  AlreadyApprovedAffiliationRequestError = 'AlreadyApprovedAffiliationRequestError',
}

export interface IAffiliationRequestResponseDeal {
  id: string;
  soldId: string;
  agentId: string;
  agentName: string;
  agentType: DealAffiliationType;
  receiptUrls: string[];
  status: DealAffiliationStatus;
  rejectionReason: DealRejectionReason;
  updatedAt: ISOString;
  errorMessage?: string;
  __typename: AffiliationRequestResponseDealType;
}

export enum RequestDealAffiliationResponseType {
  DealAffiliationRequestOutput = 'DealAffiliationRequestOutput',
  NoPermissionsError = 'NoPermissionsError',
  GeneralRequestDealAffiliationError = 'GeneralRequestDealAffiliationError',
  DealAffiliationApprovalRejectionDueToPendingRequestsError = 'DealAffiliationApprovalRejectionDueToPendingRequestsError',
  AlreadyApprovedAffiliationRequestError = 'AlreadyApprovedAffiliationRequestError',
}

export interface IRequestDealAffiliationResponse {
  id: string;
  soldId: string;
  agentId: string;
  agentType: DealAffiliationType;
  receiptUrls: string[];
  status: DealAffiliationStatus;
  rejectionReason: DealRejectionReason;
  updatedAt: ISOString;
  dealDate: ISOString;
  agentName: string;
  price: number;
  structuredAddress: {
    city: string;
    streetName: string;
    streetNumber: string;
    neighbourhood: string;
  };
  propertyType: string;
  rooms: number;
  constructionYear: number;
  area: string;
  floor: number;
  errorMessage?: string;
  __typename: RequestDealAffiliationResponseType;
}

export interface ISearchDeal {
  soldId: string;
  address: {
    city: string;
    borough: string;
    streetName: string;
    streetNumber: string;
    neighbourhood: string;
    resolutionPreferences: ResolutionPreference;
  };
  price: number;
  propertyType: string;
  rooms: number;
  area: number;
  floor: number;
  pricePerMeter: number;
  constructionYear: number;
  date: ISOString;
  affiliationType: DealAffiliationType;
  parcel: number;
  block: number;
}

export interface ISearchNumberRange {
  from?: number;
  to?: number;
}

export interface ISearchDateRange {
  from?: ISOString;
  to?: ISOString;
}

// Exclusivity (api3)
interface Exclusivity {
  exclusiveStatus?: string;
  exclusive?: boolean;
  exclusivityMode?: ExclusivityMode;
  startDate?: string;
  endDate?: string;
  requestDate: Date;
  exclusiveUrl?: string;
}

// ContactInfo (api3)
interface ContactInfo {
  name?: string;
  phone?: string;
  email?: string;
  imageUrl?: string;
  madadCategory?: string;
}

// AgentPoc (api3)
export interface AgentPoc {
  __typename?: 'AgentPoc';
  agent: {
    id: string;
    userId?: string;
  };
  displayNumber?: string;
  agentContact?: ContactInfo;
  officeContact?: ContactInfo;
  title?: string;
  company?: string;
  exclusivity: Exclusivity;
  madadSearchResult?: string;
  officeId?: string;
}

// UserPoc (api3)
interface UserPoc {
  __typename?: 'UserPoc';
  user: {
    userId: string;
  };
  displayNumber?: string;
  contactInfo?: ContactInfo;
  exclusivity?: Exclusivity;
}

// Poc (api3)
export type Poc = AgentPoc | UserPoc;

// Bulletin (api3)
export type Bulletin = Omit<IBulletin, 'poc' | 'floor' | 'buildingClass'> & {
  __typename?: 'Bulletin';
  poc: Poc;
  floor?: string;
  buildingClass?: string;
};

interface Range {
  __typename?: 'Range';
  min: number | undefined;
  max: number | undefined;
}

// Developer (api3)
interface Developer {
  __typename?: 'Developer';
  id: string;
  name: string;
  logoUrl?: string;
}

enum AutocompleteDocumentType {
  ADDRESS = 'ADDRESS',
  PROJECT_COMMERCIAL = 'PROJECT_COMMERCIAL',
  PROJECT_RESIDENTIAL = 'PROJECT_RESIDENTIAL',
  PROJECT_COMMERCIAL_DRAFT = 'PROJECT_COMMERCIAL_DRAFT',
  PROJECT_RESIDENTIAL_DRAFT = 'PROJECT_RESIDENTIAL_DRAFT',
  DEVELOPER = 'DEVELOPER',
  DEVELOPER_DRAFT = 'DEVELOPER_DRAFT',
  NEIGHBOURHOOD = 'NEIGHBOURHOOD',
  AGENT = 'AGENT',
  EDUCATION = 'EDUCATION',
  SCHOOL = 'SCHOOL',
  LAWYER = 'LAWYER',
  CITY = 'CITY',
  DISTRICT = 'DISTRICT',
  STREET = 'STREET',
  ARCHITECT = 'ARCHITECT',
  CONSTRUCTOR = 'CONSTRUCTOR',
  SALE_AGENT = 'SALE_AGENT',
  NODE = 'NODE',
  ZIPCODE = 'ZIPCODE',
  CUSTOM_ZONE = 'CUSTOM_ZONE',
  COUNTRY = 'COUNTRY',
  MORTGAGE_ADVISORS_OFFICE = 'MORTGAGE_ADVISORS_OFFICE',
  MORTGAGE_ADVISORS_OFFICE_DRAFT = 'MORTGAGE_ADVISORS_OFFICE_DRAFT',
  OFFICE = 'OFFICE',
  AGENT_NONE_PROMOTED = 'AGENT_NONE_PROMOTED',
  OFFICE_NONE_PROMOTED = 'OFFICE_NONE_PROMOTED',
}

enum GeometryShapeType {
  multipolygon = 'multipolygon',
  multilinestring = 'multilinestring',
  point = 'point',
}

interface GeometryShape {
  type: GeometryShapeType;
}

export interface AutocompleteDocument {
  docId?: string;
  identityDocId?: string;
  name?: string;
  type?: AutocompleteDocumentType;
}

export interface AutocompleteLocation {
  geometry?: GeometryShape;
  location?: number[];
}

export interface Address extends AutocompleteDocument, AutocompleteLocation {
  __typename?: 'Address';
  city?: string;
  street?: string;
  neighbourhood?: string;
  houseNumber?: string;
  zipcode?: string;
  floorCount?: string;
  unitsTotal?: string;
}

export interface City extends AutocompleteDocument, AutocompleteLocation {
  __typename?: 'City';
  city?: string;
}

export interface Neighbourhood extends AutocompleteDocument, AutocompleteLocation {
  __typename?: 'Neighbourhood';
  city?: string;
  neighbourhood?: string;
}

export interface Street extends AutocompleteDocument, AutocompleteLocation {
  __typename?: 'Street';
  city?: string;
  street?: string;
}

export type AutocompleteDocumentUnion = Address | City | Neighbourhood | Street;

interface AutocompleteDocumentRef {
  __typename?: 'AutocompleteDocumentRef';
  docId: string;
  ref?: AutocompleteDocumentUnion;
}

interface ProjectAddress {
  street?: string;
  settlement?: string;
  houseNumber?: number;
  docId?: string;
  document?: AutocompleteDocumentRef;
}

interface ProjectImage {
  url?: string;
  isMain?: boolean;
  description?: string;
  title?: string;
  rotation?: number;
}

interface ProjectPromotionDetailsBase {
  startDate?: Date;
  endDate?: Date;
}

interface ProjectPromotion {
  promotionDetails?: ProjectPromotionDetailsBase[];
  cities?: AutocompleteDocumentRef[];
  neighbourhoods?: AutocompleteDocumentRef[];
}

// Project (api3)
export interface Project {
  __typename?: 'Project';
  id: string;
  dealType: DealType;
  createdAt?: string;
  updatedAt?: string;
  status?: ProjectStatusType;
  address?: ProjectAddress;
  projectName: string;
  logoUrl?: string;
  images: ProjectImage[];
  discount: IProjectDiscount;
  floors?: Range;
  promotion?: ProjectPromotion;
  locationPoint?: {
    longitude: number;
    latitude: number;
  };
  rooms: Range;
  price: Range;
  developers: Developer[];
}

export enum UpdateFrequency {
  Realtime = 'realtime',
  Daily = 'daily',
  Weekly = 'weekly',
  Never = 'noupdates',
}

export enum RegistrationTypeNG {
  NATIVE = 'NATIVE',
  VISITOR = 'VISITOR',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
}

export enum UserRoleNG {
  VISITOR = 'VISITOR',
  USER = 'USER',
  AGENT = 'AGENT',
  IMPERSONATOR = 'IMPERSONATOR',
  ADVISOR = 'ADVISOR',
  BACKOFFICE_BASIC_USER = 'BACKOFFICE_BASIC_USER',
  BACKOFFICE_USER = 'BACKOFFICE_USER',
  BACKOFFICE_AGENT = 'BACKOFFICE_AGENT',
  LEAN_AGENT = 'LEAN_AGENT',
  BACKOFFICE_ADMIN = 'BACKOFFICE_ADMIN',
  BACKOFFICE_CAMPAIGNS_MANAGER = 'BACKOFFICE_CAMPAIGNS_MANAGER',
  BULLETINS_ADMIN = 'BULLETINS_ADMIN',
  ORANGE_CMS_USER = 'ORANGE_CMS_USER',
}

export enum PoiTypeNG {
  PROJECT = 'PROJECT',
  BULLETIN = 'BULLETIN',
  AD = 'AD',
  DEAL = 'DEAL',
  COMMERCIAL_PROJECT = 'COMMERCIAL_PROJECT',
  COMMERCIAL_BULLETIN = 'COMMERCIAL_BULLETIN',
}

export enum CommuteTypeNG {
  CAR = 'CAR',
  COMMUTE = 'COMMUTE',
  WALK = 'WALK',
  BIKE = 'BIKE',
  TRAIN = 'TRAIN',
  BUS = 'BUS',
}

export enum PoiTypeNg {
  PROJECT = 'PROJECT',
  BULLETIN = 'BULLETIN',
  AD = 'AD',
  DEAL = 'DEAL',
  COMMERCIAL_PROJECT = 'COMMERCIAL_PROJECT',
  COMMERCIAL_BULLETIN = 'COMMERCIAL_BULLETIN',
}

export enum SubscriptionUpdateFrequency {
  NOUPDATES = 'NOUPDATES',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  REALTIME = 'REALTIME',
}

export enum GeneralCondition {
  new = 'new',
  asNew = 'asNew',
  fine = 'fine',
  renovated = 'renovated',
  preserved = 'preserved',
  toRenovate = 'toRenovate',
}

export enum PropertyTypeNG {
  condo = 'condo',
  coop = 'coop',
  house = 'house',
  multiFamily = 'multiFamily',
  rental = 'rental',
  townhouse = 'townhouse',
  commercial = 'commercial',
  lotsAndLand = 'lotsAndLand',
  flat = 'flat',
  villa = 'villa',
  gardenApartment = 'gardenApartment',
  cottage = 'cottage',
  dualCottage = 'dualCottage',
  penthouse = 'penthouse',
  attic = 'attic',
  duplex = 'duplex',
  agricultureLand = 'agricultureLand',
  shop = 'shop',
  land = 'land',
}

export interface SearchFilters {
  propertyType: PropertyTypeNG;
  sellerType: Seller[];
  generalCondition: GeneralCondition[];
  amenities: Partial<Record<Amenity, boolean>>;
  dealType: DealType;
  priceRange: RangeTuple<number>;
  bathsRange: RangeTuple<BathOption>;
  roomsRange: RangeTuple<RoomOption>;
  noFee: boolean;
  floorRange: RangeTuple<number>;
  areaRange: RangeTuple<number>;
  size: RangeTuple<number>;
  buildingClass: string[];
  monthlyTaxRange: RangeTuple<number>;
  availableDate: string[];
  ppmRange: RangeTuple<number>;
  openHouse: string;
}

export interface PoiIdObject {
  id: string;
  type: PoiTypeNG;
  createdAt: string;
}

export interface ISearch {
  searchId: string;
  title: string;
  documents: AutocompleteDocumentRef[];
  query: SearchFilters;
  isDefault: boolean;
  updateFrequency: SubscriptionUpdateFrequency;
  createdAt: string;
  updatedAt: string;
}


interface SlimAgentInfo {
  agentId: string;
  agentName: string;
  userId: string;
  officeName: string;
  officeId: string;
}

interface UserOfficeManager {
  agentId: string;
  officeId: string;
  officeName: string;
  agentsInOffice: SlimAgentInfo[];
}

interface UserAgentStatus {
  agentId: string;
  displayLevel: IOfficeAgentDisplayLevel;
  officeId: string;
  pricingTier: PricingTierApi3;
  isApproved: boolean;
}

export interface UserNG {
  userId: string;
  email: string;
  fullName: string;
  phone: string;
  profileImage?: string;
  registrationType: RegistrationTypeNG;
  roles: UserRoleNG[];
  preferences?: {
    commute?: {
      commuteType: CommuteTypeNG;
      rushHour: boolean;
      place?: AutocompleteDocumentRef;
    };
  };
  favorites?: PoiIdObject[];
  contactPois?: PoiIdObject[];
  viewedPois?: PoiIdObject[];
  savedAddresses: Array<{
    document: {
      docId: string;
      ref: AutocompleteDocumentRef;
    }
    settings: SavedAddressSettings;
  }>;
  savedSearches: ISearch[];
  searchHistory: ISearch[];
  commercialEnablePrivateUser?: boolean;
  officeManager?: UserOfficeManager;
  agentStatus?: UserAgentStatus;
}

export interface LoginOutcome {
  __typename: 'LoginOutcome';
  token: string;
  refreshToken: string;
  signedUp: boolean;
  user: UserNG;
}

interface SignUpMailSentError {
  __typename: 'SignUpMailSentError';
  errorMessage: string;
}

interface LoginMailSentError {
  __typename: 'LoginMailSentError';
  errorMessage: string;
}

interface InvalidRegistrationTypeError {
  __typename: 'InvalidRegistrationTypeError';
  errorMessage: string;
}

interface NeedPasswordError {
  __typename: 'NeedPasswordError';
  errorMessage: string;
}

interface LoginFailedError {
  __typename: 'LoginFailedError';
  errorMessage: string;
}

interface InvalidEmailError {
  __typename: 'InvalidEmailError';
  errorMessage: string;
}

interface InvalidPasswordError {
  __typename: 'InvalidPasswordError';
  errorMessage: string;
}

interface InvalidAgentRegistrationError {
  __typename: 'InvalidAgentRegistrationError';
  errorMessage: string;
}

interface InvalidSignupPhoneRequiredError {
  __typename: 'InvalidSignupPhoneRequiredError';
  errorMessage: string;
}

interface SignupPhoneVerificationSmsSentError {
  __typename: 'SignupPhoneVerificationSmsSentError';
  errorMessage: string;
}

interface VerificationLoginTokenExpiredError {
  __typename: 'VerificationLoginTokenExpiredError';
  errorMessage: string;
}

interface PhoneAlreadyExistsError {
  __typename: 'PhoneAlreadyExistsError';
  errorMessage: string;
}

interface VerificationTokenMaxAttemptsExceededError {
  __typename: 'VerificationTokenMaxAttemptsExceededError';
  errorMessage: string;
}

interface UserAlreadyExistsError {
  __typename: 'UserAlreadyExistsError';
  errorMessage: string;
}

interface NeedPasswordResetError {
  __typename: 'NeedPasswordResetError';
  errorMessage: string;
}

interface RequireTermsApprovalError {
  __typename: 'RequireTermsApprovalError';
  errorMessage: string;
}

export type LoginResponseNG =
  LoginOutcome
  | SignUpMailSentError
  | LoginMailSentError
  | InvalidRegistrationTypeError
  | NeedPasswordError
  | LoginFailedError
  | InvalidEmailError
  | InvalidPasswordError
  | InvalidAgentRegistrationError
  | InvalidSignupPhoneRequiredError
  | SignupPhoneVerificationSmsSentError
  | VerificationLoginTokenExpiredError
  | PhoneAlreadyExistsError
  | VerificationTokenMaxAttemptsExceededError
  | UserAlreadyExistsError
  | NeedPasswordResetError
  | RequireTermsApprovalError;

export enum ResetPasswordStatus {
  RESET_SUCCESS = 'RESET_SUCCESS',
  RESET_FAILURE = 'RESET_FAILURE',
  RESET_INVALID_TOKEN = 'RESET_INVALID_TOKEN',
  RESET_INVALID_USER_DATA = 'RESET_INVALID_USER_DATA',
  RESET_TOKEN_ALREADY_USED = 'RESET_TOKEN_ALREADY_USED',
  RESET_USER_LOGGED_IN = 'RESET_USER_LOGGED_IN',
  RESET_INVALID_EMAIL = 'RESET_INVALID_EMAIL',
  RESET_INVALID_PASSWORD = 'RESET_INVALID_PASSWORD',
}
