import React from 'react';
import styled from '@emotion/styled';
import { FieldRenderProps } from 'react-final-form-hooks';
import { SmallText } from 'ds/components/typography';

const ValidationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ theme }) => theme.media.showAt({ to: 2 })`
    flex-basis: 100%;
  `}
`;

export const ErrorMessage = styled(SmallText)`
  justify-content: start;
  color: ${props => props.theme.colors.alert.red};
  margin-top: ${props => props.theme.spacing(1)};
`;

type Meta = FieldRenderProps['meta'];

export function withValidationMessage<T>(ValidatedComponent: React.ComponentType<T>) {

  const outComp: React.FC<Meta & T> = (props) => {
    const { touched, error } = props;

    return (
      <ValidationWrapper>
        <ValidatedComponent {...(props as T)}/>
        {touched && error ? (<ErrorMessage className="styled-error-msg">{error}</ErrorMessage>) : null}
      </ValidationWrapper>
    );
  };

  return outComp;
}

