import { Route } from 'config/routes';
import { call, fork, put, select } from 'redux-saga/effects';
import { authGate, AuthGateResponse, waitForUserResolve } from 'store/sagas/routing/handlers/utils';
import {
  isAgentManagerSelector,
  isConnectedUserSelector,
  approvedAgentSelector,
} from 'store/state/domainData/selectors';
import { AuthenticationModalType } from 'components/authentication/types';
import { navigateTo } from 'store/state/router/actions';
import { showAgentConsoleBulletinFormSelector } from 'store/state/selectors/router';
import { resetDomainData } from 'store/state/domainData/actions';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';

function* bulletinFormWorker() {
  yield call(waitForUserResolve);
  const isConnectedUser: boolean = yield select(isConnectedUserSelector);
  const isApprovedAgent: boolean = yield select(approvedAgentSelector);
  const isAgentManager: boolean = yield select(isAgentManagerSelector);

  if (!isConnectedUser) {
    const status: AuthGateResponse = yield call(authGate, undefined, AuthenticationModalType.SignIn);

    if (status === AuthGateResponse.Rejected) {
      yield put(navigateTo(Route.Home, {}, { replace: true }));
    }
  }

  yield call(waitForUserResolve);

  const isConsoleUser: boolean = yield select(showAgentConsoleBulletinFormSelector);

  if (!isConsoleUser) {
    yield put(navigateTo(Route.Home, {}, { replace: true }));
  }
  else {
    if (isApprovedAgent || isAgentManager) {
      yield put(resetDomainData({ loadType: LoadType.GetBulletinsUsageOverview }));

      const getUsageOverviewOptions: LoadOptions<LoadType.GetBulletinsUsageOverview> = {
        loadType: LoadType.GetBulletinsUsageOverview,
        meta: {
          variables: {},
        },
      };

      yield fork(queryData, getUsageOverviewOptions);
    }
  }
}

export function* bulletinFormPageHandler() {
  yield call(bulletinFormWorker);
}
