import { MarketplaceType, DealType } from 'utils/entities';
import { PropertyType, RangeTuple } from 'components/filters/types';

export const priceLimitsByMarketplaceType: Record<MarketplaceType, Record<DealType, RangeTuple<number>>> = {
  [MarketplaceType.Residential]: {
    [DealType.Rent]: [ 500, 800000 ],
    [DealType.Buy]: [ 100000, 100000000 ],
  },
  [MarketplaceType.Commercial]: {
    [DealType.Rent]: [ 500, 10000000 ],
    [DealType.Buy]: [ 50000, 100000000 ],
  },
};

export const hideNumberOfEmployees = (val: PropertyType): boolean => {
  const buildingTypes = new Set([
    PropertyType.Office,
    PropertyType.SharedWorkSpace,
    PropertyType.Studio,
  ]);

  return !buildingTypes.has(val);
};

export const hideRoomsCount = (val: PropertyType, isCommercial: boolean): boolean => {
  const noRoomsTypes = new Set([
    ...(isCommercial ? [ PropertyType.Studio ] : []),
    PropertyType.AgricultureLand,
    PropertyType.Parking,
    PropertyType.Storeroom,
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.Hotel,
    PropertyType.Restaurant,
    PropertyType.IndustrialBuilding,
    PropertyType.SharedWorkSpace,
    PropertyType.ParkingLot,
    PropertyType.FarmLand,
    PropertyType.AgricultureFarm,
    PropertyType.ProfessionalFarm,
    PropertyType.BuyerTeam,
    PropertyType.IncomeProducingProperty,
  ]);

  return noRoomsTypes.has(val);
};

export const hideUnitFloor = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
    PropertyType.Hotel,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Building,
    PropertyType.Hotel,
    PropertyType.Restaurant,
    PropertyType.IndustrialBuilding,
    PropertyType.ParkingLot,
    PropertyType.IncomeProducingProperty,
    PropertyType.Basement,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

export const hideTotalFloors = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.Restaurant,
    PropertyType.ParkingLot,
    PropertyType.Basement,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};

export const hideBuildingDetails = (val: PropertyType, isCommercial: boolean): boolean => {
  const residentialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.AgricultureLand,
    PropertyType.Parking,
  ]);

  const commercialBuildingTypes = new Set([
    PropertyType.Land,
    PropertyType.ParkingLot,
    PropertyType.AgricultureLand,
    PropertyType.FarmLand,
  ]);

  return (isCommercial ? commercialBuildingTypes : residentialBuildingTypes).has(val);
};
