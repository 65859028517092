import {
  Amenity,
  AvailabilityType,
  DealType,
  IGeneralCondition,
  ListingPoiType,
  ResolutionPreference,
} from 'utils/entities';
import { PropertyType } from 'components/filters/types';

export const COMMON_MAX_VALUE = 999999;
export const COMMON_CHARGES_MAX_VALUE = 100000;
export const NUMBER_OF_EMPLOYEES_MAX_VALUE = 10000;
export const MONTHLY_TAXES_MAX_VALUE = 999999;
export const MONTHLY_MANAGEMENT_FEE_MIN_VALUE = 5;
export const MONTHLY_MANAGEMENT_FEE_MAX_VALUE = 99999;
export const AREA_MAX_VALUE = 999999;
export const ROOMS_MAX_VALUE = 15;

export const TWENTY_MB_IN_BYTES = 20971520;
export const EXCLUSIVITY_MAX_FILES_COUNT = 1;
export const MAX_UPLOAD_IMAGES = 20;

export const RESIDENTIAL_BUILDING_CLASSES = [
  PropertyType.Flat,
  PropertyType.PrivateHouse,
  PropertyType.GardenApartment,
  PropertyType.Cottage,
  PropertyType.TwoFamilyDwelling,
  PropertyType.Duplex,
  PropertyType.Land,
  PropertyType.PenthouseApp,
  PropertyType.RoofFlat,
  PropertyType.MiniPenthouse,
  PropertyType.ResidentialUnit,
  PropertyType.SerialCottage,
  PropertyType.Studio,
  PropertyType.Building,
  PropertyType.AgricultureFarm,
  PropertyType.Triplex,
  PropertyType.Basement,
  PropertyType.Storeroom,
  PropertyType.SplitFlat,
  PropertyType.AgricultureLand,
  PropertyType.VacationProperty,
  PropertyType.BuyerTeam,
  PropertyType.ProfessionalFarm,
  PropertyType.Parking,
  PropertyType.Other,
];

export const COMMERCIAL_BUILDING_CLASSES = [
  PropertyType.Office,
  PropertyType.Shop,
  PropertyType.SharedWorkSpace,
  PropertyType.Studio,
  PropertyType.Hall,
  PropertyType.Clinic,
  PropertyType.Restaurant,
  PropertyType.Hotel,
  PropertyType.Building,
  PropertyType.IndustrialBuilding,
  PropertyType.Storeroom,
  PropertyType.Basement,
  PropertyType.ParkingLot,
  PropertyType.Land,
  PropertyType.IncomeProducingProperty,
  PropertyType.AgricultureLand,
  PropertyType.FarmLand,
  PropertyType.Other,
];

export const RESIDENTIAL_GENERAL_CONDITION_OPTIONS = [
  IGeneralCondition.New,
  IGeneralCondition.AsNew,
  IGeneralCondition.Renovated,
  IGeneralCondition.Preserved,
  IGeneralCondition.ToRenovated,
];

export const COMMERCIAL_GENERAL_CONDITION_OPTIONS = [
  IGeneralCondition.Skin,
  IGeneralCondition.Finish,
  IGeneralCondition.FurnishedFinish,
  IGeneralCondition.FullFinish,
  IGeneralCondition.RequiresRenovation,
];

export const RESIDENTIAL_AMENITIES = [
  Amenity.Parking,
  Amenity.Elevator,
  Amenity.Storage,
  Amenity.SecureRoom,
  Amenity.AirConditioner,
  Amenity.Accessible,
  Amenity.Grating,
  Amenity.PandoorDoors,
  Amenity.Balcony,
  Amenity.Garden,
];

export const COMMERCIAL_AMENITIES = [
  Amenity.Doorman,
  Amenity.Storage,
  Amenity.AirConditioner,
  Amenity.Elevator,
  Amenity.OuterSpace,
  Amenity.SecureRoom,
  Amenity.ParkingEmployee,
  Amenity.ParkingBikes,
  Amenity.ParkingVisitors,
  Amenity.Accessible,
  Amenity.Kitchenette,
  Amenity.FullTimeAccess,
  Amenity.Alarm,
  Amenity.ConferenceRoom,
  Amenity.SubDivisible,
  Amenity.Reception,
  Amenity.ColdRoom,
  Amenity.LoadingRamp,
  Amenity.HighCeiling,
  Amenity.ActiveBusiness,
];

export const DESCRIPTION_MAX_LENGTH = 500;

export const BULLETIN_TYPES: ListingPoiType[] = [ 'bulletin', 'commercialBulletin' ];

export const AVAILABILITY_OPTIONS: AvailabilityType[] = [
  AvailabilityType.Immediate,
  AvailabilityType.Flexible,
  AvailabilityType.Date,
];

export const DEAL_TYPES: DealType[] = [ DealType.Buy, DealType.Rent ];

export const resolutionPrefOptions = [
  ResolutionPreference.Accurate,
  ResolutionPreference.Street,
  ResolutionPreference.Neighborhood,
];
