import React, { useMemo } from 'react';
import { ManageListingsErrorModal } from 'store/state/app';
import { useLocale } from 'locale';
import Modal from 'ds/components/modal';
import { Button } from 'ds/components/button';
import ErrorIcon from 'assets/svg/manage-bulletin-error.svg';
import { SpacingBlock } from 'components/spacing-block';
import { H3, Text } from 'ds/components/typography';
import { Link } from 'components/link';
import { Subtitle, Wrapper } from './styled';
import { ListingResponseType, UserCannotAddListingErrorField } from 'store/sagas/apiService/types';
import { NavigateToFn, Route, State as RouteState } from 'config/routes';
import { DraftState } from 'utils/uploadBulletin';
import { uploadBulletinDraft } from '../helpers';

interface CloseUploadErrorModalVariables {
  noRedirect?: boolean;
}

interface ErrorModalProps {
  data: ManageListingsErrorModal;
  navigateTo: NavigateToFn;
  onClose: (data?: CloseUploadErrorModalVariables) => void;
  userWithReducedListingLimit: boolean;
  initialEditValues: DraftState;
  isAgentConsoleUser: boolean;
  route: RouteState;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  data,
  onClose,
  navigateTo,
  userWithReducedListingLimit,
  initialEditValues,
  isAgentConsoleUser,
  route,
}) => {
  const { t } = useLocale();

  const initialUploadValues = useMemo(() => {
    switch (route.name) {
      case Route.UploadBulletin:
      case Route.UploadBulletinForm:
        return uploadBulletinDraft.get();
    }

    return null;
  }, [ route.name ]);

  if (!data) return null;

  const uploadBulletinType = route.name === Route.EditBulletin ? initialEditValues : initialUploadValues;
  const withReducedLimit = uploadBulletinType
    && uploadBulletinType.formValues
    && uploadBulletinType.formValues.type === 'bulletin'
    && userWithReducedListingLimit;

  const { type, field } = data;
  const showManagementLink = type === ListingResponseType.UserCannotAddListingError
    && field === UserCannotAddListingErrorField.UserId;
  const showUploadButton = type === ListingResponseType.GeneralListingError
    || type === ListingResponseType.InvalidInputError;

  const onUploadClick = () => {
    if (route.name === Route.UploadBulletin || route.name === Route.EditBulletin) {
      navigateTo(route.name, { id: route.params.id });
    }
    onClose({ noRedirect: true });
  };

  const onModalClose = () => onClose();

  return (
    <Modal
      isOpen
      onClose={onModalClose}
      mobileModalPositionBottom
    >
      <Wrapper>
        <SpacingBlock mBottom={4}>
          <ErrorIcon />
        </SpacingBlock>
        {isAgentConsoleUser ? (
          <>
            <H3 weight="bold">
              {t('bulletinForm.form.errorModal.title')}
            </H3>
            <Subtitle>
              {t('bulletinForm.form.errorModal.subtitle')}
            </Subtitle>
            <Button size="large" onClick={onModalClose}>
              {t('bulletinForm.form.errorModal.button')}
            </Button>
          </>
        ) : (
          <>
            <SpacingBlock mBottom={1}>
              <H3 weight="bold">
                {t('bulletinForm.wizard.errorModal.title', { type })}
              </H3>
            </SpacingBlock>
            <Text>
              {t('bulletinForm.wizard.errorModal.subtitle', { type, field, withReducedLimit })}
              {showManagementLink ? (
                <Link onClick={onModalClose} routeName={Route.ManageBulletins} routeOptions={{ replace: true }}>
                  {t('bulletinForm.wizard.errorModal.managePageLink')}
                </Link>
              ) : null}
              {showUploadButton ? (
                <SpacingBlock mTop={4}>
                  <Button size="large" onClick={onUploadClick}>
                    {t('bulletinForm.wizard.errorModal.uploadButton', { isEdit: route.name === Route.EditBulletin })}
                  </Button>
                </SpacingBlock>
              ) : null}
            </Text>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};
