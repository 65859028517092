import {
  makeNullSafeDomainGetter,
  insightsSelector,
  makeByIdsDictionarySelector,
  createIsLoadingSelector,
  dealsAgentsByIdsSelector,
  dealsOfficesByIdsSelector,
} from './common';
import { flow, groupBy, head, negate } from 'lodash';
import { createSelector } from 'reselect';
import {
  InsightDescriptor,
  IInsight,
  InsightType,
} from 'utils/entities';
import { ILSchoolGroupsSummary, ILSchool, SchoolInsightSummary } from 'store/state/selectors/insights/schools/types';
import { FeatureDescriptor } from 'utils/insightsFeatureTypes';
import { State } from 'store/state';
import { BuildingPermitSummary } from 'components/insight-summary-renderer/BuildingPermitSummary/types';

const insightsDataSelector = makeNullSafeDomainGetter(insightsSelector, 'docId2Insights', true);

export const metaInsightsCurrentDocIdSelector = flow(insightsSelector, (domain) => domain.meta ? domain.meta.variables.docId : null);

const EMPTY: IInsight[] = [];
const baseInsightsListSelector = flow(insightsDataSelector, (docId2Insights) => (
  docId2Insights ? docId2Insights.insights : EMPTY
));

type Enhancer = (list: IInsight[]) => IInsight[];


// temporary solution, we'll need to find a way to import translate into functions
const getILSchoolPreview = (isOrthodox: boolean) => `בתי ספר יסודיים (${isOrthodox ? 'ממלכתי דתי' : 'ממלכתי'})`;

const schoolsIlEnhancer: Enhancer = (list) => {
  const ilSchool = list.find(({ type }) => type === InsightDescriptor.ILSchools);
  if (!ilSchool) return list;

  const summary = ilSchool.summary.nonText.data as ILSchoolGroupsSummary;
  const enhanced = Object.values(summary).flatMap((s) => s.schools).map((school): IInsight => {
    return {
      ...ilSchool,
      preview: getILSchoolPreview(school.orthodox),
      id: school.schoolName,
      type: InsightDescriptor.ILSyntheticSchools,
      summary: {
        nonText: { data: school },
        text: null,
      },
      geoData: ilSchool.geoData ? {
        ...ilSchool.geoData,
        features: ilSchool.geoData && ilSchool.geoData.features ? ilSchool.geoData.features.filter((f) => (
          f.properties.featureType === FeatureDescriptor.TargetPoint
          || f.properties.geoId === school.geoId
        )) : null,
      } : null,
    };
  });

  return [
    ...enhanced,
    ...list,
  ];
};

const takeSchool = (data: IInsight[]) => {
  const secularSchools = data
    .filter(i => i.type === InsightDescriptor.ILSyntheticSchools && !(i.summary.nonText.data as ILSchool).orthodox);
  return head(secularSchools);
};

export const insightsListSelector = createSelector([
  createSelector([ baseInsightsListSelector ], schoolsIlEnhancer),
], (list): IInsight[] => {
  return list;
});

type InsightsByType = Partial<Record<InsightDescriptor, IInsight[]>>;
type InsightsByTypeSelector = (state: State) => InsightsByType;

const insightsByTypeSelector = createSelector(insightsListSelector, (list): InsightsByType => groupBy(list, (i) => i.type));

export const makeInsightsByTypeSelector = (type: InsightDescriptor) => flow(insightsByTypeSelector, (hashmap) => hashmap[type]);

export const insightsByIdsSelector = makeByIdsDictionarySelector(insightsListSelector);

export const tradeoffsListSelector = createSelector([ insightsDataSelector ], (docId2Insights) => {
  if (!docId2Insights) return EMPTY;

  const tradeoffs = docId2Insights.insights.filter((i) => i.tradeoff);

  const ilSchoolInsight = tradeoffs.find(tradeoff => tradeoff.type === InsightDescriptor.ILSchools);

  if (ilSchoolInsight) {
    const bestSchoolInsight: IInsight = {
      // some super engineering comes here to select the best school for the tradeoff
      ...takeSchool(schoolsIlEnhancer(docId2Insights.insights)),
      tradeoff: ilSchoolInsight.tradeoff,
    };

    return [
      bestSchoolInsight,
      ...tradeoffs.filter(tradeoff => tradeoff.type !== InsightDescriptor.ILSchools),
    ];
  }
  return docId2Insights.insights.filter((insight) => insight.tradeoff);
});

export const hasTradeoffsListSelector = flow(tradeoffsListSelector, (tradeoffs) => tradeoffs.length > 0);
export const schoolTradeoffsSelector = flow(tradeoffsListSelector, (tradeoffs) => {
  const [ school ] = tradeoffs.filter(el => el.category === InsightType.Education);
  return school && school.summary.nonText && school.summary.nonText.data || null;
});

export const makeAreaHighlightsSelector = <T extends {}>(mapFn: (insight: IInsight) => T, filterFn: (insight: IInsight) => boolean) => createSelector([
  tradeoffsListSelector,
], (tradeoffs): T[] => (
  tradeoffs.filter(filterFn).map(mapFn)
));

const makeTradeoffSelector = <T extends {}>(mapFn: (insight: IInsight) => T, filterFn: (insight: IInsight) => boolean) => createSelector([
  tradeoffsListSelector,
], (tradeoffs): T[] => (
  tradeoffs.filter(filterFn).map(mapFn).slice(0, 4)
));

const categoriesWhitelist = new Set<InsightType>(Object.values(InsightType));
export const baseFilter = (item: IInsight) => item.tradeoff && categoriesWhitelist.has(item.category);

const goodFilter = (item: IInsight) => baseFilter(item) && item.tradeoff.goodTradeoff;
const badFilter = (item: IInsight) => baseFilter(item) && !item.tradeoff.goodTradeoff;

const areaHighlightsFilter = (item: IInsight) => baseFilter(item) && item.areaInsight;
const aboutToComeFilter = (item: IInsight) => areaHighlightsFilter(item) && item.tradeoff.futureTradeoff;
const goodToKnowFilter = (item: IInsight) => areaHighlightsFilter(item) && !item.tradeoff.futureTradeoff;

export const createAreaHighlightsGoodToKnowListSelector = <T extends {}>(areaHighlightsMap: (insight: IInsight) => T) => makeAreaHighlightsSelector(areaHighlightsMap, goodToKnowFilter);
export const createAreaHighlightsAboutToComeListSelector = <T extends {}>(areaHighlightsMap: (insight: IInsight) => T) => makeAreaHighlightsSelector(areaHighlightsMap, aboutToComeFilter);
export const createGoodTradeoffSelector = <T extends {}>(tradeoffMapFn: (insight: IInsight) => T) => makeTradeoffSelector(tradeoffMapFn, goodFilter);
export const createOtherTradeoffSelector = <T extends {}>(tradeoffMapFn: (insight: IInsight) => T) => makeTradeoffSelector(tradeoffMapFn, badFilter);

export const areaInsightsGoodToKnowListSelector = createAreaHighlightsGoodToKnowListSelector(i => i);
export const areaInsightsAboutToComeListSelector = createAreaHighlightsAboutToComeListSelector(i => i);
export const goodTradeoffSelector = createGoodTradeoffSelector(i => i);
export const otherTradeoffSelector = createOtherTradeoffSelector(i => i);

export const areaHighlightsIdsSelector = createSelector([
  tradeoffsListSelector,
], (tradeoffsList) => (
  tradeoffsList.filter(areaHighlightsFilter).map(({ id }) => id)
));

export const tradeoffIdsSelector = createSelector([
  goodTradeoffSelector,
  otherTradeoffSelector,
], (good, other) => ([
  ...good.map(({ id }) => id),
  ...other.map(({ id }) => id),
]));

export const impactfulTradeoffsSelector = createSelector([ tradeoffsListSelector ], (insights) => insights.filter(i => !i.areaInsight && i.tradeoff && i.tradeoff.impactful));

export const nonVerifiedImpactfulTradeoffSelector = createSelector([ impactfulTradeoffsSelector ],
  insights => insights.filter(insight => !insight.tradeoff.verified)
);

export const buildingPermitSelector = flow(makeInsightsByTypeSelector(InsightDescriptor.BuildingPermitRequests), head);

export const buildingPermitRequestsSelector = createSelector(
  buildingPermitSelector,
  (insight) => {
    const insightData = insight && insight.summary && insight.summary.nonText && insight.summary.nonText.data;
    return insightData
      ? (insightData as BuildingPermitSummary).requests
      : [];
  }
);

export const hasBuildingPermitRequestsSelector = flow(buildingPermitRequestsSelector, requests => Boolean(requests.length));

export const unitExpandSelector = flow(buildingPermitSelector, (insight) => {
  const summaryData = insight && (insight.summary.nonText.data as BuildingPermitSummary);

  if (!summaryData) {
    return null;
  }
  return summaryData.unitExpand;
});

const isSchoolsOverviewInsight = (insight: IInsight) => Boolean((insight.summary.nonText.data as SchoolInsightSummary).schoolsSummaryInsight);
const isSchoolsDetailsInsight = negate(isSchoolsOverviewInsight);
const getSchools = (dict: InsightsByType) => dict[InsightDescriptor.Schools];

export const makeSchoolsOverviewSelector = (byTypeSelector: InsightsByTypeSelector) => createSelector([
  flow(byTypeSelector, getSchools),
], (schools = []) => schools.find(isSchoolsOverviewInsight));

export const makeSchoolsDetailsListSelector = (byTypeSelector: InsightsByTypeSelector) => createSelector([
  flow(byTypeSelector, getSchools),
], (schools = []) => schools.filter(isSchoolsDetailsInsight));

export const schoolsOverviewSelector = makeSchoolsOverviewSelector(insightsByTypeSelector);
export const schoolsDetailsListSelector = makeSchoolsDetailsListSelector(insightsByTypeSelector);

export const insightsLoadingSelector = createIsLoadingSelector(insightsSelector);

export const hasGoodToKnowInsightsSelector = createSelector([
  areaInsightsGoodToKnowListSelector,
  areaInsightsAboutToComeListSelector,
], (goodToKnowList, aboutToComeList) => !!(goodToKnowList.length || aboutToComeList.length));

export const safeDealsAgentsByIdsSelector = makeNullSafeDomainGetter(dealsAgentsByIdsSelector, 'getPublicRealEstateAgentsByIds');
export const safeDealsOfficesByIdsSelector = makeNullSafeDomainGetter(dealsOfficesByIdsSelector, 'getPublicRealEstateOfficesByIds');
