import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useIntersectionObserver } from 'hooks/useIntersectionObserver';

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
  :active, :visited, :focus {
    outline: none;
    text-decoration: none;
  }
`;

export interface BannerLinkProps {
  href: string;
  onClick?: () => void;
  onExpose?: () => void;
}

export const BannerLink: React.FC<BannerLinkProps> = props => {
  const {
    children,
    href,
    onClick,
    onExpose,
  } = props;
  const linkRef = useRef<HTMLAnchorElement>(null);

  useIntersectionObserver((entries) => {
    const isIntersecting = entries && entries[0] && entries[0].isIntersecting;
    if (onExpose && isIntersecting) {
      onExpose();
    }
  }, [ linkRef ], {
    threshold: [ 1 ],
  });

  const handleAnchorClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Link
      ref={linkRef}
      target="_blank"
      rel="noopener noreferrer nofollow"
      href={href}
      onClick={handleAnchorClick}
    >
      {children}
    </Link>
  );
};
