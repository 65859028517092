import { call, getContext, takeEvery, fork } from 'redux-saga/effects';
import { LoadOptions, LoadType } from 'store/sagas/apiService/types';
import { queryData } from 'store/sagas/apiService';
import { SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID } from 'store/state/app/types';
import { setManageListingsFilterAgentUserId } from 'store/state/app/actions';


export function* loadListingsWorker() {
  try {
    const loadOpts: LoadOptions<LoadType.UserAllListings> = {
      loadType: LoadType.UserAllListings,
      meta: {
        variables: {
          includeResidential: true,
          timestamp: Date.now(),
        },
      },
    };

    yield call(queryData, loadOpts);
  }
  catch (error) {
    const logger = yield getContext('logger');
    logger.error('Search docId not resolved, redirecting Home.', error);
  }
}

function* changeFilterWorker(action: ReturnType<typeof setManageListingsFilterAgentUserId>) {
  const agentUserId = action.payload;

  try {
    const loadOpts: LoadOptions<LoadType.UserAllListings> = {
      loadType: LoadType.UserAllListings,
      meta: {
        variables: {
          includeResidential: true,
          ...(agentUserId ? { usersIds: [ agentUserId ] } : null),
        },
      },
    };

    yield call(queryData, loadOpts);
  }
  catch (error) {
    const logger = yield getContext('logger');
    logger.error('User listings not resolved', error);
  }
}

export function* manageBulletinsUserHandler() {
  yield call(loadListingsWorker);
  yield takeEvery(SET_MANAGE_LISTINGS_FILTER_AGENT_USER_ID, changeFilterWorker);
}

export function* manageBulletinsPageHandler() {
  yield fork(manageBulletinsUserHandler);
}
