import { DraftState } from 'utils/uploadBulletin';

export enum UploadBulletinStep {
  AdditionalInfo = 'additionalInfo',
  Address = 'address',
  Authentication = 'auth',
  BulletinType = 'bulletinType',
  Contacts = 'contacts',
  DealType = 'dealType',
  MainInfo = 'mainInfo',
  Preview = 'preview',
  UploadImage = 'uploadImage',
}


export interface UploadBulletinDraft {
  clear(): void;
  get(): DraftState;
  set(val: DraftState): void;
}

export enum IsExclusive {
  Yes = 'yes',
  No = 'no',
}
