import { createStandardAction } from 'typesafe-actions';
import * as Types from './types';
import { IEditBulletinInput, INewBulletinInput, PoiType, BulletinStatusType, PoiId } from 'utils/entities';
import { ISubmitPopupData } from 'components/upload-bulletin/report-error-popup/ReportUploadBulletinPopup';

export interface NewListingParams {
  listing: INewBulletinInput;
}

export interface EditListingParams {
  listing: IEditBulletinInput;
}

export interface EditListingPromoteStatusParams {
  id: PoiId;
  promotedStatus: boolean;
  type: PoiType & ('bulletin' | 'project' | 'commercialBulletin' | 'commercialProject');
}

export interface EditListingStatusParams {
  type: PoiType & ('bulletin' | 'project' | 'commercialBulletin' | 'commercialProject');
  status: BulletinStatusType;
  id: PoiId;
}

export interface EditListingAssignedAgentParams {
  type: PoiType & ('bulletin' | 'project' | 'commercialBulletin' | 'commercialProject');
  id: PoiId;
  agentId: string;
  userId: string;
}

export const reportAddressErrorAction = createStandardAction(Types.REPORTING_ERROR_ADDRESS)<ISubmitPopupData>();
export const newListingAction = createStandardAction(Types.NEW_LISTING)<NewListingParams>();
export const editListingAction = createStandardAction(Types.EDIT_LISTING)<EditListingParams>();
export const editListingStatusAction = createStandardAction(Types.EDIT_LISTING_STATUS)<EditListingStatusParams>();
export const editListingPromoteStatusAction = createStandardAction(Types.EDIT_LISTING_PROMOTE_STATUS)<EditListingPromoteStatusParams>();
export const validateUploadPhoneAction = createStandardAction(Types.VALIDATE_PHONE)<string>();
export const listingsTableInlineUpdateSuccess = createStandardAction(Types.LISTINGS_TABLE_INLINE_UPDATE_SUCCESS)();
export const editListingAssignedAgentAction = createStandardAction(Types.EDIT_LISTING_ASSIGNED_AGENT)<EditListingAssignedAgentParams>();
